import { useEffect } from 'react';
import { throttle } from 'lodash';

const setVhCustomProperty = (vh: number) => {
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const resizeHandler = throttle(() => {
  // We execute the same script as before
  const vh = window.innerHeight * 0.01;
  setVhCustomProperty(vh);
}, 500);

/**
 * @link https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * @desc вычисляет размер 1vh юнита, нужно для сафари,
 * */
export const useCalcVhUnit = (): void => {
  useEffect(() => {
    setVhCustomProperty(window.innerHeight * 0.01);
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
};

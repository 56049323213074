import React, { useState } from 'react';
import SwiperCore from 'swiper';
import cc from 'classcat';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { BuildingEntity, Complex } from 'services';
import { observer } from 'mobx-react';
import { findById } from 'utils/fp';
import './queuesSwiper.css';
import styles from './styles.module.css';
import { buildingGridStore } from '../../../../../LayoutPage/stores/buildingGridStore';
import arrowLeft from '../../../../../../assets/darkArrowLeft.svg';
import arrowRight from '../../../../../../assets/darkArrowRight.svg';
import { getBuildingsTabs } from '../../../../../LayoutPage/utils';
import { QueueBuildingsSlideItem } from '../QueueBuildingsSlideItem';
import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';

type Props = {
  className?: string;
} & Pick<Complex, 'buildings'>;

export const QueueBuildingsFilterRender = ({ buildings, className }: Props) => {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeSlide, setActiveSlide] = useState({ index: 0, id: 0 });

  const handleNextSlideClick = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  const handlePrevSlideClick = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const isShowQueueLabel =
    (buildings: BuildingEntity[] | undefined) =>
    (building: BuildingEntity | undefined) =>
    (index: number): boolean =>
      buildings && building
        ? building.phase_title !== buildings[index - 1]?.phase_title
        : false;

  const slides = [
    <SwiperSlide
      key="first"
      onClick={() => {
        setActiveSlide({ index: 0, id: 0 });
      }}
    >
      <QueueBuildingsSlideItem
        buildingData={{ helperText: '', label: 'Все корпуса', id: 0 }}
        isActive={activeSlide.index === 0}
        isShowQueueLabel={false}
      />
    </SwiperSlide>,
    ...getBuildingsTabs(buildings).map(
      ({ data, helperText, label, id }, index) => (
        <SwiperSlide
          key={data?.id}
          onClick={() => {
            setActiveSlide({ index: index + 1, id });
            if (buildings && buildings[index]) {
              buildingGridStore.setGridBord(
                buildings.find(
                  findById(id.toString()),
                ) as unknown as BuildingEntity,
              );
            }
          }}
        >
          {buildings && (
            <QueueBuildingsSlideItem
              buildingData={{ data, helperText, label, id }}
              isActive={activeSlide.index === index + 1}
              isShowQueueLabel={isShowQueueLabel(buildings)(data)(index)}
            />
          )}
        </SwiperSlide>
      ),
    ),
  ];

  return (
    <div className={cc([styles.wrapper, className])}>
      <div className={styles.navigateButtonGroup}>
        <ButtonBase
          indent={{
            margin: [0, 10],
          }}
          icon={
            <img
              className={styles.navigateButtonIcon}
              src={arrowLeft}
              alt="left"
            />
          }
          onClick={handlePrevSlideClick}
        />
        <ButtonBase
          icon={
            <img
              className={styles.navigateButtonIcon}
              src={arrowRight}
              alt="left"
            />
          }
          onClick={handleNextSlideClick}
        />
      </div>

      <Swiper
        className={styles.queuesSwiperWrapper}
        id="queuesSwiper"
        spaceBetween={10}
        slidesPerView="auto"
        onSwiper={(swiperInstance: SwiperClass) => setSwiper(swiperInstance)}
      >
        {slides}
      </Swiper>
    </div>
  );
};

export const QueueBuildingsFilter = observer(QueueBuildingsFilterRender);

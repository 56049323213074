import React, { ReactNode } from 'react';
import cc from 'classcat';
import { TypoSize } from 'types/style';
import styles from './styles.module.css';
import { IndentsProps, getIndentClasses } from '../Indents';

type Props = {
  size?: TypoSize;
  lineHeight?: TypoSize;
  weight?: 'bold' | 'normal' | '700';
  display?: 'block' | 'inlineBlock' | 'inline';
  align?: 'left' | 'center' | 'right';
  whiteSpace?: 'nowrap';
  wordBreak?: 'break-all' | 'break-word';
  textTransform?: 'uppercase';
  tagName?: string;
  children?: ReactNode;
  className?: string;
  [key: string]: any;
} & IndentsProps;

export const Text = ({
  children,
  tagName,
  size,
  lineHeight,
  weight,
  display,
  align,
  textTransform,
  className,
  whiteSpace,
  wordBreak,
  indent,
  ...rest
}: Props) =>
  React.createElement(
    tagName || 'span',
    {
      ...rest,
      className: cc([
        styles[`size${size || 'M'}`],
        lineHeight ? styles[`lineHeight${lineHeight}`] : '',
        styles[align || 'left'],
        display && styles[display],
        whiteSpace && styles[`whiteSpace_${whiteSpace}`],
        wordBreak && styles[`wordBreak_${wordBreak}`],
        weight && styles[`weight${weight}`],
        textTransform && styles[textTransform],
        className,
        getIndentClasses(indent),
      ]),
    },
    children,
  );

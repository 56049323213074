import React from 'react';
import circleWindowLook from 'assets/circle-window-look.svg';
import { get, reverse } from 'lodash/fp';
import L, { LatLngTuple } from 'leaflet';
import { Feature } from 'services';
import { MarkerRotate } from '../MarkerRotate';

const windowIcon = new L.Icon({
  iconUrl: circleWindowLook,
  iconRetinaUrl: circleWindowLook,
  iconSize: [55, 55],
  iconAnchor: [27.5, 27.5],
});

type Props = {
  windows: Feature[] | undefined;
};

/**
 * @desc рендер окон квартиры на корпусе в ЖК
 * */
export const WindowMarkers = ({ windows }: Props) => (
  <>
    {(windows || []).map((coordinates: Feature) => (
      <MarkerRotate
        key={coordinates.toString()}
        icon={windowIcon}
        rotationOrigin="center"
        rotationAngle={get(['properties', 'rotate'], coordinates)}
        position={
          reverse(get(['geometry', 'coordinates'], coordinates)) as LatLngTuple
        }
      />
    ))}
  </>
);

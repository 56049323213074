import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { authEmailService, Params } from 'services';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

/**
 * @desc проверка кода из письма, сюда переход по ссылке из письма после регистрации
 * */
export const AuthEmailConfirmPage = () => {
  const { push } = useHistory();
  const { code } = useParams<Pick<Params, 'code'>>();

  const confirmEmail = useCallback(
    async (code: string) => {
      try {
        await authEmailService.confirm({
          code,
        });
        const redirectURL = authRoutesStore.parentRoute;
        push(redirectURL);
      } catch (e) {
        const redirectURL = authRoutesStore.getRouteWithParent(
          'linkIsOutOfDatePage',
        );
        push(redirectURL);
      }
    },
    [code],
  );

  useEffect(() => {
    if (code) {
      void confirmEmail(code);
    } else {
      const redirectURL = authRoutesStore.parentRoute;
      push(redirectURL);
    }
  }, []);

  return null;
};

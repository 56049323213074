import { addToOffersStore } from './AddToOffersStore';
import { RequestApiStore } from '../request/RequestApiStore';

export class OffersApartmentEdit {
  removeRequestApiState: RequestApiStore;

  constructor() {
    this.removeRequestApiState = new RequestApiStore();
  }

  removeApartment = async (
    apartmentId?: number | string | null,
    offerId?: number | string,
  ) => {
    if (!apartmentId || !offerId) {
      // eslint-disable-next-line no-console
      console.error(
        `[useRemoveApartmentFromOffer]: apartmentId or offerId is not defined`,
      );
      return;
    }
    this.removeRequestApiState.setLoading(true);

    await addToOffersStore.loadActiveOffers({
      apartmentId: Number(apartmentId),
    });

    const checkedOffers = addToOffersStore.toggleCheckedOffers(Number(offerId));

    const [error] = await addToOffersStore.saveOffers(
      {
        apartmentId: Number(apartmentId),
      },
      {
        offerIds: checkedOffers,
      },
    );

    if (error) {
      this.removeRequestApiState.setError(true);
    }

    addToOffersStore.clear();
    this.removeRequestApiState.setLoading(true);
  };
}

export const offersApartmentEdit = new OffersApartmentEdit();

import React from 'react';
import arrowDownIcon from 'assets/arrowDownIcon.svg';

import styles from './styles.module.css';

type Props = {
  onClick: () => void;
  isVisible: boolean;
  isVisibleText?: string;
  notAllVisibleText?: string;
};

export const ShowAllButton = ({
  onClick,
  isVisible,
  isVisibleText = 'Скрыть',
  notAllVisibleText = 'Показать все',
}: Props) => (
  <button className={styles.separator} onClick={onClick} type="button">
    {isVisible ? isVisibleText : notAllVisibleText}
    {!isVisible ? (
      <img src={arrowDownIcon} alt="arrowUp" className={styles.iconUp} />
    ) : (
      <img src={arrowDownIcon} alt="arrowDown" className={styles.iconDown} />
    )}
  </button>
);

import { action, makeObservable, observable } from 'mobx';
import { has } from 'lodash/fp';
import {
  CityInfo,
  Dictionary,
  DictionaryItem,
  DictionaryKeys,
  District,
  infoService,
} from 'services';
import { RequestApiStore } from '../request/RequestApiStore';

export class InfoStore {
  dictionaries: Partial<Record<DictionaryKeys, Dictionary>> = {};

  city: CityInfo = {};

  requestApi: RequestApiStore;

  districts: District[] | null = null;

  constructor() {
    this.requestApi = new RequestApiStore();
    (
      [
        'offer_status',
        'residential_complex_type',
        'residential_complex_sales_status',
        'residential_complex_class',
        'residential_complex_house_type',
        'residential_complex_material',
        'residential_complex_facade',
        'residential_complex_finishing',
        'residential_complex_contract_type',
        'residential_complex_payment_type',
        'residential_complex_territory',
        'residential_complex_parking',
        'residential_complex_energy_class',
        'residential_complex_gas',
        'building_garbage',
        'residential_complex_badges',
        'residential_complex_advantages',
        'residential_complex_album_type',
        'rooms',
        'districtIds',
        'apartment_window_type',
        'apartment_type',
        'apartment_status',
        'apartment_type_room',
        'empty',
      ] as DictionaryKeys[]
    ).forEach((key) => {
      this.dictionaries[key] = {
        field: null,
        id: 0,
        items: [],
        title: '',
      };
    });

    makeObservable(this, {
      dictionaries: observable,
      city: observable,
      addInfo: action.bound,
      addCity: action.bound,
      loadInfo: action.bound,
    });
  }

  addCity(city: CityInfo) {
    this.city = city;
  }

  addDistricts(districts: District[]) {
    this.districts = districts;
  }

  addInfo(info: Record<DictionaryKeys, Dictionary>) {
    Object.entries(info).forEach(([key, value]) => {
      this.dictionaries[key as DictionaryKeys] = value;
    });
  }

  getItemsStartingAt(item: DictionaryItem[], rooms: number): DictionaryItem[] {
    return item.filter((_el, index) => index >= rooms);
  }

  getItemsBeforeIndex(item: DictionaryItem[], rooms: number): DictionaryItem[] {
    return item.filter((_el, index) => index < rooms);
  }

  getInfoBy = (key: DictionaryKeys): Dictionary | null =>
    this.dictionaries[key] || null;

  hasInfo = (key: DictionaryKeys): boolean => has(key, this.dictionaries);

  async loadInfo(): Promise<void> {
    try {
      this.requestApi.setLoading(true);
      const [, result] = await infoService.getInfo();

      const { city, catalogs } = result?.data;

      if (city && city.id) {
        try {
          const districts = await infoService.getDistricts(city.id);
          this.addDistricts(districts);
        } catch (e) {
          this.requestApi.setLoading(false);
          this.requestApi.setError(true);
        }
      }

      this.addCity(city);
      this.addInfo(catalogs);
      this.requestApi.setLoading(false);
    } catch (e) {
      this.requestApi.setLoading(false);
      this.requestApi.setError(true);
    }
  }
}

export const infoStore = new InfoStore();

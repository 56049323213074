import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router';
import { appRoutesService } from 'services';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { authRoutesStore } from '../../stores/user/AuthStore/AuthRoutes/AuthRoutes';

export const guardedRoute = (Component: any, to?: string) =>
  observer((props: any) =>
    rootProfileStore.authStore.isAuth ? (
      <Component />
    ) : (
      <Redirect to={to || appRoutesService.getRoute('authPhoneCodePage')} />
    ),
  );

export const guardedModalRoute = (Component: any, to?: string) =>
  observer(({ location }: any) => {
    useEffect(() => {
      authRoutesStore.setSuccessAuthRoute(location);
    }, [location]);

    return rootProfileStore.authStore.isAuth ? (
      <Component />
    ) : (
      <Redirect to={to || appRoutesService.getRoute('authPhoneCodePage')} />
    );
  });

import React from 'react';
import { Td, Tr } from '../../../../../../../components/atoms';
import { Skeleton } from '../../../../../../../components/atoms/Skeleton';

export const DesktopSkeletonRow = () => (
  <Tr>
    {Array.from(
      {
        length: 10,
      },
      (_, index) => (
        <Td key={index}>
          <Skeleton
            width="90%"
            height={16}
            animation="wave"
            highlightColor="rgba(35, 46, 73, 0.05)"
          />
        </Td>
      ),
    )}
  </Tr>
);

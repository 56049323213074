import { offerItemStore } from 'stores';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { useIsEditOffer } from '../useIsEditOffer';

export const useGetOfferViewFieldStatus = () => {
  const { isEditOffer } = useIsEditOffer({
    authStore: rootProfileStore.authStore,
  });

  const isShowComplex = isEditOffer || offerItemStore.includeView('complex');
  const isShowMap = isEditOffer || offerItemStore.includeView('map');
  const isShowPrice = isEditOffer || offerItemStore.includeView('price');
  const isShowDeveloper =
    isEditOffer || offerItemStore.includeView('developer');

  return {
    isShowComplex,
    isShowMap,
    isShowPrice,
    isShowDeveloper,
  };
};

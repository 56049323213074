/**
 * @desc если хотябы один элемент списка "elements" содержится в "targetString" вернет true
 * */
export const includeIsAtLeastOne =
  (elements: string[]) =>
  (targetString: string): boolean => {
    for (let i = 0; i < elements.length; i += 1) {
      const element = elements[i];
      if (targetString.toLowerCase().includes(element.toLowerCase())) {
        return true;
      }
    }
    return false;
  };

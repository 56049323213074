import React from 'react';
import { observer } from 'mobx-react';
import { Router, useHistory } from 'react-router';
import {
  FacebookShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import eyeDark from 'assets/eyeDark.svg';

import { Icon } from 'components/atoms/Icon';
import { useModal } from 'react-modal-hook';
import { useGetResponsiveModalComponent } from 'hooks';
import { BaseLink, BasePopup } from 'components/atoms';
import { SocialButton } from 'components/atoms/SocialButton';
import { useMediaQuery } from 'beautiful-react-hooks';
import { CopyLinkForm } from 'components/containers/CopyLinkForm';
import { VisibilitySettingForm } from '../VisibilitySettingForm';
import { offerItemStore } from '../../../../stores';
import styles from './styles.module.css';
import { getLinkToOfferForClient } from '../../utils';

export const ShareOfferLinkPopupRender = () => {
  const { offer } = offerItemStore;
  const isLarge = useMediaQuery('(min-width: 768px)');
  const shareLink = getLinkToOfferForClient(offer?.hash || '');
  return (
    <div className={styles.shareOfferLinkPopup}>
      <div className={styles.shareOfferLinkPopupBody}>
        <h3 className={styles.shareOfferLinkPopupTitle}>Настройка видимости</h3>
        <VisibilitySettingForm />
        <CopyLinkForm shareLink={shareLink} />
      </div>
      <div className={styles.shareOfferLinkPopupFooter}>
        <div className={styles.shareOfferLinkPopupSocialButtonGroup}>
          <WhatsappShareButton url={shareLink}>
            <SocialButton type="whatsApp" />
          </WhatsappShareButton>
          <TelegramShareButton url={shareLink}>
            <SocialButton type="tg" />
          </TelegramShareButton>
          <VKShareButton url={shareLink}>
            <SocialButton type="vk" />
          </VKShareButton>
          <FacebookShareButton url={shareLink}>
            <SocialButton type="fb" />
          </FacebookShareButton>
        </div>
        <BaseLink
          path="offersViewClient"
          target="_blank"
          searchParams={{
            isEdit: false,
          }}
          params={{
            /** @todo: использовать константу */
            offerTabs: 'list',
            hash: offer?.hash || '',
          }}
          icon={<Icon size={16} src={eyeDark} />}
        >
          {isLarge && <>Посмотреть глазами клиента</>}
        </BaseLink>
      </div>
    </div>
  );
};

export const ShareOfferLinkPopup = observer(ShareOfferLinkPopupRender);

export const useShareOfferLinkPopup = () => {
  const history = useHistory();
  const { PopupWrapper } = useGetResponsiveModalComponent({
    mobileHeaderChildrenTitle: 'Настройка видимости',
    DesktopModalWrapper: (props) => <BasePopup {...props} isOpen size="MD" />,
  });

  const [showShareOfferLinkPopup, hideShareOfferLinkPopup] = useModal(
    () => (
      <PopupWrapper isOpen onClose={hideShareOfferLinkPopup}>
        <Router history={history}>
          <ShareOfferLinkPopup />
        </Router>
      </PopupWrapper>
    ),
    [PopupWrapper],
  );

  return {
    showShareOfferLinkPopup,
    hideShareOfferLinkPopup,
  };
};

import React, { useMemo } from 'react';
import { flow, get } from 'lodash/fp';
import { observer } from 'mobx-react';
import {
  Text,
  DictionaryView,
  NumberFormat,
  DictionaryBadge,
  PlaceholderImage,
} from 'components/atoms';
import {
  ApartmentDto,
  DictionaryKeys,
  getAllApartmentPlans,
  getApartmentPreview,
} from 'services';
import { MayBe } from 'types';
import { FavoritesAddButton } from 'components/containers/FavoritesAddButton';
import { OffersStatusAndAddButton } from 'components/containers/OffersStatusAndAddButton';
import { toJS } from 'mobx';
import { ApartmentPlan } from '../ApartmentPlan';
import { ApartmentSquareInfo } from '../ApartmentSquareInfo';
import styles from './styles.module.css';
import { ShowApartmentLayout } from '../ShowApartmentLayout';

type Props = {
  apartment?: ApartmentDto;
};

export const HeaderRenderer = ({ apartment }: Props) => {
  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('apartment_type_room'),
      )(apartment),
    [apartment],
  );
  const apartment_status = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const apartmentPreview: MayBe<string> = useMemo(
    () => getApartmentPreview(apartment),
    [apartment],
  );
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  return (
    <header>
      <div className={styles.topWrapper}>
        <div className={styles.logo}>
          {apartmentPreview && (
            <img
              className={styles.logoImage}
              src={apartmentPreview}
              alt="apartment preview"
            />
          )}

          {!apartmentPreview && apartmentPlans.length > 0 && (
            <ApartmentPlan apartment={apartment} />
            // <ShowApartmentLayout hideZoomControl apartment={apartment} />
          )}
          {!apartmentPreview && apartmentPlans.length === 0 && (
            <PlaceholderImage type="FLOOR_PLAN" />
          )}
        </div>

        <div className={styles.topRight}>
          <div className={styles.infoWrapper}>
            <div>
              <DictionaryBadge
                badgeInner
                dictKey="apartment_status"
                dictItemId={apartment_status}
              />

              <Text
                align="left"
                lineHeight="2XL"
                size="S"
                display="block"
                weight="bold"
              >
                <DictionaryView
                  dictKey="apartment_type_room"
                  dictItemId={apartmentTypeRoomId}
                />
              </Text>
            </div>

            <div>
              <Text
                className={styles.price_m2}
                align="right"
                lineHeight="XL"
                size="XS"
                display="block"
                weight="bold"
              >
                <NumberFormat value={apartment?.price_m2} format="rub_meter2" />
              </Text>
              <Text align="right" size="L" display="block" weight="bold">
                <NumberFormat value={apartment?.price} />
              </Text>
            </div>
          </div>

          {apartment?.id && (
            <div className={styles.actionButtonsWrapper}>
              <OffersStatusAndAddButton apartmentId={apartment.id} />
              <FavoritesAddButton apartmentId={apartment.id} />
            </div>
          )}
        </div>
      </div>

      <div className={styles.squareListWrapper}>
        <ApartmentSquareInfo apartment={apartment} />
      </div>
    </header>
  );
};

export const Header = observer(HeaderRenderer);

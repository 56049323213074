import React, { useCallback } from 'react';
import { BuildingsStore, ComplexFilterStore, LeafletStoreClass } from 'stores';

type Props = {
  buildingsStore: BuildingsStore;
  leafletStore: LeafletStoreClass;
  complexFilterStore: ComplexFilterStore;
};

export const useComplexListOnIncrement = ({
  buildingsStore,
  leafletStore,
  complexFilterStore,
}: Props) =>
  useCallback(
    (ev: React.SyntheticEvent<HTMLDivElement>) => {
      if (buildingsStore.requestApi.isLoading) {
        return;
      }
      const target = ev.target as HTMLDivElement;
      const isBottom =
        target.scrollHeight - target.scrollTop - 60 < target.clientHeight;

      if (!buildingsStore.hasMore && isBottom) {
        const page = (buildingsStore.pageInfo?.pageInfo?.page || 1) + 1;
        if (
          buildingsStore?.complexes &&
          buildingsStore?.complexes?.totalPages &&
          page > buildingsStore?.complexes?.totalPages
        ) {
          return;
        }

        buildingsStore.pageInfo.changePage(page);

        void buildingsStore.searchComplexes(
          complexFilterStore.compileFilters,
          leafletStore.mapBounds,
          buildingsStore.pageInfo.pageInfo,
        );
      }
    },
    [buildingsStore, buildingsStore.requestApi.isLoading],
  );

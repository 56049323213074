import { makeObservable, computed, action } from 'mobx';
import { isAxiosError } from 'services/libs/axios/helpers';
import {
  AttemptResult,
  authLogoutService,
  User,
  UserInfoResponse,
  UserProfileService,
} from 'services';
import { MayBe } from 'types';
import { isNotNil } from 'utils/fp';
import { AuthTokenStore } from './AuthTokenStore';
import { AuthUserStore } from './AuthUserStore';

/**
 * @desc агрегирующий стор,
 * 1. вычисляет авторизован пользователь или нет
 * 2. выполняет выход из приложения
 * 3. выполняет обновление объекта пользователя
 * 4. выполняет вход в приложение
 * 5. выполняет вход в приложение по ключу
 * */
export class AuthStore {
  authUser: AuthUserStore;

  authToken: AuthTokenStore;

  userProfileService: UserProfileService;

  constructor(
    authUser: AuthUserStore,
    authToken: AuthTokenStore,
    userProfileService: UserProfileService,
  ) {
    this.authUser = authUser;
    this.authToken = authToken;
    this.userProfileService = userProfileService;

    makeObservable(this, {
      updateUser: action,
      logout: action,
      isAuth: computed,
    });
  }

  get isAuth(): boolean {
    return isNotNil(this.authUser.user) && isNotNil(this.authToken.token);
  }

  setAuthData = (
    user: User,
    token: MayBe<string>,
    expiresAt: MayBe<string>,
  ) => {
    this.authUser.setUser(user);
    this.authToken.setToken(token, expiresAt);
  };

  /**
   * @desc запросить объект текущего пользователя и сохранить в стор,
   * при наличии ошибки стор обнуляется
   * */
  updateUser = async (): Promise<AttemptResult<UserInfoResponse>> => {
    const [error, user] = await this.userProfileService.getUserProfile();

    if (user) {
      this.authUser.setUser(user.user);
    }
    if (isAxiosError(error)) {
      if (error?.response) {
        if (error?.response.status === 401) {
          this.authUser.clear();
          this.authToken.clear();
        }
      }
    }

    return [error, user];
  };

  clear = () => {
    this.authUser.clear();
    this.authToken.clear();
  };

  logout = async () => {
    if (this.isAuth) {
      await authLogoutService.logout();
    }
    this.clear();
  };
}

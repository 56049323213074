import React, { ComponentProps } from 'react';
import { MapContainer } from 'react-leaflet';
import { Map as MapType } from 'leaflet';
import { LeafletZoom } from '../LeafletZoom';
import './style.global.css';
import { MayBe } from '../../../../types';

type Props = Omit<ComponentProps<typeof MapContainer>, 'zoomControl'> & {
  hideZoomControl?: boolean;
  mapInstanceForZoom?: MayBe<MapType>;
};

/**
 * @desc компонент с заранее настроенным поведением зума,
 * в проекте на всех картах мы не даем зумить колесиком мыши,
 * зум только по кнопкам
 * */
export const BaseMapContainer = ({
  children,
  mapInstanceForZoom,
  hideZoomControl,
  ...props
}: Props) => (
  <MapContainer scrollWheelZoom={false} {...props} zoomControl={false}>
    {children}
    {!hideZoomControl && <LeafletZoom mapInstance={mapInstanceForZoom} />}
  </MapContainer>
);

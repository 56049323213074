import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://006ef64ab3794614ac0b5bfdf1fd1aa0@o733712.ingest.sentry.io/5784659',
  tracesSampleRate: 1.0,
});

export class LoggerService {
  error(...msg: unknown[]): void {
    Sentry.captureException(msg);
  }

  debug(...msg: unknown[]): void {
    // eslint-disable-next-line no-console
    console.log(...msg);
  }

  warn(...msg: unknown[]): void {
    // eslint-disable-next-line no-console
    console.warn(...msg);
  }
}

export const loggerService = new LoggerService();

import { action, makeObservable, observable, computed, reaction } from 'mobx';
import { flow, sortBy } from 'lodash/fp';
import {
  BuildingEntity,
  Section,
  ApartmentDto,
  buildingService,
  filterSectionByMatrix,
  ResponsePageInfo,
  DictionaryItem,
  getInfoItemByKey,
  ApartmentStatusKeys,
  BuildingSearchParameters,
} from 'services';
import { infoStore, RequestApiStore } from 'stores';
import { MayBe } from '../../../types';

export class BuildingGridStore {
  gridBordObject: BuildingEntity | null = null;

  requestApi: RequestApiStore;

  apartmentStatusDictionary: {
    reservationInfo: MayBe<DictionaryItem>;
    bookedInfo: MayBe<DictionaryItem>;
    soldInfo: MayBe<DictionaryItem>;
  } = {
    reservationInfo: null,
    bookedInfo: null,
    soldInfo: null,
  };

  apartments_layouts: any[] | undefined;

  constructor() {
    this.requestApi = new RequestApiStore();
    makeObservable(this, {
      gridBordObject: observable.shallow,
      apartmentStatusDictionary: observable.shallow,
      setGridBord: action.bound,

      getSections: computed,
      getApartments: computed,
      getApartmentsLayouts: computed,
    });

    reaction(
      () => infoStore.requestApi.isLoading,
      (isLoading) => {
        if (isLoading) {
          return;
        }
        this.apartmentStatusDictionary.reservationInfo = getInfoItemByKey(
          'apartment_status',
        )('apartment_status_reserv' as ApartmentStatusKeys)(
          infoStore.dictionaries,
        );
        this.apartmentStatusDictionary.bookedInfo = getInfoItemByKey(
          'apartment_status',
        )('apartment_status_reservation' as ApartmentStatusKeys)(
          infoStore.dictionaries,
        );
        this.apartmentStatusDictionary.soldInfo = getInfoItemByKey(
          'apartment_status',
        )('apartment_status_sold' as ApartmentStatusKeys)(
          infoStore.dictionaries,
        );
      },
      {
        fireImmediately: true,
      },
    );
  }

  setGridBord(object: BuildingEntity): void {
    this.gridBordObject = object;
  }

  get getSections(): Section[] {
    return this.gridBordObject?.sections || [];
  }

  get getApartments(): ApartmentDto[] {
    return this.gridBordObject?.apartments || [];
  }

  get getApartmentsLayouts(): any[] {
    return this.gridBordObject?.apartments_layouts || [];
  }

  getApartmentByNumber = (
    number: ApartmentDto['number'],
  ): ApartmentDto | undefined => {
    if (!number) {
      return undefined;
    }
    return this.gridBordObject?.apartments?.find(
      (apart) => apart?.number?.toString() === (number || '').toString(),
    );
  };

  loadingBuilding = async (
    buildingId: string,
    filters?: BuildingSearchParameters,
  ): Promise<ResponsePageInfo<ApartmentDto[]> | undefined> => {
    try {
      this.requestApi.clear();
      this.requestApi.setLoading(true);

      const result = await buildingService.getBuilding(buildingId, filters);
      if (result) {
        this.setGridBord({
          ...result,
          sections: flow<any, Section[], Section[]>(
            filterSectionByMatrix,
            sortBy<Section>('id'),
          )(result?.sections),
        });
      }
    } catch (e) {
      this.requestApi.setError(true);
    }
    this.requestApi.setLoading(false);
    return undefined;
  };
}
export const buildingGridStore = new BuildingGridStore();

import { action, makeObservable, observable } from 'mobx';
import { albumService, AlbumTypes } from 'services';
import { Album, AlbumPhotoToFilter } from 'services/v1/rc/album/types';
import {
  albumToAlbumFilterOptions,
  equalByType,
  mapAlbumIdToAlbumPhoto,
  sortAlbumsDesc,
} from 'services/v1/rc/album/helpers';
import { flow, filter } from 'lodash/fp';
import { AlbumFilterOption } from './types';
import { MayBe } from '../../types';
import { RequestApiStore } from '../request/RequestApiStore';

/**
 * @todo: добавить комментариев
 * */
class AlbumsStore {
  photosToShow: AlbumPhotoToFilter[] = [];

  albumsToShow: Album[] = [];

  albumFilterOptions: AlbumFilterOption[] = [];

  requestApiStore: RequestApiStore;

  hideFeedbackForm = false;

  constructor() {
    this.requestApiStore = new RequestApiStore();

    makeObservable(this, {
      albumsToShow: observable,
      hideFeedbackForm: observable,
      photosToShow: observable,
      albumFilterOptions: observable,
      getSortedAlbums: action.bound,
      setHideFeedbackForm: action.bound,
    });
  }

  clear() {
    this.photosToShow = [];
    this.albumFilterOptions = [];
    this.albumsToShow = [];
  }

  setHideFeedbackForm = (hideFeedbackForm: boolean) => {
    this.hideFeedbackForm = hideFeedbackForm;
  };

  findOpenAlbumById = (albumId: string) => (albums: Album[]) =>
    albums.find((element) => element && element.id === Number(albumId)) || null;

  findOpenAlbumByType = (type: AlbumTypes) => (albums: Album[]) =>
    albums.find((element) => element && element.type === type) || null;

  async getSortedAlbums(complexId: string, albumId: string | AlbumTypes) {
    this.requestApiStore.setLoading(true);
    this.requestApiStore.setError(false);

    const [error, result] = await albumService.getAlbums(complexId);

    this.requestApiStore.setLoading(false);

    if (error) {
      this.requestApiStore.setError(true);
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }
    if (!result) {
      return;
    }

    let startAlbum: MayBe<Album> = null;

    if (albumId === 'presentation') {
      startAlbum = this.findOpenAlbumByType(albumId)(result);
    } else if (albumId) {
      startAlbum = this.findOpenAlbumById(albumId)(result);
    }

    this.clear();
    if (startAlbum === null) {
      return;
    }

    this.albumsToShow = flow(
      filter(equalByType(startAlbum)),
      sortAlbumsDesc,
    )(result);

    const photosToShow: AlbumPhotoToFilter[] = mapAlbumIdToAlbumPhoto(
      this.albumsToShow,
    );

    /** @desc для отображения формы обратной связи в конце */
    if (!this.hideFeedbackForm) {
      photosToShow.push({} as any);
    }

    this.photosToShow = photosToShow;
    this.albumFilterOptions = albumToAlbumFilterOptions(this.albumsToShow);
  }
}

export const albumsStore = new AlbumsStore();

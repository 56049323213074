import qs from 'qs';
import { FeedbackEntity } from './types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { API_ROUTES } from '../../../constants';
import { loggerService } from '../../../loggerService';
import { ResponseStatus, ThrowableResult } from '../../../types';

export interface FeedbackService {
  feedback: (data: FeedbackEntity) => Promise<ThrowableResult<ResponseStatus>>;
}

export const feedbackService: FeedbackService = {
  feedback: async (
    data: FeedbackEntity,
  ): Promise<ThrowableResult<ResponseStatus>> => {
    try {
      const responseAxiosResponse = await makeRequest<ResponseStatus>({
        url: API_ROUTES.feedback,
        method: 'post',
        params: {
          ...data,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};

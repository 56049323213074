import { useEffect, useState } from 'react';
import { has } from 'lodash';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { useGetQueryString } from 'hooks';
import { IsEditOfferContextProps } from '../../context/IsEditOfferContext';

type Props = {
  authStore: AuthStore;
};

/**
 * @desc вычисляем в каком режиме сейчас страница подборки
 * 1. в режиме редактирования если, пользователь "риэлтор", isEditOffer == true; isOfferPreview == false;
 * 2. в режиме просмотра если, пользователь не риэлтор или в адресной строке есть параметр "?isEdit=true", isEditOffer == false; isOfferPreview == true;
 * */
export const useIsEditOffer = ({
  authStore,
}: Props): IsEditOfferContextProps => {
  const [isEditOffer, setEditOffer] = useState(false);
  const [isOfferPreview, setOfferPreview] = useState(false);
  const { query } = useGetQueryString<{ isEdit?: string }>();

  useEffect(() => {
    if (!authStore.isAuth) {
      setEditOffer(false);
    } else if (authStore.authUser.user?.role === 'realtor') {
      if (has(query, 'isEdit')) {
        const isEditTrue = query?.isEdit === 'true';
        setEditOffer(isEditTrue);
        setOfferPreview(!isEditTrue);
      } else {
        setEditOffer(true);
        setOfferPreview(false);
      }
    }
  }, [query, authStore.isAuth]);

  return {
    isEditOffer,
    isOfferPreview,
  };
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { OffersEditorFormPopup } from './containers/OffersEditorFormPopup';
import { useGetQueryString } from '../../../../hooks';
import { Params } from '../../../../services';

export const OffersEditorPage = () => {
  const history = useHistory();
  const { query } = useGetQueryString<Pick<Params, 'offerId'>>();

  return (
    <OffersEditorFormPopup
      offerId={query.offerId}
      onClose={() => {
        history.goBack();
      }}
    />
  );
};

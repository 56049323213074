export const scrollIntoViewByElement =
  (toScroll: boolean, options?: ScrollIntoViewOptions) => (ref: Element) => {
    if (!ref) {
      return;
    }
    if (toScroll) {
      ref.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
        ...options,
      });
    }
  };

import { makeObservable, observable, action, computed, reaction } from 'mobx';
import { setTokenToRequestHeader } from 'services/libs/axios/helpers';
import { AuthTokenObject, AUTH_TOKEN_NAME } from 'services';
import { MayBe } from 'types';
import { SyncTrunk } from 'stores/SyncTrunk/SyncTrunk';
import { authTokenStoreStorageAdapter } from './lib/adapters/authTokenStoreStorageAdapter';

type State = AuthTokenObject;

/**
 * @desc хранение и доступ к токену авторизации
 * */
export class AuthTokenStore {
  state: State = {
    token: null,
    expiresAt: null,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      clear: action,
      setToken: action,
      token: computed,
    });
  }

  get token() {
    return this.state.token;
  }

  clear() {
    this.state = {
      token: null,
      expiresAt: null,
    };
  }

  setToken = (token: MayBe<string>, expiresAt: MayBe<string>) => {
    this.state = {
      token,
      expiresAt,
    };
  };

  setTokenToStoreAndRequestHeader = (
    token: MayBe<string> = null,
    expiresAt: MayBe<string> = null,
  ) => {
    this.setToken(token, expiresAt);
    setTokenToRequestHeader(token);
  };

  initReaction = () => {
    reaction(
      () => this.token,
      (token) => {
        setTokenToRequestHeader(token);
      },
      {
        fireImmediately: true,
      },
    );
  };

  syncTrunk = () => {
    void new SyncTrunk({
      store: this,
      storage: authTokenStoreStorageAdapter,
      storageKey: AUTH_TOKEN_NAME,
      autoInit: true,
    });
  };
}

import { useEffect, useState } from 'react';

export const useImageLoad = (
  imageUrl?: string,
  width?: number,
  height?: number,
) => {
  const [image, setImage] = useState<HTMLImageElement | null>();

  useEffect(() => {
    if (!imageUrl) return;
    const img = new Image(width, height);
    img.src = imageUrl;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    img.onload = (_this: GlobalEventHandlers, ev: Event): any => {
      setImage(img);
    };
  }, [imageUrl]);

  return image;
};

import React, { useCallback } from 'react';
import { TabItemProps, useTabs } from '../../../../components/containers/Tabs';
import { BuildingEntity } from '../../../../services';
import { TabsSelect } from '../../../../components/containers/TabsSelect';

type Props = {
  tabs: TabItemProps<BuildingEntity>[];
  onChangeTab?: (building: TabItemProps<BuildingEntity>) => void;
  initActiveTab?: number;
};

export const BuildingNavSelect = ({
  tabs,
  onChangeTab,
  initActiveTab = 0,
}: Props) => {
  const { tabList, changeTab, currentActiveTab } = useTabs<BuildingEntity>({
    tabList: tabs,
    currentActiveTab: initActiveTab,
  });

  const onTabChange = useCallback(
    (index: number) => () => {
      if (onChangeTab) {
        onChangeTab(tabList[index]);
      }
      changeTab(index)();
    },
    [currentActiveTab, tabList, onChangeTab],
  );

  return (
    <TabsSelect<BuildingEntity>
      tabList={tabList}
      changeTab={onTabChange}
      currentActiveTab={currentActiveTab}
    />
  );
};

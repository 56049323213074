import React from 'react';
import cc from 'classcat';
import { Text } from 'components/atoms';
import { scrollIntoViewByElement } from 'utils';
import styles from './styles.module.css';

type Props = {
  onClick: (event: any) => void;
  isActive: boolean;
  title: string;
  subTitle: string;
  isVisible?: boolean;
};

export const FloorButton = ({
  onClick,
  isActive,
  title,
  subTitle,
  isVisible,
}: Props) => {
  if (!isVisible) {
    return null;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      ref={scrollIntoViewByElement(isActive) as any}
      className={cc([
        styles.floorButton,
        {
          [styles.floorButtonActive]: isActive,
        },
      ])}
    >
      <Text tagName="div" size="S" weight="bold">
        {title} этаж
      </Text>
      <Text
        whiteSpace="nowrap"
        tagName="div"
        size="XS"
        lineHeight="M"
        weight="normal"
      >
        {subTitle} квартир
      </Text>
    </button>
  );
};

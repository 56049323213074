import { makeObservable, action, observable } from 'mobx';
import { SyncTrunk } from '../../SyncTrunk/SyncTrunk';
import { apartmentServiceStorageAdapter } from './adapters';

type State = {
  isAccessToView: boolean;
};

export class ApartmentAccessStatusStore {
  state: State = {
    isAccessToView: true,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setIsAccessToView: action,
    });
  }

  setIsAccessToView = (isAccessToView: boolean): void => {
    this.state.isAccessToView = isAccessToView;
  };

  syncTrunk = () => {
    void new SyncTrunk({
      store: this,
      storage: apartmentServiceStorageAdapter,
      storageKey: '',
      autoInit: true,
    });
  };
}

import { useEffect, useRef } from 'react';
import { Cell } from 'services';
import { useInView } from 'react-intersection-observer';
import { scrollIntoViewByElement } from 'utils';
import { useIsActiveApartment } from './useIsActiveApartment';
import { useLoadApartmentWhenIsInViewport } from './useLoadApartmentWhenIsInViewport';

type UseGridCardProps = {
  matrixItem: Cell;
};

export const useGridCard = ({ matrixItem }: UseGridCardProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
  });
  const cardRef = useRef<HTMLDivElement>();

  const isApartmentActive = useIsActiveApartment(matrixItem);

  useEffect(() => {
    if (cardRef.current) {
      scrollIntoViewByElement(isApartmentActive, {
        block: 'start',
      })(cardRef.current);
    }
  }, [cardRef.current, isApartmentActive]);

  useEffect(() => {
    if (cardRef.current) {
      ref(cardRef.current);
    }
  }, [cardRef.current]);

  const { apartment, isFoundApartment } = useLoadApartmentWhenIsInViewport({
    matrixItem,
    cardRef,
    inView,
  });

  return {
    apartment,
    isFoundApartment,
    cardRef,
    isApartmentActive,
  };
};

import React, { useCallback, useState } from 'react';
import { useId } from 'react-id-generator';
import { Popover } from 'react-tiny-popover';
import { useWillUnmount } from 'beautiful-react-hooks';
import cc from 'classcat';
import dropdown from 'assets/dropdown.svg';
import { flow } from 'lodash/fp';
import { TabButton, TabProps } from '../Tabs';
import { Icon } from '../../atoms/Icon';
import styles from './styles.module.css';

type Props<TabItemPayload> = Omit<TabProps<TabItemPayload>, 'setTabList'>;

/**
 * @desc компонент для представления табов в виде селекта
 * */
export const TabsSelect = <TabItemPayload extends {}>({
  currentActiveTab,
  tabList,
  changeTab,
}: Props<TabItemPayload>) => {
  const idList: string[] = useId(tabList.length, 'TabsSelect');
  const [isOpen, setOpen] = useState(false);

  const handleSortVisibility = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const currentTab = tabList[currentActiveTab];

  useWillUnmount(() => setOpen(false));

  const popoverContent = (
    <div className={styles.overlay}>
      {tabList.map((item, index) => (
        <div
          onClick={flow(changeTab(index), () => setOpen(false))}
          key={idList[index]}
          className={cc([
            styles.item,
            {
              [styles.itemActive]: index === currentActiveTab,
            },
          ])}
        >
          <TabButton {...item} />
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      onClickOutside={handleSortVisibility}
      positions={['bottom']}
      isOpen={isOpen}
      content={popoverContent}
    >
      <div
        onClick={handleSortVisibility}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <TabButton {...currentTab} />
        <Icon
          size={10}
          style={{
            marginLeft: '5px',
            transform: isOpen ? '' : 'rotate(180deg)',
          }}
          src={dropdown}
        />
      </div>
    </Popover>
  );
};

import React from 'react';
import cc from 'classcat';
import floorPlanPlaceholderImage from 'assets/placeholders/floorPlanPlaceholder.jpg';
import styles from './styles.module.css';

export const PLACEHOLDER_IMAGE_TYPES = {
  FLOOR_PLAN: 'FLOOR_PLAN',
  USER_AVATAR: 'USER_AVATAR',
  COMPLEX_LOGO: 'COMPLEX_LOGO',
};

type Props = {
  type?: keyof typeof PLACEHOLDER_IMAGE_TYPES;
  className?: string;
};

export const PlaceholderImage = ({ className, type }: Props) => (
  <div className={cc([styles.floorPlanPlaceholderWrapper, className])}>
    <img
      className={styles.floorPlanPlaceholderImage}
      src={cc({
        [floorPlanPlaceholderImage]:
          type === PLACEHOLDER_IMAGE_TYPES.FLOOR_PLAN,
      })}
      alt=""
    />
  </div>
);

import { SyncStorage } from 'stores/SyncTrunk/SyncTrunk';
import { MayBe } from 'types';
import { authTokenStorageService } from 'services';

export const authTokenStoreStorageAdapter: SyncStorage = {
  getItem(): MayBe<string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const token = authTokenStorageService.getToken();
    if (token) {
      return JSON.stringify({
        state: token,
      });
    }
    return null;
  },
  setItem(_, json: string) {
    const { state } = JSON.parse(json);
    authTokenStorageService.setToken(state?.token, state?.expiresAt);
  },
  removeItem() {
    authTokenStorageService.remove();
  },
};

import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { buildingFilters } from 'stores';
import { observer } from 'mobx-react';
import { Params } from 'services';
import { BaseLink } from 'components/atoms';
import { TabsView } from 'components/containers/Tabs';
import arrowLeft from 'assets/darkArrowLeft.svg';
import { LayoutFilters } from '../LayoutFilters';

import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';
import { ApartmentViewFilterWithShowCondition } from '../ApartmentViewFilterWithShowCondition';
import styles from './styles.module.css';

type Props = {
  showMainChessFilters: () => void;
} & Pick<ReturnType<typeof useLayoutTabsState>, 'currentActiveTab'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'tabList'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'changeTab'>;

const LayoutPageHeaderRight = {
  tabsView: 'LayoutPageHeaderRight',
};

export const HeaderRightRender = ({
  currentActiveTab,
  tabList,
  changeTab,
  showMainChessFilters,
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  return (
    <div className={styles.wrapper}>
      <ApartmentViewFilterWithShowCondition />

      <LayoutFilters
        onFilterOpen={showMainChessFilters}
        onFilterReset={buildingFilters.filters.reset}
        resultedFilters={buildingFilters.filters.filterToApiFormat}
      />

      <TabsView
        testId={LayoutPageHeaderRight.tabsView}
        tabList={tabList}
        changeTab={changeTab}
        currentActiveTab={currentActiveTab}
      />
      <BaseLink
        path="view"
        params={{
          complexId,
        }}
        icon={<img style={{ width: '16px' }} src={arrowLeft} alt="" />}
      >
        На страницу ЖК
      </BaseLink>
    </div>
  );
};

export const HeaderRight = memo(observer(HeaderRightRender));

import { isObservableArray, isObservableMap, observable } from 'mobx';
import { has } from 'lodash';
import { isPrimitive } from './utils';

export const parseStore = (store: any, data: any) => {
  if (!store || !data) {
    return;
  }

  Object.keys(data).forEach((key) => {
    if (!has(store, key)) {
      return;
    }

    const storeValue = store[key];
    const dataValue = data[key];

    if (isObservableArray(storeValue)) {
      // mobx array
      store[key] = observable.array(dataValue);
    } else if (isObservableMap(storeValue)) {
      // mobx map
      store[key] = observable.map(dataValue);
    } else if (isPrimitive(dataValue)) {
      // js/mobx primitive objects
      store[key] = dataValue;
    } else if (!storeValue) {
      // if store value is empty, assign persisted data to it directly
      store[key] = dataValue;
    } else {
      // nested pure js object or mobx observable object
      parseStore(storeValue, dataValue);
    }
  });
};

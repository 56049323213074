import { makeObservable, action, observable } from 'mobx';
import SimpleBar from 'simplebar-react';
import { throttle } from 'lodash';

export class ScrollBarStore {
  simpleBarInstance: SimpleBar | null = null;

  scrollContainer: HTMLElement | null = null;

  scrollTop = 0;

  scrollLeft = 0;

  constructor() {
    makeObservable(this, {
      // simpleBarInstance: observable,
      scrollTop: observable,
      scrollLeft: observable,
      scrollTo: action,
      setSimpleBarInstance: action,
      onScrollHandler: action,
    });
  }

  clear = () => {
    this.scrollContainer = null;
    this.simpleBarInstance = null;
    this.scrollTop = 0;
    this.scrollLeft = 0;
  };

  scrollTo = (top: number, left: number) => {
    if (!this.scrollContainer) {
      return;
    }
    const { offsetHeight, offsetWidth } = this.scrollContainer || {};
    if (!offsetHeight || !offsetWidth) {
      return;
    }
    this.scrollContainer?.scrollTo({
      // top: top - offsetHeight / 2,
      // left: left - offsetWidth / 4,
      top,
      left,
      behavior: 'smooth',
    });
  };

  onScrollHandler = () => {
    this.scrollTop = this.scrollContainer?.scrollTop || 0;
    this.scrollLeft = this.scrollContainer?.scrollLeft || 0;
  };

  onScrollHandlerThrottle = throttle(this.onScrollHandler, 200);

  onScroll = () => {
    this.scrollContainer?.addEventListener(
      'scroll',
      this.onScrollHandlerThrottle,
    );
  };

  setSimpleBarInstance = (scrollContainer: HTMLElement) => {
    this.scrollContainer = scrollContainer;
    if (!this.scrollContainer) {
      return;
    }
    // this.scrollContainer?.scrollTo({ top: 500, behavior: 'smooth' });
    this.onScroll();
  };
}

export const scrollBarStore = new ScrollBarStore();

import React, { ComponentType } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const withGoogleReCaptchaProvider = <OwnProps extends {}>(
  Component: ComponentType<OwnProps>,
): ComponentType<OwnProps> => {
  const WithComponent = (props: OwnProps) => (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_CAPTCHA}
      language="RU"
      scriptProps={{
        appendTo: 'head',
      }}
    >
      <Component {...props} />
    </GoogleReCaptchaProvider>
  );

  WithComponent.displayName = `withGoogleReCaptchaProvider(${
    Component?.displayName || Component?.name || 'Component'
  })`;

  return WithComponent;
};

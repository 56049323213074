import React from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { NumberFormat, Text } from 'components/atoms';
import { ApartmentInOffer, Complex } from 'services';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { useUserIsOwnersOfferItem } from 'hooks';
import { offerItemStore } from 'stores';
import { useRemoveApartmentFromOffer } from '../../hooks/useRemoveApartmentFromOffer';
import { useGetOfferViewFieldStatus } from '../../../OffersViewPage/hooks/useGetOfferViewFieldStatus';
import styles from './styles.module.css';
import { CopyLinkButton } from '../../../../components/containers/CopyLink/components/CopyLinkButton';
import { CopyLink } from '../../../../components/containers/CopyLink';
import { useGetPathToOffers } from '../../hooks/useGetPathToOffers';

type Props = {
  complex: Complex;
  apartment: ApartmentInOffer;
  isIframeMode: boolean;
  className?: string;
};

export const ApartmentActionsRender = ({
  complex,
  apartment,
  className,
  isIframeMode
}: Props) => {
  const { userIsOwner } = useUserIsOwnersOfferItem({
    offer: offerItemStore.offer,
  });

  const { goToOfferPage } = useGetPathToOffers(
    offerItemStore.offer?.id,
    offerItemStore.offer?.hash,
  );

  const { removeApartment } = useRemoveApartmentFromOffer(
    apartment?.id,
    offerItemStore.offer?.id,
    goToOfferPage,
  );
  const { isShowPrice, isShowDeveloper } = useGetOfferViewFieldStatus();

  const hasContent = isShowDeveloper || isShowPrice || (!isIframeMode && userIsOwner );

  if (!hasContent) return null;

  return (
    <div className={cc([styles.flatEdit, className])}>
      {isShowDeveloper && (
        <div className={styles.developer}>
          <img
            src={complex?.developer?.logo?.logo || ''} // TODO: поставить заглушку если у застройщика нет лого
            alt="лого застройщика"
            className={styles.developerLogo}
          />

          <div className={styles.developerInfoWrapper}>
            <Text className={styles.developerTitle}>
              {complex?.developer?.title}
            </Text>

            <Text className={styles.developerInfo}>28 ЖК с 2008 года</Text>
          </div>
        </div>
      )}

      {isShowPrice && (
        <>
          <span className={styles.title}>Стоимость</span>
          <NumberFormat
            value={apartment?.price}
            format="rub"
            className={styles.apartmentPrice}
          />

          <span className={styles.title}>Цена за м²</span>
          <NumberFormat
            value={apartment?.price_m2}
            format="rub_meter2"
            className={styles.priceForMeter}
          />
        </>
      )}

      <div className={styles.apartmentGroup}>
        {/* @todo: этот функционал еще не готов */}
        {/* <Link to="/" className={styles.apartmentLink}> */}
        {/*  Забронировать */}
        {/* </Link> */}
        {/* <Link to="/" className={styles.apartmentLink}> */}
        {/*  Записаться на просмотр */}
        {/* </Link> */}
        {/* <Link to="/" className={styles.apartmentLink}> */}
        {/*  Не нравится */}
        {/* </Link> */}
        {!isIframeMode && <CopyLink
          defaultLabel="Поделится"
          successLabel="Поделится"
          link={window.location.href}
        >
          {(props) => <CopyLinkButton variant="text" {...props} />}
        </CopyLink>}

        {!isIframeMode && userIsOwner && (
          <ButtonBase
            className={styles.apartmentLink}
            variant="text"
            color="alert"
            onClick={removeApartment}
          >
            Удалить
          </ButtonBase>
        )}
      </div>
    </div>
  );
};

export const ApartmentActions = observer(ApartmentActionsRender);

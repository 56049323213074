import React from 'react';

import { FirstColumnFilter } from '../Search/villages/FirstColumnFilters';
import { SecondColumnFilter } from '../Search/villages/SecondColumnFilters';
import { ThirdColumnFilters } from '../Search/villages/ThirdColumnFilters';

import styles from './AllFilters.module.css';

export const SecondTab: React.FC = () => (
  <>
    <div className={styles.formColumn}>
      <FirstColumnFilter />
    </div>

    <div className={styles.formColumn}>
      <SecondColumnFilter />
    </div>

    <div className={styles.formColumn}>
      <ThirdColumnFilters />
    </div>
  </>
);

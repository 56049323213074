import React, { ComponentProps } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { infoStore } from 'stores/InfoStore';
import { DictionaryKeys, findDictItemColor, findDictItemValue } from 'services';
import styles from './styles.module.css';
import { Text } from '../Text';
import { Badge } from '../Badge';

type Props = {
  dictKey: DictionaryKeys;
  dictItemId: number[];
  className?: string;
  dictStore?: typeof infoStore;
  /**
   * @desc бэйждж отображается рядом с названием
   * @example
   * <Badge/> Label
   * */
  badgeOuter?: boolean;
  /**
   * @desc текст оборачивается в бэйдж
   * @example
   * <Badge> Label </Badge>
   * */
  badgeInner?: boolean;

  hideText?: boolean;
} & Pick<ComponentProps<typeof Badge>, 'size'>;

/**
 * @desc компонент для отрисовки значений из словаря имеющих цвет
 * */
export const DictionaryBadgeRender = ({
  dictKey,
  dictItemId,
  className,
  dictStore,
  badgeOuter,
  badgeInner,
  hideText,
  size,
}: Props) => {
  const labels: (string | undefined)[] = findDictItemValue(
    dictKey,
    dictItemId,
  )(dictStore?.dictionaries);

  const colors: (string | undefined)[] = findDictItemColor(
    dictKey,
    dictItemId,
  )(dictStore?.dictionaries);

  return (
    <div className={cc([styles.wrapper, className])}>
      {badgeOuter &&
        labels.map((item, index) => (
          <div key={item} className={styles.badgeOuter}>
            <Badge color={colors[index]} size={size || 'small'} />
            {!hideText && (
              <Text weight="normal" size="S">
                {item || ''}
              </Text>
            )}
          </div>
        ))}
      {badgeInner &&
        labels.map((item, index) => (
          <div key={item} className={styles.badgeOuter}>
            <Badge size={size || 'small'} color={colors[index]}>
              {!hideText && <>{item || ''}</>}
            </Badge>
          </div>
        ))}
    </div>
  );
};
DictionaryBadgeRender.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dictStore: infoStore,
};

export const DictionaryBadge = observer(DictionaryBadgeRender);

import React from 'react';

import { InputGroup } from 'components/atoms';
import { ReactiveInput } from 'components/containers/Search/components/ReactiveInput';
import { ReactiveSelect } from 'components/containers/Search/components/ReactiveSelect';

import { buildingFilters } from 'stores/BuildingFilters';

import styles from '../../../../../components/containers/AllFilters/AllFilters.module.css';

const FirstColumnFilters = () => (
  <>
    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Стоимость</span>
      <InputGroup>
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="priceMin"
          placeholder="Общая, от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="priceMax"
          placeholder="Общая, до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Тип помещения</span>
      <ReactiveSelect
        store={buildingFilters.filters}
        filterKey="roomType"
        placeholder="Любой"
        dictKey="apartment_type_room"
      />
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Тип квартиры</span>
      <ReactiveSelect
        store={buildingFilters.filters}
        filterKey="rooms"
        placeholder="Любой"
        dictKey="rooms"
      />
    </div>
  </>
);

export default FirstColumnFilters;

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  innerRef?: React.RefObject<HTMLDivElement>;
  children: ReactNode;
};

export const Block = ({
  className,
  style = {},
  title,
  children,
  innerRef,
}: Props) => (
  <div className={cc([styles.block, className])} style={style} ref={innerRef}>
    {title ? <span className={styles.blockTitle}>{title}</span> : null}
    {children}
  </div>
);

import React, { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Params } from 'services';
import { reaction } from 'mobx';
import { findById } from 'utils/fp';
import { useGridApartmentRequest } from '../../hooks/useGridApartmentRequest';
import { BuildingGridStore } from '../../stores/buildingGridStore';
import { ComplexItemStore } from '../../../../stores/ComplexItemStore/store';
import { floorPlanStore } from '../../stores/FloorPlanStore';
import { FloorPlansMap } from './containers/FloorPlansMap';
import styles from './styles.module.css';
import { dataTestIdToProps } from '../../../../utils/test/dataTestIdToProps';

type Props = {
  complexItemStore?: ComplexItemStore;
  buildingGridStore?: BuildingGridStore;
};

const FloorPlansTestIDs = {
  wrapper: 'FloorPlans_wrapper',
};

export const FloorPlansRender = ({
  buildingGridStore,
  complexItemStore,
}: Props) => {
  const { complexId, layoutTabs, buildingId, apartmentId } =
    useParams<
      Pick<Params, 'complexId' | 'layoutTabs' | 'buildingId' | 'apartmentId'>
    >();

  useGridApartmentRequest({
    buildingGridStore,
    complexItemStore,
    complexId,
    layoutTabs,
    buildingId,
  });

  useEffect(() => {
    if (buildingGridStore?.getApartments.length) {
      const activeApartment = buildingGridStore?.getApartments.find(
        findById(apartmentId),
      );
      floorPlanStore.setActiveApartment(activeApartment);
    }
  }, [apartmentId, buildingGridStore?.getApartments]);

  useEffect(
    () =>
      reaction(
        () => buildingGridStore?.getSections,
        () => {
          floorPlanStore.mapFloorsToState(buildingGridStore?.getSections);
          floorPlanStore.mapApartmentToFloors(buildingGridStore?.getApartments);
        },
      ),
    [buildingGridStore?.getSections],
  );

  return (
    <SimpleBar
      {...dataTestIdToProps(FloorPlansTestIDs.wrapper)}
      className={styles.wrapper}
    >
      <FloorPlansMap />
    </SimpleBar>
  );
};

export const FloorPlans = observer(FloorPlansRender);

import React, { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import photoCamera from 'assets/photoCamera.svg';
import { AlbumPhotoImage } from 'services';
import { MayBe } from 'types';
import styles from './styles.module.css';

type Props = {
  photo: AlbumPhotoImage;
  count: number;
  type: MayBe<any>;
  dateAt: MayBe<string>;
  name: string;
} & Pick<ComponentProps<typeof Link>, 'to'>;

export const AlbumItem = ({ to, photo, count, type, dateAt, name }: Props) => (
  <li className={styles.albumsListItem}>
    <Link to={to} className={styles.albumLink}>
      <div
        className={styles.albumItem}
        style={{ backgroundImage: `url("${photo?.logo || ''}")` }}
      >
        <div className={styles.albumInfo}>
          <span className={styles.albumCount}>
            <img
              src={photoCamera}
              alt="photos count"
              style={{ marginRight: '5px' }}
            />
            {count}
          </span>
        </div>

        <div className={styles.albumName}>
          {name === 'Ход строительства' || type === 'construction-progress'
            ? dateAt
            : name}
        </div>
      </div>
    </Link>
  </li>
);

import React, { useState } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import MapIcon from 'assets/MapIcon.svg';
import UlIcon from 'assets/UlIcon.svg';
import useWindowSize from 'hooks/useWindowSize';

import { PageWrapper, Icon } from 'components/atoms';
import { ComplexPage } from 'pages/ComplexPage';
import { ComplexList } from 'pages/Main/containers/ComplexList';

import { appRoutesService } from 'services';
import { Route, Switch } from 'react-router';
import { MainPageMap } from 'pages/Main/containers/LeafletMap';

import { NavbarMainPage } from './containers/Navbar/NavbarMainPage';
import { SearchAndFilterBar } from './containers/SearchAndFilterBar';
import { AuthPage } from '../AuthPage';
import { PrivacyPage } from '../PrivacyPage';
import styles from './styles.module.css';

const Main = () => {
  const [isActiveMap, setActiveMap] = useState<boolean>(false);
  const [isActiveComplexes, setActiveComplexes] = useState<boolean>(true);

  const toggleViewComplexesAndMap = () => {
    if (isActiveMap) {
      setActiveMap(false);
      setActiveComplexes(true);
    } else {
      setActiveMap(true);
      setActiveComplexes(false);
    }
  };

  const toggleViewComplexesAndMapButtonIcon = isActiveMap ? UlIcon : MapIcon;

  const { width } = useWindowSize();

  if (!width) {
    return <></>;
  }

  return (
    <>
      <PageWrapper className={styles.root}>
        <div className={styles.navbarWrapper}>
          <NavbarMainPage />
        </div>

        <div className={styles.content}>
          <div className={styles.searchAndFilter}>
            <SearchAndFilterBar />
          </div>

          <div
            className={cc([
              styles.map,
              {
                [styles.map_show]: isActiveMap,
                [styles.map_hide]: isActiveComplexes,
              },
            ])}
          >
            <MainPageMap />
          </div>

          <div
            className={cc([
              styles.complexes,
              {
                [styles.complexes_show]: isActiveComplexes,
                [styles.complexes_hide]: isActiveMap,
              },
            ])}
          >
            <ComplexList />
          </div>
        </div>
      </PageWrapper>

      <Switch>
        <Route
          path={appRoutesService.getRoute('view')}
          component={ComplexPage}
        />
        <Route path={appRoutesService.getRoute('privacy')}>
          <PrivacyPage />
        </Route>
        <AuthPage parentRoute="" />
      </Switch>

      <button
        type="button"
        onClick={toggleViewComplexesAndMap}
        className={styles.toggleViewComplexesAndMap}
      >
        <Icon src={toggleViewComplexesAndMapButtonIcon} size={32} />
      </button>
    </>
  );
};

export default observer(Main);

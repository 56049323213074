import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'components/atoms/Icon';
import plusIcon from 'assets/plus.svg';
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import { OffersSearch } from '../../../OffersPage/components/OffersSearch';
import styles from './styles.module.css';

type Props = {
  onCreateNewOffer: () => void;
};

export const AddToOffersHeaderRender = ({ onCreateNewOffer }: Props) => (
  <header className={styles.addToOffersHeaderWrapper}>
    <div className={styles.addToOffersHeaderTop}>
      <h2 className={styles.addToOffersHeaderTitle}>Добавить в подборку</h2>
      <ButtonBase
        onClick={onCreateNewOffer}
        icon={<Icon src={plusIcon} size={16} />}
      >
        Создать
      </ButtonBase>
    </div>
    <OffersSearch
      value={addToOffersStore.offersFilterStore.searchParams}
      onChange={addToOffersStore.offersFilterStore.setSearch}
    />
  </header>
);

export const AddToOffersHeader = observer(AddToOffersHeaderRender);

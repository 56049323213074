import { useGetQueryString } from 'hooks';
import { appRoutesService } from 'services';
import { useHistory } from 'react-router-dom';
import { rootProfileStore } from 'stores/user/RootProfileStore';

type ReturnProps = {
  goToOfferPage: () => void;
};

export const useGetPathToOffers = (
  offerId?: number | string,
  hash?: string,
): ReturnProps => {
  const { query } = useGetQueryString<{ isEdit: string }>();
  const history = useHistory();
  const { isAuth } = rootProfileStore.authStore;
  let pathToOfferList = '';

  if (isAuth && query.isEdit === 'true') {
    pathToOfferList = appRoutesService.replaceRoute('profileOfferViewRealtor', {
      offerId: offerId?.toString(),
    });
  } else {
    pathToOfferList = appRoutesService.replaceRoute(
      'offersViewClient',
      {
        hash,
        offerTabs: 'list',
      },
      {
        isEdit: query.isEdit,
      },
    );
  }
  const goToOfferPage = () => {
    history.push(pathToOfferList);
  };

  return {
    goToOfferPage,
  };
};

import React, { useState, useEffect } from 'react';
import cc from 'classcat';

import sliderVideoPlay from 'assets/sliderVideoPlay.svg';
import styles from './style.module.css';

type Props = {
  activePhotoImageURL: string;
  photoImageURL: string;
  videoURL?: string;
};

const getEmberId = (videoURL: string) => {
  const [emberId] = videoURL.match('[^/]+$') || [''];
  return emberId;
};

export const SliderVideoRender = ({
  activePhotoImageURL,
  photoImageURL,
  videoURL,
}: Props) => {
  const [url, setUrl] = useState(``);
  const [isPlay, setIsPlay] = useState(false);

  const playVideo = () => {
    setIsPlay(true);
  };

  useEffect(() => {
    if (videoURL && activePhotoImageURL === photoImageURL) {
      const emberId = getEmberId(videoURL);

      setUrl(
        `https://www.youtube.com/embed/${emberId}${
          isPlay ? '?autoplay=1' : ''
        }`,
      );
    } else {
      setIsPlay(false);
      setUrl(``);
    }
  }, [activePhotoImageURL, isPlay]);

  return (
    <div className={styles.wrapper}>
      <iframe
        className={styles.iframe}
        src={url}
        frameBorder="0"
        title="Видео альбома"
      />
      <img
        data-src={photoImageURL}
        className={cc([
          'swiper-lazy img-fit',
          styles.photo,
          {
            [styles.photoHide]: isPlay,
          },
        ])}
        loading="lazy"
        alt="Слайдер альбома"
      />
      {!isPlay && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a onClick={playVideo}>
          <img
            className={styles.play}
            src={sliderVideoPlay}
            alt="Воспроизвести"
          />
        </a>
      )}
    </div>
  );
};

export const SliderVideo = SliderVideoRender;

import qs from 'qs';
import { serialize } from 'object-to-formdata';
import {
  AuthEmailCodeRequestBody,
  AuthEmailCodeResponse,
  AuthEmailValidateRequestBody,
  AuthEmailValidateResponse,
  AuthEmailConfirmRepeatRequestBody,
  AuthEmailConfirmRequestBody,
  AuthEmailConfirmResponse,
  AuthEmailConfirmRepeatResponse,
  AuthEmailChangeRequestBody,
  AuthEmailChangeResponse,
  AuthEmailChangeConfirmRequestBody,
  AuthEmailChangeConfirmResponse,
} from './types';
import { AttemptResult, ThrowableResult } from '../../../types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { API_ROUTES } from '../../../constants';
import { loggerService } from '../../../loggerService';
import { attempt, attemptWithAuth } from '../../../libs/axios/attempt';

export const authEmailService = {
  code: async (
    data: AuthEmailCodeRequestBody,
  ): Promise<AttemptResult<AuthEmailCodeResponse>> =>
    attempt<AuthEmailCodeResponse>({
      url: API_ROUTES.auth.email.code,
      method: 'post',
      data: serialize(data),
    }),

  validate: async (
    data: AuthEmailValidateRequestBody,
  ): Promise<ThrowableResult<AuthEmailValidateResponse>> => {
    try {
      const responseAxiosResponse =
        await makeRequest<AuthEmailValidateResponse>({
          url: API_ROUTES.auth.email.validate,
          method: 'post',
          data: serialize(data),
        });

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  confirm: async (
    data: AuthEmailConfirmRequestBody,
  ): Promise<ThrowableResult<AuthEmailConfirmResponse>> => {
    try {
      const responseAxiosResponse = await makeRequest<AuthEmailConfirmResponse>(
        {
          url: API_ROUTES.auth.email.confirm.confirm,
          method: 'post',
          params: {
            ...data,
          },
          paramsSerializer: (params) => qs.stringify(params),
        },
      );

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  confirmRepeat: async (
    data: AuthEmailConfirmRepeatRequestBody,
  ): Promise<ThrowableResult<AuthEmailConfirmRepeatResponse>> => {
    try {
      const responseAxiosResponse =
        await makeRequest<AuthEmailConfirmRepeatResponse>({
          url: API_ROUTES.auth.email.confirm.repeat,
          method: 'post',
          params: {
            ...data,
          },
          paramsSerializer: (params) => qs.stringify(params),
        });

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  change: async (
    data: AuthEmailChangeRequestBody,
  ): Promise<AttemptResult<AuthEmailChangeResponse>> =>
    attemptWithAuth<AuthEmailChangeResponse>({
      url: API_ROUTES.auth.email.change.change,
      method: 'post',
      params: {
        ...data,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }),
  changeConfirm: async (
    data: AuthEmailChangeConfirmRequestBody,
  ): Promise<AttemptResult<AuthEmailChangeConfirmResponse>> =>
    attemptWithAuth<AuthEmailChangeConfirmResponse>({
      url: API_ROUTES.auth.email.change.confirm,
      method: 'post',
      params: {
        ...data,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }),
};

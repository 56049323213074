import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';

import { InputGroup } from 'components/atoms';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import { ReactiveCheckbox } from './ReactiveCheckbox';

import styles from '../../AllFilters/AllFilters.module.css';

const SecondColumnFilterRenderer = () => (
  <>
    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Статус продаж</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="salesStatus"
          dictKey="residential_complex_sales_status"
          placeholder="Не важно"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Статус строительства</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="contractType"
          dictKey="construction_phase_status"
        />
      </div>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Способ оплаты</span>
      <ReactiveSelect
        store={complexFilterStore.filters}
        filterKey="paymentType"
        dictKey="residential_complex_payment_type"
      />
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Год постройки</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="builtDateMin"
          placeholder="От"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="builtDateMax"
          placeholder="До"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Водоснабжение</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageWaterSupply"
          dictKey="village_water_supply"
          placeholder="Любой"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Отопление</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageHeating"
          dictKey="village_heating"
        />
      </div>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Электричество</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageElectricity"
          dictKey="village_electricity"
          placeholder="Любой"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Канализация</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageCanalization"
          dictKey="village_canalization"
        />
      </div>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Преимущества</span>
      <ReactiveSelect
        store={complexFilterStore.filters}
        filterKey="villageAdvantages"
        dictKey="residential_complex_advantages"
      />
    </div>
  </>
);

export const SecondColumnFilter = memo(SecondColumnFilterRenderer);

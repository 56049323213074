import { memo, ReactElement } from 'react';

type Props<TabKey extends number> = {
  tabs: Record<TabKey, ReactElement>;
  currentActiveTab: TabKey;
};

export const TabContentRendererRender = <TabKey extends number>({
  currentActiveTab,
  tabs,
}: Props<TabKey>) => tabs[currentActiveTab] || null;

export const TabContentRenderer = memo<Props<any>>(
  TabContentRendererRender,
  (prev, next) => prev.currentActiveTab === next.currentActiveTab,
);

import { useFormikContext } from 'formik';
import useDeepCompareEffect from 'use-deep-compare-effect';
import deepEqual from 'fast-deep-equal';
import { FormikHelpers } from 'formik/dist/types';
import { usePrevState } from 'hooks';

type Props<Values extends {}> = {
  validationSchema?: any | (() => any);
  listenerCallback: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
  ) => void;
};

export const FormikValuesListener = <Values extends {}>({
  listenerCallback,
  validationSchema,
}: Props<Values>) => {
  const formikContext = useFormikContext<Values>();

  const { values } = formikContext;
  const prevValues = usePrevState(values);
  useDeepCompareEffect(() => {
    if (validationSchema && !validationSchema.isValidSync(values)) {
      return;
    }
    if (!deepEqual(prevValues, values)) {
      listenerCallback(values, formikContext);
    }
  }, [values]);
  return null;
};

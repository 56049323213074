import React from 'react';
import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import styles from './styles.module.css';

type Props = {
  onCreateNewOffer: () => void;
};

export const AddToOffersHeaderMobile = ({ onCreateNewOffer }: Props) => (
  <div>
    <h2>Добавить в подборку</h2>
    <ButtonBase
      className={styles.addToOffersHeaderCreateButton}
      variant="text"
      color="primary"
      onClick={onCreateNewOffer}
    >
      Создать новую
    </ButtonBase>
  </div>
);

/* eslint no-useless-escape: "off" */
import numeral from 'numeral';

/**
 * @desc сервис регистрирует новые типы форматирования и локализацию
 * */
export const numeralService = () => {
  numeral.register('locale', 'ru', {
    delimiters: {
      thousands: ' ',
      decimal: '.',
    },
    abbreviations: {
      thousand: 'тыс.',
      million: 'млн.',
      billion: 'млрд.',
      trillion: 'трлн.',
    },
    ordinal() {
      return '.';
    },
    currency: {
      symbol: '₽',
    },
  });
  numeral.locale('ru');

  numeral.register('format', 'meter_2', {
    regexps: {
      format: /(m2)/,
      unformat: /(m2)/,
    },
    format(value: number, format: string, roundingFunction) {
      const userFormat = format;
      let numberFormat: string | RegExp;

      const numberToFormat = (
        value: number,
        format: string,
        roundingFunction: any,
      ) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return
        numeral._.numberToFormat(value, numberFormat, roundingFunction);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      if (numeral._.includes(format, 'Rm2')) {
        numberFormat = format.replace(/\s?\Rm2/, '');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const output = numberToFormat(value, numberFormat, roundingFunction);
        return userFormat.replace(numberFormat, output).replace('Rm2', '₽/м²');
      }
      numberFormat = format.replace(/\s?\m2/, '');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const output = numberToFormat(value, numberFormat, roundingFunction);
      return userFormat.replace(numberFormat, output).replace('m2', 'м²');
    },
    unformat: (value: string) => parseInt(value, 10),
  });

  /**
   * @example
   * input: 79657858178
   * output: +7 (965) 785-81-78
   * */
  numeral.register('format', 'mobile', {
    regexps: {
      format: /\+?N?[\.\ \-]?\(?NNN\)?[\.\ \-]?NNN[\.\ \-]?NN-NN/,
      unformat: /\+?N?[\.\ \-]?\(?NNN\)?[\.\ \-]?NNN[\.\ \-]?NN-NN/,
    },
    format(value, formatString) {
      function format(phoneNumber: string, formatString: string) {
        phoneNumber = phoneNumber.toString();
        for (let i = 0, l = phoneNumber.length; i < l; i += 1) {
          formatString = formatString.replace('N', phoneNumber[i]);
        }
        return formatString;
      }

      return format(value, formatString);
    },
    unformat: (value: string) => parseInt(value, 10),
  });

  /**
   * @example
   * input: 79657858178
   * output: 7 965 785 81 78
   * */
  numeral.register('format', 'phone', {
    regexps: {
      format: /\+?N?[\.\ \-]?\(?NNN\)?[\.\ \-]?NNN[\.\ \-]?NN NN/,
      unformat: /\+?N?[\.\ \-]?\(?NNN\)?[\.\ \-]?NNN[\.\ \-]?NN NN/,
    },
    format(value, formatString) {
      function format(phoneNumber: string, formatString: string) {
        phoneNumber = phoneNumber.toString();
        for (let i = 0, l = phoneNumber.length; i < l; i += 1) {
          formatString = formatString.replace('N', phoneNumber[i]);
        }
        return formatString;
      }

      return format(value, formatString);
    },
    unformat: (value: string) => parseInt(value, 10),
  });
};

export const isInViewport = (
  element: HTMLElement,
  offsets?: {
    offsetTop?: number;
    offsetRight?: number;
    offsetBottom?: number;
    offsetLeft?: number;
  },
) => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top + (offsets?.offsetTop || 0) >= 0 &&
    rect.left + (offsets?.offsetLeft || 0) >= 0 &&
    rect.bottom - (offsets?.offsetBottom || 0) <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right - (offsets?.offsetRight || 0) <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

import React from 'react';
import { ApartmentDto } from 'services/v1/rc/apartment/types';
import { withProps } from 'recompose';
import {
  ColumnWithFilterAndSortBy,
  TableInstanceWithSort,
} from 'types/ReactTable';
import { columnAccessor } from 'utils';
import { CellProps, useFilters, useSortBy, useTable } from 'react-table';
import { DictionaryBadge } from 'components/atoms/DictionaryBadge';

import { flow, get } from 'lodash/fp';
import { ThFilter } from '../../index';
import {
  DictionaryView,
  Table,
  NumberFormat,
} from '../../../../../../../components/atoms';
import { THeader } from '../THeader';
import { TableBody } from '../TableBody';

const columns: Array<ColumnWithFilterAndSortBy<ApartmentDto>> = [
  {
    Header: 'Статус',
    disableFilters: false,
    disableSortBy: false,
    id: 'apartment_status',
    accessor: flow(get('catalogs'), get('apartment_status')),
    Cell: ({ value }: CellProps<ApartmentDto, number[]>) => (
      <DictionaryBadge
        badgeOuter
        dictKey="apartment_status"
        dictItemId={value}
      />
    ),
  },
  {
    Header: '№',
    accessor: columnAccessor<ApartmentDto>('number'),
    disableFilters: true,
  },
  {
    Header: 'Этаж',
    accessor: columnAccessor<ApartmentDto>('floor'),
    disableFilters: true,
  },
  {
    Header: 'Комнаты',
    Filter: withProps({
      filterKey: 'rooms',
      dictionaryKey: 'rooms',
    })(ThFilter),
    accessor: flow(get('catalogs'), get('rooms')),
    Cell: ({ value }: CellProps<ApartmentDto, number[]>) => (
      <DictionaryView dictKey="rooms" dictItemId={value} />
    ),
  },
  {
    Header: 'Тип',
    disableFilters: true,
    id: 'apartment_type_room',
    accessor: flow(get('catalogs'), get('apartment_type_room')),
    Cell: ({ value }: CellProps<ApartmentDto, number[]>) => (
      <DictionaryView dictKey="apartment_type_room" dictItemId={value} />
    ),
  },
  {
    Header: 'Площадь',
    accessor: columnAccessor<ApartmentDto>('square'),
    disableFilters: true,
    Cell: ({ value }: CellProps<ApartmentDto, string>) => (
      <NumberFormat value={parseFloat(value)} format="meter2" />
    ),
  },
  {
    Header: 'Стоимость',
    accessor: columnAccessor<ApartmentDto>('price'),
    disableFilters: true,
    Cell: ({ value }: CellProps<ApartmentDto, number>) => (
      <NumberFormat value={value} format="rub" />
    ),
  },
  {
    Header: '₽ / м²',
    accessor: columnAccessor<ApartmentDto>('price_m2'),
    disableFilters: true,
    Cell: ({ value }: CellProps<ApartmentDto, number>) => (
      <NumberFormat value={value} format="rub_meter2" />
    ),
  },
  {
    Header: 'Отделка',
    accessor: flow(get('catalogs'), get('residential_complex_finishing')),
    Filter: withProps({
      filterKey: 'finishing',
      dictionaryKey: 'residential_complex_finishing',
    })(ThFilter),
    Cell: ({ value }: CellProps<ApartmentDto, number[]>) => (
      <DictionaryView
        dictKey="residential_complex_finishing"
        dictItemId={value}
      />
    ),
  },
  {
    Header: 'Окна',
    id: 'apartment_window_type',
    accessor: flow(get('catalogs'), get('apartment_window_type')),
    Cell: ({ value }: CellProps<ApartmentDto, number[]>) => (
      <DictionaryView dictKey="apartment_window_type" dictItemId={value} />
    ),
    Filter: withProps({
      filterKey: 'windowType',
      dictionaryKey: 'apartment_window_type',
    })(ThFilter),
  },
];

type Props = {
  data: ApartmentDto[];
};

export const ApartmentTable = ({ data }: Props) => {
  const columnsMemo = React.useMemo(() => columns, []);

  const tableInstance: TableInstanceWithSort<ApartmentDto> =
    useTable<ApartmentDto>(
      {
        columns: columnsMemo,
        data,
      },
      useFilters,
      useSortBy,
    ) as unknown as TableInstanceWithSort<ApartmentDto>;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Table {...getTableProps()}>
      <THeader headerGroups={headerGroups} />
      <TableBody
        getTableBodyProps={getTableBodyProps}
        prepareRow={prepareRow}
        rows={rows}
      />
    </Table>
  );
};

import React, { useEffect, useMemo } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { FormikConfig, FormikHelpers } from 'formik/dist/types';
import { FeedbackEntity } from 'services';
import { feedbackSchema } from 'validationSchemas';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { FeedbackStore } from 'stores/FeedbackStore/FeedbackStore';
import { maskedInputValueToNumber } from 'utils/maskedInputValueToNumber';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { Alert } from 'components/atoms';
import { FormSubmitStatus } from 'components/atoms/Form/FormSubmitStatus';
import styles from './styles.module.css';

type Props = Pick<FormikConfig<FeedbackEntity>, 'component'> & {
  feedbackStore: FeedbackStore;
  successComponentProps?: {
    goBack?: () => void;
  };
  validationSchema?: any | (() => any);
};

export const BaseFeedbackFormRender = ({
  component,
  feedbackStore,
  successComponentProps,
  validationSchema,
}: Props) => {
  const { requestStatus, initialValues } = feedbackStore;
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => feedbackStore.clear, []);

  const onSubmit = async (
    values: FeedbackEntity,
    { resetForm }: FormikHelpers<FeedbackEntity>,
  ) => {
    const newValues = {
      ...values,
      phone: maskedInputValueToNumber(values.phone),
    };
    await feedbackStore.sendFeedbackData(newValues);
    if (!requestStatus.isError) {
      resetForm();
    }
  };

  const isShowForm = useMemo(
    () =>
      !requestStatus.isSubmitting ||
      (requestStatus.isSubmitting && requestStatus.isError),
    [
      requestStatus.isSubmitting,
      requestStatus.isError,
      requestStatus.isLoading,
    ],
  );
  const _successComponent = useMemo(
    () => () =>
      (
        <FormSubmitStatus
          className={styles.formSubmitStatusWrapper}
          onClick={successComponentProps?.goBack}
        />
      ),
    [successComponentProps],
  );

  const _component = useMemo(() => component, [isShowForm]);

  return (
    <>
      <Formik<FeedbackEntity>
        validationSchema={validationSchema || feedbackSchema}
        onSubmit={injectReCaptchaTokenToFormValues<FeedbackEntity>(
          executeRecaptcha,
        )(onSubmit)}
        component={isShowForm ? _component : _successComponent}
        initialValues={initialValues}
      />

      <Alert isShow={requestStatus.isSubmitting && requestStatus.isError}>
        Не удалось отправить форму. Проверьте подключение к интернету и обновите
        браузер
      </Alert>
    </>
  );
};

export const BaseFeedbackForm = withGoogleReCaptchaProvider<Props>(
  observer(BaseFeedbackFormRender),
);

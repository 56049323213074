import React, { ReactNode } from 'react';
import { Offers } from 'services';
import styles from './styles.module.css';

type Props = {
  children?: ReactNode;
} & Pick<Offers, 'title'>;

export const Header = ({ children, title }: Props) => (
  <header className={styles.header}>
    <div className={styles.headerTop}>
      <h1 className={styles.headerTitle}>{title}</h1>
      {/* @todo: скрыл пока нет функционала для отображения карты */}
      {/* <OfferTabs /> */}
    </div>
    {children}
  </header>
);

import { attemptWithAuth } from '../../../libs/axios/attempt';
import { API_ROUTES } from '../../../constants';
import { Feed } from '../../feed/types';
import { AttemptResult } from '../../../types';

export const userFeedService = {
  feedList: (): Promise<AttemptResult<Feed[]>> =>
    attemptWithAuth<Feed[]>({
      url: API_ROUTES.user.feed,
    }),
};

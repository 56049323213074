import React, { ComponentProps, ComponentType, useMemo } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { flow } from 'lodash/fp';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import {
  APP_ROUTES,
  Params,
  appRoutesService,
} from 'services/appRoutesService';
import { preventDefault } from 'utils/fp/preventDefault';
import { APP_ROUTES_MODAL, appModalRoutesService } from 'services';

type Props<Component extends {}> = {
  path?: keyof typeof APP_ROUTES;
  pathModal?: keyof typeof APP_ROUTES_MODAL;
  params?: Params;
  component?: ComponentType<Component>;
  replace?: boolean;
  innerRef?: React.Ref<HTMLAnchorElement>;
  className?: string;
  to?: string;
  searchParams?: object;
  [K: string]: any;
} & Partial<ComponentProps<typeof ButtonBase>> &
  Omit<NavLinkProps, 'to' | 'activeStyle'>;

export const BaseLink = <Component extends {}>({
  component,
  path,
  pathModal,
  params,
  replace,
  innerRef,
  className,
  exact,
  isActive,
  activeClassName,
  searchParams,
  to,
  ...rest
}: Props<Component>) => {
  const _path = useMemo(() => {
    if (pathModal) {
      if (replace) {
        return appModalRoutesService.replaceModalType(
          pathModal,
          params,
          searchParams,
        );
      }
      return appModalRoutesService.pushNewModalType(
        pathModal,
        params,
        searchParams,
      );
    }
    if (path) {
      return appRoutesService.replaceRoute(path, params, searchParams);
    }
    return '';
  }, [pathModal, params, path, searchParams]);

  return (
    <NavLink
      {...(rest as any)}
      replace={replace}
      innerRef={innerRef}
      exact={exact}
      isActive={isActive}
      activeClassName={activeClassName}
      to={_path || to}
      component={
        component ||
        (({ navigate, ...restComponent }: { navigate: () => void }) => (
          <ButtonBase
            {...rest}
            {...restComponent}
            tagName="a"
            onClick={flow(preventDefault, navigate)}
          />
        ))
      }
      className={className}
    />
  );
};

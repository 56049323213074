import { makeObservable, observable, action } from 'mobx';
import { ID, MayBe } from 'types';
import {
  AttemptResult,
  OffersAddItem,
  OffersApartmentAddParams,
  OffersApartmentAddResponse,
  OffersApartmentAddUpdateBody,
  OffersApartmentAddUpdateResponse,
  OffersApartmentAddUpdateUrlParams,
  OffersApartmentAddUrlParams,
  offersService,
  OffersService,
} from 'services';
import { OffersFilterStore } from './OffersFilterStore';
import { RequestApiStore } from '../request/RequestApiStore';
import { CancelTokenStore } from '../request/CancelTokenStore';

export class AddToOffersStore {
  checkedOffers: ID[] = [];

  offers: OffersAddItem[] = [];

  offersFilterStore: OffersFilterStore;

  targetApartment: MayBe<ID> = null;

  requestApiStore: RequestApiStore;

  saveRequestApiStore: RequestApiStore;

  offersService: OffersService;

  loadActiveOffersCancelToken: CancelTokenStore;

  saveOffersCancelTokenStore: CancelTokenStore;

  constructor(offersService: OffersService) {
    this.saveOffersCancelTokenStore = new CancelTokenStore();
    this.loadActiveOffersCancelToken = new CancelTokenStore();

    this.offersService = offersService;
    this.saveRequestApiStore = new RequestApiStore();
    this.requestApiStore = new RequestApiStore();
    this.offersFilterStore = new OffersFilterStore();
    makeObservable(this, {
      checkedOffers: observable,
      targetApartment: observable,
      offers: observable,

      clear: action,
      loadActiveOffers: action,
      saveOffers: action,
      toggleCheckedOffers: action,
      addCheckedOffers: action,
    });
  }

  includeCheckedOffers = (offer: ID) => this.checkedOffers.includes(offer);

  clear = () => {
    this.checkedOffers = [];
    this.offers = [];
    this.requestApiStore.clear();
    this.offersFilterStore.clear();
    this.targetApartment = null;
    this.loadActiveOffersCancelToken.executeCancelToken();
  };

  /** @desc вычислить новый список подборок для квартиры */
  toggleCheckedOffers = (offer: ID) => {
    if (this.includeCheckedOffers(offer)) {
      return this.checkedOffers.filter((id) => id !== offer);
    }

    return [...this.checkedOffers, offer];
  };

  /** @desc записать новый список подборок для квартиры */
  setCheckedOffers = (offers: ID[]) => {
    this.checkedOffers = observable.array(offers);
  };

  /**
   * @desc метод выполняет сохранение нового списка подборок на сервере,
   * а затем если запрос выполнен удачно, сохраняет список в стор.
   * */
  toggleCheckedOffersWithSave = async (offer: ID) => {
    if (!this.targetApartment) {
      // eslint-disable-next-line no-console
      console.error(`this.targetApartment is not define`);
      return;
    }
    const newOffers = this.toggleCheckedOffers(offer);
    const [error] = await this.saveOffers(
      {
        apartmentId: this.targetApartment,
      },
      {
        offerIds: newOffers,
      },
    );

    if (!error) {
      this.setCheckedOffers(newOffers);
    }
  };

  addCheckedOffers = (offers: ID[]) => {
    this.checkedOffers = observable.array(offers);
  };

  loadActiveOffers = async (
    urlParams: OffersApartmentAddUrlParams,
    params?: OffersApartmentAddParams,
  ): Promise<AttemptResult<OffersApartmentAddResponse>> => {
    this.loadActiveOffersCancelToken.executeCancelToken();

    this.requestApiStore.setLoading(true);
    const [error, result] = await this.offersService.apartmentAdd(
      urlParams,
      params,
      {
        cancelToken: this.loadActiveOffersCancelToken.cancelTokenSource?.token,
      },
    );

    if (error) {
      this.requestApiStore.setError(true);
    }

    if (result) {
      this.addCheckedOffers(
        result
          .filter(({ is_apartment_add }) => is_apartment_add)
          .map(({ id }) => id),
      );
      this.offers = result;
    }

    this.requestApiStore.setLoading(false);

    return [error, result];
  };

  saveOffers = async (
    urlParams: OffersApartmentAddUpdateUrlParams,
    body: OffersApartmentAddUpdateBody,
  ): Promise<AttemptResult<OffersApartmentAddUpdateResponse>> => {
    this.saveOffersCancelTokenStore.executeCancelToken();
    this.saveRequestApiStore.setLoading(true);
    const [error, result] = await this.offersService.apartmentAddUpdate(
      urlParams,
      body,
      {
        cancelToken: this.saveOffersCancelTokenStore.cancelTokenSource?.token,
      },
    );

    if (error) {
      this.saveRequestApiStore.setError(true);
    }

    this.saveRequestApiStore.setLoading(false);

    return [error, result];
  };
}

export const addToOffersStore = new AddToOffersStore(offersService);

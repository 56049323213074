import React from 'react';
import { MayBe } from 'types';
import { Company } from 'services';
import { ResidentialComplexLogo, Text } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  company?: MayBe<Company>;
  type: 'developer' | 'agency';
};

export const SalesDepartmentCompanyHeader = ({ company, type }: Props) => (
  <div className={styles.companyWrapper}>
    <div className={styles.companyLogo}>
      <ResidentialComplexLogo
        size="large"
        src={company?.logo?.logo || ''}
        alt="company logo"
      />
    </div>
    <div>
      <Text tagName="div" size="S" weight="bold">
        {company?.title}
      </Text>
      <Text tagName="div" size="XS">
        {type === 'developer' && <>Застройщик</>}
        {type === 'agency' && <>Агенство</>}
      </Text>
    </div>
  </div>
);

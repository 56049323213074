import { loggerService } from '../../../loggerService';
import { ThrowableResult } from '../../../types';
import { Stock } from './types';
import { makeRequestWithAuth } from '../../../libs/axios/makeRequest';
import { API_ROUTES } from '../../../constants';

export const stockService = {
  getStockById: async (stockId: string): Promise<ThrowableResult<Stock>> => {
    try {
      const response = await makeRequestWithAuth<Stock>({
        url: API_ROUTES.stock.replace(':stockId', stockId),
        method: 'get',
      });

      return response.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};

import React from 'react';
import { MultiValueProps } from 'react-select/src/components/MultiValue';
import { TagButton } from 'components/atoms';
import { Option } from '../../types';

export const MultiValue = ({
  removeProps,
  className,
  children,
  cx,
  getStyles,
  ...rest
}: MultiValueProps<Option, any>) => (
  <TagButton
    style={getStyles('multiValue', rest)}
    isSelected
    size="MD"
    onClick={removeProps.onClick as any}
  >
    <>{children}</>
  </TagButton>
);

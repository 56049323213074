import { useCallback } from 'react';
import { debounce } from 'lodash';
import { EditOfferStatusFormValues } from '../../types';
import { offerItemStore, offersEditStore } from '../../../../stores';
import { submissionErrorsHandler } from '../../../../utils/formik/submissionErrorsHandler';

export const useEditOfferStatusOnSubmit = () => {
  const onSubmit = useCallback(
    debounce(async (value: EditOfferStatusFormValues, formikHelper) => {
      if (!value?.offerId) {
        // eslint-disable-next-line no-console
        console.error(`[useEditOfferStatusOnSubmit]: offerId is not found`);
        return;
      }
      const [error] = await offersEditStore.editStatus({
        offerId: value?.offerId,
        statusId: value.statusId?.value?.id,
      });
      if (!error) {
        void (await offerItemStore.fetchOffer({
          offerId: value?.offerId,
        }));
      }
      if (error) {
        submissionErrorsHandler(error, formikHelper);
      }
    }, 250),
    [],
  );

  return {
    onSubmit,
  };
};

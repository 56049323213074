import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik/dist/types';
import { Alert, AlertSubmission } from 'components/atoms';
import { authPhoneValidateSchema } from 'validationSchemas';
// @todo: перенести необходимые стили в этот компонент
import styles from 'pages/AuthPage/pages/AuthPhoneValidatePage/styles.module.css';
import {
  appRoutesService,
  AuthPhoneChangeConfirmRequestBody,
  AuthPhoneCodeResponse,
  authPhoneService,
} from 'services';
import { FormRender } from 'pages/AuthPage/pages/AuthPhoneValidatePage/components/FormRender';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { useGetQueryString, useRequestApi } from 'hooks';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { changePhoneStore } from '../../stores/ChangePhoneStore';

export const ProfileConfirmNewPhonePopupRender = () => {
  const { query, setQuery } =
    useGetQueryString<
      Omit<AuthPhoneCodeResponse['auth'], 'description' | 'type'>
    >();
  const requestStateSendValidationCode = useRequestApi();
  const { setError, setSubmitting, clear } = useRequestApi();

  const { push } = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (
    values: AuthPhoneChangeConfirmRequestBody,
    formikHelpers: FormikHelpers<AuthPhoneChangeConfirmRequestBody>,
  ) => {
    clear();

    const [error, response] = await authPhoneService.changeConfirm(values);

    if (error) {
      submissionErrorsHandler(error, formikHelpers);
      setSubmitting(true);
      setError(true);
    } else if (response) {
      changePhoneStore.clear();
      rootProfileStore.authStore.authUser.setUser(response.user);
      push(appRoutesService.getRoute('profile'));
    }
  };

  const sendValidationCode = async () => {
    if (!executeRecaptcha || !changePhoneStore.state.newPhone) {
      return;
    }
    const token = await executeRecaptcha();

    const [error, response] = await authPhoneService.change({
      phone: changePhoneStore.state.newPhone,
      'g-recaptcha-response': token,
    });

    if (response) {
      setQuery({
        ...response.auth,
      });
    } else if (error) {
      requestStateSendValidationCode.setSubmitting(true);
      requestStateSendValidationCode.setError(true);
    }
  };

  useEffect(() => {
    if (!executeRecaptcha) {
      return;
    }
    if (!changePhoneStore.state.newPhone) {
      push(appRoutesService.getRoute('profileNewPhone'));
      return;
    }
    if (!changePhoneStore.state.oldPhoneIsConfirm) {
      push(appRoutesService.getRoute('profileConfirmOldPhone'));
      return;
    }
    void sendValidationCode();
  }, [changePhoneStore.state.newPhone, executeRecaptcha]);

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
      mobileHeaderChildrenTitle="Подтверждение номера"
    >
      <Formik<AuthPhoneChangeConfirmRequestBody>
        initialValues={{
          phone: changePhoneStore.state.newPhone || '',
          authId: query.id,
        }}
        validationSchema={authPhoneValidateSchema}
        onSubmit={injectReCaptchaTokenToFormValues<AuthPhoneChangeConfirmRequestBody>(
          executeRecaptcha,
        )(onSubmit)}
        render={(props) => (
          <AuthFormWrapper
            className={styles.wrapper}
            showButtonBack
            onClickButtonBack={() => {
              push(appRoutesService.getRoute('profile'));
            }}
          >
            <div className={styles.formWrapper}>
              <FormRender
                {...props}
                UIDictionary={{
                  title: 'Теперь мы позвоним на новый номер',
                  subTitle:
                    'Введите последние 4 цифры номера, с которого поступит звонок',
                }}
                reCall={sendValidationCode}
              />
            </div>

            <AlertSubmission isSubmitting errors={props.errors} />

            <Alert
              isShow={
                requestStateSendValidationCode.isError &&
                requestStateSendValidationCode.isSubmitting
              }
            >
              Не удалось выполнить звонок. Проверьте подключение к интернету и
              обновите браузер
            </Alert>
          </AuthFormWrapper>
        )}
      />
    </AuthPopup>
  );
};

export const ProfileConfirmNewPhonePopup = withGoogleReCaptchaProvider(
  observer(ProfileConfirmNewPhonePopupRender),
);

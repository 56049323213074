import { useMediaQuery } from 'beautiful-react-hooks';
import { TabsView, TabsContext } from 'components/containers/Tabs';
import { TabsSelect } from 'components/containers/TabsSelect';
import React, { useContext } from 'react';

export const OffersStatusTabs = () => {
  const { currentActiveTab, changeTab, tabList } = useContext(TabsContext);
  const isLarge = useMediaQuery('(min-width: 1024px)');

  if (isLarge) {
    return (
      <TabsView<any>
        currentActiveTab={currentActiveTab}
        changeTab={changeTab}
        tabList={tabList}
      />
    );
  }

  return (
    <TabsSelect<any>
      currentActiveTab={currentActiveTab}
      changeTab={changeTab}
      tabList={tabList}
    />
  );
};

import React, { ReactNode } from 'react';
import cc from 'classcat';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  isConfirmed: boolean;
  isEdit: boolean;
  UIDictionary: {
    /** */
    confirmationWait: string;
    /** */
    confirmationOk: string;
    /** кнопка изменить */
    openEdit: string;
    /** кнопка сохранить */
    save: string;
  };

  onEdit: () => void;
  onCancel: () => void;
  onSave: () => void;

  className?: string;
};

export const ConfirmationAndChangeComponent = ({
  children,
  UIDictionary,
  isConfirmed,
  isEdit,
  onEdit,
  onCancel,
  onSave,
  className,
}: Props) => (
  <div className={cc([styles.wrapper, className])}>
    {children}

    {/** по умолчанию */}
    <div
      className={cc([
        styles.controlBar,
        {
          [styles.hide]: isEdit,
        },
      ])}
    >
      <div
        className={cc([
          styles.status,
          {
            [styles.statusConfirmed]: isConfirmed,
          },
        ])}
      >
        {isConfirmed && UIDictionary.confirmationOk}
        {!isConfirmed && UIDictionary.confirmationWait}
      </div>

      <div className={styles.controlBarRight}>
        <ButtonBase
          color="primary"
          size="medium"
          variant="text"
          onClick={onEdit}
        >
          {UIDictionary.openEdit}
        </ButtonBase>
      </div>
    </div>

    {/** редактирование */}
    <div
      className={cc([
        styles.controlBarEdit,
        {
          [styles.hide]: !isEdit,
        },
      ])}
    >
      <div
        className={cc([
          styles.status,
          styles.controlBarEditStatus,
          {
            [styles.statusConfirmed]: isConfirmed,
          },
        ])}
      >
        {isConfirmed && UIDictionary.confirmationOk}
        {!isConfirmed && UIDictionary.confirmationWait}
      </div>

      <div className={styles.controlBarEditRight}>
        <>
          <ButtonBase
            color="primary"
            size="medium"
            variant="text"
            onClick={onCancel}
          >
            Отменить
          </ButtonBase>
          <ButtonBase
            color="primary"
            size="medium"
            variant="text"
            onClick={onSave}
          >
            {UIDictionary.save}
          </ButtonBase>
        </>
      </div>
    </div>
  </div>
);

import React from 'react';
import { MayBe } from 'types';
import { Badge } from 'components/atoms/Badge';
import { Text } from 'components/atoms/Text';

import styles from './styles.module.css';

type Props = {
  is_working?: MayBe<boolean>;
};

export const CallToActionStatus = ({ is_working }: Props): JSX.Element => (
  <div className={styles.callToActionStatus}>
    {!is_working && (
      <>
        <Badge size="small" color="#DD6465" className={styles.statusBadge} />
        <Text size="XS">Сейчас закрыто</Text>
      </>
    )}
    {is_working && (
      <>
        <Badge size="small" color="#20CB6A" className={styles.statusBadge} />{' '}
        <Text size="XS">Сейчас на связи</Text>
      </>
    )}
  </div>
);

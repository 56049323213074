import React, { useCallback, useState } from 'react';
import { useFileUpload } from 'use-file-upload';
import { observer } from 'mobx-react';
import { Text, Avatar, Skeleton } from 'components/atoms';
import avatarPlaceholder from 'assets/noAvatar.svg';
import { ResponseStatus, userProfileService } from 'services';
import { FormValidationStatusesView } from 'components/atoms/Form/FormValidationStatusesView';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { useRequestApi } from 'hooks';
import { fileValidate } from '../../utils';

import styles from './styles.module.css';
import { UserProfileForm } from '../../types';

type Props = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  values: UserProfileForm;
};

export const ImageUploaderRender = ({ setFieldValue, values }: Props) => {
  const [, selectFile] = useFileUpload();
  const { logo, role, name, surname } = values;
  const { authUser } = rootProfileStore.authStore;
  const [fileError, setFileError] = useState<undefined | string>();
  const { isLoading, setLoading } = useRequestApi();

  const uploadFile = useCallback(async (file) => {
    try {
      setLoading(true);
      const response = await userProfileService.saveUserAvatar(file as File);
      if (response) {
        setFieldValue('logo', response);
        // eslint-disable-next-line no-unused-expressions
        authUser.user &&
          authUser.setUser({
            ...authUser.user,
            logo: response,
          });
      }
    } catch (error) {
      if (error.response) {
        const err = error.response.data as ResponseStatus;
        setFileError(err?.message);
      } else {
        setFileError(
          'Не удалось загрузить файл. Проверьте подключение к интернету и обновите браузер',
        );
      }
    }
    setLoading(false);
  }, []);

  return (
    <>
      <FormValidationStatusesView
        className={styles.mainInfoAvatarWrapper}
        classNameError={styles.mainInfoAvatarError}
        isError={!!fileError}
        error={fileError}
      >
        {!isLoading && (
          <button
            className={styles.mainInfoAvatar}
            type="button"
            onClick={() => {
              selectFile(
                { accept: 'image/*', multiple: false },
                ({ file }: any) => {
                  fileValidate
                    .validate(file)
                    .then(() => {
                      setFileError(undefined);
                      void uploadFile(file);
                    })
                    .catch((err) => {
                      setFileError(err.message);
                    });
                },
              );
            }}
          >
            <Avatar
              src={logo?.src || logo?.url || avatarPlaceholder}
              className={styles.avatarImage}
            />
          </button>
        )}
        {isLoading && (
          <Skeleton
            color="#d3d3e2"
            animation="wave"
            className={styles.mainInfoAvatarLoader}
          />
        )}
      </FormValidationStatusesView>
      <div className={styles.userInfoBlock}>
        <Text weight="bold" tagName="h3" className={styles.userInitials}>
          {name} {surname}
        </Text>
        {role && <Text className={styles.userRole}>{role.label}</Text>}
      </div>
    </>
  );
};

export const ImageUploader = observer(ImageUploaderRender);

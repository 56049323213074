import React, { memo, ReactElement, useMemo } from 'react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { EmptyData, LinkButton } from 'components/atoms';
import { buildingFilters } from 'stores';
import { getCountActiveFilter } from 'components/containers/Search/utils';
import styles from './styles.module.css';

type Props = {
  /** @desc  */
  isLoading?: boolean;

  apartmentCount: number;
  children: ReactElement;
};

export const ApartmentsNotFoundRender = ({
  apartmentCount,
  children,
  isLoading,
}: Props) => {
  const buildingHasNotApartmentsByFilter =
    !apartmentCount &&
    getCountActiveFilter(toJS(buildingFilters.filters.filterToApiFormat)) === 0;
  const buildingHasNotApartments = useMemo(
    () => !apartmentCount,
    [apartmentCount],
  );

  if (!isLoading && buildingHasNotApartmentsByFilter) {
    return (
      <EmptyData title="В корпусе нет квартир" className={styles.wrapper} />
    );
  }

  if (!isLoading && buildingHasNotApartments) {
    return (
      <EmptyData
        title="Для вашего запроса нет результатов"
        description={
          <>
            Попробуйте изменить фильтры или{' '}
            <LinkButton onClick={buildingFilters.filters.reset}>
              сбросить поиск
            </LinkButton>
          </>
        }
        className={styles.wrapper}
      />
    );
  }

  return children;
};

export const ApartmentsNotFound = memo(observer(ApartmentsNotFoundRender));

import React, { useState, createContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { complexFilterStore } from 'stores';
import { toJS } from 'mobx';

const viewContext = createContext<any>(null);

const { Provider } = viewContext;

type Params = { complexId: any };

const ViewProvider = (props: { children: ReactNode }) => {
  const initFilters = {};
  const { filters } = complexFilterStore.filters;
  const { complexId: currentComplexId } = useParams<Params>();
  const [villageFilter, setVillageFilter] = useState(filters);
  const [villageData, setVillageData] = useState({});

  const fetchData = (statusId: string) => {
    const searchParams = new URLSearchParams(villageFilter as any).toString();

    axios
      .get(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${process.env.REACT_APP_API_V1}/v1/village/cottages?complexIds=${currentComplexId}&${searchParams}&cottageStatus=${statusId}`,
      )
      .then((res) => {
        if (res?.data) {
          setVillageData((prevState) => ({
            ...prevState,
            [statusId]: res.data,
          }));
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
  };

  const fetchAll = () => {
    fetchData('building');
    fetchData('finished');
    fetchData('template');
  };

  useEffect(() => {
    if (Object.keys(villageFilter).length) {
      fetchAll();
    }
  }, [villageFilter]);

  useEffect(() => {
    if (!Object.keys(villageData).length) {
      fetchAll();
    }
  }, [villageData]);

  const handleChangeVillageFilter = (e: { target: any }) => {
    const { name, value } = e.target;
    setVillageFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetVillageFilter = () => {
    setVillageFilter(initFilters);
    fetchAll();
  };

  const states = {
    villageFilter,
    villageData,
  };

  const actions = {
    handleChangeVillageFilter,
    handleResetVillageFilter,
  };

  return <Provider value={{ ...states, ...actions }} {...props} />;
};

export { ViewProvider, viewContext };

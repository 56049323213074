import { useEffect, useMemo, useRef, useState } from 'react';
import { throttle } from 'lodash';
import { DIRECTION_DOWN, DIRECTION_UP } from 'hammerjs';
import { MayBe } from '../../../../../types';

type Props = {
  onClose: () => void;
  onOpen: () => void;
  isFullInfo: boolean;
};

export const useApartmentViewHammer = ({
  onClose,
  onOpen,
  isFullInfo,
}: Props) => {
  const [position, setPosition] = useState(0);
  const swipeButton = useRef<HTMLButtonElement | HTMLDivElement | null>();

  const hammerElement: MayBe<HammerManager> = useMemo(() => {
    if (swipeButton.current) {
      const manager = new Hammer.Manager(swipeButton.current);
      manager.add(new Hammer.Pan());
      manager.add(new Hammer.Tap());

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return manager;
    }
    return null;
  }, [swipeButton.current]);

  const panMoveDown = (evt: HammerInput) => {
    const minDeltaY = 185 / 4;
    if (Math.abs(evt.deltaY) >= minDeltaY) {
      onClose();
    } else {
      setPosition(evt.deltaY);
    }
  };

  const panMoveUp = (evt: HammerInput) => {
    const maxDeltaY = 185 / 2;
    if (Math.abs(evt.deltaY) >= maxDeltaY) {
      onOpen();
    } else {
      setPosition(evt.deltaY);
    }
  };

  const panEnd = () => {
    if (!isFullInfo) {
      setPosition(0);
    }
  };

  useEffect(() => {
    if (hammerElement) {
      hammerElement.on(
        'panmove',
        throttle((evt) => {
          if (isFullInfo) {
            return;
          }
          if (evt.direction === DIRECTION_DOWN) {
            panMoveDown(evt);
            return;
          }

          if (evt.direction === DIRECTION_UP && !isFullInfo) {
            panMoveUp(evt);
          }
        }, 50),
      );
      hammerElement.on('panend', panEnd);
      hammerElement.on('tap', onOpen);
    }
  }, [hammerElement, isFullInfo]);

  return {
    position,
    swipeButton,
  };
};

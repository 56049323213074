import { loggerService } from 'services';
import { MayBe } from 'types';

export const STATUS_PERMIT_VIEW_APARTMENT = 'STATUS_PERMIT_VIEW_APARTMENT';

type State = {
  state: {
    isAccessToView: boolean;
  };
};
/**
 * @desc хранит состояние статуса доступа к квартирам для не авторизованного пользователя
 * */
export const apartmentServiceStorage = {
  setStatusPermitViewApartment: (state: State) => {
    localStorage.setItem(STATUS_PERMIT_VIEW_APARTMENT, JSON.stringify(state));
  },
  getStatusPermitViewApartment: (): MayBe<State> => {
    try {
      const userRawData = localStorage.getItem(STATUS_PERMIT_VIEW_APARTMENT);

      if (userRawData) {
        return (JSON.parse(userRawData) as State) || null;
      }
    } catch (e) {
      loggerService.error(e);
    }
    return null;
  },
  removeStatusPermitViewApartment: () => {
    localStorage.removeItem(STATUS_PERMIT_VIEW_APARTMENT);
  },
};

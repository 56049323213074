import React, { ReactElement, ReactNode, MouseEvent } from 'react';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import styles from './styles.module.css';

/**
 * КНОПКА УСТАРЕЛА ИЗ-ЗА ПЛОХОЙ РЕАЛИЗАЦИИ
 * */

/** @deprecated */
type DropdownButtonProps = {
  overlayContent?: ReactElement;
  className?: string;
  variant?: string;
  dropDownClassName?: string;
  children: ReactNode;
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
};

/** @deprecated */
export const DropdownButton = ({
  dropDownClassName,
  className = '',
  variant = 'secondary',
  overlayContent,
  children,
  onVisibleChange,
  visible,
}: DropdownButtonProps) => {
  const overlay = (
    <div className={`${styles.dropdownOverlay} ${dropDownClassName || ''}`}>
      {overlayContent}
    </div>
  );

  return (
    <Dropdown
      trigger={['click']}
      onVisibleChange={onVisibleChange}
      visible={visible}
      overlay={overlay}
      animation="slide-up"
    >
      <button
        type="button"
        className={`${styles.button} ${styles[variant]} ${className}`}
      >
        {children}
      </button>
    </Dropdown>
  );
};

/** @deprecated */
type ButtonType = 'button' | 'submit' | 'reset';

/** @deprecated */
type ButtonProps = {
  onClick?: (e: MouseEvent) => void;
  children: ReactNode;
  className?: string;
  variant?: string;
  type?: ButtonType;
  dropdown?: ReactElement;
  dropDownClassName?: string;
  style?: React.CSSProperties;
  onVisibleChange?: (visible: boolean) => void;
  visible?: boolean;
};

/** @deprecated */
export const Button = ({
  onClick,
  type = 'button',
  className = '',
  variant = 'secondary',
  dropdown,
  dropDownClassName,
  children,
  style = {},
  onVisibleChange,
  visible,
}: ButtonProps) => {
  if (dropdown) {
    return (
      <DropdownButton
        onVisibleChange={onVisibleChange}
        visible={visible}
        dropDownClassName={dropDownClassName}
        overlayContent={dropdown}
        className={className}
        variant={variant}
      >
        {children}
      </DropdownButton>
    );
  }

  return (
    /* eslint-disable react/button-has-type */
    <button
      type={type}
      onClick={onClick}
      className={`${styles.button} ${styles[variant]} ${className}`}
      style={style}
    >
      {children}
    </button>
  );
};

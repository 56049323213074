import { appRoutesService } from '../../../services';

export const useHrefToClipboard = () => ({
  onHrefToClipboard: () => {
    void navigator.clipboard.writeText(window.location.href);
  },
});

export const useApartmentHrefToClipboard = (
  offerId?: string | number | null,
  hash?: string,
  apartmentId?: string | number | null,
) => {
  const getLinkToApartment = () => {
    if (!offerId || !hash || !apartmentId) {
      // eslint-disable-next-line no-console
      console.error(
        `One of thinks not found: hash=${hash?.toString() || ''}; apartmentId=${
          apartmentId || ''
        } ;offerId=${offerId || ''}`,
      );
      return '';
    }

    return [
      window.location.origin,
      appRoutesService.replaceRoute('offersApartmentView', {
        offerId: offerId.toString(),
        apartmentId: apartmentId.toString(),
        hash,
      }),
    ].join('');
  };

  const onHrefToClipboard = () => {
    void navigator.clipboard.writeText(getLinkToApartment());
  };

  return {
    getLinkToApartment,
    onHrefToClipboard,
  };
};

import React from 'react';
import { Stock } from 'services';
import { PromotionsHeader } from '../PromotionsHeader';
import { ComplexContent } from '../ComplexContent';

type Props = {
  promotion: Stock;
};

export const PromotionContent = ({ promotion }: Props) => (
  <>
    <PromotionsHeader
      title={promotion.title}
      start_at={promotion.start_at}
      end_at={promotion.end_at}
      is_active={promotion.is_active}
      logo={promotion.logo}
    />
    <ComplexContent
      description={promotion.description}
      salesDepartments={promotion.salesDepartments}
    />
  </>
);

import { createContext } from 'react';

export type IsEditOfferContextProps = {
  isOfferPreview: boolean;
  isEditOffer: boolean;
};

export const IsEditOfferContext = createContext<IsEditOfferContextProps>({
  isOfferPreview: false,
  isEditOffer: false,
});

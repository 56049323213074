import { API_ROUTES } from 'services/constants';
import { InfoDto, District, CityInfo } from './types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { loggerService } from '../../../loggerService';
import {AttemptResult, ThrowableResult} from '../../../types';
import { attemptWithAuth } from 'services/libs/axios/attempt';

export const infoService = {
  getInfo: async (): Promise<AttemptResult<InfoDto>> =>
      attemptWithAuth<InfoDto>({
        url: API_ROUTES.info,
        method: 'get',
      }),
  async getCities(): Promise<ThrowableResult<CityInfo[]>> {
    try {
      const response = await makeRequest<CityInfo[]>({
        method: 'get',
        url: API_ROUTES.cities,
      });
      return response?.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  async getDistricts(cityId: number): Promise<ThrowableResult<District[]>> {
    try {
      const response = await makeRequest<District[]>({
        method: 'get',
        url: API_ROUTES.districts.replace(':cityId', cityId.toString()),
      });

      return response.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};

import { FavoritesIdsStoreState } from 'stores';
import { loggerService } from '../../loggerService';

export const FAVORITES_IDS_STORAGE = 'FAVORITES_IDS_STORAGE';

type FavoritesIdsStorageState = {
  state: FavoritesIdsStoreState;
};

export const favoritesIdsStorage = {
  get: () => {
    try {
      const favoritesRawData = localStorage.getItem(FAVORITES_IDS_STORAGE);

      if (favoritesRawData) {
        return (
          (JSON.parse(favoritesRawData) as FavoritesIdsStorageState) || null
        );
      }
    } catch (e) {
      loggerService.error(e);
    }
    return null;
  },
  set: (favoritesIds: FavoritesIdsStorageState) => {
    localStorage.setItem(FAVORITES_IDS_STORAGE, JSON.stringify(favoritesIds));
  },
  remove: () => {
    localStorage.removeItem(FAVORITES_IDS_STORAGE);
  },
};

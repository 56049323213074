import { ApartmentDto, DictionaryKeys } from 'services';
import { UIPropertyVisibilityFilters } from 'stores/index';
import { UIPropertyVisibilityFiltersOption } from 'stores/UI/UIPropertyVisibilityFilters/types';

export type ApartmentsViewFilterValues = Pick<ApartmentDto, 'price_m2'> &
  Pick<ApartmentDto, 'price'> &
  Pick<ApartmentDto, 'square'> &
  Pick<ApartmentDto, 'layouts'> & //
  Pick<Record<DictionaryKeys, number[]>, 'apartment_type_room'> &
  Pick<Record<DictionaryKeys, number[]>, 'rooms'> &
  Pick<Record<DictionaryKeys, number[]>, 'residential_complex_finishing'>;

export type ApartmentUIPropertyVisibilityFilterOptions =
  UIPropertyVisibilityFiltersOption<keyof ApartmentsViewFilterValues>[];

export const apartmentUIPropertyVisibilityFilterOptions: ApartmentUIPropertyVisibilityFilterOptions =
  [
    {
      label: 'Тип помещения',
      value: 'apartment_type_room',
      active: true,
    },
    {
      label: 'Отделка',
      value: 'residential_complex_finishing',
      active: true,
    },
    {
      label: 'Тип квартиры',
      value: 'rooms',
      active: true,
    },
    {
      label: 'Цена/м²',
      value: 'price_m2',
      active: true,
    },
    {
      label: 'Площадь',
      value: 'square',
      active: true,
    },
    {
      label: 'Стоимость',
      value: 'price',
      active: true,
    },
  ];

export const apartmentUIPropertyVisibilityFilter =
  new UIPropertyVisibilityFilters<ApartmentsViewFilterValues>(
    apartmentUIPropertyVisibilityFilterOptions,
  );

import { useMemo } from 'react';
import { TabItemProps } from '../../../components/containers/Tabs';
import { BuildingEntity } from '../../../services';
import { getBuildingsTabs } from '../utils';

type Props = {
  buildings?: Array<BuildingEntity>;
};

export const useCreateBuildingsTabs = ({
  buildings,
}: Props): TabItemProps<BuildingEntity>[] =>
  useMemo<TabItemProps<BuildingEntity>[]>(
    (): TabItemProps<BuildingEntity>[] => getBuildingsTabs(buildings),
    [buildings],
  );

import React, { ReactNode } from 'react';
import { Marker } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import fullscreenIcon from 'assets/fullscreen.svg';
import { BaseMapContainer, MobilePopup } from 'components/atoms';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { useModal } from 'react-modal-hook';
import { MayBe } from 'types';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { useMediaQuery } from 'beautiful-react-hooks';
import { MapPin } from '../../../../../pages/Main/containers/LeafletMap/components/MapMarker';
import { useMobileAddressMapPopup } from '../../../MobileAddressMapPopup';

import styles from './styles.module.css';
import { Icon } from '../../../../atoms/Icon';

type Props = {
  centerLatLng: LatLngTuple;
  address?: MayBe<string>;
  children?: ReactNode;
};

export const SalesDepartmentOnMap = ({
  centerLatLng,
  children,
  address,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const { showMobileAddressMapPopup } = useMobileAddressMapPopup({
    centerLatLng,
    address,
  });

  return (
    <div className={styles.mapWrapper}>
      <BaseMapContainer
        hideZoomControl={!isLarge}
        crs={L.CRS.EPSG3395}
        center={centerLatLng}
        zoom={18}
      >
        <YandexMapLayer />
        <Marker icon={MapPin} position={centerLatLng} />
        {children}
        <ButtonBase
          icon={<Icon size={16} src={fullscreenIcon} alt="fullscreen" />}
          className={styles.openMapButton}
          onClick={showMobileAddressMapPopup}
        >
          Открыть карту
        </ButtonBase>
      </BaseMapContainer>
    </div>
  );
};

export const useSalesDepartmentOnMapMobilePopup = (
  props: Pick<Props, 'centerLatLng'>,
) => {
  const [
    showSalesDepartmentOnMapMobilePopup,
    hideSalesDepartmentOnMapMobilePopup,
  ] = useModal(() => (
    <MobilePopup isOpen onClose={hideSalesDepartmentOnMapMobilePopup}>
      <SalesDepartmentOnMap {...props} />
    </MobilePopup>
  ));

  return {
    showSalesDepartmentOnMapMobilePopup,
    hideSalesDepartmentOnMapMobilePopup,
  };
};

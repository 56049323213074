import React, { ComponentProps, memo } from 'react';
import { observer } from 'mobx-react';
import deepEqual from 'fast-deep-equal';
import { ComplexInfo } from '../../components/ComplexInfo';
import { HeaderRight } from '../../components/HeaderRight';
import { refreshStore } from '../../stores/RefreshStore/RefreshStore';
import { complexItemStore } from '../../../../stores/ComplexItemStore/store';
import style from './styles.module.css';
import { ComplexInfoSkeleton } from '../../components/ComplexInfoSkeleton';

type Props = {
  headerProps: ComponentProps<typeof HeaderRight>;
};

export const LayoutPageHeaderDesktopRender = ({ headerProps }: Props) => {
  const { target: complexData, requestApi } = complexItemStore;

  return (
    <div className={style.layoutPageHeaderDesktopWrapper}>
      <header className={style.layoutPageHeaderDesktopHeader}>
        <div className={style.layoutPageHeaderDesktopLeftContent}>
          {requestApi.isLoading && <ComplexInfoSkeleton />}
          {!requestApi.isLoading && (
            <ComplexInfo
              onRefresh={refreshStore.call}
              updated_at={complexData?.updated_at}
              logo={complexData?.logo || null}
              title={complexData?.title || ''}
              address={complexData?.address}
            />
          )}
        </div>

        <div className={style.layoutPageHeaderDesktopRightContent}>
          <HeaderRight {...headerProps} />
        </div>
      </header>
    </div>
  );
};

export const LayoutPageHeaderDesktop = memo(
  observer(LayoutPageHeaderDesktopRender),
  deepEqual,
);

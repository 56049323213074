import { MayBe } from 'types';

export const getLinkToMap = (
  lng?: MayBe<number | string>,
  lat?: MayBe<number | string>,
  zoom = 18,
): MayBe<string> => {
  if (!lng || !lat) {
    return null;
  }

  return `https://yandex.ru/maps/?pt=${lng},${lat}&z=${zoom}&l=map`;
};

import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { TabContentRenderer } from 'components/atoms';
import { useTabs } from 'components/containers/Tabs';

import { ID } from 'types';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { createCountFormatter } from 'utils/createCountFormatter';
import { ComplexesList } from './components/ComplexesList';

import { ApartmentsList } from './components/ApartmentsList';
import { LayoutTabsEnum } from './types';
import { FavoritesPageHeader } from './components/FavoritesPageHeader';

const formatApartmentsTitle = createCountFormatter({
  few: 'квартир',
  one: 'квартира',
  two: 'квартиры',
});

const getComplexTitle = (complexIds: ID[]) => {
  if (complexIds.length) {
    return `${complexIds.length} ЖК в избранном`;
  }
  return undefined;
};

const getApartmentTitle = (apartmentIds: ID[]) => {
  if (apartmentIds.length) {
    return `${apartmentIds.length} ${formatApartmentsTitle(
      apartmentIds.length,
    )} в избранном`;
  }
  return undefined;
};

export const FavoritesPageRenderer = () => {
  const {
    state: { apartmentIds, complexIds },
  } = rootProfileStore.favoritesIdsStore;

  const favoritesTabsList = useMemo(
    () => [
      {
        label: 'Жилые комплексы',
        badgeText: complexIds.length.toString(),
      },
      {
        label: 'Квартиры',
        badgeText: apartmentIds.length.toString(),
      },
    ],
    [apartmentIds.length, complexIds.length],
  );

  const { currentActiveTab, changeTab, setTabList } = useTabs({
    tabList: favoritesTabsList,
  });

  useEffect(() => {
    setTabList(favoritesTabsList);
  }, [favoritesTabsList]);

  const title = useMemo(() => {
    let title: string | undefined;
    if (currentActiveTab === 0) {
      title = getComplexTitle(complexIds);
      return title || 'Объектов нет';
    }
    if (currentActiveTab === 1) {
      title = getApartmentTitle(apartmentIds);
      return title || 'Квартир нет';
    }

    return '';
  }, [apartmentIds.length, complexIds.length, currentActiveTab]);

  return (
    <>
      <FavoritesPageHeader
        tabList={favoritesTabsList}
        changeTab={changeTab}
        currentActiveTab={currentActiveTab}
        title={title}
      />
      <TabContentRenderer
        tabs={{
          [LayoutTabsEnum.complexes]: <ComplexesList />,
          [LayoutTabsEnum.apartments]: <ApartmentsList />,
        }}
        currentActiveTab={currentActiveTab}
      />
    </>
  );
};

export const FavoritesPage = observer(FavoritesPageRenderer);

import React, { Fragment } from 'react';
import { Skeleton } from 'components/atoms';
import { FeedListWrapper } from '../FeedListWrapper';
import styles from '../FeedItem/styles.module.css';

export const FeedPreloader = () => (
  <FeedListWrapper>
    {Array.from({
      length: 3,
    }).map((_, index) => (
      <Fragment key={index.toString()}>
        <Skeleton
          width={70}
          height={16}
          animation="wave"
          highlightColor="rgba(35, 46, 73, 0.05)"
        />
        <div className={styles.feedItemWrapper}>
          <div className={styles.feedItemFieldWrapper}>
            <Skeleton
              width={190}
              height={40}
              animation="wave"
              highlightColor="rgba(35, 46, 73, 0.05)"
            />
            <Skeleton
              width={40}
              height={40}
              animation="wave"
              highlightColor="rgba(35, 46, 73, 0.05)"
            />
            <Skeleton
              width={40}
              height={40}
              animation="wave"
              highlightColor="rgba(35, 46, 73, 0.05)"
            />
          </div>
        </div>
      </Fragment>
    ))}
  </FeedListWrapper>
);

import { memo, createElement } from 'react';
import { observer } from 'mobx-react';
import { join } from 'lodash/fp';
import { DictionaryKeys, findDictItemValue } from 'services';
import { infoStore } from 'stores/InfoStore';

type Props = {
  dictKey: DictionaryKeys;
  dictItemId: number[];
  className?: string;
  tagName?: string;
  dictStore?: typeof infoStore;
};

export const DictionaryViewRender = ({
  dictKey,
  dictItemId,
  className,
  tagName,
  dictStore,
  ...rest
}: Props) =>
  createElement(
    tagName || 'span',
    {
      className,
      ...rest,
    },
    join(', ')(findDictItemValue(dictKey, dictItemId)(dictStore?.dictionaries)),
  );

DictionaryViewRender.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dictStore: infoStore,
};

export const DictionaryView = memo(observer(DictionaryViewRender));

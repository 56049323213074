import React from 'react';
import { observer } from 'mobx-react';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { FormikMaskedInput } from 'components/atoms';
import { appRoutesService } from 'services';
import { ConfirmationAndChangeComponent } from '../ConfirmationAndChangeComponent';
import { changePhoneStore } from '../../../../stores/ChangePhoneStore';
import styles from '../UserFormRender/styles.module.css';

const UIDictionary = {
  confirmationWait: 'Подтвердите переход на новый номер',
  confirmationOk: 'Подтвержден',
  openEdit: 'Изменить номер',
  save: 'Подтвердить',
};

export const ChangePhoneNumberRender = () => {
  const { push } = useHistory();

  const isConfirmed = !changePhoneStore.state.isChange;

  const onEdit = () => {
    if (changePhoneStore.state.isChange) {
      if (!changePhoneStore.state.newPhone) {
        push(appRoutesService.getRoute('profileNewPhone'));
        return;
      }

      if (!changePhoneStore.state.oldPhoneIsConfirm) {
        push(appRoutesService.getRoute('profileConfirmOldPhone'));
        return;
      }
      if (!changePhoneStore.state.newPhoneIsConfirm) {
        push(appRoutesService.getRoute('profileConfirmNewPhone'));
      }
    } else {
      changePhoneStore.state.isChange = true;
      push(appRoutesService.getRoute('profileNewPhone'));
    }
  };

  return (
    <>
      <ConfirmationAndChangeComponent
        className={styles.formMainField}
        isEdit={changePhoneStore.state.isChange}
        isConfirmed={isConfirmed}
        onSave={onEdit}
        onEdit={onEdit}
        onCancel={changePhoneStore.clear}
        UIDictionary={UIDictionary}
      >
        <Field
          label="Номер телефона"
          name="phone"
          component={FormikMaskedInput}
          disabled
        />
      </ConfirmationAndChangeComponent>

      {changePhoneStore.state.isChange && (
        <Field
          className={styles.formMainField}
          label="Новый номер телефона"
          name="newPhone"
          component={FormikMaskedInput}
          disabled
        />
      )}
    </>
  );
};

export const ChangePhoneNumber = observer(ChangePhoneNumberRender);

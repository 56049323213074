import {
  flow,
  get,
  isNil,
  map,
  reverse,
  head,
  negate,
  filter,
} from 'lodash/fp';
import { toJS } from 'mobx';
import { MayBe } from 'types';
import { PolygonCoordinates } from 'types/PolygonCoordinates';
import { ApartmentDto, ApartmentPhoto, Layouts } from './types';
import { JSONString } from '../../../types';
import { ifElse } from '../../../../utils/fp';

/**
 * @example [[1,2],[3,4]] ->  [[2,1],[4,3]]
 * */
export const reversePolygonCoords = flow<
  any,
  Array<[number, number]>,
  Array<[number, number]>,
  Array<[number, number]>
>(
  toJS,
  map(reverse),
  ifElse<Array<[number, number]>>(
    isNil,
    () => [],
    (args: Array<[number, number]>) => args,
  ),
);

export const getAllApartmentPolygons = flow<
  any,
  Layouts[] | null | undefined,
  Layouts[] | null | undefined,
  (MayBe<JSONString> | undefined)[],
  (PolygonCoordinates[] | undefined)[],
  (PolygonCoordinates[] | undefined)[]
>(
  get<ApartmentDto, 'layouts'>(['layouts']),
  toJS,
  map<Layouts, MayBe<JSONString> | undefined>(
    get<Layouts, 'polygon'>(['polygon']),
  ),
  map<MayBe<JSONString> | undefined, Array<[number, number]> | undefined>(
    (polygon: MayBe<JSONString> | undefined) => {
      if (polygon) {
        return JSON.parse(polygon) as Array<[number, number]>;
      }
      return undefined;
    },
  ),
  map<Array<[number, number]> | undefined, Array<[number, number]> | undefined>(
    reversePolygonCoords,
  ),
);

export const getAllApartmentSquares = flow<any, any, any, any>(
  get<ApartmentDto, 'layouts'>(['layouts']),
  toJS,
  map<Layouts, MayBe<any> | undefined>(get<Layouts, 'id'>(['id'])),
);

export const getAllApartmentsLayoutsIds = flow(
  get<ApartmentDto, 'layouts'>(['layouts']),
  toJS,
  map<Layouts, MayBe<any> | undefined>(get<Layouts, 'id'>(['id'])),
  filter(negate(isNil)),
);

export const getApartmentSquares = (
  apartmentsLayouts: Layouts[],
  apartmentsLayoutsIds: number[],
) =>
  apartmentsLayouts
    .filter((e) => apartmentsLayoutsIds.includes(e.id as number))
    .map((e) => e.squares)
    .flat()
    .filter((e) => e);

export const getAllFloorPlans = flow<
  any,
  MayBe<Layouts[]> | undefined,
  MayBe<Layouts[]> | undefined,
  (MayBe<string> | undefined)[],
  MayBe<string>[]
>(
  get<ApartmentDto, 'layouts'>(['layouts']),
  toJS,
  map<Layouts, MayBe<string> | undefined>(
    get<Layouts, 'floor_photo'>('floor_photo'),
  ),
  filter(negate(isNil)),
);

export const getAllApartmentPlans = flow<
  any,
  MayBe<Layouts[]> | undefined,
  MayBe<Layouts[]> | undefined,
  (MayBe<string> | undefined)[],
  MayBe<string>[]
>(
  get<ApartmentDto, 'layouts'>(['layouts']),
  toJS,
  map<Layouts, MayBe<string> | undefined>(get<Layouts, 'photo'>('photo')),
  filter(negate(isNil)),
);

export const getApartmentPreview = flow<
  any,
  MayBe<ApartmentPhoto[]> | undefined,
  MayBe<ApartmentPhoto[]> | undefined,
  MayBe<ApartmentPhoto> | undefined,
  MayBe<string> | null
>(
  get<ApartmentDto, 'photos'>(['photos']),
  toJS,
  head,
  ifElse<MayBe<ApartmentPhoto> | undefined>(
    isNil,
    () => null,
    get<ApartmentPhoto, 'logo'>(['logo']),
  ),
);

export const getFirstLayoutPhoto = flow<
  any,
  MayBe<Layouts[]> | undefined,
  Layouts | undefined,
  MayBe<string> | undefined
>(get<ApartmentDto, 'layouts'>(['layouts']), head, get(['photo']));

import { useAuthUserIsRealtor } from 'hooks/index';
import { MayBe } from 'types';
import { OffersViewItem } from 'services';
import { rootProfileStore } from 'stores/user/RootProfileStore';

type Props = {
  offer: MayBe<OffersViewItem>;
};

/**
 * @desc хук вычисляет является ли текущий авторизованный пользователь владельцем переданной подборки
 * */
export const useUserIsOwnersOfferItem = ({ offer }: Props) => {
  const { user } = rootProfileStore.authStore.authUser;
  const { isRealtor } = useAuthUserIsRealtor();

  return {
    userIsOwner: isRealtor && user?.id === offer?.realtor.id,
  };
};

import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

/**
 * если не авторизован и зашел первый раз:
 * - открыть
 * - ждать 10 секунд
 * - показать окно авторизации
 * если зашел повторно:
 * - показать окно авторизации
 * если авторизован:
 * - открыть
 * */

type Props = {
  isOpen: boolean;
  time: number;
};

export const useAutoCloseApartmentViewForNotAuth = ({
  isOpen,
  time,
}: Props) => {
  const { isAuth } = rootProfileStore.authStore;
  const { state, setIsAccessToView } =
    rootApartmentStore.apartmentAccessStatusStore;
  const { push } = useHistory();

  const openAuthPage = () => {
    const url = authRoutesStore.getRouteWithParent('authPhoneCodePage');
    push(url);
  };

  useEffect(() => {
    if (!isOpen) {
      return undefined;
    }
    if (isAuth && !state.isAccessToView) {
      setIsAccessToView(true);
    } else if (state.isAccessToView) {
      const timerId = setTimeout(() => {
        if (isAuth) {
          return;
        }
        setIsAccessToView(false);
        openAuthPage();
      }, time);

      return () => {
        setIsAccessToView(false);
        clearTimeout(timerId);
      };
    } else {
      setIsAccessToView(false);
      openAuthPage();
    }
    return undefined;
  }, [isOpen, isAuth]);
};

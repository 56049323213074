import React from 'react';
import { Formik } from 'formik';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { observer } from 'mobx-react';
import { authPhoneCodeSchema } from 'validationSchemas';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { AlertSubmission } from 'components/atoms';
import { AuthPhoneCodeRequestBody } from 'services';
import { useDidMount } from 'beautiful-react-hooks';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { FormRender } from './components/FormRender';
import { useFormSubmit } from './hooks/useFormSubmit';

export const AuthPhoneCodePageTestIDs = {
  popup: 'AuthPhoneCodePage_popup',
};

export const AuthPhoneCodePageRender = () => {
  useDidMount(rootProfileStore.authStore.logout);

  const { onSubmit } = useFormSubmit();

  return (
    <AuthPopup
      testId={AuthPhoneCodePageTestIDs.popup}
      mobileHeaderChildrenTitle="Добро пожаловать!"
    >
      <Formik<AuthPhoneCodeRequestBody>
        validationSchema={authPhoneCodeSchema}
        onSubmit={onSubmit}
        initialValues={{}}
        render={(props) => (
          <>
            <AuthFormWrapper>
              <FormRender {...props} />
            </AuthFormWrapper>
            <AlertSubmission isSubmitting errors={props.errors} />
          </>
        )}
      />
    </AuthPopup>
  );
};

export const AuthPhoneCodePage = withGoogleReCaptchaProvider(
  observer(AuthPhoneCodePageRender),
);

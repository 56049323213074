import React from 'react';
import { observer } from 'mobx-react';
import { complexFilterStore } from 'stores';
import { AllFilters } from '../AllFilters';
import styles from './styles.module.css';
import { FilterBottomButtons } from './components/FilterBottomButtons';
import { FilterHeader } from './components/FilterHeader';

type Props = {
  onClose: () => void;
};

export const AllFiltersPopupRender = ({ onClose }: Props) => (
  <div className={styles.wrapper}>
    <section className={styles.filtersWrapper}>
      <FilterHeader onClose={onClose} />

      <AllFilters />
    </section>

    <FilterBottomButtons
      onClose={onClose}
      onCleanFilter={complexFilterStore.filters.reset}
    />
  </div>
);

export const AllFiltersPopup = observer(AllFiltersPopupRender);

import React, { ComponentProps } from 'react';
import { matchPath, useLocation } from 'react-router';
import { isEmpty } from 'lodash';

import { MustBeAuthForContinue } from 'components/atoms/MustBeAuthForContinue';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { AuthByPhoneForm } from './components/AuthByPhoneForm';
import { AuthByEmailForm } from './components/AuthByEmailForm';

type Props = Pick<
  ComponentProps<typeof MustBeAuthForContinue>,
  'title' | 'subTitle' | 'imagePreview' | 'imageWrapperClassName'
>;

export const AuthForGetAccessToFeature = ({
  title,
  subTitle,
  imagePreview,
  imageWrapperClassName,
}: Props) => {
  const location = useLocation();

  const authByPhone = matchPath(location.pathname, {
    path: authRoutesStore.getRouteWithParent('authPhoneCodePage'),
  });
  const authByEmail = matchPath(location.pathname, {
    path: authRoutesStore.getRouteWithParent('authEmailCodePage'),
  });

  return (
    <AuthPopup size="LG">
      <MustBeAuthForContinue
        title={title}
        subTitle={subTitle}
        imageWrapperClassName={imageWrapperClassName}
        imagePreview={imagePreview}
        formComponent={
          <>
            {!isEmpty(authByPhone) && <AuthByPhoneForm />}
            {!isEmpty(authByEmail) && <AuthByEmailForm />}
          </>
        }
      />
    </AuthPopup>
  );
};

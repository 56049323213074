import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { appRoutesService, authEmailService } from 'services';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { profileNewEmailSchema } from 'validationSchemas';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { AlertSubmission } from 'components/atoms/AlertSubmission';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { NewEmailFormValues } from './types';
import { FormRender } from './components/FormRender';
import { changeEmailStore } from '../../../../stores/ChangeEmailStore';
import { submissionErrorsHandler } from '../../../../../../utils/formik/submissionErrorsHandler';
import { injectReCaptchaTokenToFormValues } from '../../../../../../utils/injectReCaptchaTokenToFormValues';

import styles from './styles.module.css';

export const NewEmailPopupRender = () => {
  const { push } = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (
    values: NewEmailFormValues,
    formikHelpers: FormikHelpers<NewEmailFormValues>,
  ) => {
    if (!values.newEmail) {
      return;
    }

    try {
      void (await authEmailService.code({
        email: rootProfileStore.authStore?.authUser.user?.email,
      }));
      changeEmailStore.setNewEmail(values.newEmail);
      push(appRoutesService.getRoute('profileConfirmOldEmail', values));
    } catch (e) {
      submissionErrorsHandler(e, formikHelpers);
    }
  };

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
      mobileHeaderChildrenTitle="Изменение почты"
    >
      <Formik<NewEmailFormValues>
        validationSchema={profileNewEmailSchema}
        initialValues={{}}
        onSubmit={injectReCaptchaTokenToFormValues<NewEmailFormValues>(
          executeRecaptcha,
        )(onSubmit)}
        render={(props) => (
          <>
            <AuthFormWrapper
              className={styles.formWrapper}
              showButtonBack
              onClickButtonBack={() => {
                push(appRoutesService.getRoute('profile'));
              }}
            >
              <FormRender {...props} />
            </AuthFormWrapper>
            <AlertSubmission isSubmitting errors={props.errors} />
          </>
        )}
      />
    </AuthPopup>
  );
};

export const NewEmailPopup = withGoogleReCaptchaProvider(
  observer(NewEmailPopupRender),
);

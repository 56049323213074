import React from 'react';
import { observer } from 'mobx-react';
import { TabsSelect } from 'components/containers/TabsSelect';
import { floorPlanStore } from '../../../../stores/FloorPlanStore';

export const FloorSelectRender = () => {
  const { activeFloor, floors } = floorPlanStore;

  const tabList = floors.map(([title, apartments]) => ({
    label: `${title} этаж`,
    helperText: `${apartments.length} квартир`,
  }));

  return (
    <TabsSelect<any>
      tabList={tabList}
      changeTab={(index) => () => {
        floorPlanStore.changeActiveFloor(index);
      }}
      currentActiveTab={activeFloor}
    />
  );
};

export const FloorSelect = observer(FloorSelectRender);

import React from 'react';
import { BuildingObjectStats } from '../../../../../../services';
import styles from './styles.module.css';
import { Text } from '../../../../../../components/atoms';

type Props = {
  total?: BuildingObjectStats;
};

export const StatsTotal = ({ total }: Props) => (
  <div>
    <Text
      whiteSpace="nowrap"
      indent={{
        margin: [0, 0, 10],
      }}
      tagName="div"
      size="M"
      className={styles.mainPrices}
    >
      {[
        <span key="sumMin_sumMax_1">от </span>,
        <Text key="sumMin_sumMax_2" weight="700" size="2XL" lineHeight="2XL">
          {((total?.sumMin || 1) / 1e6).toFixed(1)}
        </Text>,
        <span key="sumMin_sumMax_3"> млн до </span>,
        <Text key="sumMin_sumMax_4" weight="700" size="2XL" lineHeight="2XL">
          {((total?.sumMax || 1) / 1e6).toFixed(1)}
        </Text>,
        <span key="sumMin_sumMax_5"> млн</span>,
      ]}
    </Text>
    <Text
      whiteSpace="nowrap"
      className={styles.pricesSquare}
      tagName="div"
      size="M"
    >
      {[
        'от ',
        total?.m2min?.toLocaleString() || 0,
        ' до ',
        total?.m2max?.toLocaleString() || 0,
        ' ₽/м²',
      ]}
    </Text>
  </div>
);

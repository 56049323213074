import { useEffect } from 'react';
import { reaction } from 'mobx';
import { MayBe } from 'types';
import { InfoStore } from 'stores';
import { LeafletStoreClass } from 'stores/LeafletStore';
import { CityInfo } from 'services';
import { usePrevState } from 'hooks/usePrevState';

type Props = {
  leafletStore?: LeafletStoreClass;
  infoStore?: InfoStore;
};

/**
 * @desc хук предназначен для обновления центра карты в случае если
 * город в словаре был изменен
 * */
export const useUpdateMapCenter = ({ leafletStore, infoStore }: Props) => {
  useEffect(() => {
    if (!leafletStore?.instance || !infoStore?.city) {
      return undefined;
    }
    return reaction(
      () => infoStore?.city,
      (city: MayBe<CityInfo>) => {
        if (!city) {
          return;
        }
        leafletStore.updateCenter(city.latitude, city.longitude);
      },
    );
  }, [leafletStore?.instance]);
};

/**
 * @desc хук предназначен для инициализации центра карты, проверка на предыдущее состояние инстанса карты необходима
 * тк стор получает его асинхронно и может сложится ситуация когда сайдэфект сработал но инстанса еще нет, из-за чего центр карты не запишется
 * */
export const useInitMapCenter = ({ leafletStore, infoStore }: Props) => {
  const prevLeafletInstance = usePrevState(leafletStore?.instance);

  useEffect(() => {
    if (!leafletStore?.instance || !infoStore?.city || prevLeafletInstance) {
      return;
    }
    leafletStore.updateCenter(
      infoStore.city.latitude,
      infoStore.city.longitude,
    );
  }, [leafletStore?.instance]);
};

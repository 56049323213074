import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';
import { InputGroup } from 'components/atoms';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';

import styles from '../../AllFilters/AllFilters.module.css';

const ThirdColumnFiltersRenderer = () => (
  <>
    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Площадь дома</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Площадь участка</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareLandMin"
          placeholder="от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareLandMax"
          placeholder="до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="material"
          dictKey="residential_complex_material"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="finishing"
          dictKey="residential_complex_finishing"
        />
      </div>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Гараж</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageGarage"
          dictKey="village_garage"
        />
      </div>
    </div>
  </>
);

export const ThirdColumnFilters = memo(ThirdColumnFiltersRenderer);

import { useMediaQuery } from 'beautiful-react-hooks';
import React, {
  ComponentProps,
  ComponentType,
  ReactNode,
  useMemo,
} from 'react';
import { MobilePopup, Text } from '../../components/atoms';

type Props<DesktopModalProps extends {}> = {
  mediaQuery?: string;

  DesktopModalWrapper: ComponentType<DesktopModalProps>;
  mobileHeaderChildren?: ReactNode;
  mobileHeaderChildrenTitle?: string;
};

export const useGetResponsiveModalComponent = <DesktopModalProps extends {}>({
  mediaQuery = '(min-width: 1024px)',
  mobileHeaderChildren,
  mobileHeaderChildrenTitle,
  DesktopModalWrapper,
}: Props<DesktopModalProps>) => {
  const isLarge = useMediaQuery(mediaQuery);

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (props: DesktopModalProps) => (
        <DesktopModalWrapper {...props} isOpen ariaHideApp={false} />
      );
    }
    return (
      props: Pick<
        ComponentProps<typeof MobilePopup>,
        'children' | 'isOpen' | 'onClose'
      >,
    ) => (
      <MobilePopup
        headerChildren={
          mobileHeaderChildren || (
            <Text align="center" size="L" weight="700">
              {mobileHeaderChildrenTitle}
            </Text>
          )
        }
        {...props}
      />
    );
  }, [isLarge, mobileHeaderChildrenTitle]);
  return {
    PopupWrapper,
  };
};

import qs from 'qs';
import { searchParamsToObject } from 'utils/queryString';
import { flow } from 'lodash/fp';
import { History } from 'history';
import { modalTypeArraysJoin, modalTypeStringToArray } from './helpers';
import { Params } from '../appRoutesService';

export const APP_ROUTES_MODAL_TYPE = 'modalType';

export const APP_ROUTES_MODAL = {
  offersCreate: `${APP_ROUTES_MODAL_TYPE}=offersCreate`,
  offersEdit: `${APP_ROUTES_MODAL_TYPE}=offersEdit&offerId=:offerId`,
  addToOffer: `${APP_ROUTES_MODAL_TYPE}=addToOffer&apartmentId=:apartmentId`,
  salesDepartment: `${APP_ROUTES_MODAL_TYPE}=salesDepartment&complexId=:complexId&salesDepartmentType=:salesDepartmentType&salesDepartmentId=:salesDepartmentId`,
};

export class AppModalRoutesService {
  location: Location;

  constructor(location: Location) {
    this.location = location;
  }

  /**
   * @desc заменяет текущее активное модальное окно открытое через query string
   * */
  replaceModalType(
    modalType: keyof typeof APP_ROUTES_MODAL,
    params?: Params,
    queryString?: object,
    pathname?: string,
  ): string {
    if (!APP_ROUTES_MODAL[modalType]) {
      throw new Error(`modalType with ${modalType} not defined`);
    }

    const modalRoute = {
      ...qs.parse(APP_ROUTES_MODAL[modalType]),
      ...params,
      ...queryString,
    };

    return `${pathname || this.location.pathname}?${qs.stringify(modalRoute)}`;
  }

  /**
   * @desc добавляет новое модальное окно открытое через query string
   * */
  pushNewModalType(
    modalType: keyof typeof APP_ROUTES_MODAL,
    params?: Params,
    queryString?: object,
    pathname?: string,
  ): string {
    if (!APP_ROUTES_MODAL[modalType]) {
      throw new Error(`modalType with ${modalType} not defined`);
    }

    const prevSearchParamsObject = searchParamsToObject(this.location.search);

    const newSearchParamsObject = {
      ...qs.parse(APP_ROUTES_MODAL[modalType]),
      ...params,
      ...queryString,
    };

    const prevModalType: string[] = modalTypeStringToArray(
      prevSearchParamsObject,
    );

    const newModalType: string[] = modalTypeStringToArray(
      newSearchParamsObject,
    );

    const nextSearchParamsObject = {
      ...prevSearchParamsObject,
      ...newSearchParamsObject,
      modalType: modalTypeArraysJoin([prevModalType, newModalType]),
    };

    return `${pathname || this.location.pathname}?${qs.stringify(
      nextSearchParamsObject,
    )}`;
  }

  getLocationForModal = (
    modalType: keyof typeof APP_ROUTES_MODAL,
    params?: Params,
    queryString?: object,
    pathname?: string,
  ): History.LocationDescriptor<any> => {
    if (!APP_ROUTES_MODAL[modalType]) {
      throw new Error(`modalType with ${modalType} not defined`);
    }

    const modalRoute = {
      ...qs.parse(APP_ROUTES_MODAL[modalType]),
      ...params,
      ...queryString,
    };

    return {
      hash: '',
      pathname: pathname || this.location.pathname,
      search: `?${qs.stringify(modalRoute)}`,
      state: undefined,
    };
  };

  /**
   * @desc проверит есть ли в поисковой строке переданное модальное окно
   * */
  modalIsOpen = (
    modalType: keyof typeof APP_ROUTES_MODAL,
    search: string,
  ): boolean => {
    const searchObject: string[] = flow(
      searchParamsToObject,
      modalTypeStringToArray,
    )(search);

    return searchObject.includes(modalType);
  };
}

export const appModalRoutesService = new AppModalRoutesService(window.location);

import { offersEditStore } from 'stores';

type Props = {
  onSuccess: () => void;
};

export const useDeleteOffer = ({ onSuccess }: Props) => {
  const onDeleteOffer = (offerId?: string) => async () => {
    const [, result] = await offersEditStore.delete({
      offerId: Number(offerId),
    });
    if (result) {
      onSuccess();
    }
  };

  return {
    onDeleteOffer,
  };
};

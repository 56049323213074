import React from 'react';
import { observer } from 'mobx-react';

import FirstColumnFilters from './FirstColumnFilters';
import SecondColumnFilters from './SecondColumnFilters';
import ThirdColumnFilters from './ThirdColumnFilters';

import styles from '../../../../../components/containers/AllFilters/AllFilters.module.css';

export const FiltersContentRender = () => (
  <div className={styles.formWrapper}>
    <div className={styles.formColumn}>
      <FirstColumnFilters />
    </div>

    <div className={styles.formColumn}>
      <SecondColumnFilters />
    </div>

    <div className={styles.formColumn}>
      <ThirdColumnFilters />
    </div>
  </div>
);

export const FiltersContent = observer(FiltersContentRender);

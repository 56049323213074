import { ComponentProps, useCallback, useEffect, useState } from 'react';
import useDeepEffect from 'use-deep-compare-effect';
import { loggerService } from '../../../../services';
import { TabButton } from '../components';

export type TabItemProps<TabItemPayload> = ComponentProps<typeof TabButton> & {
  data?: TabItemPayload;
};

export type TabsState<TabItemPayload> = {
  currentActiveTab: number;
  tabList: TabItemProps<TabItemPayload>[];
};

export type TabProps<TabItemPayload> = {
  getCurrentActiveTab?: () => TabItemProps<TabItemPayload>;
  changeTab: (index: number) => () => void;
  setTabList: (tabList: TabItemProps<TabItemPayload>[]) => void;
} & TabsState<TabItemPayload>;

export const useTabs = <TabItemPayload>(
  props: Partial<TabsState<TabItemPayload>> = {},
): TabProps<TabItemPayload> => {
  const [{ tabList, currentActiveTab }, setState] = useState<
    TabsState<TabItemPayload>
  >({
    currentActiveTab: props.currentActiveTab || 0,
    tabList: props.tabList || [],
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      currentActiveTab: props.currentActiveTab || 0,
    }));
  }, [props.currentActiveTab]);

  useDeepEffect(() => {
    setState((prev) => ({
      ...prev,
      tabList: props.tabList || [],
    }));
  }, [props.tabList]);

  const changeTab = useCallback(
    (index: number) => () => {
      if (index >= tabList.length) {
        loggerService.debug(`[useTabs] index out of range`);
        return;
      }
      setState({
        currentActiveTab: index,
        tabList,
      });
    },
    [currentActiveTab, tabList],
  );
  const getCurrentActiveTab = useCallback(
    () => tabList[currentActiveTab],
    [currentActiveTab, tabList],
  );

  const setTabList = (tabList: TabItemProps<TabItemPayload>[]) => {
    setState((prev) => ({
      ...prev,
      tabList,
    }));
  };

  return {
    getCurrentActiveTab,
    changeTab,
    currentActiveTab,
    tabList,
    setTabList,
  };
};

import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';

import { InputGroup } from 'components/atoms';
import { PopupDistrictsChoicer } from 'components/containers/PopupDistrictsChoicer';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';

import styles from '../../AllFilters/AllFilters.module.css';

const FirstColumnFilterRenderer = () => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;

  return (
    <>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Район</span>
        {districts && (
          <PopupDistrictsChoicer
            data={districts}
            filterKey="districtIds"
            store={complexFilterStore.filters}
            disabled={isLoading}
            placeholder="Любой"
          />
        )}
      </div>
      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Стоимость</span>
        <InputGroup>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="priceMin"
            placeholder="Общая, от"
            className={styles.complexInputLeft}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="priceMax"
            placeholder="Общая, до"
            className={styles.complexInputRight}
          />
        </InputGroup>
      </div>
      <div className={styles.formSubColumnWrapper}>
        <div className={styles.formItem}>
          <span className={styles.formLabel}>Тип участка</span>
          <ReactiveSelect
            store={complexFilterStore.filters}
            filterKey="villageTypeLand"
            dictKey="village_type_land"
            placeholder="Не важно"
          />
        </div>

        <div className={styles.formItem}>
          <span className={styles.formLabel}>ВРИ</span>
          <ReactiveSelect
            store={complexFilterStore.filters}
            filterKey="villageVRI"
            dictKey="village_vri"
          />
        </div>
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип дома</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageTypeHouse"
          placeholder="Любой"
          dictKey="village_type_house"
        />
      </div>
      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Расстояние до города</span>
        <InputGroup>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="distanceToCityMin"
            placeholder="От"
            className={styles.complexInputLeft}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="distanceToCityMax"
            placeholder="До"
            className={styles.complexInputRight}
          />
        </InputGroup>
      </div>

      <div className={styles.formInputs}>
        <span className={styles.formLabel}>Агентское вознаграждение, %</span>
        <InputGroup>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="agentCommissionPercentMin"
            placeholder="От"
            className={styles.complexInputLeft}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="agentCommissionPercentMax"
            placeholder="До"
            className={styles.complexInputRight}
          />
        </InputGroup>
      </div>
    </>
  );
};

export const FirstColumnFilter = memo(FirstColumnFilterRenderer);

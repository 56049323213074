import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  content: any;
  sidebar: any;
  isEdit: boolean;
  isShown: boolean;
};

export const Layout = ({ content, sidebar, isEdit, isShown }: Props) => (
  <div className={styles.layoutWrapper}>
    <main
      className={cc([
        styles.layoutContent,
        {
          [styles.layoutContentEdit]: isEdit,
        },
      ])}
    >
      {content && content()}
    </main>
    <aside
      className={cc([
        styles.layoutSidebar,
        {
          [styles.layoutSidebarEdit]: isEdit,
          [styles.isShown]: isShown,
        },
      ])}
    >
      {sidebar && sidebar()}
    </aside>
  </div>
);

import React from 'react';
import L from 'leaflet';
import { Marker } from 'react-leaflet';
import { ComplexRawInOffer } from 'services';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { getMapPin } from 'pages/Main/containers/LeafletMap/components/MapMarker';
import { BaseMapContainer } from 'components/atoms';
import { Loader } from 'components/atoms/Loader';
import styles from './styles.module.css';

type Props = {
  complex?: ComplexRawInOffer;
  onOpenMap?: () => void;
  isLoading?: boolean;
};

const MapPin = getMapPin('height:24px');

export const ComplexCardDesktopMap = ({
  complex,
  isLoading,
  onOpenMap,
}: Props) => {
  if (!complex) {
    return null;
  }
  const { latitude, longitude } = complex;

  const centerLatLng: any = [latitude, longitude];

  return (
    <button
      type="button"
      onClick={onOpenMap}
      className={styles.complexCardMapButton}
    >
      <BaseMapContainer
        className={styles.complexCardMapWrapper}
        hideZoomControl
        crs={L.CRS.EPSG3395}
        zoom={10}
        center={centerLatLng}
        dragging={false}
      >
        <YandexMapLayer />
        <Marker icon={MapPin} position={centerLatLng} />
      </BaseMapContainer>
      {isLoading && (
        <div className={styles.complexCardMapLoader}>
          <Loader />
        </div>
      )}
    </button>
  );
};

import { toJS, autorun } from 'mobx';
import { parseStore } from './parseStore';

export type SyncStorage = {
  removeItem(key: string): void;
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
};

type SyncTrunkOption = {
  store: any;
  storageKey: string;
  storage: SyncStorage;
  autoInit?: boolean;
};

export class SyncTrunk {
  storage: SyncStorage;

  storageKey: string;

  store: any;

  constructor({
    storageKey,
    store,
    storage = localStorage,
    autoInit,
  }: SyncTrunkOption) {
    this.storage = storage;
    this.store = store;
    this.storageKey = storageKey;

    if (autoInit) {
      this.init();
    }
  }

  persist = () => {
    const data = toJS(this.store);
    this.storage.setItem(this.storageKey, JSON.stringify(data));
  };

  init = () => {
    try {
      const dataJSON = this.storage.getItem(this.storageKey);
      if (dataJSON) {
        parseStore(this.store, JSON.parse(dataJSON));
      }
    } catch (e) {
      // do nothing
    }

    this.persist();
    autorun(this.persist);
  };

  clear() {
    this.storage.removeItem(this.storageKey);
  }
}

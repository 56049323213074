import React from 'react';
import cc from 'classcat';
import { FormFieldLabel, ContactView } from 'components/atoms';
import { OffersViewItem } from 'services';
import { MayBe } from 'types';
import { RealtorInfo } from '../RealtorInfo';
import { AgencyLogo } from '../AgencyLogo';
import styles from './styles.module.css';

type Props = {
  offer?: MayBe<OffersViewItem>;
};

export const ViewSidebar = ({ offer }: Props) => (
  <div className={styles.viewSidebarWrapper}>
    <div className={cc([styles.viewSidebarBlock, styles.viewSidebarTitle])}>
      <RealtorInfo realtor={offer?.realtor} startDate={offer?.created_at} />
    </div>
    <div className={cc([styles.viewSidebarBlock, styles.viewSidebarContacts])}>
      <ContactView type="tel" phone={offer?.realtor.phone} />
      <ContactView type="mailto" email={offer?.realtor.email} />
    </div>
    {offer?.realtor.agency && (
      <div className={styles.viewSidebarBlock}>
        <AgencyLogo agency={offer?.realtor.agency} />
      </div>
    )}
    {offer?.note && (
      <div className={styles.viewSidebarBlock}>
        <FormFieldLabel
          className={styles.viewSidebarFromRealtorTitle}
          fieldName=""
        >
          От риелтора
        </FormFieldLabel>
        <div className={styles.viewSidebarFromRealtor}>{offer?.note}</div>
      </div>
    )}
  </div>
);

import { action, makeObservable, observable } from 'mobx';
import { MayBe } from 'types';
import { SyncTrunk } from '../../../stores/SyncTrunk/SyncTrunk';

type State = {
  newPhone: MayBe<string>;
  oldPhoneIsConfirm: boolean;
  newPhoneIsConfirm: boolean;
  isChange: boolean;
};

export class ChangePhoneStore {
  state: State = {
    newPhone: null,
    oldPhoneIsConfirm: false,
    newPhoneIsConfirm: false,
    isChange: false,
  };

  constructor() {
    makeObservable(this, {
      state: observable,

      setNewPhone: action.bound,
      setOldPhoneConfirm: action.bound,
      setNewPhoneConfirm: action.bound,
    });
  }

  clear = () => {
    this.state.newPhone = null;
    this.state.oldPhoneIsConfirm = false;
    this.state.newPhoneIsConfirm = false;
    this.state.isChange = false;
  };

  setNewPhone(newPhone: MayBe<string>) {
    this.state.newPhone = newPhone;
    this.state.isChange = !!newPhone;
  }

  setOldPhoneConfirm(confirm: boolean) {
    this.state.oldPhoneIsConfirm = confirm;
  }

  setNewPhoneConfirm(confirm: boolean) {
    this.state.newPhoneIsConfirm = confirm;
  }
}

export const changePhoneStore = new ChangePhoneStore();

const trunk = new SyncTrunk({
  storageKey: 'changePhoneStore',
  store: changePhoneStore,
  storage: localStorage,
});

trunk.init();

import React from 'react';
import cc from 'classcat';
import { get } from 'lodash/fp';
import {
  BuildingDto,
  getMaxRowsTitles,
  getAllRowsTitlesDescription,
} from 'services';
import { Text } from 'components/atoms/Text';
import { useId } from 'react-id-generator';
import styles from './styles.module.css';

type Props = Pick<BuildingDto, 'sections'>;

export const FloorSideBar = ({ sections }: Props) => {
  const rowsTitles: string[] = getMaxRowsTitles(sections || []);
  const rowsTitlesDescription: string[] = getAllRowsTitlesDescription(
    sections || [],
  );
  const idList: string[] = useId(rowsTitles?.length, 'rowsTitles');

  const calcGridRow = (index: number) => {
    const startRowLine = 3;
    return `${index + startRowLine}`;
  };

  return (
    <>
      {rowsTitles?.map((rowTitle, index) => (
        <div
          key={idList[index]}
          className={cc([styles.wrapper, index === 0 && styles.borderTopNone])}
          style={{
            gridRow: calcGridRow(index),
          }}
        >
          <Text tagName="div" align="center" size="L">
            {rowTitle}
          </Text>
          {get(index, rowsTitlesDescription) && (
            <Text tagName="div" align="center" size="XL">
              {get(index, rowsTitlesDescription)}
            </Text>
          )}
        </div>
      ))}
    </>
  );
};

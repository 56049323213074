import React from 'react';
import { MayBe } from 'types';
import { Complex } from 'services';
import { ComplexPresentationCarousel } from '../../../ComplexPage/containers/ComplexPresentationCarousel';

type Props = {
  complex: MayBe<Complex>;
};

export const ComplexPresentationCarouselContainer = ({ complex }: Props) => {
  const targetBadges: number[] =
    (complex?.catalogs?.residential_complex_badges &&
      [...complex?.catalogs?.residential_complex_badges].sort(
        (a, b) => a - b,
      )) ||
    [];
  return (
    <>
      {complex?.presentation && targetBadges && (
        <ComplexPresentationCarousel
          hideFeedbackForm
          complex={complex}
          badges={targetBadges}
        />
      )}
    </>
  );
};

import React from 'react';
import {
  DateFormat,
  ResidentialComplexLogo,
} from '../../../../components/atoms';
import styles from './styles.module.css';
import { MayBe } from '../../../../types';
import { Agency } from '../../../../services';

type Props = {
  agency?: MayBe<Agency>;
};

export const AgencyLogo = ({ agency }: Props) => (
  <div className={styles.complexLogoWrapper}>
    <ResidentialComplexLogo
      src={agency?.logo?.src || ''}
      size="large"
      className={styles.complexLogo}
    />
    <div>
      <div className={styles.complexLogoTitle}>{agency?.title}</div>
      {agency?.founded_at && (
        <div className={styles.complexLogoSubTitle}>
          Работает с <DateFormat format="year" value={agency?.founded_at} />{' '}
          года
        </div>
      )}
    </div>
  </div>
);

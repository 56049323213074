import React from 'react';
import { Router, useHistory } from 'react-router-dom';
import { AddToOffersForm } from './containers/AddToOffersForm';
import { useGetQueryString } from '../../../../hooks';
import { Params } from '../../../../services';

export const AddToOffersPage = () => {
  const history = useHistory();
  const { query } = useGetQueryString<Pick<Params, 'apartmentId'>>();

  return (
    <Router history={history}>
      <AddToOffersForm
        {...(query.apartmentId
          ? {
              apartmentId: parseInt(query.apartmentId, 10),
            }
          : {})}
        isOpen
        onClose={() => history.goBack()}
      />
    </Router>
  );
};

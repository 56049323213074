import { useState } from 'react';
import { MayBe } from 'types';

export type RenderPropProps = {
  isAnimation: boolean;
  onClick: () => void;
  label?: MayBe<string>;
};

type Props = {
  link: string;
  delay?: number;
  render?: (props: RenderPropProps) => JSX.Element;
  children?: (props: RenderPropProps) => JSX.Element;
  defaultLabel?: MayBe<string>;
  successLabel?: MayBe<string>;
};

export const CopyLink = ({
  link,
  render,
  children,
  delay = 1000,
  defaultLabel,
  successLabel,
}: Props) => {
  const [isAnimation, setStartAnimation] = useState(false);
  const [timeoutId, setTimeOutId] = useState<NodeJS.Timeout | undefined>();

  const clearTimeoutId = () => timeoutId && clearTimeout(timeoutId);

  const onClick = async () => {
    clearTimeoutId();
    setStartAnimation(true);

    await navigator.clipboard.writeText(link);

    const timerId = setTimeout(() => setStartAnimation(false), delay);
    setTimeOutId(timerId);
  };

  const label = isAnimation ? successLabel : defaultLabel;

  if (children) {
    return children({
      onClick,
      isAnimation,
      label,
    });
  }
  if (render) {
    return render({
      onClick,
      isAnimation,
      label,
    });
  }

  // eslint-disable-next-line no-console
  console.error('CopyLinkButton, props render and children is not defined.');
  return null;
};

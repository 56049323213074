import React from 'react';
import { Stock } from 'services';
import styles from './styles.module.css';
import { SalesDepartmentsCard } from '../../../../components/containers/SalesDepartmentsCard';

type Props = Pick<Stock, 'salesDepartments' | 'description'>;

export const ComplexContent = ({ salesDepartments, description }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.left}>
      <div
        dangerouslySetInnerHTML={{
          __html: description || '',
        }}
        className={styles.content}
      />
    </div>
    {salesDepartments && (
      <div className={styles.right}>
        <SalesDepartmentsCard
          hiddenFooter
          salesDepartments={[salesDepartments]}
        />
      </div>
    )}
  </div>
);

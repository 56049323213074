import qs from 'qs';
import { serialize } from 'object-to-formdata';
import { API_ROUTES } from 'services/constants';
import { attemptWithAuth } from 'services/libs/axios/attempt';
import { AttemptResult } from '../../../types';
import {
  Favorites,
  FavoritesIds,
  FavoritesSyncBody,
  FavoritesSyncResult,
  FavoritesToggleBody,
  FavoritesToggleResult,
} from './types';

export interface FavoritesService {
  listIds: () => Promise<AttemptResult<FavoritesIds>>;
  list: () => Promise<AttemptResult<Favorites>>;
  toggle: (
    data: FavoritesToggleBody,
  ) => Promise<AttemptResult<FavoritesToggleResult>>;
  sync: (
    data: FavoritesSyncBody,
  ) => Promise<AttemptResult<FavoritesSyncResult>>;
}

export const favoritesService: FavoritesService = {
  listIds: () =>
    attemptWithAuth<FavoritesIds>({
      url: API_ROUTES.favorites.listIds,
      method: 'get',
      paramsSerializer: (params) => qs.stringify(params),
    }),
  list: () =>
    attemptWithAuth<Favorites>({
      url: API_ROUTES.favorites.list,
      method: 'get',
      paramsSerializer: (params) => qs.stringify(params),
    }),
  toggle: async (data: FavoritesToggleBody) =>
    attemptWithAuth<FavoritesToggleResult>({
      url: API_ROUTES.favorites.toggle,
      method: 'post',
      data: serialize(data),
    }),
  sync: async (data: FavoritesSyncBody) =>
    attemptWithAuth<FavoritesSyncResult>({
      url: API_ROUTES.favorites.sync,
      method: 'post',
      data: serialize(data),
    }),
};

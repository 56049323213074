import { Cell } from 'services';
import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';

export const useIsActiveApartment = (matrixItem: Cell): boolean => {
  const { apartmentUIStore } = rootApartmentStore;

  return matrixItem.title
    ? apartmentUIStore.isActiveApartmentByNumber(matrixItem.title)
    : false;
};

import { MayBe } from 'types';
import { BuildingSearchParameters } from '../services';
import { FilterStore } from './FilterStore';

export type BuildingFilterType = Omit<
  BuildingSearchParameters,
  'notFirstFloor' | 'notLastFloor' | 'isLastFloor' | 'isPenthouse' | 'mapBounds'
> & {
  notFirstFloor?: MayBe<boolean>;
  notLastFloor?: MayBe<boolean>;
  isLastFloor?: MayBe<boolean>;
  isPenthouse?: MayBe<boolean>;
};

export class BuildingFiltersStore {
  filters: FilterStore<BuildingFilterType, BuildingSearchParameters>;

  constructor() {
    this.filters = new FilterStore<
      BuildingFilterType,
      BuildingSearchParameters
    >({});
  }
}

export const buildingFilters = new BuildingFiltersStore();

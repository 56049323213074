import { Yup } from './yup';
import { maskedInputValueToNumber } from '../utils/maskedInputValueToNumber';

export const feedbackSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Не длиннее 50 символов').required(),
  phone: Yup.string()
    .transform(maskedInputValueToNumber)
    .length(11, 'Не верный формат номера')
    .required(),
});

/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled from 'styled-components';
import css from '@styled-system/css';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  border,
  color,
  compose,
  flexbox,
  layout,
  position,
  typography,
  space,
  shadow,
  opacity,
  textAlign,
} from 'styled-system';

import type { BoxProps } from './Box.types';

export const Box = styled.div.withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop),
})<BoxProps>(
  compose(
    border,
    color,
    flexbox,
    layout,
    position,
    space,
    shadow,
    opacity,
    textAlign,
    typography,
  ),
  css({
    boxSizing: 'border-box',
  }),
);

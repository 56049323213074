import { useEffect, useMemo } from 'react';
import { reaction, toJS } from 'mobx';
import { ID } from 'types';
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { offersEditStore } from 'stores';
import { throttle, cloneDeep } from 'lodash';
import { differenceWith, flow, head } from 'lodash/fp';
import {
  AttemptResult,
  OffersAddItem,
  OffersApartmentAddResponse,
} from 'services';

const differenceOffers = (offers: OffersAddItem[]) =>
  flow<any, OffersAddItem[], OffersAddItem | undefined>(
    differenceWith(
      (prev: OffersAddItem, next: OffersAddItem) => prev.id === next.id,
      offers,
    ),
    head,
  );

const requestOffers =
  (apartmentId?: ID) =>
  (): Promise<AttemptResult<OffersApartmentAddResponse>> | undefined => {
    if (!apartmentId) {
      return undefined;
    }
    return addToOffersStore.loadActiveOffers(
      {
        apartmentId,
      },
      {
        search: addToOffersStore.offersFilterStore.params.search,
      },
    );
  };

export const useLoadOffers = (apartmentId?: ID) => {
  const requestOffersWithId = useMemo(
    () => requestOffers(apartmentId),
    [apartmentId],
  );

  const requestOffersThrottle = useMemo(
    () => throttle(requestOffers(apartmentId), 1000),
    [apartmentId],
  );

  /** @desc инициация реакции для обновления списка подборок после создания новой подборки */
  useEffect(() => {
    void requestOffersWithId();

    const disposer = reaction(
      () => offersEditStore.editRequestApiState.isLoading,
      (isLoading, isPrevLoading) => {
        if (isLoading && !isPrevLoading) {
          return;
        }
        const prevOffers = cloneDeep(toJS(addToOffersStore.offers));
        requestOffersWithId()?.then(([, result]) => {
          if (!result) {
            return;
          }
          const newOffer = differenceOffers(result)(prevOffers);

          if (newOffer) {
            addToOffersStore.toggleCheckedOffersWithSave(newOffer.id);
          }
        });
      },
    );

    return () => disposer();
  }, [apartmentId]);

  /** @desc инициация реакции для поиска по подборкам */
  useEffect(() => {
    if (!apartmentId) {
      return undefined;
    }
    const disposer = reaction(
      () => addToOffersStore.offersFilterStore.params.search,
      (search, prevSearch) => {
        if (search !== prevSearch) {
          requestOffersThrottle();
        }
      },
    );
    return () => disposer();
  }, [apartmentId]);
};

import React, { useMemo } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import { ApartmentInOffer, Complex, Feature } from 'services';
import { LogoWithContent, Text, Icon } from 'components/atoms';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { useBuildingOnMapPopup } from 'pages/LayoutPage/containers/BuildingOnMapPopup';
import { getWindowsFromLayers } from 'pages/LayoutPage/utils';

import { complexItemStore } from 'stores/ComplexItemStore/store';

import iconLeft from 'assets/leftIcon.svg';
import map_icon from 'assets/switcherMap.svg';

import { useMediaQuery } from 'beautiful-react-hooks';
import styles from './styles.module.css';
import { useGetOfferViewFieldStatus } from '../../../OffersViewPage/hooks/useGetOfferViewFieldStatus';

type Props = {
  complex: Complex;
  apartment: ApartmentInOffer;
  goToOfferPage: () => void;
};

export const MobileInfoHeader = ({
  complex,
  apartment,
  goToOfferPage,
}: Props) => {
  const { isShowMap, isShowComplex } = useGetOfferViewFieldStatus();

  const isMobile = useMediaQuery('(max-width: 767px)');
  const windows: Feature[] | undefined = useMemo(
    () => getWindowsFromLayers(apartment?.layouts),
    [apartment?.layouts],
  );

  const formattedPolygon: Array<[number, number]> =
    complexItemStore.reversePolygonCoords(complex.polygon);

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon: formattedPolygon,
  });

  return (
    <div className={styles.wrapper}>
      {(isShowComplex || isShowMap) && (
        <div className={styles.content}>
          {isShowComplex && (
            <LogoWithContent
              indent={{
                margin: [0, 0, 10],
              }}
              src={complex.logo?.url || ''}
              alt="logo"
              className={styles.logoWrapper}
            >
              <Text
                wordBreak="break-word"
                className={styles.complexTitle}
                tagName="h3"
              >
                <LinesEllipsis
                  text={complex.title?.trim()}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                />
              </Text>

              <Text className={styles.complexText}>
                {apartment.building_title}
                {apartment.building_completion_construction_at &&
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  `, сдан в ${apartment.building_completion_construction_at}`}
              </Text>
            </LogoWithContent>
          )}
          {isShowMap && (
            <ButtonBase
              className={styles.watchOnMapButton}
              icon={<Icon src={map_icon} />}
              onClick={showBuildingOnMapPopup}
            >
              {!isMobile && <>Смотреть на карте</>}
            </ButtonBase>
          )}
        </div>
      )}

      {/* TODO: добавить ссылку на подборку */}
      <ButtonBase
        icon={<img src={iconLeft} alt="go to flats" />}
        className={styles.toFlatsLink}
        onClick={goToOfferPage}
      >
        Назад к подборке
      </ButtonBase>
    </div>
  );
};

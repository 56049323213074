import React from 'react';
import cc from 'classcat';
import { Text } from 'components/atoms/Text';
import { TabItemProps } from 'components/containers/Tabs';
import { BuildingEntity } from 'services';
import styles from './styles.module.css';

type Props = {
  buildingData: TabItemProps<BuildingEntity>;
  isShowQueueLabel: boolean;
  isActive: boolean;
};

export const QueueBuildingsSlideItem = ({
  buildingData: { data, label, helperText },
  isActive,
  isShowQueueLabel,
}: Props) => (
  <>
    <div className={styles.queueText}>
      {isShowQueueLabel && data?.phase_title && `${data.phase_title} очередь`}
    </div>
    <button
      type="button"
      className={cc([
        styles.blockCard,
        {
          [styles.activeCard]: isActive,
        },
      ])}
    >
      <Text tagName="div" size="S" weight="bold">
        {label}
      </Text>
      {helperText && (
        <Text tagName="div" size="XS" lineHeight="M" weight="normal">
          {helperText}
        </Text>
      )}
    </button>
  </>
);

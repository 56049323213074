import React, { ReactNode } from 'react';
import { MayBe } from 'types';
import { DateFormat } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  children?: ReactNode;
  apartmentCount: number;
  updated_at?: MayBe<string>;
};

export const InfoCard = ({ children, apartmentCount, updated_at }: Props) => (
  <div className={styles.infoCardWrapper}>
    <div className={styles.infoCardLeft}>
      {updated_at && (
        <div>
          Последнее обновление{' '}
          <DateFormat value={updated_at} format="dateMonthYearShortInTime" />
        </div>
      )}
      <div>Квартир в подборке {apartmentCount}</div>
    </div>
    {children && <div className={styles.infoCardRight}>{children}</div>}
  </div>
);

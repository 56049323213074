import React, { MouseEvent, useCallback } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useId } from 'react-id-generator';
import {
  ResidentialComplexCard,
  EmptyData,
  LinkButton,
} from 'components/atoms';

import { leafletStore, complexFilterStore, buildingsStore } from 'stores';

import { appRoutesService } from 'services';

import { rootProfileStore } from 'stores/user/RootProfileStore';
import { bindComplexToPin } from 'stores/Complex/BindComplexToPin';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';

import { ComplexListSkeleton } from './components/ComplexListSkeleton';

import { ComplexListHeader } from './components/ComplexListHeader';
import { useComplexListOnIncrement } from './hooks/useComplexListOnIncrement';
import { useReactionToLoadComplexData } from './hooks/useReactionToLoadComplexData';
import styles from './styles.module.css';

const ComplexListTestIDs = {
  complexList: 'ComplexList_complexList',
  complexItem: 'ComplexList_complexItem',
};

export const ComplexListRender = () => {
  const { complexes, requestApi } = buildingsStore;

  const onScrollIncrement = useComplexListOnIncrement({
    leafletStore,
    buildingsStore,
    complexFilterStore,
  });

  useReactionToLoadComplexData({
    leafletStore,
    buildingsStore,
    complexFilterStore,
  });

  const idList: string[] = useId(complexes.data?.list.length, 'complexes');

  const toggleComplexFavorites = useCallback(
    (id: number) => (evt: MouseEvent) => {
      evt.preventDefault();
      if (id) {
        rootProfileStore.favoritesComposeAuth.toggleComplex(id);
      }
    },
    [],
  );

  return (
    <div className={styles.root} onScroll={onScrollIncrement}>
      <ComplexListHeader
        foundedCount={buildingsStore?.complexes?.total || 0}
        isLoading={requestApi.isLoading}
      />

      {!complexes.data?.list.length && !requestApi.isLoading && (
        <EmptyData
          title="Для вашего запроса нет результатов"
          description={
            <>
              Попробуйте изменить фильтры или{' '}
              <LinkButton onClick={complexFilterStore.filters.reset}>
                сбросить поиск
              </LinkButton>
            </>
          }
          className={styles.emptyDataWrapper}
        />
      )}

      <ul
        {...dataTestIdToProps(ComplexListTestIDs.complexList)}
        className={styles.list}
      >
        {complexes &&
          complexes.data?.list.map((building, index) => (
            <li
              {...dataTestIdToProps(ComplexListTestIDs.complexItem)}
              key={idList[index]}
              className={styles.listItem}
              onMouseEnter={() => {
                bindComplexToPin.setTargetId(building.id);
              }}
              onMouseLeave={() => {
                bindComplexToPin.setTargetId(null);
              }}
            >
              <Link
                to={{
                  pathname: appRoutesService.replaceRoute('view', {
                    complexId: building.id.toString(),
                  }),
                }}
                className={cc([
                  styles.card,
                  {
                    [styles.cardHover]:
                      bindComplexToPin.targetId === building.id,
                  },
                ])}
              >
                <ResidentialComplexCard
                  buildingObject={building}
                  toggleComplexFavorites={toggleComplexFavorites}
                />
              </Link>
            </li>
          ))}

        {requestApi.isLoading && <ComplexListSkeleton />}
      </ul>
    </div>
  );
};

export const ComplexList = observer(ComplexListRender);

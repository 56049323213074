import { useEffect } from 'react';
import { OffersEditStore, OfferItemStore } from 'stores';
import { MayBe } from 'types';

type Props = {
  offersEditStore: OffersEditStore;
  offerItemStore: OfferItemStore;
  offerId?: MayBe<string>;
};

export const useLoadOffer = ({
  offerId,
  offersEditStore,
  offerItemStore,
}: Props) => {
  useEffect(() => {
    if (offerId) {
      offersEditStore.setIsEdit(true);
      void offerItemStore.fetchOffer({
        offerId: parseInt(offerId, 10),
      });
    } else {
      offersEditStore.setIsEdit(false);
    }
  }, [offerId]);
};

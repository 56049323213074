import React, { useEffect } from 'react';
import { BasePopup } from 'components/atoms';
import { useGetResponsiveModalComponent } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { appRoutesService, Params } from 'services';

import { observer } from 'mobx-react';
import { promotionStore } from '../../stores/PromotionStore';
import { PromotionContent } from './components/PromotionContent';
import styles from './styles.module.css';

export const PromotionPageRender = () => {
  const { push } = useHistory();
  const { stockId, complexId } =
    useParams<Pick<Params, 'stockId' | 'complexId'>>();

  const { promotion } = promotionStore;

  const { PopupWrapper } = useGetResponsiveModalComponent({
    DesktopModalWrapper: BasePopup,
    mobileHeaderChildrenTitle: 'Акция',
  });

  useEffect(() => {
    if (stockId) {
      void promotionStore.loadPromotion(stockId);
    }
  }, [stockId]);

  return (
    <PopupWrapper
      size="LG"
      isOpen
      onClose={() => {
        push(
          appRoutesService.replaceRoute('view', {
            complexId,
          }),
        );
      }}
    >
      <div className={styles.wrapper}>
        <PromotionContent promotion={promotion} />
      </div>
    </PopupWrapper>
  );
};

export const PromotionPage = observer(PromotionPageRender);

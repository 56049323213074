import React from 'react';
import { observer } from 'mobx-react';
import { SelectSingle } from 'components/atoms';
import { BuildingFilterType } from 'stores/BuildingFilters';
import { infoStore } from 'stores/InfoStore';
import { DictionaryItem, DictionaryKeys } from 'services';
import { FilterStore } from 'stores/FilterStore';

type Props = {
  filterKey: any;
  placeholder?: string;
  onlyInternal?: boolean;
  dictKey: DictionaryKeys;
  disabled?: boolean;
  store: FilterStore<any, any>;
  filterDictionaryItems?: (dictItems: DictionaryItem[]) => DictionaryItem[];
};

const ReactiveSelectRender = ({
  onlyInternal = false,
  placeholder = 'Выбрать',
  filterKey,
  dictKey,
  disabled,
  store,
  filterDictionaryItems,
}: Props) => {
  const filterItem: string[] = store.filters[filterKey];
  let selectItems: DictionaryItem[];

  if (filterDictionaryItems && typeof filterDictionaryItems === 'function') {
    selectItems =
      filterDictionaryItems(infoStore.dictionaries[dictKey]?.items || []) || [];
  } else {
    selectItems = infoStore.dictionaries[dictKey]?.items || [];
  }

  return (
    <SelectSingle
      items={Object.fromEntries(
        selectItems.map((dictItem) => [dictItem.id, dictItem.value]),
      )}
      value={filterItem || []}
      placeholder={placeholder}
      onChange={(newValue) => {
        store.setField(
          filterKey as keyof BuildingFilterType,
          newValue as string[],
        );
      }}
      multiselect
      disabled={disabled}
      onlyInternal={onlyInternal}
    />
  );
};

export const ReactiveSelect = observer(ReactiveSelectRender);

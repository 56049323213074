import React from 'react';
import { Field, useFormikContext } from 'formik';
import { get, has, omit } from 'lodash';
import { OTHER_OPTION } from 'services/constants';
import { FormikAsyncSelect } from '../../../../../../components/atoms/Form/FormikSelect';
import { FormikTextField } from '../../../../../../components/atoms/Form/FormikTextField';
import { Option } from '../../../../../../components/atoms/Form/FormikSelect/types';
import { ConfirmationAndChangeComponent } from '../ConfirmationAndChangeComponent';
import { optionIsOther } from '../../../../../../services/constants/handlers';

type Props = {
  mainField: {
    label: string;
    name: string;
    placeholder?: string;
    loadOptions: (
      inputValue: string,
      callback: (options: Option[]) => void,
    ) => Promise<any>;
    className?: string;
  };
  otherField: {
    label: string;
    name: string;
    placeholder?: string;
    className?: string;
  };
  calcShowOtherField?: () => boolean;
};

const UIDictionary = {
  confirmationWait: 'Находится на проверке',
  confirmationOk: 'Подтверждена',
  openEdit: '',
  save: '',
};

export const FieldSelectOrOtherField = <FormValues extends {}>({
  mainField,
  otherField,
  calcShowOtherField,
}: Props) => {
  const { values } = useFormikContext<FormValues>();

  let otherFieldIsShow = true;

  if (has(values, mainField.name)) {
    otherFieldIsShow = calcShowOtherField
      ? calcShowOtherField()
      : optionIsOther(get(values, [mainField.name]));
  }

  return (
    <>
      <ConfirmationAndChangeComponent
        className={mainField.className}
        isConfirmed={get(values, ['developerIsConfirmed'], false)}
        isEdit={false}
        UIDictionary={UIDictionary}
        onSave={() => {
          //
        }}
        onCancel={() => {
          //
        }}
        onEdit={() => {
          //
        }}
      >
        <Field
          {...omit(mainField, 'className')}
          defaultOptions={[OTHER_OPTION]}
          component={FormikAsyncSelect}
        />
      </ConfirmationAndChangeComponent>

      {otherFieldIsShow && (
        <Field component={FormikTextField} {...otherField} />
      )}
    </>
  );
};

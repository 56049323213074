import { createContext } from 'react';
import { UseBuildingNavBarTabsReturnType } from '../../hooks/useBuildingNavBarTabs';

export const BuildingNavBarTabsContext =
  createContext<UseBuildingNavBarTabsReturnType>({
    activeBuilding: 0,
    buildingsTabs: [],
    changeFilterByBuildings: () => null,
  });

export const BuildingNavBarTabsContextProvider =
  BuildingNavBarTabsContext.Provider;

export const BuildingNavBarTabsContextConsumer =
  BuildingNavBarTabsContext.Consumer;

import { SyntheticEvent, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { buildingFilters } from 'stores';
import { Params } from 'services';
import { ApartmentsStore } from 'stores/Apartment/ApartmentsStore/ApartmentsStore';

export const useApartmentListIncrement = (apartmentsStore: ApartmentsStore) => {
  const { complexId, buildingId } =
    useParams<Pick<Params, 'complexId' | 'buildingId'>>();

  return useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const target = event.target as HTMLDivElement;
      const isBottom =
        Math.floor(target.scrollHeight - target.scrollTop) <=
        target.clientHeight;

      if (!apartmentsStore.hasMore && isBottom && complexId) {
        apartmentsStore.pageInfo.changePage(
          (apartmentsStore.pageInfo?.pageInfo?.page || 1) + 1,
        );

        void apartmentsStore.loadApartments(
          complexId,
          apartmentsStore.pageInfo.pageInfo,
          {
            ...buildingFilters.filters.filterToApiFormat,
            buildingIds: [String(buildingId)],
          },
        );
      }
    },
    [buildingFilters, complexId, buildingId, apartmentsStore],
  );
};

import { SyncStorage } from 'stores/SyncTrunk/SyncTrunk';
import { apartmentServiceStorage } from 'services';

export const apartmentServiceStorageAdapter: SyncStorage = {
  getItem(): string | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const state = apartmentServiceStorage.getStatusPermitViewApartment();
    if (state) {
      return JSON.stringify(state);
    }
    return null;
  },
  removeItem(): void {
    apartmentServiceStorage.removeStatusPermitViewApartment();
  },
  setItem(_, json: string): void {
    apartmentServiceStorage.setStatusPermitViewApartment(JSON.parse(json));
  },
};

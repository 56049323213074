import React, { ReactNode, useCallback } from 'react';
import { Route, Switch } from 'react-router';
import { APP_ROUTES, appRoutesService } from 'services';
import {
  AuthEmailCodePage,
  AuthEmailConfirmPage,
  AuthEmailNotFoundPage,
  AuthEmailSendLinkPage,
  AuthEmailValidatePage,
  AuthPhoneCodePage,
  AuthPhoneRegistrationPage,
  AuthPhoneRegistrationSuccessPage,
  AuthPhoneValidatePage,
  LinkIsOutOfDatePage,
} from './pages';
import { AuthEmailChangePage } from './pages/AuthEmailChangePage';

type Props = {
  parentRoute?: string;

  components?: Partial<Record<keyof typeof APP_ROUTES, ReactNode>>;
};

export const AuthPage = ({ parentRoute, components }: Props) => {
  const getPathWithParent = useCallback(
    (key: keyof typeof APP_ROUTES): string =>
      [parentRoute, appRoutesService.getRoute(key)].join(''),
    [parentRoute],
  );

  return (
    <Switch>
      <Route exact path={getPathWithParent('authPhoneCodePage')}>
        {components?.authPhoneCodePage ? (
          components?.authPhoneCodePage
        ) : (
          <AuthPhoneCodePage />
        )}
      </Route>
      <Route exact path={getPathWithParent('authEmailCodePage')}>
        {components?.authEmailCodePage ? (
          components?.authEmailCodePage
        ) : (
          <AuthEmailCodePage />
        )}
      </Route>
      <Route exact path={getPathWithParent('authEmailConfirmPage')}>
        <AuthEmailConfirmPage />
      </Route>
      <Route exact path={getPathWithParent('authEmailNotFoundPage')}>
        <AuthEmailNotFoundPage />
      </Route>
      <Route path={getPathWithParent('authEmailSendLinkPage')}>
        <AuthEmailSendLinkPage />
      </Route>
      <Route path={getPathWithParent('authEmailValidatePage')}>
        <AuthEmailValidatePage />
      </Route>
      <Route path={getPathWithParent('authPhoneRegistrationPage')}>
        <AuthPhoneRegistrationPage />
      </Route>
      <Route path={getPathWithParent('authPhoneRegistrationSuccessPage')}>
        <AuthPhoneRegistrationSuccessPage />
      </Route>
      <Route path={getPathWithParent('authPhoneValidatePage')}>
        <AuthPhoneValidatePage />
      </Route>
      <Route path={getPathWithParent('linkIsOutOfDatePage')}>
        <LinkIsOutOfDatePage />
      </Route>
      <Route path={getPathWithParent('authEmailChangePage')}>
        <AuthEmailChangePage />
      </Route>
    </Switch>
  );
};

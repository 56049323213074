import React from 'react';
import { Pin } from 'services';
/** @todo: избавится от FlexBox */
import { FlexBox } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  pin: Pin;
};

export const DefaultPin = ({ pin }: Props) => (
  <div className={`${styles.pin} js--pinOpener js--pinId-${pin.id}`}>
    <div className={styles.pinWrapper}>
      <div className={styles.pinIcon} data-icon="true" />
    </div>
  </div>
);

export const PinDetails = ({ pin }: Props) => (
  <FlexBox
    className={`${styles.pinDetails} js--pinOpener js--pinId-${pin.id}`}
    width="max-content"
    alignItems="stretch"
  >
    <img
      src={pin.photo}
      alt="img"
      style={{ maxHeight: '60px' }}
      loading="lazy"
    />
    <FlexBox
      flexDirection="column"
      justifyContent="center"
      className={styles.pinDetailsText}
    >
      <span className={styles.title}>{pin.title}</span>
      <span className={styles.builder}>{pin.developer}</span>
    </FlexBox>
  </FlexBox>
);

import React from 'react';
import { SalesDepartments } from 'services';
import { ContactView } from 'components/atoms';

import styles from './styles.module.css';

type Props = {
  department: SalesDepartments;
};

export const DepartmentContacts = ({ department }: Props) => (
  <div className={styles.wrapper}>
    <ContactView
      indent={{ margin: [0, 0, 15] }}
      type="address"
      address_original={department?.address_original}
      longitude={department?.longitude}
      latitude={department?.latitude}
    />
    <ContactView
      indent={{ margin: [0, 0, 15] }}
      type="tel"
      phone={department?.phone}
    />
    <ContactView
      indent={{ margin: [0, 0, 15] }}
      type="mailto"
      email={department?.email}
    />
    <ContactView type="site" site={department?.site} />
  </div>
);

import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Field, Form, Formik } from 'formik';
import { flow, filter } from 'lodash/fp';
import { MayBe } from 'types';
import { offersEditStore } from 'stores';
import { editOfferStatusSchemas } from 'validationSchemas';
import { FormikFieldButtonGroup } from 'components/atoms/Form/FormikFieldButtonGroup';
import { FormikValuesListener } from 'components/atoms/Form/FormikValuesListener';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import { DictionaryItem, infoItemsToOptions, OffersViewItem } from 'services';
import { EditOfferStatusFormValues } from '../../types';
import { useEditOfferStatusOnSubmit } from '../../hooks/useEditOfferStatusOnSubmit';
import styles from './style.module.css';

type Props = {
  offer?: MayBe<OffersViewItem>;
};

export const EditOfferStatusRender = ({ offer }: Props) => {
  const { onSubmit } = useEditOfferStatusOnSubmit();

  const options = useMemo(
    () =>
      flow<any, DictionaryItem[], Option[]>(
        filter<DictionaryItem>((item) => item.id !== offer?.status_id),
        infoItemsToOptions,
      )(offersEditStore.offerStatus?.items),
    [offersEditStore.offerStatus, offer],
  );

  const initialValues = useMemo(
    () => ({
      offerId: offer?.id,
    }),
    [offer?.id],
  );

  return (
    <Formik<EditOfferStatusFormValues>
      validationSchema={editOfferStatusSchemas}
      enableReinitialize
      initialValues={initialValues}
      onSubmit={() => {
        //
      }}
    >
      {() => (
        <Form className={styles.editOfferStatusWrapper}>
          <FormikValuesListener<EditOfferStatusFormValues>
            validationSchema={editOfferStatusSchemas}
            listenerCallback={onSubmit}
          />

          <Field
            label="Этап сделки"
            name="statusId"
            component={FormikFieldButtonGroup}
            options={options}
          />
        </Form>
      )}
    </Formik>
  );
};

export const EditOfferStatus = observer(EditOfferStatusRender);

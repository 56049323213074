import qs from 'qs';
import { serialize } from 'object-to-formdata';
import { AxiosRequestConfig } from 'axios';
import { attempt, attemptWithAuth } from '../../../libs/axios/attempt';
import { API_ROUTES } from '../../../constants';
import {
  OffersApartmentAddParams,
  OffersApartmentAddResponse,
  OffersApartmentAddUpdateBody,
  OffersApartmentAddUpdateResponse,
  OffersApartmentAddUpdateUrlParams,
  OffersApartmentAddUrlParams,
  OffersApartmentParams,
  OffersApartmentResponse,
  OffersApartmentStatusResponse,
  OffersApartmentStatusUrlParams,
  OffersCreateBody,
  OffersCreateResponse,
  OffersEditBody,
  OffersEditNoteBody,
  OffersEditNoteResponse,
  OffersEditResponse,
  OffersEditStatusBody,
  OffersEditStatusResponse,
  OffersEditViewBody,
  OffersEditViewResponse,
  OffersFavoriteBody,
  OffersFavoriteResponse,
  OffersListParams,
  OffersListResponse,
  OffersViewHashUrlParams,
  OffersViewResponse,
  OffersViewUrlParams,
  OffersApartmentUrlParams,
} from './types';
import { RequestPageInfo } from '../../../types';

export type OffersService = typeof offersService;

export const offersService = {
  list: (
    params: OffersListParams,
    pageInfo?: RequestPageInfo,
    config?: Pick<AxiosRequestConfig, 'cancelToken'>,
  ) =>
    attemptWithAuth<OffersListResponse>({
      url: API_ROUTES.offers.list,
      method: 'get',
      params: {
        ...params,
        ...pageInfo,
      },
      paramsSerializer: (params) => qs.stringify(params),
      ...config,
    }),
  view: ({ offerId }: OffersViewUrlParams) =>
    attemptWithAuth<OffersViewResponse>({
      url: API_ROUTES.offers.view.viewByOfferId.replace(
        ':offerId',
        offerId.toString(),
      ),
      method: 'get',
    }),
  viewHash: ({ hash }: OffersViewHashUrlParams) =>
    attempt<OffersViewResponse>({
      url: API_ROUTES.offers.view.viewByHash.replace(':hash', hash),
      method: 'get',
    }),
  create: (body: OffersCreateBody) =>
    attemptWithAuth<OffersCreateResponse>({
      url: API_ROUTES.offers.create,
      method: 'post',
      data: serialize(body),
    }),
  edit: (body: OffersEditBody) =>
    attemptWithAuth<OffersEditResponse>({
      url: API_ROUTES.offers.edit.edit,
      method: 'post',
      data: serialize(body),
    }),
  editView: (body: OffersEditViewBody) =>
    attemptWithAuth<OffersEditViewResponse>({
      url: API_ROUTES.offers.edit.view,
      method: 'post',
      data: serialize(body),
    }),
  editNote: (body: OffersEditNoteBody) =>
    attemptWithAuth<OffersEditNoteResponse>({
      url: API_ROUTES.offers.edit.note,
      method: 'post',
      data: serialize(body),
    }),
  editStatus: (body: OffersEditStatusBody) =>
    attemptWithAuth<OffersEditStatusResponse>({
      url: API_ROUTES.offers.edit.status,
      method: 'post',
      data: serialize(body),
    }),
  favorite: (body: OffersFavoriteBody) =>
    attemptWithAuth<OffersFavoriteResponse>({
      url: API_ROUTES.offers.favorite,
      method: 'post',
      data: serialize(body),
    }),
  apartment: (
    { apartmentId }: OffersApartmentUrlParams,
    params?: OffersApartmentParams,
  ) =>
    attempt<OffersApartmentResponse>({
      url: API_ROUTES.offers.apartment.apartment.replace(
        ':apartmentId',
        apartmentId.toString(),
      ),
      method: 'get',
      params,
      paramsSerializer: (params) => qs.stringify(params),
    }),
  apartmentAdd: (
    { apartmentId }: OffersApartmentAddUrlParams,
    params?: OffersApartmentAddParams,
    config?: Pick<AxiosRequestConfig, 'cancelToken'>,
  ) =>
    attemptWithAuth<OffersApartmentAddResponse>({
      url: API_ROUTES.offers.apartment.add.replace(
        ':apartmentId',
        apartmentId.toString(),
      ),
      method: 'get',
      params,
      paramsSerializer: (params) => qs.stringify(params),
      ...config,
    }),
  apartmentAddUpdate: (
    { apartmentId }: OffersApartmentAddUpdateUrlParams,
    body: OffersApartmentAddUpdateBody,
    config?: Pick<AxiosRequestConfig, 'cancelToken'>,
  ) =>
    attemptWithAuth<OffersApartmentAddUpdateResponse>({
      url: API_ROUTES.offers.apartment.add.replace(
        ':apartmentId',
        apartmentId.toString(),
      ),
      method: 'post',
      data: serialize(body),
      ...config,
    }),
  apartmentStatus: (
    { apartmentId }: OffersApartmentStatusUrlParams,
    config?: Pick<AxiosRequestConfig, 'cancelToken'>,
  ) =>
    attemptWithAuth<OffersApartmentStatusResponse>({
      url: API_ROUTES.offers.apartment.status.replace(
        ':apartmentId',
        apartmentId.toString(),
      ),
      method: 'get',
      ...config,
    }),
};

import { useCallback } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthPhoneCodeRequestBody, authPhoneService } from 'services';
import { maskedInputValueToNumber } from 'utils/maskedInputValueToNumber';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { useRequestApi } from 'hooks';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

export const useFormSubmit = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { push } = useHistory();
  const { isError, isSubmitting, setError, setSubmitting } = useRequestApi();

  const onSubmit = useCallback(
    async (
      values: AuthPhoneCodeRequestBody,
      formikHelpers: FormikHelpers<any>,
    ) => {
      try {
        const result = await authPhoneService.code({
          ...values,
          phone: maskedInputValueToNumber(values.phone),
        });

        const redirectUrl = authRoutesStore.getRouteWithParent(
          'authPhoneValidatePage',
          {
            ...omit(result.auth, ['description', 'type']),
            phone: maskedInputValueToNumber(values.phone),
          },
        );

        push(redirectUrl);

        setSubmitting(true);
      } catch (e) {
        submissionErrorsHandler(e, formikHelpers);
        setSubmitting(true);
        setError(true);
      }
    },
    [],
  );

  return {
    onSubmit:
      injectReCaptchaTokenToFormValues<AuthPhoneCodeRequestBody>(
        executeRecaptcha,
      )(onSubmit),
    isError,
    isSubmitting,
  };
};

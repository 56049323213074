import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import cc from 'classcat';
import SwiperType from 'swiper/types/swiper-class';
import styles from '../../styles.module.css';
import { SliderVideo } from '../SliderVideo';
import { SliderImage } from '../SliderImage';
import { SliderFeedbackForm } from '../SliderFeedbackForm';
import { AlbumPhotoToFilter } from '../../../../services';

type Props = {
  photosToShow: AlbumPhotoToFilter[];
  activeIndex: number;
  onSwiper?: (swiper: SwiperType) => void;
  thumbsSwiper: SwiperType | undefined;
  onSlideChange?: (swiper: SwiperType) => void;
};

export const PhotosSwiper = ({
  photosToShow,
  activeIndex,
  onSwiper,
  thumbsSwiper,
  onSlideChange,
}: Props) => (
  <Swiper
    className={styles.swiper}
    navigation
    id="albumPageSlider"
    lazy
    thumbs={{ swiper: thumbsSwiper }}
    onSlideChange={onSlideChange}
    onSwiper={onSwiper}
  >
    {photosToShow.map(({ id, url, videoUrl }, index) => (
      <SwiperSlide
        className={cc([
          styles.swiperSlide,
          {
            [styles.swiperSlideFeedback]: !url && !videoUrl,
            [styles.swiperSlideVideo]: videoUrl,
          },
        ])}
      >
        {url && videoUrl && (
          <SliderVideo
            activePhotoImageURL={photosToShow[activeIndex]?.url}
            photoImageURL={url}
            videoURL={videoUrl}
          />
        )}

        {url && !videoUrl && <SliderImage imageUrl={url} />}

        {!url && !videoUrl && (
          <SliderFeedbackForm lastImageUrl={photosToShow[index - 1]?.url} />
        )}
      </SwiperSlide>
    ))}
  </Swiper>
);

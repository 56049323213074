import { makeObservable, observable } from 'mobx';
import { MayBe } from 'types';
import {
  Complex,
  OffersApartmentParams,
  offersService,
  OffersApartmentUrlParams,
  viewService,
  ApartmentInOffer,
  Layouts,
} from '../../services';
import { RequestApiStore } from '../request/RequestApiStore';

type State = {
  complex: MayBe<Complex>;
  apartment: MayBe<ApartmentInOffer>;
  apartment_layouts: MayBe<Layouts>;
};

/**
 * @desc стор хранит полноценные избранного с полноценными объектами,
 * используется для отрисовки избранного в профиле
 * */
export class OfferApartmentStore {
  state: State = {
    complex: null,
    apartment: null,
    apartment_layouts: null,
  };

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();
    makeObservable(this, {
      state: observable,
    });
  }

  loadApartmentData = async (
    { apartmentId }: OffersApartmentUrlParams,
    params?: OffersApartmentParams,
  ) => {
    this.requestApi.setLoading(true);
    const [error, result] = await offersService.apartment(
      { apartmentId },
      params,
    );
    if (result) {
      this.requestApi.setLoading(false);
      this.state = {
        complex: viewService.mapDtoToPlain(result.complex),
        apartment: result.apartment,
        apartment_layouts: result.apartment_layouts,
      };
    }
    if (error) {
      this.requestApi.setLoading(false);
      this.requestApi.setError(true);
    }
    return [error, result];
  };
}

export const offerApartmentStore = new OfferApartmentStore();

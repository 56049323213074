import React from 'react';
import BaseAutoSuggest, {
  InputProps,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
  OnSuggestionsClearRequested,
} from 'react-autosuggest';

import styles from './styles.module.css';
import { RenderSuggestionsContainer } from './renderSuggestionsContainer';
import { renderSuggestion } from './renderSuggestion';
import { renderInputComponent } from './renderInputComponent';

/**
 * @link https://github.com/moroshko/react-autosuggest#theme-optional
 * */
const theme = {
  container: styles.container,
  input: styles.input,
  inputFocused: styles.inputFocused,
  suggestionsList: styles.suggestionsList,
  suggestionsContainer: styles.suggestionsContainer,
  suggestionsContainerOpen: styles.suggestionsContainerOpen,
  suggestion: styles.suggestion,
  suggestionHighlighted: styles.suggestionHighlighted,
};

export type MobileAutoSuggestOption<P> = {
  /** заголовок который будет показан в списке */
  label: string;
  /** значение например ID которое привязано к опции */
  value: string | number;
  /** ссылка на иконку
   * */
  icon?: string;
  /** дополнительные данные, например исходные данные */
  payload?: P;
};

type Props<P extends any> = {
  suggestions: MobileAutoSuggestOption<P>[];
  /**
   * @link https://github.com/moroshko/react-autosuggest#onsuggestionselected-optional
   * */
  onSuggestionSelected?: OnSuggestionSelected<MobileAutoSuggestOption<P>>;
  /**
   * @link https://github.com/moroshko/react-autosuggest#onsuggestionsfetchrequested-required
   * */
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  onSuggestionsClearRequested: OnSuggestionsClearRequested;
  placeholder?: string;
} & Pick<InputProps<P>, 'value'> &
  Pick<InputProps<P>, 'onChange'>;

export const MobileAutoSuggest = <OptionPayload extends any>({
  suggestions,
  placeholder,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  value,
  onChange,
}: Props<OptionPayload>) => {
  /**
   * @link https://github.com/moroshko/react-autosuggest#getsuggestionvalue-required
   * */
  const getSuggestionValue = (item: MobileAutoSuggestOption<unknown>): string =>
    item.label;

  return (
    <BaseAutoSuggest<MobileAutoSuggestOption<OptionPayload>>
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={RenderSuggestionsContainer}
      suggestions={
        suggestions as unknown as ReadonlyArray<
          MobileAutoSuggestOption<OptionPayload>
        >
      }
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      inputProps={{
        placeholder,
        value,
        onChange,
      }}
      theme={theme}
    />
  );
};

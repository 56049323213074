import { reaction } from 'mobx';
import {
  offersStore,
  addToOffersStore,
  offersEditStore,
  apartmentInOffersStatusStore,
  offersFilterStore,
  offerItemStore,
} from '../../OffersStore';
import { rootProfileStore } from '../RootProfileStore';

/**
 * @desc сброс зависимых от авторизации сторов
 * */
export class LogoutResetStore {
  dispose: any | undefined;

  subscribeToIsAuth = () => {
    this.dispose = reaction(
      () => rootProfileStore.authStore.isAuth,
      () => {
        offersStore.clear();
        offersStore.clearState();

        offerItemStore.clear();
        offersFilterStore.clear();
        apartmentInOffersStatusStore.clear();
        offersEditStore.clear();
        addToOffersStore.clear();
      },
    );
  };
}

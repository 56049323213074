import React, { useRef } from 'react';
import { Router, useHistory } from 'react-router';
import { useDidMount } from 'beautiful-react-hooks';
import feedPreview from 'assets/images/feedPreview.jpg';
import { MustBeAuthForContinue } from 'components/atoms/MustBeAuthForContinue';
import { useGetResponsiveModalComponent } from 'hooks';
import { BasePopup, FormikMaskedInput, Loader } from 'components/atoms';
import { useModal } from 'react-modal-hook';
import { BaseFeedbackForm } from 'components/containers/BaseFeedbackForm';
import { feedbackService } from 'services';
import { Field, Form } from 'formik';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { PrivacyButton } from 'pages/AuthPage/components/PrivacyButton';
import * as H from 'history';
import { accessToFeedsSchema } from 'validationSchemas';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { FeedbackStore } from 'stores/FeedbackStore/FeedbackStore';
import styles from './styles.module.css';

type Props = {
  history: H.History;
  onClose: () => void;
};

export const AccessToFeedsModal = ({ onClose, history }: Props) => {
  const { authUser } = rootProfileStore.authStore;
  const feedbackStore = useRef(new FeedbackStore(feedbackService));
  const { PopupWrapper } = useGetResponsiveModalComponent({
    DesktopModalWrapper: BasePopup,
    mobileHeaderChildrenTitle: 'Фиды',
    mobileHeaderChildren: '',
  });

  useDidMount(() => {
    feedbackStore.current.initialValues = {
      type: 'feed',
      phone: authUser.user?.phone,
    };
  });

  return (
    <PopupWrapper size="LG" onClose={onClose} isOpen>
      <Router history={history}>
        <MustBeAuthForContinue
          imageWrapperClassName={styles.previewImageWrapper}
          title="Получите доступ к фидам по 2 городам прямо сейчас"
          subTitle="Свяжитесь с нами, чтобы обсудить условия"
          imagePreview={feedPreview}
          formComponent={
            <BaseFeedbackForm
              validationSchema={accessToFeedsSchema}
              component={({ isSubmitting }) => (
                <Form>
                  <div className={styles.formWrapper}>
                    <Field
                      label="Телефон"
                      name="phone"
                      component={FormikMaskedInput}
                    />
                    <ButtonBase
                      icon={isSubmitting && <Loader color="primary" />}
                      fullWidth
                      color="primary"
                      type="submit"
                    >
                      Продолжить
                    </ButtonBase>
                    <PrivacyButton />
                  </div>
                </Form>
              )}
              feedbackStore={feedbackStore.current}
            />
          }
        />
      </Router>
    </PopupWrapper>
  );
};

export const useAccessToFeedsModal = () => {
  const history = useHistory();
  const [showAccessToFeedsModal, hideAccessToFeedsModal] = useModal(() => (
    <AccessToFeedsModal history={history} onClose={hideAccessToFeedsModal} />
  ));

  return {
    showAccessToFeedsModal,
    hideAccessToFeedsModal,
  };
};

import axios from 'axios';

import { unauthorizedInterceptorsOnRejected } from './interceptors/unauthorizedInterceptors';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_V1 || 'https://api.dev-esello.ru/',
});

/** @desc инстанс с токеном авторизации */
export const axiosInstanceWithAuth = axios.create({
  baseURL: process.env.REACT_APP_API_V1 || 'https://api.dev-esello.ru/',
});

axiosInstanceWithAuth.interceptors.response.use(
  undefined,
  unauthorizedInterceptorsOnRejected,
);

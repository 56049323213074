import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useMediaQuery } from 'beautiful-react-hooks';
import Simplebar from 'simplebar-react';

import { ApartmentDto } from 'services';
import hideRightSidebar from 'assets/hideRightSidebar.svg';
import { ButtonBase } from '../../../../components/ButtonBase';
import styles from './styles.module.css';
import { ApartmentViewMobilePopup } from '../ApartmentViewMobilePopup';

type Props = {
  onClose?: () => void;
  isOpen: boolean;
  children?: ReactNode;
} & Pick<ApartmentDto, 'number'>;

export const ApartmentViewPopup = ({
  onClose,
  children,
  isOpen,
  number,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 768px)');

  if (isLarge) {
    return (
      <ReactModal
        shouldCloseOnEsc
        ariaHideApp={false}
        isOpen={isOpen}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContent}
      >
        <ButtonBase
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          className={styles.closeButton}
          icon={<img src={hideRightSidebar} alt="close" />}
        />
        <Simplebar className={styles.simplebar}>{children}</Simplebar>
      </ReactModal>
    );
  }

  return (
    <ApartmentViewMobilePopup
      isOpen={isOpen}
      number={number}
      onClose={() => onClose && onClose()}
    >
      {children}
    </ApartmentViewMobilePopup>
  );
};

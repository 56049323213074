import { useEffect } from 'react';
import L, { Map as MapType } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-plugins/layer/tile/Yandex';
import { useMap } from 'react-leaflet';
import { MayBe } from 'types';

type Props = {
  mapInstance?: MayBe<MapType>;
};

export const YandexMapLayer = ({ mapInstance }: Props) => {
  const map = useMap();
  useEffect(() => {
    const _map = mapInstance || map;
    const baseLayers = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      'Yandex map': L.yandex() // 'map' is default
        .addTo(_map),
    };
    L.control
      .layers(baseLayers, undefined, {
        collapsed: false,
        hideSingleBase: true,
      })
      .addTo(_map);
  }, [map, mapInstance]);

  return null;
};

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Table = ({ children, className }: Props) => (
  <table className={cc([styles.table, className])}>{children}</table>
);

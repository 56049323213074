import React from 'react';

import { useDidMount } from 'beautiful-react-hooks';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { Complex } from 'services/v1/rc/view/types';
import { Link } from 'react-router-dom';
import { DictionaryBadge } from 'components/atoms';
import { appRoutesService, setPresetToImage } from 'services';
import styles from './styles.module.css';
import './swiper.css';
import { AlbumQueryStringType } from '../../../AlbumPage/types';

type Props = {
  complex: Complex;
  badges: number[];
} & Pick<AlbumQueryStringType, 'hideFeedbackForm'>;

export const ComplexPresentationCarousel = ({
  complex,
  badges,
  hideFeedbackForm,
}: Props) => {
  useDidMount(() => {
    SwiperCore.use([Navigation]);
  });

  const { presentation } = complex;

  return (
    <div className={styles.sliderWrapper}>
      {presentation && (
        <Swiper spaceBetween={5} slidesPerView="auto" navigation id="photocard">
          {presentation.map((photo) => (
            <SwiperSlide key={photo.url} className={styles.swiperSlide}>
              <Link
                to={appRoutesService.replaceRoute(
                  'albums',
                  {
                    complexId: complex.id.toString(),
                    albumId: 'presentation',
                  },
                  {
                    photoId: photo.id.toString(),
                    hideFeedbackForm,
                  } as AlbumQueryStringType,
                )}
                className={styles.swiperImageLink}
              >
                <img
                  src={setPresetToImage(photo.url, 'presentation')}
                  alt="placeholder"
                  className={styles.swiperImage}
                  loading="lazy"
                />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      )}

      {badges && (
        <div className={styles.badges}>
          <DictionaryBadge
            badgeInner
            dictKey="residential_complex_badges"
            dictItemId={badges}
          />
        </div>
      )}
    </div>
  );
};

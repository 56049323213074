import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';
import { Text } from '../Text';

type Props = {
  children?: ReactNode;
  src?: string;
  alt?: string;
  className?: string;
  size?: 30 | 60;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Avatar = ({ children, src, alt, className, size }: Props) => (
  <div
    className={cc([
      styles.wrapper,
      {
        [styles.avatarSize30]: size === 30,
        [styles.avatarSize60]: size === 60,
      },
      className,
    ])}
  >
    {!children && (
      <img
        className={styles.img}
        src={src}
        alt={alt || 'аватар пользователя'}
      />
    )}
    {children && (
      <Text size="L" lineHeight="L">
        {children}
      </Text>
    )}
  </div>
);

import { useCallback } from 'react';
import * as React from 'react';
import { filter, isNil, negate } from 'lodash/fp';
import { ChangeEvent } from 'react-autosuggest';
import { SuggestStore } from 'stores/SuggestStore/SuggestStore';
import { FilterStore } from 'stores/FilterStore';

type ReturnType = {
  searchValue: string;
  onChange: (event: React.FormEvent<HTMLElement>, params: ChangeEvent) => void;
  resetFilter: () => void;
};

type Props = {
  filterStore: FilterStore<any, any>;
  suggestStore: SuggestStore;
};

export const useSuggestOnChange = ({
  filterStore,
  suggestStore,
}: Props): ReturnType => {
  const { searchValue, setSearchValue } = suggestStore;

  const resetFilter = () => {
    filterStore.setFields({
      address: null,
      complexIds: null,
      developerIds: null,
    });
  };

  const isResetFilter = (prevSearchValue: string, nextSearchValue: string) => {
    if (prevSearchValue.length && nextSearchValue.length === 0) {
      return (
        filter(negate(isNil))([
          filterStore.filters.address,
          filterStore.filters.complexIds,
          filterStore.filters.developerIds,
        ]).length > 0
      );
    }
    return false;
  };

  const onChange = useCallback(
    (_: any, { newValue }: ChangeEvent) => {
      if (isResetFilter(searchValue, newValue)) {
        resetFilter();
      }
      setSearchValue(newValue);
    },
    [searchValue],
  );

  return {
    resetFilter,
    onChange,
    searchValue,
  };
};

import React, { ReactNode } from 'react';

type Props = {
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  width?: React.CSSProperties['width'];
  flexDirection?: React.CSSProperties['flexDirection'];
  flexWrap?: React.CSSProperties['flexWrap'];
  className?: string;
  children: ReactNode;
};

export const FlexBox = ({
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  width,
  flexDirection = 'row',
  flexWrap = 'nowrap',
  className = '',
  children,
}: Props) => (
  <div
    className={className}
    style={{
      display: 'flex',
      width,
      flexWrap,
      flexDirection,
      alignItems,
      justifyContent,
    }}
  >
    {children}
  </div>
);

import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { findById } from 'utils/fp';
import { useHistory } from 'react-router-dom';
import { useGetQueryString } from '../../hooks';
import { appRoutesService, Params } from '../../services';
import { useSalesDepartmentModal } from '../../components/containers/SalesDepartmentModal';
import { complexItemStore } from '../../stores/ComplexItemStore/store';

export const SalesDepartmentsPageRender = () => {
  const { target: complexData } = complexItemStore;
  const { push } = useHistory();
  const { query } =
    useGetQueryString<
      Pick<Params, 'complexId' | 'salesDepartmentType' | 'salesDepartmentId'>
    >();

  const department = complexData?.salesDepartments?.find(
    findById(query.salesDepartmentId),
  );

  const { showSalesDepartmentModal } = useSalesDepartmentModal({
    department,
    onClose: () => {
      push(
        appRoutesService.replaceRoute('view', {
          complexId: query.complexId,
        }),
      );
    },
    type: query.salesDepartmentType as any,
  });

  useEffect(() => {
    if (!complexData) {
      return;
    }
    showSalesDepartmentModal();
  }, [complexData]);

  return null;
};

export const SalesDepartmentsPage = observer(SalesDepartmentsPageRender);

import React from 'react';
import { flow, join, filter, includes } from 'lodash/fp';
import { Address, AddressScheme, joinTypeWithTitle } from '../../../services';
import { Text } from '../Text';
import { MayBe } from '../../../types';
import styles from './styles.module.css';

type Props = {
  address?: MayBe<Address[]>;
};

export const ComplexAddressView = ({ address }: Props) => {
  const topAddressFragments = filter<Address>(
    ({ scheme }) => !includes(scheme, ['country', 'region'] as AddressScheme[]),
  )(address);

  const bottomAddressFragments = filter<Address>(
    ({ scheme }) =>
      !includes(scheme, [
        'city',
        'area',
        'settlement',
        'street',
        'house',
      ] as AddressScheme[]),
  )(address);

  return (
    <>
      <Text
        className={styles.top_address}
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="div"
        size="L"
      >
        {flow<any, string[], string>(
          joinTypeWithTitle,
          join(', '),
        )(topAddressFragments)}
      </Text>
      <Text className={styles.bottom_address} tagName="div" size="M">
        {flow<any, string[], string>(
          joinTypeWithTitle,
          join(', '),
        )(bottomAddressFragments)}
      </Text>
    </>
  );
};

import React from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { ReactiveSelect } from 'components/containers/Search/components/ReactiveSelect';
import { Box } from 'components/styledComponents/Box';

import * as S from './RoomsСottagesFilter.styled';

type Props = {
  maxRoomsInViewport: number;
};

const tabs = [
  {
    label: 'ЖК',
  },
  {
    label: 'Коттеджи',
  },
];

export const RoomsСottagesFilter = ({ maxRoomsInViewport }: Props) => {
  const { currentFilterTab, setFilterTab } = complexFilterStore.filters;

  return (
    <S.Container>
      <Box display="flex" flexDirection="row">
        <S.LeftCol>
          <S.Tabs>
            {tabs.map(({ label }, idx) => (
              <S.Tab
                onClick={() => setFilterTab(idx)}
                active={idx === currentFilterTab}
                key={label}
              >
                {label}
              </S.Tab>
            ))}
          </S.Tabs>
        </S.LeftCol>
        <S.RightCol>
          {currentFilterTab ? (
            <ReactiveSelect
              store={complexFilterStore.filters}
              onlyInternal
              placeholder=""
              filterKey="villageTypeHouse"
              dictKey="village_type_house"
              filterDictionaryItems={(dictionaryItems) =>
                infoStore.getItemsStartingAt(
                  dictionaryItems,
                  maxRoomsInViewport,
                )
              }
            />
          ) : (
            <ReactiveSelect
              store={complexFilterStore.filters}
              onlyInternal
              placeholder=""
              filterKey="rooms"
              dictKey="rooms"
              filterDictionaryItems={(dictionaryItems) =>
                infoStore.getItemsStartingAt(
                  dictionaryItems,
                  maxRoomsInViewport,
                )
              }
            />
          )}
        </S.RightCol>
      </Box>
    </S.Container>
  );
};

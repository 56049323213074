import React from 'react';
import { MayBe } from 'types';
import cc from 'classcat';
import eye from 'assets/eye.svg';
import eyeDark from 'assets/eyeDark.svg';
import { createCountFormatter } from 'utils/createCountFormatter';
import { Icon } from '../Icon';
import styles from './styles.module.css';

type Props = {
  count?: MayBe<number>;
  showLabel?: boolean;
  reverse?: boolean;
  dark?: boolean;
  className?: string;
};

export const formatterViewCount = createCountFormatter({
  few: 'просмотров',
  one: 'просмотр',
  two: 'просмотра',
});

export const ViewCount = ({
  count,
  showLabel,
  reverse,
  dark,
  className,
}: Props) => {
  if (!count) {
    return null;
  }
  return (
    <div
      className={cc([
        styles.viewCountWrapper,
        {
          [styles.viewCountWrapperRevers]: reverse,
          [styles.viewCountWrapperDark]: dark,
        },
        className,
      ])}
    >
      <Icon
        className={styles.viewCountIcon}
        src={dark ? eyeDark : eye}
        size={16}
      />
      {count && <span className={styles.viewCountCount}>{count}</span>}
      {showLabel && count && (
        <span className={styles.viewCountLabel}>
          {formatterViewCount(count)}
        </span>
      )}
    </div>
  );
};

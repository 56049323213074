import React from 'react';
import { observer } from 'mobx-react';

import { ApartmentDto } from '../../../../../../../services';
import { ListItem } from '../ListItem';
import { useGetOpenApartment } from '../../../hooks/useGetOpenApartment';
import { MobileSkeleton } from '../MobileSkeleton';
import styles from './styles.module.css';
import { rootApartmentStore } from '../../../../../../../stores/Apartment/RootApartmentStore';

type Props = {
  /** @desc  */
  isLoading?: boolean;
  data: ApartmentDto[];
};
export const ListMobileRender = ({ data, isLoading }: Props) => {
  const onOpenApartment = useGetOpenApartment();
  const { apartmentUIStore } = rootApartmentStore;

  return (
    <div className={styles.wrapper}>
      {data.map((apartment) => (
        <ListItem
          onClick={onOpenApartment(apartment.id, apartment.number)}
          isActive={
            apartment.id
              ? apartmentUIStore.isActiveApartment(apartment.id)
              : false
          }
          key={apartment.id}
          apartment={apartment}
        />
      ))}
      {isLoading && (
        <>
          <MobileSkeleton />
          <MobileSkeleton />
          <MobileSkeleton />
        </>
      )}
    </div>
  );
};

export const ListMobile = observer(ListMobileRender);

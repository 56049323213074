import React, { ReactNode } from 'react';
import { pick } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { DATA_TEST_ID } from 'utils/test/dataTestIdToProps';
import { FormValidationStatusesView } from '../FormValidationStatusesView';

type Props = {
  children: ReactNode;
  name: string;
  className?: string;
};

export const FormValidationStatuses = ({
  children,
  name,
  className,
  ...rest
}: Props) => {
  const [, meta] = useField(name);
  const { isValid, submitCount } = useFormikContext();
  const submitError = !isValid && submitCount > 0 && meta?.error;

  const isError = (meta?.touched && meta?.error) || submitError;

  return (
    <FormValidationStatusesView
      {...pick(rest, DATA_TEST_ID)}
      className={className}
      isError={!!isError}
      error={meta.error}
    >
      {children}
    </FormValidationStatusesView>
  );
};

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Section } from 'services';
import { TabItemProps } from 'components/containers/Tabs';
import { TabsSelect } from 'components/containers/TabsSelect';
import { floorPlanStore } from '../../stores/FloorPlanStore';
import { buildingGridStore } from '../../stores/buildingGridStore';

export const SectionSelectRender = () => {
  const sections = buildingGridStore?.getSections || [];
  const { activeSection, changeActiveSection } = floorPlanStore;

  const tabList: TabItemProps<Section>[] = sections.map((section) => ({
    label: section.title || '',
    data: section,
  }));

  useEffect(() => {
    changeActiveSection(0);
  }, [sections]);

  return (
    <TabsSelect<any>
      tabList={tabList}
      changeTab={(index) => () => {
        floorPlanStore.changeActiveSection(index);
      }}
      currentActiveTab={activeSection}
    />
  );
};

export const SectionSelect = observer(SectionSelectRender);

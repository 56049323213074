import React, { useState, ReactNode } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import Dropdown from 'rc-dropdown';

import { TagButton } from 'components/atoms';

import { District } from 'services';

import styles from 'components/atoms/Select/SelectSingle.module.css';
import { DropdownOverlay } from './components/DropdownOverlay';
import { FilterStore } from '../../../stores/FilterStore';

type Props = {
  filterKey: any;
  placeholder?: string;
  disabled?: boolean;
  data: District[];
  store: FilterStore<any, any>;
};

export const PopupDistrictsChoicerRenderer = ({
  placeholder = 'Выбрать',
  filterKey,
  disabled,
  data,
  store,
}: Props) => {
  const [dropdownVisible, toggleDropdownVisibility] = useState(false);
  const [displayingValues, setDisplayingValues] = useState<string[]>([]);

  const filterItem: number[] | null = store.filters[filterKey];

  const handleVisibilityChange = () => {
    if (!disabled) {
      toggleDropdownVisibility(!dropdownVisible);
    }
  };

  const clearFilter = () => {
    store.resetField('districtIds');
    setDisplayingValues([]);
  };

  const displayingValue: ReactNode =
    displayingValues.length > 0 && filterItem && filterItem.length > 0 ? (
      displayingValues.map((title) => (
        <span className={styles.dispalyingValueWrapper} key={title}>
          <TagButton type="button" isSelected className="selectedButton">
            {title}
          </TagButton>
        </span>
      ))
    ) : (
      <span className={styles.placeholder}>{placeholder}</span>
    );

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={styles.overlayWrapper}
      onVisibleChange={handleVisibilityChange}
      visible={dropdownVisible}
      overlay={
        <DropdownOverlay
          data={data}
          isOpen={dropdownVisible}
          filterItem={filterItem}
          onClose={handleVisibilityChange}
          clearFilter={clearFilter}
          setDisplayingValues={setDisplayingValues}
          displayingValues={displayingValues}
          store={store}
          filterKey={filterKey}
        />
      }
      animation="slide-up"
      align={{ offset: [0, -1] }}
    >
      <div>
        <div
          className={cc([
            styles.root,
            {
              [styles.disabled]: disabled,
              [styles.withTags]: filterItem,
              [styles.isOpen]: dropdownVisible,
            },
          ])}
        >
          {displayingValue}
        </div>
      </div>
    </Dropdown>
  );
};

export const PopupDistrictsChoicer = observer(PopupDistrictsChoicerRenderer);

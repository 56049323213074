import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from 'components/styledComponents/Box';

export const TabsMenuWrapper = styled(Box)(
  css({
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  }),
);

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';

import { Logo } from 'services';

import styles from './styles.module.css';

type Props = {
  list: Logo[];
};

SwiperCore.use([Navigation, Pagination, Controller]);

export const ApartmentPreviewSlideshow = ({ list }: Props) => (
  <div className={styles.previewSlideshowWrapper}>
    <Swiper
      className={styles.previewSlideshowImageWrapper}
      spaceBetween={0}
      slidesPerView={1}
      pagination={{
        el: `.${styles.previewSlideshowPagination}`,
        type: 'bullets',
      }}
    >
      {list.map((item) => (
        <SwiperSlide key={item.id}>
          <img
            className={styles.previewSlideshowImage}
            src={item.url || ''}
            alt="фото квартиры"
          />
        </SwiperSlide>
      ))}

      <div
        style={{
          gridTemplateColumns: `repeat(${list.length}, auto)`,
        }}
        className={styles.previewSlideshowPagination}
      />
    </Swiper>
  </div>
);

import React from 'react';
import { DictionaryBadge } from '../../../../../../components/atoms';

type Props = {
  apartment_status?: number[];
};

export const ApartmentStatus = ({ apartment_status }: Props) => (
  <div>
    {apartment_status && (
      <DictionaryBadge
        badgeInner
        size="medium"
        dictItemId={apartment_status}
        dictKey="apartment_status"
      />
    )}
  </div>
);

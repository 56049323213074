import { action, makeObservable, observable, toJS } from 'mobx';
import {
  SearchService,
  searchService,
  loggerService,
  SuggestComplex,
  SuggestDeveloper,
  suggestService,
  SuggestYandex,
  SearchParameters,
} from 'services';
import { flow, get, map, includes, toNumber } from 'lodash/fp';
import pin from 'assets/bigPin.svg';
import residental from 'assets/residental.svg';
import { RequestApiStore } from '../request/RequestApiStore';
import LeafletStore, { LeafletStoreClass } from '../LeafletStore';
import { complexFilterStore, ComplexFilterStore } from '../ComplexFilterStore';
import { YandexSuggestResult } from '../../react-app-env';
import { AutoSuggestOption } from '../../components/atoms/AutoSuggest';
import { MayBe } from '../../types';

/**
 * @desc стор для поиска в нашем API И в API yandex map
 * */
export class SuggestStore {
  /** статус поиска */
  requestApi: RequestApiStore;

  // <editor-fold desc="DATA">
  /** */
  yandex: AutoSuggestOption<YandexSuggestResult>[] = [];

  /** список ЖК */
  complexes: AutoSuggestOption<SuggestComplex>[] = [];

  /** список застройщиков */
  developers: AutoSuggestOption<SuggestDeveloper>[] = [];
  // </editor-fold>

  // <editor-fold desc="DI components">
  searchService: SearchService;

  leafletStore: LeafletStoreClass;

  filters: ComplexFilterStore;
  // </editor-fold>

  searchValue = '';

  constructor(
    searchService: SearchService,
    leafletStore: LeafletStoreClass,
    filters: ComplexFilterStore,
  ) {
    this.searchService = searchService;
    this.leafletStore = leafletStore;
    this.filters = filters;
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      yandex: observable,
      complexes: observable,
      developers: observable,
      searchValue: observable,

      suggestByAllSource: action.bound,
      setSearchValue: action.bound,
    });
  }

  /**
   * @desc выполнить поиск во всех источниках
   * */
  suggestByAllSource(search: string): void {
    this.requestApi.setLoading(true);

    try {
      void this.searchInOurApi(search);
      void this.searchInYandexApi(search);
    } catch (e) {
      loggerService.error(e);
      this.requestApi.setError(true);
    }

    this.requestApi.setLoading(false);
  }

  /** @desc поиск регионов и городов */
  async searchInYandexApi(search: string): Promise<void> {
    if (!window.ymaps) {
      loggerService.error('[searchInYandexApi]: ymaps is not defined');
      return;
    }

    const result: MayBe<SuggestYandex[]> = await suggestService.yandexSuggest(
      search,
    );

    this.yandex = map<
      YandexSuggestResult,
      AutoSuggestOption<YandexSuggestResult>
    >(
      (item: YandexSuggestResult): AutoSuggestOption<YandexSuggestResult> => ({
        label: item.displayName,
        value: item.value,
        icon: pin,
        payload: item,
      }),
    )(result);
  }

  /** @desc поиск организаций и ЖК */
  async searchInOurApi(search: string): Promise<void> {
    const result = await suggestService.suggest(search);

    this.complexes = map<SuggestComplex, AutoSuggestOption<SuggestComplex>>(
      (item: SuggestComplex): AutoSuggestOption<SuggestComplex> => ({
        label: item.title || '',
        value: item.id,
        icon: residental,
        payload: item,
      }),
    )(result.complexes);

    this.developers = map<
      SuggestDeveloper,
      AutoSuggestOption<SuggestDeveloper>
    >(
      (item: SuggestDeveloper): AutoSuggestOption<SuggestDeveloper> => ({
        label: item.title || '',
        value: item.id,
        icon: item.logo || undefined,
        payload: item,
      }),
    )(result.developers);
  }

  clear = () => {
    this.developers = [];
    this.complexes = [];
    this.yandex = [];
  };

  setSearchValue = (value: string): void => {
    this.searchValue = value;
  };

  /**
   * @desc генерирует объект для поиска на основе выбора пользователя
   * */
  getFilterBySelectedSuggestion = (
    suggestion: AutoSuggestOption<
      SuggestComplex | SuggestDeveloper | SuggestYandex
    >,
  ):
    | (Pick<SearchParameters, 'address'> &
        Pick<SearchParameters, 'developerIds'> &
        Pick<SearchParameters, 'complexIds'>)
    | undefined => {
    if (suggestStore.isDeveloper(suggestion.value)) {
      return {
        developerIds: [String((suggestion.payload as SuggestDeveloper).id)],
      };
    }
    if (suggestStore.isComplex(suggestion.value)) {
      return {
        complexIds: [String((suggestion.payload as SuggestComplex).id)],
      };
    }
    if (suggestStore.isYandexAddress(suggestion.value)) {
      return {
        address: (suggestion.payload as SuggestYandex).value || '',
      };
    }
    return undefined;
  };

  /**
   * @desc поиск по полю "value" из объекта типа AutoSuggestOption
   * */
  isComplex = (value: any) =>
    flow<
      any,
      AutoSuggestOption<SuggestComplex>[],
      (string | number)[],
      number[],
      boolean
    >(
      toJS,
      map<AutoSuggestOption<SuggestComplex>, string | number>(
        get<AutoSuggestOption<SuggestComplex>, 'value'>('value'),
      ),
      map<string | number, number>(toNumber),
      includes(parseInt(value, 10)),
    )(this.complexes);

  /**
   * @desc поиск по полю "value" из объекта типа AutoSuggestOption
   * */
  isDeveloper = (value: any): boolean =>
    flow<
      any,
      AutoSuggestOption<SuggestDeveloper>[],
      (string | number)[],
      number[],
      boolean
    >(
      toJS,
      map<AutoSuggestOption<SuggestDeveloper>, string | number>(
        get<AutoSuggestOption<SuggestDeveloper>, 'value'>('value'),
      ),
      map<string | number, number>(toNumber),
      includes(parseInt(value, 10)),
    )(this.developers);

  /**
   * @desc поиск по полю "value" из объекта типа AutoSuggestOption
   * */
  isYandexAddress = (value: any) =>
    flow<any, SuggestYandex[], string[], boolean>(
      toJS,
      map<SuggestYandex, string>(get('value')),
      includes(value),
    )(this.yandex);
}

export const suggestStore = new SuggestStore(
  searchService,
  LeafletStore,
  complexFilterStore,
);

import React, { ComponentProps } from 'react';
import { Formik } from 'formik';
import { useWillUnmount } from 'beautiful-react-hooks';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { withProps } from 'recompose';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { offersEditorFormSchema } from 'validationSchemas';
import { observer } from 'mobx-react';
import { offersEditStore, offerItemStore } from 'stores';
import { FormRenderWithObserver } from '../../components/FormRender';
import { OffersEditorFormValues } from '../../types';
import { useSubmitOffer } from '../../hooks/useSubmitOffer';
import { useLoadOffer } from '../../hooks/useLoadOffer';
import { useCreateInitialValues } from '../../hooks/useCreateInitialValues';
import { useDeleteOffer } from '../../hooks/useDeleteOffer';

type Props = {
  offerId?: string;
  onClose: () => void;
};

export const OffersEditorFormRender = ({ offerId, onClose }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { onSubmit } = useSubmitOffer({ onClose });

  useWillUnmount(() => {
    offersEditStore.clear();
    offerItemStore.clear();
  });

  useLoadOffer({
    offerId,
    offersEditStore,
    offerItemStore,
  });

  const { onDeleteOffer } = useDeleteOffer({
    onSuccess: onClose,
  });

  const { initialValues } = useCreateInitialValues({
    offerItemStore,
    offersEditStore,
  });

  return (
    <Formik<OffersEditorFormValues>
      validationSchema={offersEditorFormSchema}
      onSubmit={injectReCaptchaTokenToFormValues<OffersEditorFormValues>(
        executeRecaptcha,
      )(onSubmit)}
      enableReinitialize
      initialValues={initialValues}
      component={withProps<
        Pick<ComponentProps<typeof FormRenderWithObserver>, 'onDeleteOffer'>,
        ComponentProps<typeof FormRenderWithObserver>
      >({
        onDeleteOffer: onDeleteOffer(offerId),
      })(FormRenderWithObserver)}
    />
  );
};

export const OffersEditorForm = withGoogleReCaptchaProvider(
  observer(OffersEditorFormRender),
);

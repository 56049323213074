import React, { ReactNode } from 'react';
import {
  flow,
  get,
  isString,
  size,
  filter,
  isNil,
  negate,
  map,
} from 'lodash/fp';
import { ApartmentDto, Layouts } from 'services';
import styles from './styles.module.css';
import windowGridIcon from '../../../../../../assets/windowGridIcon.svg';
import flatGridIcon from '../../../../../../assets/flatGridIcon.svg';

const hasWindows = flow(get(['windows', 'features']), size, (size) => size > 0);
const hasFlatPlan = flow(get(['polygon']), isString);

type Props = Pick<ApartmentDto, 'layouts'>;

export const NotWindowsOrPlanView = ({ layouts }: Props) =>
  !layouts ? (
    <div>
      <div className={styles.row}>
        <img className={styles.icon} src={windowGridIcon} alt="" />
        <img className={styles.icon} src={flatGridIcon} alt="" />
      </div>
    </div>
  ) : (
    <div className={styles.wrapper}>
      {flow<any, Layouts[], ReactNode[]>(
        filter(negate(isNil)), // @todo: уточнить для многоэтажных квартир в каком виде будет приходить планировка
        map((layout) => (
          <div key={layout.id} className={styles.row}>
            {!hasWindows(layout) && (
              <img className={styles.icon} src={windowGridIcon} alt="" />
            )}
            {!hasFlatPlan(layout) && (
              <img className={styles.icon} src={flatGridIcon} alt="" />
            )}
          </div>
        )),
      )(layouts)}
    </div>
  );

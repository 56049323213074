import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { complexItemStore } from 'stores/ComplexItemStore/store';
import { requestCacheStore } from 'stores';
import { Params } from 'services';
import { refreshStore } from '../../stores/RefreshStore/RefreshStore';

export const useLoadingComplexData = () => {
  const { complexId } =
    useParams<Pick<Params, 'complexId' | 'buildingId' | 'layoutTabs'>>();
  const loadingComplexData = useCallback(() => {
    if (complexId) {
      void complexItemStore.fetchComplex(parseInt(complexId, 10));
    }
  }, [complexId]);

  useEffect(() => {
    refreshStore.add(loadingComplexData);
    loadingComplexData();
    return () => {
      /** почистить кеш с квартирами */
      requestCacheStore.clearCache();

      refreshStore.remove(loadingComplexData);
    };
  }, [complexId, loadingComplexData]);
};

import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';
import { Text } from '../Text';
import { AutoSuggestOption } from './index';

/**
 * @link https://github.com/moroshko/react-autosuggest#getsectionsuggestions-required-when-multisectiontrue
 * */
export const renderSuggestion = ({ label, icon }: AutoSuggestOption<any>) => (
  <div className={cc([styles.suggestion])}>
    {icon && <img className={styles.selectIcon} src={icon} alt="icon" />}
    <Text size="S">{label}</Text>
  </div>
);

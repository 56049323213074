import React, { useMemo } from 'react';
import numeral from 'numeral';
import { isNumber, isString } from 'lodash/fp';

export const numberFormatPatterns = {
  phone: 'N NNN NNN NN NN',
  mobile: '+N (NNN) NNN-NN-NN',
  rub_meter2: '0,0[.]00 Rm2',
  rub: '0,0[.]00 $',
  rubWithoutSymbol: '0,0[.]00',
  meter2: '0,0.00',
};

export type NumberFormatPatterns = keyof typeof numberFormatPatterns;

type Props = {
  value?: number | string | null;
  className?: string;
  tagName?: string;
  format?: NumberFormatPatterns;
};

export const numberFormat = (
  value: Props['value'],
  format: Props['format'],
) => {
  if (!format) {
    return '0.00 м²';
  }
  if (format !== 'meter2') {
    return numeral(value).format(numberFormatPatterns[format]);
  }

  /**
   * @desc форматирование квадратных метров обеспечено API,
   * здесь лишь добавляется символ квадратных метров
   * и на случай если пришло число без форматирования
   * оно форматируется по стандарту "00.00"
   * */
  if (isNumber(value)) {
    return `${numeral(value).format(numberFormatPatterns[format])} м²`;
  }

  if (isString(value)) {
    return `${value} м²`;
  }
  return '0.00 м²';
};

export const NumberFormat = ({
  value,
  className,
  tagName,
  format = 'rub',
  ...rest
}: Props) => {
  const formatNumber = useMemo(
    () => numberFormat(value, format),
    [format, value],
  );
  return React.createElement(
    tagName || 'span',
    {
      className,
      ...rest,
    },
    formatNumber,
  );
};

import React, { ReactNode } from 'react';
import { ProfileMenu } from '../ProfileMenu';

import styles from './styles.module.css';

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => (
  <section className={styles.layoutWrapper}>
    <div className={styles.layout}>
      <ProfileMenu />
      <div className={styles.contentWrapper}>{children}</div>
    </div>
  </section>
);

import React, { useMemo } from 'react';
import { flow, get } from 'lodash/fp';

import {
  ApartmentInOffer,
  Complex,
  DictionaryKeys,
  Feature,
  getAllApartmentPlans,
  getFirstLayoutPhoto,
  Layouts,
} from 'services';
import {
  DictionaryBadge,
  DictionaryView,
  NumberFormat,
  Text,
  PlaceholderImage,
  Icon,
  OverlayZoomButton,
} from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { useBuildingOnMapPopup } from 'pages/LayoutPage/containers/BuildingOnMapPopup';
import { complexItemStore } from 'stores/ComplexItemStore/store';

import map_icon from 'assets/switcherMap.svg';

import { getWindowsFromLayers } from 'pages/LayoutPage/utils';

import { useMediaQuery } from 'beautiful-react-hooks';

import { useApartmentPlanOnMap } from 'pages/LayoutPage/pages/ApartmentView/hooks/useApartmentPlanOnMap';
import { MayBe } from 'types';
import { useApartmentPlanPopup } from 'pages/LayoutPage/containers/ApartmentPlanPopup';
import { useGetOfferViewFieldStatus } from '../../../OffersViewPage/hooks/useGetOfferViewFieldStatus';
import styles from './styles.module.css';

type Props = {
  complex: Complex;
  apartment: ApartmentInOffer;
  apartmentLayouts: MayBe<Layouts>;
};

export const MainApartmentInfo = ({
  complex,
  apartment,
  apartmentLayouts,
}: Props) => {
  const { isShowMap, isShowComplex } = useGetOfferViewFieldStatus();

  const isLarge = useMediaQuery('(min-width: 1280px)');

  const windows: Feature[] | undefined = useMemo(
    () => getWindowsFromLayers(apartment?.layouts),
    [apartment?.layouts],
  );

  const formattedPolygon: Array<[number, number]> =
    complexItemStore.reversePolygonCoords(complex.polygon);

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon: formattedPolygon,
  });

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('apartment_type_room'),
      )(apartment),
    [apartment],
  );

  const apartment_status = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const apartmentSquares = flow(get('[0].squares'))(apartmentLayouts);

  const roomType = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('rooms'),
      )(apartment),
    [apartment],
  );

  const floorPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  const { count } = useApartmentPlanOnMap({
    plans: floorPlans,
  });

  const apartmentPreviewImage = getFirstLayoutPhoto(apartment);
  const { showApartmentPlanPopup } = useApartmentPlanPopup(
    apartment,
    count,
    apartmentSquares,
  );

  return (
    <div className={styles.flatInfoWrapper}>
      {apartmentPreviewImage && (
        <div className={styles.apartmentPreviewWrapper}>
          <OverlayZoomButton
            className={styles.apartmentPreview}
            onClick={showApartmentPlanPopup}
          >
            <img
              src={apartmentPreviewImage}
              className={styles.apartmentPreview}
              alt="фото квартиры"
            />
          </OverlayZoomButton>
        </div>
      )}
      {!apartmentPreviewImage && (
        <PlaceholderImage
          type="FLOOR_PLAN"
          className={styles.apartmentPreviewWrapper}
        />
      )}

      <div className={styles.flatInfo}>
        <div className={styles.badgeWrapper}>
          <DictionaryBadge
            badgeInner
            dictKey="apartment_status"
            dictItemId={apartment_status}
          />

          <Text className={styles.offerDate}>
            Добавлено в подборку {apartment.offer.created_at}
          </Text>
        </div>

        <Text className={styles.flatName} weight="bold">
          <DictionaryView dictKey="rooms" dictItemId={roomType} />{' '}
          <DictionaryView
            dictKey="apartment_type_room"
            dictItemId={apartmentTypeRoomId}
          />{' '}
          <NumberFormat value={apartment?.square} format="meter2" />
        </Text>

        {isShowComplex && (
          <Text className={styles.apartmentComplexText}>
            в{' '}
            <span className={styles.apartmentComplexLink}>
              {complex?.title}
            </span>
            {', '}
            {apartment.building_title}
            {apartment.building_completion_construction_at &&
              `, сдан в ${apartment.building_completion_construction_at}`}
          </Text>
        )}

        {/* @todo: вынести в отдельный компонент и переиспользовать тут и в ЖК */}
        {isShowMap && (
          <div className={styles.complexAddressWrapper}>
            {complex.address_original && (
              <Text className={styles.complexAddress}>
                {complex.address_original}
              </Text>
            )}
            <ButtonBase
              className={styles.watchOnMapButton}
              icon={<Icon src={map_icon} />}
              onClick={showBuildingOnMapPopup}
            >
              {isLarge && <>Смотреть на карте</>}
            </ButtonBase>
          </div>
        )}

        <Text className={styles.offerDateMobile}>
          Добавлено в подборку {apartment.offer.created_at}
        </Text>
      </div>
    </div>
  );
};

import React from 'react';
import { every, isNil } from 'lodash';
import LinesEllipsis from 'react-lines-ellipsis';
import { OffersAddItem } from 'services';
import { OffersContact } from '../../../OffersPage/components/OffersContact';
import styles from './styles.module.css';

type Props = {
  offer: OffersAddItem;
};

export const OffersCard = ({ offer }: Props) => (
  <div className={styles.offersCardWrapper}>
    <div className={styles.offersCardTitle}>{offer.title}</div>
    {offer.description && (
      <div className={styles.offersCardDescription}>
        <LinesEllipsis text={offer.description || ''} maxLine={2} />
      </div>
    )}
    {!every([offer.customer, offer.emails, offer.phones], isNil) && (
      <OffersContact
        name={offer.customer}
        emails={offer.emails}
        phones={offer.phones}
      />
    )}
  </div>
);

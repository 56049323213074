import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { observer } from 'mobx-react';
import { flow, has, map, every, isEqual } from 'lodash/fp';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { useHistory } from 'react-router-dom';
import { authPhoneRegistrationSchema } from 'validationSchemas';
import { AuthPhoneValidateRequestBody } from 'services';
import { useGetQueryString } from 'hooks';
import { AlertSubmission } from 'components/atoms';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { FormRender } from './components/FormRender';
import { RegistrationFormValues } from './types';
import { useFormSubmit } from './hooks/useFormSubmit/useFormSubmit';

/**
 * @desc проверка наличия всех необходимых параметров в адресной строке для регистрации
 * */
const validateQueryString = (
  query: Omit<AuthPhoneValidateRequestBody, 'g-recaptcha-response'>,
): boolean => {
  const requiredKeys: (keyof AuthPhoneValidateRequestBody)[] = [
    'code',
    'authId',
    'phone',
  ];
  return flow<any, boolean[], boolean>(
    map((key: string) => has(key)(query)),
    every(isEqual(true)),
  )(requiredKeys);
};

export const AuthPhoneRegistrationPageRender = () => {
  const { push } = useHistory();
  const { query } = useGetQueryString<
    Omit<AuthPhoneValidateRequestBody, 'g-recaptcha-response'> & {
      expiresAt: string;
    }
  >();

  /**
   * @desc если каких то параметров не хватате, то редирект на авторизацию
   * */
  useEffect(() => {
    if (!validateQueryString(query)) {
      const redirectURL =
        authRoutesStore.getRouteWithParent('authPhoneCodePage');
      push(redirectURL);
    }
  }, []);

  const { onSubmit } = useFormSubmit({ authStore: rootProfileStore.authStore });

  return (
    <AuthPopup mobileHeaderChildrenTitle="Укажите информацию о себе">
      <Formik<RegistrationFormValues>
        validationSchema={authPhoneRegistrationSchema}
        onSubmit={onSubmit}
        enableReinitialize
        initialValues={{
          authId: query.authId,
          phone: query.phone,
          code: query.code,
        }}
        render={(props) => (
          <>
            <AuthFormWrapper
              onClickButtonBack={() => {
                const redirectURL =
                  authRoutesStore.getRouteWithParent('authPhoneCodePage');
                push(redirectURL);
              }}
              showButtonBack
            >
              <FormRender {...props} />
            </AuthFormWrapper>

            <AlertSubmission isSubmitting errors={props.errors} />
          </>
        )}
      />
    </AuthPopup>
  );
};

export const AuthPhoneRegistrationPage = withGoogleReCaptchaProvider(
  observer(AuthPhoneRegistrationPageRender),
);

import React from 'react';
import { observer } from 'mobx-react';
import { BuildingFilterType } from 'stores/BuildingFilters';

import { Checkbox } from 'components/atoms/Checkbox';
import { FilterStore } from 'stores/FilterStore';

type Props = {
  filterKey: any;
  /** @todo: Добавить дженерик */
  store: FilterStore<any, any>;
  placeholder?: string;
  className?: string;
};

export const ReactiveCheckboxRender = ({
  placeholder = 'Выбрать',
  filterKey,
  store,
  className,
}: Props) => {
  const filterItem: boolean = store.filters[filterKey] as boolean;

  const handleChange = () => {
    store.setField(filterKey as keyof BuildingFilterType, !filterItem);
  };

  return (
    <Checkbox
      label={placeholder}
      onChange={handleChange}
      name={filterKey}
      checked={filterItem}
      className={className}
    />
  );
};

export const ReactiveCheckbox = observer(ReactiveCheckboxRender);
export default ReactiveCheckbox;

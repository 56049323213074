import React, { memo } from 'react';
import { Skeleton } from 'components/atoms/Skeleton';
import styles from './styles.module.css';
import { Text } from '../../../../../../components/atoms/Text';

export const GridSkeletonLoaderRender = () => {
  const size = 5;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Text tagName="div" size="XXS" textTransform="uppercase">
            1 секция
          </Text>
        </div>
        {Array.from({ length: size }, (_, index) => (
          <div className={styles.headerCol} key={`header_${index}`}>
            <Text tagName="div" size="XL">
              {index + 1}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.floor}>
        {Array.from({ length: size }, (_, index) => (
          <div className={styles.floorRow} key={`floor_${index}`}>
            <Text
              className={styles.title}
              tagName="div"
              align="center"
              size="L"
            >
              {index + 1}
            </Text>
          </div>
        ))}
      </div>
      <div className={styles.grid}>
        {Array.from({ length: size * size }, () => (
          <Skeleton
            width={150}
            height={100}
            animation="wave"
            className={styles.item}
            highlightColor="rgba(35, 46, 73, 0.05)"
          />
        ))}
      </div>
    </div>
  );
};

export const GridSkeletonLoader = memo(GridSkeletonLoaderRender);

import React, { forwardRef } from 'react';
import { Avatar } from 'components/atoms/Avatar';
import dropdownOpen from 'assets/dropdownOpen.svg';
import avatarPlaceholder from 'assets/noAvatar.svg';
import { MayBe } from 'types';
import { Icon } from 'components/atoms/Icon';
import { User } from 'services';
import styles from './styles.module.css';

type Props = {
  onClick?: () => void;
  user: MayBe<User>;
};

export const ProfileHeaderButton = forwardRef<HTMLButtonElement, Props>(
  ({ onClick, user }: Props, ref) => (
    <button
      onClick={onClick}
      type="button"
      className={styles.wrapper}
      ref={ref}
    >
      <Avatar src={user?.logo?.url || user?.logo?.src || avatarPlaceholder} />
      <div className={styles.label}>
        {user?.surname} {user?.name}
      </div>
      {onClick && <Icon src={dropdownOpen} />}
    </button>
  ),
);

import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import React, { ComponentProps, useMemo } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Icon, MobilePopup, Text } from 'components/atoms';
import arrowLeftColor from 'assets/arrowLeftColor.svg';
import styles from '../../../../../components/containers/AllFiltersPopup/styles.module.css';
import { MainChessFilters } from '../index';

export const useMainChessFiltersPopup = () => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (props: ComponentProps<typeof ReactModal>) => (
        <ReactModal
          {...props}
          shouldCloseOnEsc
          isOpen
          ariaHideApp={false}
          overlayClassName={styles.modalOverlay}
          className={styles.modal}
        />
      );
    }
    return (
      props: Pick<
        ComponentProps<typeof MobilePopup>,
        'children' | 'isOpen' | 'onClose'
      >,
    ) => (
      <MobilePopup
        IconBack={<Icon size={24} src={arrowLeftColor} alt="close filter" />}
        headerChildren={
          <Text align="center" size="L" weight="700">
            Фильтр
          </Text>
        }
        {...props}
      />
    );
  }, [isLarge]);

  const [showMainChessFilters, hideMainChessFilters] = useModal(
    () => (
      <PopupWrapper isOpen onClose={hideMainChessFilters}>
        <MainChessFilters onClose={hideMainChessFilters} />
      </PopupWrapper>
    ),
    [PopupWrapper],
  );

  return {
    showMainChessFilters,
    hideMainChessFilters,
  };
};

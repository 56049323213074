import React, { ReactNode, useMemo } from 'react';
import cc from 'classcat';
import sortedDefault from 'assets/sortedDefault.svg';
import sortedDesc from 'assets/sortedDesc.svg';
import sortedAsc from 'assets/sortedAsc.svg';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  /**
   * sorting by this field
   * @link https://react-table.tanstack.com/docs/api/useSortBy#column-properties
   * */
  isSorted?: boolean;
  /** order sorting desc */
  isSortedDesc?: boolean;
  /** is it sorted field? */
  canSort?: boolean;
  /**
   * whether a column is filterable?
   * @link https://react-table.tanstack.com/docs/api/useFilters#column-properties
   * */
  canFilter?: boolean;
};

export const Th = ({
  children,
  className,
  isSorted,
  isSortedDesc,
  canSort,
  canFilter,
  ...rest
}: Props) => {
  const sortIcon = useMemo(() => {
    if (!canSort) {
      return null;
    }
    if (canSort && !isSorted && isSortedDesc === undefined) {
      return sortedDefault;
    }
    return isSortedDesc ? sortedDesc : sortedAsc;
  }, [isSorted, isSortedDesc, canSort]);

  return (
    <th {...rest} className={cc([styles.th, className])}>
      <div className={styles.content}>
        {children}
        {sortIcon ? (
          <img className={styles.sortedIcon} src={sortIcon} alt="sorted" />
        ) : null}
      </div>
    </th>
  );
};

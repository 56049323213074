import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  size?: 'small' | 'medium';
  color?: 'default' | 'primary';
};

export const Loader = ({ size = 'small', color = 'default' }: Props) => (
  <div
    className={cc([
      styles.loader,
      {
        [styles.loader_size_small]: size === 'small',
        [styles.loader_size_medium]: size === 'medium',

        [styles.loader_color_primary]: color === 'primary',
        [styles.loader_color_default]: color === 'default',
      },
    ])}
  />
);

import { useCallback, useState } from 'react';

type Props = {
  initialCount?: number;
  minCount?: number;
  maxCount?: number;
};

export const useCounter = ({ initialCount, maxCount, minCount }: Props) => {
  const [count, _setCount] = useState(initialCount || 0);

  const onDecrement = useCallback(() => {
    if (minCount && count <= minCount) {
      return;
    }
    _setCount(count - 1);
  }, [count]);
  const onIncrement = useCallback(() => {
    if (maxCount && count >= maxCount) {
      return;
    }
    _setCount(count + 1);
  }, [count]);
  const setCount = useCallback(
    (count: number) => {
      if (maxCount && count > maxCount) {
        return;
      }
      if (minCount && count < minCount) {
        return;
      }
      _setCount(count);
    },
    [count],
  );

  return {
    count,
    onDecrement,
    onIncrement,
    setCount,
  };
};

import { action, makeObservable, observable, computed } from 'mobx';
import {
  AttemptResult,
  DictionaryItem,
  OFFER_STATUSES,
  OffersCreateBody,
  OffersCreateResponse,
  OffersEditBody,
  OffersEditNoteBody,
  OffersEditNoteResponse,
  OffersEditResponse,
  OffersEditStatusBody,
  OffersEditStatusResponse,
  OffersEditViewBody,
  OffersEditViewResponse,
  OffersFavoriteBody,
  OffersFavoriteResponse,
  offersService,
  OffersService,
} from 'services';
import { ID } from 'types';
import { RequestApiStore } from '../request/RequestApiStore';
import { InfoStore, infoStore } from '../InfoStore';
import { findById } from '../../utils/fp';

export class OffersEditStore {
  /** @desc true - режим редактирования, false - режим создания */
  isEdit = false;

  offersService: OffersService;

  editRequestApiState: RequestApiStore;

  editStatusRequestApiState: RequestApiStore;

  favoritesRequestApiState: RequestApiStore;

  editNoteRequestApiState: RequestApiStore;

  infoStore: InfoStore;

  constructor(offersService: OffersService, infoStore: InfoStore) {
    this.offersService = offersService;
    this.infoStore = infoStore;
    this.editRequestApiState = new RequestApiStore();
    this.favoritesRequestApiState = new RequestApiStore();
    this.editStatusRequestApiState = new RequestApiStore();
    this.editNoteRequestApiState = new RequestApiStore();

    makeObservable(this, {
      isEdit: observable,
      clear: action,
      setIsEdit: action,
      offerStatus: computed,
    });
  }

  get offerStatus() {
    return this.infoStore.getInfoBy('offer_status');
  }

  getStatusById = (id: ID): DictionaryItem | undefined => {
    const items = this.infoStore.getInfoBy('offer_status')?.items;

    return items?.find(findById(id.toString()));
  };

  getStatusByKey = (statusKey: OFFER_STATUSES): DictionaryItem | undefined => {
    const items = this.infoStore.getInfoBy('offer_status')?.items;

    return items?.find(({ key }) => key === statusKey);
  };

  clear = () => {
    this.favoritesRequestApiState.clear();
    this.editRequestApiState.clear();
    this.editStatusRequestApiState.clear();
  };

  setIsEdit = (isEdit: boolean) => {
    this.isEdit = isEdit;
  };

  edit = async (
    body: OffersEditBody,
  ): Promise<AttemptResult<OffersEditResponse>> => {
    this.editRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.edit(body);

    if (error) {
      this.editRequestApiState.setError(true);
    }

    this.editRequestApiState.setLoading(false);
    return [error, result];
  };

  delete = async (
    body: Pick<OffersEditStatusBody, 'offerId'>,
  ): Promise<AttemptResult<OffersEditStatusResponse>> => {
    const dictionaryItem = this.getStatusByKey('offer_status_delete');
    if (!dictionaryItem) {
      return [
        new Error('Dictionary by key "offer_status_delete" not found'),
        null,
      ];
    }
    return this.editStatus({
      ...body,
      /** @desc id в словаре offer_status_delete */
      statusId: dictionaryItem?.id,
    });
  };

  setActiveStatus = async (
    body: Pick<OffersEditStatusBody, 'offerId'>,
  ): Promise<AttemptResult<OffersEditStatusResponse>> => {
    const dictionaryItem = this.getStatusByKey('offer_status_active');
    if (!dictionaryItem) {
      return [
        new Error('Dictionary by key "offer_status_active" not found'),
        null,
      ];
    }
    return this.editStatus({
      ...body,
      /** @desc id в словаре offer_status_delete */
      statusId: dictionaryItem?.id,
    });
  };

  setFinishStatus = async (
    body: Pick<OffersEditStatusBody, 'offerId'>,
  ): Promise<AttemptResult<OffersEditStatusResponse>> => {
    const dictionaryItem = this.getStatusByKey('offer_status_finish');
    if (!dictionaryItem) {
      return [
        new Error('Dictionary by key "offer_status_finish" not found'),
        null,
      ];
    }
    return this.editStatus({
      ...body,
      /** @desc id в словаре offer_status_delete */
      statusId: dictionaryItem?.id,
    });
  };

  editStatus = async (
    body: OffersEditStatusBody,
  ): Promise<AttemptResult<OffersEditStatusResponse>> => {
    this.editStatusRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.editStatus(body);

    if (error) {
      this.editStatusRequestApiState.setError(true);
    }

    this.editStatusRequestApiState.setLoading(false);
    return [error, result];
  };

  addToFavorites = async (
    body: OffersFavoriteBody,
  ): Promise<AttemptResult<OffersFavoriteResponse>> => {
    this.favoritesRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.favorite(body);

    if (error) {
      this.favoritesRequestApiState.setError(true);
    }

    this.favoritesRequestApiState.setLoading(false);
    return [error, result];
  };

  create = async (
    body: OffersCreateBody,
  ): Promise<AttemptResult<OffersCreateResponse>> => {
    this.editRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.create(body);

    if (error) {
      this.editRequestApiState.setError(true);
    }

    this.editRequestApiState.setLoading(false);
    return [error, result];
  };

  editNote = async (
    body: OffersEditNoteBody,
  ): Promise<AttemptResult<OffersEditNoteResponse>> => {
    this.editNoteRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.editNote(body);

    if (error) {
      this.editNoteRequestApiState.setError(true);
    }

    this.editNoteRequestApiState.setLoading(false);
    return [error, result];
  };

  editView = async (
    body: OffersEditViewBody,
  ): Promise<AttemptResult<OffersEditViewResponse>> => {
    this.editNoteRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.editView(body);

    if (error) {
      this.editNoteRequestApiState.setError(true);
    }

    this.editNoteRequestApiState.setLoading(false);
    return [error, result];
  };
}

export const offersEditStore = new OffersEditStore(offersService, infoStore);

import React, { ComponentProps, useState } from 'react';
import { observer } from 'mobx-react';
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { Checkbox } from 'components/atoms/Checkbox';
import { OffersCard } from '../OffersCard';

type Props = ComponentProps<typeof OffersCard>;

export const SelectableOffersCardRender = ({ offer }: Props) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Checkbox
      disabled={isLoading}
      checked={addToOffersStore.checkedOffers.includes(offer.id)}
      name="offer"
      onChange={async () => {
        setLoading(true);
        await addToOffersStore.toggleCheckedOffersWithSave(offer.id);
        setLoading(false);
      }}
      label={<OffersCard offer={offer} />}
    />
  );
};

export const SelectableOffersCard = observer(SelectableOffersCardRender);

import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { every, isNil } from 'lodash';
import { Link } from 'react-router-dom';
import ReactJoin from 'react-join';

import { Icon, ViewCount, DateFormat, NumberFormat } from 'components/atoms';
import arrowRightDark from 'assets/arrowRightDark.svg';
import { Offers } from 'services';
import { createCountFormatter } from 'utils/createCountFormatter';
import { OffersContact } from '../OffersContact';
import { OffersCardPopover } from '../OffersCardPopover';

import styles from './styles.module.css';

const apartmentCountFormat = createCountFormatter({
  few: 'квартир',
  one: 'квартира',
  two: 'квартиры',
});

/**
 * @todo добавить вывод данных из пропсов
 * @todo добавить форматирование сумм от/до компонентом NumberFormat
 * @todo добавить ссылку на страницу подборки
 * @todo добавить вариацию ссылки когда подборка пустая
 * @todo добавить функции на вызовы экшенов меню
 * */

type Props = Offers;

export const OffersCard = ({
  title,
  description,
  emails,
  phones,
  customer,
  views_count,
  updated_at,
  stats,
  id,
  status_id,
  is_favorite,
}: Props) => (
  <Link
    to={`/profile/offers/${id?.toString()}`}
    className={styles.offerCardLink}
  >
    <div className={styles.offersCardWrapper}>
      <div className={styles.offersCardInfo}>
        <header className={styles.offersCardInfoHeader}>
          <div className={styles.offersCardInfoTitleWrapper}>
            <OffersCardPopover
              title={title}
              status_id={status_id}
              is_favorite={is_favorite}
              id={id}
            />
          </div>

          <div className={styles.offersCardInfoHeaderStats}>
            <ViewCount reverse count={views_count} />
            {updated_at && (
              <DateFormat
                format="dateMonthYearShortWithTime"
                value={updated_at}
              />
            )}
          </div>
        </header>
        <p className={styles.offersCardInfoDescription}>
          {description && <LinesEllipsis text={description} maxLine={2} />}
        </p>

        {!every([customer, emails, phones], isNil) && (
          <OffersContact name={customer} emails={emails} phones={phones} />
        )}
      </div>
      {!stats && (
        <div className={styles.offersCardStats}>
          <div className={styles.offersCardStatsInfo}>
            <div className={styles.offersCardStatsCount}>
              0 квартир в подборке
            </div>
          </div>
        </div>
      )}
      {stats && (
        <div className={styles.offersCardStats}>
          <div className={styles.offersCardStatsInfo}>
            <div className={styles.offersCardStatsCount}>
              {stats.apartment_count}{' '}
              {apartmentCountFormat(stats.apartment_count)},{' '}
              {stats.complex_count} ЖК
            </div>
            <div className={styles.offersCardStatsPrice}>
              <ReactJoin separator={<span>—</span>}>
                {[
                  <NumberFormat
                    format="rubWithoutSymbol"
                    value={stats.min_price}
                  />,
                  <NumberFormat
                    format="rubWithoutSymbol"
                    value={stats.max_price}
                  />,
                ]}
              </ReactJoin>{' '}
              ₽₽
            </div>
          </div>

          <Icon src={arrowRightDark} size={16} />
        </div>
      )}
    </div>
  </Link>
);

import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  hideForProduction?: boolean;
};

export const FeatureToggle = ({ children, hideForProduction }: Props) => {
  if (hideForProduction && process.env.NODE_ENV === 'production') {
    return null;
  }

  return <>{children}</>;
};

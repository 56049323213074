import React from 'react';
import { observer } from 'mobx-react';
import { useFormSubmit } from 'pages/AuthPage/pages/AuthPhoneCodePage/hooks/useFormSubmit';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { Field, Form, Formik } from 'formik';
import {
  AlertSubmission,
  BaseLink,
  FormikMaskedInput,
  Loader,
} from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { PrivacyButton } from 'pages/AuthPage/components/PrivacyButton';
import { authPhoneCodeSchema } from 'validationSchemas';
import { AuthPhoneCodeRequestBody } from 'services';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

import styles from '../AuthForm/styles.module.css';

type Props = {};

export const AuthByPhoneFormRender = (props: Props) => {
  const { onSubmit } = useFormSubmit();
  return (
    <Formik<AuthPhoneCodeRequestBody>
      validationSchema={authPhoneCodeSchema}
      onSubmit={onSubmit}
      initialValues={{}}
      render={(props) => (
        <Form>
          <div className={styles.textFieldWrapper}>
            <Field label="Телефон" name="phone" component={FormikMaskedInput} />
          </div>
          <div className={styles.buttonGroupWrapper}>
            <ButtonBase
              icon={props.isSubmitting && <Loader color="primary" />}
              type="submit"
              fullWidth
              color="primary"
            >
              Продолжить
            </ButtonBase>
            <BaseLink
              className={styles.buttonLink}
              to={authRoutesStore.getRouteWithParent('authEmailCodePage')}
              variant="text"
              color="primary"
            >
              Войти через почту
            </BaseLink>
          </div>
          <PrivacyButton />
          <AlertSubmission isSubmitting errors={props.errors} />
        </Form>
      )}
    />
  );
};

export const AuthByPhoneForm = withGoogleReCaptchaProvider(
  observer(AuthByPhoneFormRender),
);

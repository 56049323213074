import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { FormikTextField, BaseLink, Loader } from 'components/atoms';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { AuthFormWrapper } from '../../../../components/AuthFormWrapper';
import { PrivacyButton } from '../../../../components/PrivacyButton';
import styles from './styles.module.css';

type Props = FormikProps<any>;

const AuthEmailCodePageFormRenderTestIDs = {
  openAuthPhoneCodePage: 'AuthEmailCodePage_openAuthPhoneCodePage',
  title: 'AuthEmailCodePage_title',
  emailField: 'AuthEmailCodePage_emailField',
  submitButton: 'AuthEmailCodePage_submitButton',
};

export const FormRender = ({ handleSubmit, isSubmitting }: Props) => (
  <AuthFormWrapper>
    <form onSubmit={handleSubmit}>
      <div
        {...dataTestIdToProps(AuthEmailCodePageFormRenderTestIDs.title)}
        className={styles.title}
      >
        Вход через почту
      </div>

      <Field
        {...dataTestIdToProps(AuthEmailCodePageFormRenderTestIDs.emailField)}
        label="Электронная почта"
        name="email"
        placeholder="example@mail.com"
        component={FormikTextField}
      />

      <div className={styles.buttonGroup}>
        <ButtonBase
          {...dataTestIdToProps(
            AuthEmailCodePageFormRenderTestIDs.submitButton,
          )}
          icon={isSubmitting && <Loader color="primary" />}
          type="submit"
          fullWidth
          color="primary"
        >
          Продолжить
        </ButtonBase>
        <BaseLink
          {...dataTestIdToProps(
            AuthEmailCodePageFormRenderTestIDs.openAuthPhoneCodePage,
          )}
          fullWidth
          to={authRoutesStore.getRouteWithParent('authPhoneCodePage')}
          variant="text"
          color="primary"
        >
          Войти через телефон
        </BaseLink>
      </div>
      <PrivacyButton />
    </form>
  </AuthFormWrapper>
);

import React from 'react';
import { getPhotoAlbums } from 'services/v1/rc/album/helpers';
import { ComplexAlbum } from 'services';
import { ID, MayBe } from 'types';
import { LocationDescriptorObject } from 'history';
import { AlbumsWithBlock } from '../AlbumsWithBlock';

type Props = {
  albums: Array<ComplexAlbum>;
  complexId?: MayBe<ID>;
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  to?: LocationDescriptorObject<any>;
};

export const PhotoAlbumContainer = ({
  albums,
  complexId,
  className,
  innerRef,
  to,
}: Props) => {
  const photoAlbums = getPhotoAlbums(albums);
  return (
    <>
      {photoAlbums.length > 0 && complexId && (
        <AlbumsWithBlock
          to={to}
          innerRef={innerRef}
          className={className}
          title="Фотографии"
          albums={photoAlbums}
          complexId={complexId}
        />
      )}
    </>
  );
};

import React, { Fragment, useContext } from 'react';
import { ComplexCard } from 'pages/OffersViewPage/components/ComplexCard';
import { ID, MayBe } from 'types';
import { OffersViewItem } from 'services';
import { IsEditOfferContext } from 'pages/OffersViewPage/context/IsEditOfferContext';

import { useGetOfferViewFieldStatus } from 'pages/OffersViewPage/hooks/useGetOfferViewFieldStatus';
import { ApartmentCardWithLink } from '../../components/ApartmentCardWithLink';

import { ApartmentsCardActionsBar } from '../../components/ApartmentsCardActionsBar';
import styles from './styles.module.css';
import { getAddressFromOfferComplex } from '../../../../services/v1/rc/offers/handlers';

type Props = {
  offerId?: MayBe<ID>;
  offerHash?: string;
  offer: MayBe<OffersViewItem>;
  isIframeMode?: boolean;
} & Pick<OffersViewItem, 'complexes' | 'apartments'>;

export const ApartmentAndComplexList = ({
  complexes,
  apartments,
  offerHash,
  offerId,
  offer,
  isIframeMode,
}: Props) => {
  const { isEditOffer } = useContext(IsEditOfferContext);

  const { isShowComplex, isShowMap, isShowPrice } =
    useGetOfferViewFieldStatus();

  return (
    <div className={styles.apartmentAndComplexListWrapper}>
      {complexes?.map((complex) => (
        <Fragment key={complex.id}>
          <ComplexCard
            showCard={isShowComplex}
            showMap={isShowMap}
            complex={complex}
          />
          {apartments
            ?.filter(
              ({ residential_complex_id }) =>
                residential_complex_id === complex.id,
            )
            ?.map((apartment) => (
              <ApartmentsCardActionsBar
                key={apartment?.id?.toString()}
                offer={offer}
                apartment={apartment}
                className={styles.apartmentInfo}
                isDisableActions={isIframeMode}
              >
                <ApartmentCardWithLink
                  showPrice={isShowPrice}
                  apartment={apartment}
                  offerId={offerId}
                  offerHash={offerHash}
                  isEditOffer={isEditOffer}
                  complexAddress={
                    isShowMap ? getAddressFromOfferComplex(complex) : null
                  }
                />
              </ApartmentsCardActionsBar>
            ))}
        </Fragment>
      ))}
    </div>
  );
};

import { makeObservable, observable, action, computed } from 'mobx';
import { OffersListParams } from '../../services';
import { ID } from '../../types';

export class OffersFilterStore {
  params: OffersListParams = {
    search: '',
    statusIds: [],
  };

  constructor() {
    makeObservable(this, {
      params: observable,
      clear: action,
      setSearch: action.bound,
      setStatusIds: action.bound,

      searchParams: computed,
      searchStatusIds: computed,
    });
  }

  clear(): void {
    this.params = {};
  }

  get searchParams() {
    return this.params.search;
  }

  get searchStatusIds() {
    return this.params.statusIds;
  }

  setSearch(search?: string) {
    this.params.search = search;
  }

  setStatusIds(statusIds: ID[]) {
    this.params.statusIds = statusIds;
  }
}

export const offersFilterStore = new OffersFilterStore();

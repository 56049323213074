import React from 'react';
import { observer } from 'mobx-react';

import { feedbackStore } from '../../../stores/FeedbackStore/FeedbackStore';
import { FormRender } from './components/FeedbackForm';
import styles from './style.module.css';
import { BaseFeedbackForm } from '../BaseFeedbackForm';

type Props = {
  goBack?: () => void;
};

export const FeedbackFormRender = ({ goBack }: Props) => (
  <div className={styles.wrapper}>
    <BaseFeedbackForm
      successComponentProps={{
        goBack,
      }}
      feedbackStore={feedbackStore}
      component={FormRender}
    />
  </div>
);

export const FeedbackForm = observer(FeedbackFormRender);

import { makeObservable, action } from 'mobx';

/**
 * @desc стор хранит ссылки на функции которые необходимо вызвать
 * например для обновления других сторов когда функции на обновление хранятся в разных
 * компонентах
 * */
export class RefreshStore {
  handlers: Function[] = [];

  constructor() {
    makeObservable(this, {
      add: action.bound,
      clear: action.bound,
      call: action.bound,
    });
  }

  add = (handler: Function): void => {
    if (this.has(handler)) {
      return;
    }

    this.handlers.push(handler);
  };

  has = (handler: Function): boolean => this.handlers.includes(handler);

  remove = (handler: Function) => {
    this.handlers = this.handlers.filter((item) => item !== handler);
  };

  clear = () => {
    this.handlers = [];
  };

  call = () => {
    this.handlers.forEach((handler: Function) => {
      handler();
    });
  };
}

export const refreshStore = new RefreshStore();

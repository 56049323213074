import React from 'react';
import { observer } from 'mobx-react';
import { offersStore } from 'stores/OffersStore/OffersStore';
import { TabsProvider } from 'components/containers/Tabs';
import { Route, useHistory, useRouteMatch } from 'react-router';
import { Text } from 'components/atoms//Text';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { appModalRoutesService } from 'services';
import { Box } from 'components/styledComponents/Box';
import useWindowSize from 'hooks/useWindowSize';
import { OffersHeader } from './components/OffersHeader';
import { OffersNotFound } from './components/OffersNotFound';
import { OffersList } from './components/OffersList';
import { useOffersTabs } from './hooks/useOffersTabs';
import { useOffersLoad } from './hooks/useOffersLoad';
import { appRoutesService } from '../../../../services';
import { OffersViewPagePopup } from '../../../OffersViewPage';
import { useOnScrollIncrement } from './hooks/useOnScrollIncrement';

import styles from './styles.module.css';

export const OffersPageRender = () => {
  const { width = 0 } = useWindowSize();
  const match = useRouteMatch<{ menuItem?: string }>('/profile/:menuItem');
  const menuItem = match?.params?.menuItem;

  const { currentActiveTab, changeTab, tabList, setTabList } = useOffersTabs();
  const { state, offers } = offersStore;

  const { onScrollIncrement } = useOnScrollIncrement();
  const { push } = useHistory();

  const isMobile = width <= 768;

  useOffersLoad({
    currentActiveTab,
    tabList,
  });

  return (
    <Box mt={menuItem === 'offers' && isMobile ? -45 : 0}>
      <div className={styles.offersHeaderTop}>
        <Text tagName="h2" className={styles.profileMenuHeader}>
          Подборки
        </Text>

        <ButtonBase
          color="primary"
          onClick={() =>
            push(appModalRoutesService.replaceModalType('offersCreate'))
          }
        >
          Создать подборку
        </ButtonBase>
      </div>

      <TabsProvider
        value={{ currentActiveTab, changeTab, tabList, setTabList }}
      >
        <OffersHeader />
        {state.total === 0 && <OffersNotFound />}
        {state.total !== 0 && (
          <OffersList onScrollIncrement={onScrollIncrement} offers={offers} />
        )}
      </TabsProvider>
      <Route
        path={appRoutesService.getRoute('profileOfferViewRealtor')}
        component={OffersViewPagePopup}
      />
    </Box>
  );
};

export const OffersPage = observer(OffersPageRender);

import { OfferTabsType } from '../types';
import { appRoutesService } from '../../../services';

export const getActiveTabByRouteName = (layoutTabs?: OfferTabsType): number => {
  if (layoutTabs === 'map') {
    return 1;
  }
  return 0;
};

const presentationLink = process.env.REACT_APP_PRESENTATION_URL || window.location.origin;

export const getLinkToOfferForClient = (hash: string) =>  [
    presentationLink,
    '/?',
    appRoutesService.replaceRoute(
      'offersViewClient',
      {
        offerTabs: 'list',
        hash,
      },
      {
        isEdit: false,
      },
    ),
  ].join('');


import { pull } from 'lodash';
import { flow } from 'lodash/fp';
import { action, computed, makeObservable, observable } from 'mobx';
import { FAVORITES_IDS_STORAGE, favoritesIdsStorage } from 'services';
import { favoritesService } from 'services/v1/rc/favorites';
import { ID } from '../../../types';
import { SyncTrunk } from '../../SyncTrunk/SyncTrunk';
import { favoritesIdsStorageAdapter } from './lib/adapters/favoritesIdsStorageAdapter';

const toggleIdInList = (list: ID[]) => (id: ID) =>
  list.includes(id) ? pull(list, id) : [...list, id];

export type FavoritesIdsStoreState = {
  complexIds: ID[];
  apartmentIds: ID[];
};

/**
 * @desc хранит только ID добавленных в избранное квартир и ЖК
 * */
export class FavoritesIdsStore {
  state: FavoritesIdsStoreState = {
    complexIds: [],
    apartmentIds: [],
  };

  constructor() {
    makeObservable(this, {
      state: observable,

      clear: action,

      toggleComplex: action,
      toggleApartment: action,

      setComplexIds: action,
      setApartmentIds: action,

      complexIds: computed,
      apartmentIds: computed,
    });
  }

  get complexIds() {
    return this.state.complexIds;
  }

  get apartmentIds() {
    return this.state.apartmentIds;
  }

  clearState = () => {
    this.state = {
      complexIds: [],
      apartmentIds: [],
    };
  };

  clearStorage = () => favoritesIdsStorage.remove();

  /**
   * @desc полный сброс стора и хранилища
   * */
  clear = flow(this.clearState, this.clearStorage);

  includeComplex = (id: ID) => this.state.complexIds.includes(id);

  includeApartment = (id: ID) => this.state.apartmentIds.includes(id);

  /**
   * @desc добавляет/удаляет ID в списке ЖК
   * */
  toggleComplex = (id: ID) => {
    this.state.complexIds = toggleIdInList(this.state.complexIds)(id);
  };

  /**
   * @desc добавляет/удаляет ID в списке квартир
   * */
  toggleApartment = (id: ID) => {
    this.state.apartmentIds = toggleIdInList(this.state.apartmentIds)(id);
  };

  /**
   * @desc перезаписывает список id ЖК
   * */
  setComplexIds = (complexIds?: ID[]) => {
    this.state.complexIds = complexIds || [];
  };

  /**
   * @desc перезаписывает список id квартир
   * */
  setApartmentIds = (apartmentIds?: ID[]) => {
    this.state.apartmentIds = apartmentIds || [];
  };

  loadFavoritesIds = async () => {
    const [error, result] = await favoritesService.listIds();
    if (result) {
      this.state = result;
    }
    if (error) {
      throw new Error(error.toString());
    }
    return [error, result];
  };

  syncTrunk = () => {
    void new SyncTrunk({
      store: this,
      storage: favoritesIdsStorageAdapter,
      storageKey: FAVORITES_IDS_STORAGE,
      autoInit: true,
    });
  };
}

import { useMemo } from 'react';
import { toJS } from 'mobx';
import { OfferItemStore, OffersEditStore } from 'stores';
import { offerToFormValues } from '../../utils';

type Props = {
  offersEditStore: OffersEditStore;
  offerItemStore: OfferItemStore;
};

export const useCreateInitialValues = ({
  offerItemStore,
  offersEditStore,
}: Props) => {
  const initialValues = useMemo(
    () =>
      offerItemStore.fetchOfferRequestApiState.isLoading ||
      !offersEditStore.isEdit
        ? offerToFormValues(null)
        : offerToFormValues(toJS(offerItemStore.offer)),
    [offerItemStore.offer, offerItemStore.fetchOfferRequestApiState.isLoading],
  );

  return {
    initialValues,
  };
};

import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BuildingEntity, Params } from 'services';
import { TabItemProps } from 'components/containers/Tabs';
import { LayoutTabs } from '../types';
import { getActiveTabByRouteName, getUrlForCurrentActiveTab } from '../utils';

export type UseChangeFilterByBuildingReturnType = {
  changeFilterByBuildings: (building: TabItemProps<BuildingEntity>) => void;
};

/**
 * @desc обновляет адресную строку в зависимости от выбранного корпуса,
 * при этом остальные параметры строки сохраняются
 * */
export const useChangeFilterByBuilding =
  (): UseChangeFilterByBuildingReturnType => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { push } = useHistory();
    const { complexId, layoutTabs, apartmentId, apartmentNumber } =
      useParams<
        Pick<
          Params,
          'complexId' | 'layoutTabs' | 'apartmentNumber' | 'apartmentId'
        >
      >();
    const changeFilterByBuildings = useCallback(
      (building: TabItemProps<BuildingEntity>): void => {
        if (!building) {
          return;
        }

        push(
          getUrlForCurrentActiveTab(
            getActiveTabByRouteName(layoutTabs as LayoutTabs),
            {
              complexId,
              apartmentId,
              apartmentNumber,
              buildingId: building?.id.toString(),
            },
          ),
        );
      },
      [complexId, apartmentId, layoutTabs],
    );

    return {
      changeFilterByBuildings,
    };
  };

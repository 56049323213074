import React from 'react';
import { Skeleton } from 'components/atoms';
import styles from './styles.module.css';

export const MobileSkeleton = () => (
  <div className={styles.wrapper}>
    <div className={styles.left}>
      <Skeleton
        className={styles.marginBottom4}
        width={38}
        height={16}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        className={styles.marginBottom8}
        width={114}
        height={16}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        width={98}
        height={16}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
    </div>
    <div className={styles.right}>
      <Skeleton
        className={styles.marginBottom4}
        width={69}
        height={12}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        className={styles.marginBottom8}
        width={78}
        height={16}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        width={71}
        height={16}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
    </div>
  </div>
);

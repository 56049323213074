import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { ApartmentDto, Cell } from '../../../../../../../services';
import { buildingGridStore } from '../../../../../stores/buildingGridStore';

type UseGridCardProps = {
  matrixItem: Cell;
  cardRef: MutableRefObject<HTMLDivElement | undefined>;
  inView: boolean;
};

export const useLoadApartmentWhenIsInViewport = ({
  matrixItem,
  cardRef,
  inView,
}: UseGridCardProps) => {
  const [isFoundApartment, setIsFoundApartment] = useState<boolean | undefined>(
    undefined,
  );
  const [apartment, setApartment] = useState<ApartmentDto | undefined>(
    undefined,
  );

  const findApartment = useCallback(() => {
    if (!apartment && isFoundApartment === undefined && inView) {
      const apartment = buildingGridStore?.getApartmentByNumber(
        matrixItem?.title,
      );
      if (apartment) {
        setApartment(apartment);
        setIsFoundApartment(true);
      } else {
        setIsFoundApartment(false);
      }
    }
  }, [matrixItem, cardRef.current, apartment, isFoundApartment, inView]);

  useEffect(findApartment, [
    matrixItem,
    cardRef.current,
    apartment,
    isFoundApartment,
    inView,
  ]);

  return { apartment, isFoundApartment };
};

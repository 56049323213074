import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { changeEmailStore } from 'pages/ProfilePage/stores/ChangeEmailStore';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { AuthPopup } from '../../../../../AuthPage/components/AuthPopup';
import { appRoutesService } from '../../../../../../services';
import styles from '../../../../../AuthPage/pages/AuthEmailSendLinkPage/styles.module.css';

export const NewEmailConfirmPopupRender = () => {
  const { push } = useHistory();

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>Подтвердите почту</div>
        <div className={styles.subTitle}>
          Мы выслали письмо с кнопкой подтверждения на почту
          <br />
          <strong>{changeEmailStore?.state?.newEmail}</strong>
        </div>
        <ButtonBase
          indent={{
            margin: [20],
          }}
          onClick={() => {
            push(appRoutesService.getRoute('profile'));
          }}
        >
          Хорошо
        </ButtonBase>
      </div>
    </AuthPopup>
  );
};

export const NewEmailConfirmPopup = observer(NewEmailConfirmPopupRender);

import { ApartmentSquareInfo } from 'pages/LayoutPage/pages/ApartmentView/components/ApartmentSquareInfo';
import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import ReactJoin from 'react-join';
import { ApartmentInOffer, Logo } from 'services';
import {
  DictionaryView,
  NumberFormat,
  PlaceholderImage,
} from 'components/atoms';
import { MayBe } from 'types';
import { ApartmentPreviewSlideshow } from './components/ApartmentPreviewSlideshow';
import { ApartmentPrice } from './components/ApartmentPrice';
import { ApartmentStatus } from './components/ApartmentStatus';
import { ApartmentUpdateDate } from './components/ApartmentUpdateDate';
import styles from './styles.module.css';

type Props = {
  complexAddress?: MayBe<string>;
  apartment: ApartmentInOffer;
  showPrice: boolean;
};

export const ApartmentCard = ({
  apartment,
  complexAddress,
  showPrice,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const { catalogs, offer } = apartment;

  const previews: Logo[] =
    apartment.layouts?.map(({ photo }, index) => ({
      url: photo,
      id: index,
    })) || [];
  
  return (
    <div className={styles.apartmentCardWrapper} key={apartment.id}>
      <div className={styles.apartmentCardLeft}>
        {previews.length > 0 && <ApartmentPreviewSlideshow list={previews} />}
        {!previews.length && (
          <PlaceholderImage
            className={styles.apartmentFloorPlanPreview}
            type="FLOOR_PLAN"
          />
        )}
      </div>
      <div className={styles.apartmentCardRight}>
        <div className={styles.apartmentCardTop}>
          <div>
            <div className={styles.apartmentCardTopStatusWrapper}>
              <ApartmentStatus apartment_status={catalogs?.apartment_status} />
            </div>

            <div className={styles.apartmentCardTitle}>
              <ReactJoin separator={' '}>
                {[
                  catalogs?.rooms && (
                    <DictionaryView
                      dictKey="rooms"
                      dictItemId={catalogs?.rooms}
                    />
                  ),
                  catalogs?.apartment_type_room && (
                    <DictionaryView
                      dictKey="apartment_type_room"
                      dictItemId={catalogs?.apartment_type_room}
                    />
                  ),
                  <NumberFormat value={apartment?.square} format="meter2" />,
                ]}
              </ReactJoin>
            </div>
            <div className={styles.apartmentCardAddress}>{complexAddress}</div>
            {!isLarge && (
              <ApartmentUpdateDate
                created_at={apartment.offer.created_at}
                updated_at={apartment.updated_at}
              />
            )}
          </div>
          {!isLarge && showPrice && (
            <ApartmentPrice
              old_price={offer.price}
              price={apartment.price}
              price_m2={apartment.price_m2}
            />
          )}
          {isLarge && (
            <ApartmentUpdateDate
              created_at={apartment.offer.created_at}
              updated_at={apartment.updated_at}
            />
          )}
        </div>
        <div className={styles.apartmentCardBottom}>
          <ApartmentSquareInfo apartment={apartment} />
          {isLarge && showPrice && (
            <ApartmentPrice
              old_price={apartment.price}
              price={apartment.price}
              price_m2={apartment.price_m2}
            />
          )}
        </div>
      </div>
    </div>
  );
};

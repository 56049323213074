import React, { ComponentProps } from 'react';
import { useModal } from 'react-modal-hook';
import { LatLngTuple } from 'leaflet';
import { every } from 'lodash/fp';
import { BasePopup, TruncateHtml } from 'components/atoms';
import { SalesDepartments } from 'services';
import { isNotNaN } from 'utils/fp';
import { DepartmentContacts } from './components/DepartmentContacts';
import { WorkTimesView } from './components/WorkTimesView';
import styles from './styles.module.css';
import { ManagerCard } from './components/ManagerCard';
import { SalesDepartmentCompanyHeader } from '../SalesDepartmentsCard/component/SalesDepartmentCompanyHeader';
import { SalesDepartmentOnMap } from './components/SalesDepartmentOnMap';
import { useGetResponsiveModalComponent } from '../../../hooks';

type Props = {
  department?: SalesDepartments;
} & ComponentProps<typeof SalesDepartmentCompanyHeader>;

export const SalesDepartmentModal = ({ department, type }: Props) => {
  const centerLatLng: LatLngTuple = [
    parseFloat(department?.latitude || ''),
    parseFloat(department?.longitude || ''),
  ];

  return (
    <div className={styles.wrapper}>
      {every(isNotNaN)(centerLatLng) && (
        <SalesDepartmentOnMap
          address={department?.address_original}
          centerLatLng={centerLatLng}
        />
      )}

      <div className={styles.contentWrapper}>
        {department?.company && (
          <div className={styles.companyAvatarWrapper}>
            <SalesDepartmentCompanyHeader
              type={type}
              company={department.company}
            />
          </div>
        )}

        <div className={styles.title}>
          <span
            dangerouslySetInnerHTML={{
              __html: department?.title || '',
            }}
          />
        </div>

        {department?.description && (
          <div className={styles.description}>
            <TruncateHtml text={department?.description} maxLine={6} />
          </div>
        )}
        {department && (
          <div className={styles.contactsWrapper}>
            <DepartmentContacts department={department} />
            <WorkTimesView work_times={department.work_times} />
          </div>
        )}

        <div className={styles.managerList}>
          {department?.contacts?.map((contact) => (
            <ManagerCard key={contact.id} contact={contact} />
          ))}
        </div>
      </div>
    </div>
  );
};

type PropsUseModal = {
  onClose?: () => void;
} & Props;

export const useSalesDepartmentModal = ({
  department,
  type,
  onClose,
}: PropsUseModal) => {
  const { PopupWrapper } = useGetResponsiveModalComponent<
    ComponentProps<typeof BasePopup>
  >({
    mobileHeaderChildrenTitle: 'Контакты',
    DesktopModalWrapper: (props) => <BasePopup {...props} isOpen size="MD" />,
  });

  const [showSalesDepartmentModal, hideSalesDepartmentModal] = useModal(
    () => (
      <PopupWrapper
        isOpen
        onClose={() => {
          hideSalesDepartmentModal();
          if (onClose) {
            onClose();
          }
        }}
      >
        <SalesDepartmentModal type={type} department={department} />
      </PopupWrapper>
    ),
    [department, type, PopupWrapper, onClose],
  );

  return {
    showSalesDepartmentModal,
    hideSalesDepartmentModal,
  };
};

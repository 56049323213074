import React, { FC, useState } from 'react';

type Props = {
  TabItem: (
    active: boolean,
    text: string,
    onClick: () => void,
  ) => React.ReactNode;
  TabContent: (activeKey: string) => React.ReactNode;
  rootWrapperClassName?: string;
  itemsWrapperClassName?: string;
  contentWrapperClassName?: string;
  items: Record<string, string>;
};

/**
 * @deprecated
 * @desc вместо из использовать /components/containers/Tabs
 * */
export const Tabs: FC<Props> = (props) => {
  const {
    rootWrapperClassName = '',
    itemsWrapperClassName = '',
    items,
    TabItem,
  } = props;

  const [currentValue, setCurrentValue] = useState(Object.keys(items)[0]);

  return (
    <div className={rootWrapperClassName}>
      <ul className={itemsWrapperClassName}>
        {Object.entries(items).map(([key, value]) =>
          TabItem(currentValue === key, value, () => setCurrentValue(key)),
        )}
      </ul>
    </div>
  );
};

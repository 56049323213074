import React, { ReactNode } from 'react';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
};

export const FeedListWrapper = ({ children }: Props) => (
  <div className={styles.feedListWrapper}>{children}</div>
);

import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import { MobilePopup, Text, MobileAutoSuggest } from 'components/atoms';
import { useSuggestByAddressComplexesAndDevelopers } from 'components/containers/Search/hooks/useSuggestByAddressComplexesAndDevelopers';
import { SuggestComplex, SuggestDeveloper, SuggestYandex } from 'services';
import { flow } from 'lodash/fp';
import { observer } from 'mobx-react';
import styles from './styles.module.css';

type Props = {
  onClose: () => void;
};

export const NavbarPopupSearchRender = ({ onClose }: Props) => {
  const {
    searchValue,
    onChange,
    onSuggestionsClearRequested,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionSelected,
  } = useSuggestByAddressComplexesAndDevelopers();

  return (
    <div className={styles.wrapper}>
      <MobileAutoSuggest<SuggestComplex | SuggestDeveloper | SuggestYandex>
        value={searchValue}
        onChange={onChange}
        placeholder="Адрес, название ЖК"
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionSelected={flow(onSuggestionSelected, onClose)}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
      />
    </div>
  );
};

export const NavbarPopupSearch = observer(NavbarPopupSearchRender);

export const useNavbarPopupSearch = () => {
  const [isOpenNavbarPopupSearch, setOpen] = useState(false);
  const [showNavbarPopupSearch, hideNavbarPopupSearch] = useModal(() => (
    <MobilePopup
      headerChildren={
        <Text align="center" size="L" weight="700">
          Поиск
        </Text>
      }
      isOpen
      onClose={hideNavbarPopupSearch}
    >
      <NavbarPopupSearch onClose={hideNavbarPopupSearch} />
    </MobilePopup>
  ));

  const toggleNavbarPopupSearch = () => {
    if (isOpenNavbarPopupSearch) {
      setOpen(false);
      hideNavbarPopupSearch();
    } else {
      setOpen(true);
      showNavbarPopupSearch();
    }
  };

  return {
    toggleNavbarPopupSearch,
    showNavbarPopupSearch: () => {
      setOpen(true);
      showNavbarPopupSearch();
    },
    hideNavbarPopupSearch: () => {
      setOpen(false);
      hideNavbarPopupSearch();
    },
  };
};

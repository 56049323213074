import { action, computed, makeObservable, observable } from 'mobx';
import { MayBe } from 'types';

type publishPeriodType = 'month' | 'week' | 'day';
type SortBy = 'updated' | 'alphabetic';
type SortDirection = 'asc' | 'desc';

export type VillageFilterType = {
  address?: MayBe<string>;

  squareMin?: MayBe<number>;
  squareMax?: MayBe<number>;
  squareKitchenMin?: MayBe<number>;
  squareKitchenMax?: MayBe<number>;
  squareLivingMin?: MayBe<number>;
  squareLivingMax?: MayBe<number>;
  priceMin?: MayBe<number>;
  priceMax?: MayBe<number>;
  floorMin?: MayBe<number>;
  floorMax?: MayBe<number>;
  ceilingHeightMin?: MayBe<number>;

  complexIds?: MayBe<string[]>;
  developerIds?: MayBe<string[]>;
  rooms?: MayBe<number[]>;
  roomType?: MayBe<string[]>;
  windowType?: MayBe<string[]>;
  finishing?: MayBe<string[]>;
  material?: MayBe<string[]>;
  salesStatus?: MayBe<string[]>;
  contractType?: MayBe<string[]>;
  paymentType?: MayBe<string[]>;
  complexClass?: MayBe<string[]>;
  houseType?: MayBe<string[]>;
  advantages?: MayBe<string[]>;

  notFirstFloor?: boolean;
  notLastFloor?: boolean;
  isLastFloor?: boolean;
  isPenthouse?: boolean;

  builtDateMin?: MayBe<number>;
  builtDateMax?: MayBe<number>;

  publishPeriod?: MayBe<publishPeriodType>;
};

const initialFilters = {
  address: null,

  squareMin: null,
  squareMax: null,
  squareKitchenMin: null,
  squareKitchenMax: null,
  squareLivingMin: null,
  squareLivingMax: null,
  priceM2Min: null,
  priceM2Max: null,
  priceMin: null,
  priceMax: null,
  floorMin: null,
  floorMax: null,
  ceilingHeightMin: null,

  complexIds: null,
  developerIds: null,
  rooms: null,
  roomType: null,
  windowType: null,
  finishing: null,
  material: null,
  salesStatus: null,
  contractType: null,
  paymentType: null,
  complexClass: null,
  houseType: null,
  advantages: null,

  notFirstFloor: false,
  notLastFloor: false,
  isLastFloor: false,
  isPenthouse: false,

  builtDateMin: null,
  builtDateMax: null,

  publishPeriod: null,
};

export class VillageFiltersStore {
  constructor() {
    makeObservable(this, {
      filters: observable,
      sort: observable,
      setFilter: action.bound,
      setSort: action.bound,
      setSortDirection: action.bound,
      cleanFilters: action.bound,
      cleanSingleFilter: action.bound,
      compileFilters: computed,
    });
  }

  filters: VillageFilterType = initialFilters;

  sort: MayBe<SortBy> = null;

  sortDirection: SortDirection = 'asc';

  isList = true;

  isPin = true;

  setFilter<K extends keyof VillageFilterType>(
    key: K,
    value: VillageFilterType[K],
  ) {
    this.filters[key] = value;
  }

  cleanFilters() {
    this.filters = initialFilters;
  }

  cleanSingleFilter(key: keyof VillageFilterType) {
    this.filters[key] = undefined;
  }

  setSort(sort: SortBy) {
    this.sort = sort;
  }

  setSortDirection(direction: SortDirection) {
    this.sortDirection = direction;
  }

  get compileFilters(): Partial<VillageFilterType> {
    return Object.keys(this.filters).reduce((acc, key) => {
      let newValue = this.filters[key as keyof VillageFilterType];

      if (newValue) {
        if (typeof newValue === 'boolean') {
          newValue = Number(newValue);
        }

        acc = { ...acc, [key]: newValue };
      }

      return acc;
    }, {});
  }
}

export const villageFilters = new VillageFiltersStore();

import React, { useMemo } from 'react';
import { Field, FieldArray, Form } from 'formik';
import { observer } from 'mobx-react';
import { FormikProps } from 'formik/dist/types';

import lockIcon from 'assets/lock.svg';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';

import { offersEditStore } from 'stores';
import {
  AlertSubmission,
  FormikMaskedInput,
  FormikFieldArray,
  Loader,
  Icon,
  FormikTextField,
  FormikTextareaField,
} from 'components/atoms';
import { isNotNil } from 'utils/fp';
import { OffersEditorFormValues } from '../../types';
import styles from './styles.module.css';

type Props = FormikProps<OffersEditorFormValues> & {
  onDeleteOffer?: () => Promise<any>;
};

export const FormRenderDataTestIds = {
  submit: 'OffersEditorForm_submit',
  delete: 'OffersEditorForm_submit',
};

export const FormRender = ({
  isSubmitting,
  values,
  errors,
  onDeleteOffer,
}: Props) => {
  const { isEdit } = offersEditStore;

  const offerCanBeDelete = useMemo(
    () => isNotNil(values.offerId),
    [values.offerId],
  );

  const title = useMemo(
    () => (isEdit ? 'Редактирование подборки' : 'Создание подборки'),
    [isEdit],
  );

  const saveButtonTitle = useMemo(
    () => (isEdit ? 'Сохранить подборку' : 'Создать подборку'),
    [isEdit],
  );

  return (
    <>
      <Form className={styles.formRenderWrapper}>
        <header className={styles.formRenderHeader}>
          <h2 className={styles.formRenderTitle}>{title}</h2>
        </header>
        <div className={styles.formRenderBody}>
          <div className={styles.formRenderInputGroup}>
            <Field
              label="Название подборки"
              placeholder="Видно только вам"
              name="title"
              component={FormikTextField}
            />
            <Field
              label="Имя клиента"
              placeholder="Видно клиенту"
              name="customer"
              component={FormikTextField}
            />
            <FieldArray name="phones">
              {(props) => (
                <FormikFieldArray
                  label="Номер телефона"
                  component={FormikMaskedInput}
                  componentProps={{
                    pattern: 'mobile',
                    placeholder: 'Для отправки подборки',
                  }}
                  {...props}
                />
              )}
            </FieldArray>

            <FieldArray name="emails">
              {(props) => (
                <FormikFieldArray
                  label="Почта клиента"
                  component={FormikTextField}
                  {...props}
                />
              )}
            </FieldArray>

            <div className={styles.formRenderDisclaimerWrapper}>
              <Icon src={lockIcon} size={32} />
              <div className={styles.formRenderDisclaimerText}>
                Мы надежно храним данные ваших клиентов и не передаем их третьим
                лицам
              </div>
            </div>
          </div>
          <Field
            label="Описание подборки"
            placeholder="Видно только вам"
            name="description"
            component={FormikTextareaField}
          />
        </div>
        <footer className={styles.formRenderFooter}>
          <ButtonBase
            disabled={isSubmitting}
            data-test-id={FormRenderDataTestIds.submit}
            icon={isSubmitting && <Loader color="primary" />}
            color="primary"
            type="submit"
          >
            {saveButtonTitle}
          </ButtonBase>
          <ButtonBase
            onClick={onDeleteOffer}
            data-test-id={FormRenderDataTestIds.delete}
            disabled={!offerCanBeDelete || isSubmitting}
          >
            Удалить
          </ButtonBase>
        </footer>
      </Form>
      <AlertSubmission isSubmitting errors={errors} />
    </>
  );
};

export const FormRenderWithObserver = observer(FormRender);

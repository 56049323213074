import { useCallback } from 'react';
import { FormikHelpers } from 'formik/dist/types';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { offersEditStore } from 'stores';
import { OffersEditorFormValues } from '../../types';
import { formValuesToOffer } from '../../utils';

type Props = {
  onClose: () => void;
};

export const useSubmitOffer = ({ onClose }: Props) => {
  const { isEdit, create, edit } = offersEditStore;

  const makeEditRequest = useCallback(
    (request: any) =>
      async (
        values: OffersEditorFormValues,
        formikHelpers: FormikHelpers<any>,
      ) => {
        const [error, result] = await request(formValuesToOffer(values));

        if (error) {
          submissionErrorsHandler(error, formikHelpers);
          return;
        }

        if (result && result.status) {
          onClose();
        }
      },
    [],
  );

  const onSubmit = async (
    values: OffersEditorFormValues,
    formikHelpers: FormikHelpers<any>,
  ): Promise<void> => {
    if (isEdit) {
      return makeEditRequest(edit)(values, formikHelpers);
    }
    return makeEditRequest(create)(values, formikHelpers);
  };

  return {
    onSubmit,
  };
};

import React, { ComponentProps, ReactNode } from 'react';
import cc from 'classcat';
import { ResidentialComplexLogo } from '../ResidentialComplexLogo';
import styles from './styles.module.css';
import { getIndentClasses } from '../Indents';

type Props = {
  children?: ReactNode;
  className?: string;
} & ComponentProps<typeof ResidentialComplexLogo>;

export const LogoWithContent = ({
  className,
  children,
  indent,
  ...rest
}: Props) => (
  <div className={cc([styles.wrapper, getIndentClasses(indent), className])}>
    <ResidentialComplexLogo
      size="large"
      className={styles.logo}
      indent={{
        margin: [0, 10],
      }}
      {...rest}
    />
    <div className={styles.content}>{children}</div>
  </div>
);

import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Params } from 'services';
import {
  TabItemProps,
  TabProps,
  useTabs,
} from 'components/containers/Tabs/hooks/useTabs';
import { getActiveTabByRouteName, getUrlForCurrentActiveTab } from '../utils';
import { LayoutTabs } from '../types';

const TAB_LIST: TabItemProps<undefined>[] = [
  {
    label: 'Шахматка',
  },
  {
    label: 'План этажа',
  },
  {
    label: 'Список',
  },
];

export const useLayoutTabsState = (): Omit<TabProps<string>, 'setTabList'> => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory();

  const { complexId, layoutTabs, apartmentId, buildingId, apartmentNumber } =
    useParams<Params>();
  const { tabList, changeTab, currentActiveTab } = useTabs<string>({
    tabList: TAB_LIST,
    currentActiveTab: getActiveTabByRouteName(layoutTabs as LayoutTabs),
  });

  /**
   * Обновление маршрута при изменении акивной табы
   * */
  useEffect(() => {
    if (
      currentActiveTab === getActiveTabByRouteName(layoutTabs as LayoutTabs)
    ) {
      return;
    }
    const url = getUrlForCurrentActiveTab(currentActiveTab, {
      complexId,
      buildingId,
      apartmentId,
      apartmentNumber,
    });
    push(url);
  }, [currentActiveTab]);

  return { tabList, changeTab, currentActiveTab };
};

import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { flatten } from 'lodash/fp';
import { PolygonCoordinates } from 'types/PolygonCoordinates';

type Props = {
  polygons: PolygonCoordinates[][] | undefined;
  delay?: number;
};

export const FitBoundsOfPolygons = ({ polygons, delay }: Props) => {
  const map = useMap();
  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (polygons?.length) {
      // map
      timerId = setTimeout(
        () => map.fitBounds(flatten(polygons)),
        delay || 250,
      );
    }
    return () => timerId && clearTimeout(timerId);
  }, [polygons]);
  return null;
};

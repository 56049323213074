import { AxiosRequestConfig, AxiosPromise } from 'axios';
import { axiosInstance, axiosInstanceWithAuth } from './index';

export const makeRequest = <RT extends {}>(
  config: AxiosRequestConfig,
): AxiosPromise<RT> =>
  axiosInstance({
    ...config,
  }) as unknown as AxiosPromise<RT>;

export const makeRequestWithAuth = <RT extends {}>(
  config: AxiosRequestConfig,
): AxiosPromise<RT> =>
  axiosInstanceWithAuth({
    ...config,
  }) as unknown as AxiosPromise<RT>;

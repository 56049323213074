import React from 'react';
import { DateFormat } from 'components/atoms';
import { MayBe } from 'types';
import styles from './styles.module.css';

type Props = {
  created_at?: MayBe<string>;
  updated_at?: MayBe<string>;
};

export const ApartmentUpdateDate = ({ updated_at, created_at }: Props) => (
  <div className={styles.apartmentCardUpdateDate}>
    {created_at && (
      <div>
        Добавлено в подборку{' '}
        <DateFormat format="dateMonthYearShortInTime" value={created_at} />
      </div>
    )}
    {updated_at && (
      <div>
        Обновлено от{' '}
        <DateFormat format="dateMonthYearShortInTime" value={updated_at} />
      </div>
    )}
  </div>
);

import { get } from 'lodash/fp';

const getNumber = get('number');
const getPhaseTitle = get('phase_title');

export const compareByNumber = <T>(prev: T, next: T) => {
  const prevNumber = getNumber(prev) as number;
  const nextNumber = getNumber(next) as number;
  if (prevNumber > nextNumber) {
    return 1;
  }
  if (prevNumber < nextNumber) {
    return -1;
  }

  return 0;
};

export const compareByPhaseTitle = <T>(prev: T, next: T) => {
  const prevNumber = getPhaseTitle(prev) as number;
  const nextNumber = getPhaseTitle(next) as number;
  if (prevNumber > nextNumber) {
    return 1;
  }
  if (prevNumber < nextNumber) {
    return -1;
  }

  return 0;
};

import React from 'react';
import { observer } from 'mobx-react';
import { useFormSubmit } from 'pages/AuthPage/pages/AuthEmailCodePage/hooks/useFormSubmit';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { Field, Formik, Form } from 'formik';
import {
  AlertSubmission,
  BaseLink,
  FormikTextField,
  Loader,
} from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { PrivacyButton } from 'pages/AuthPage/components/PrivacyButton';
import { authEmailCodeSchema } from 'validationSchemas';
import { AuthEmailCodeRequestBody } from 'services';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

import styles from '../AuthForm/styles.module.css';

type Props = {};

export const AuthByEmailFormRender = (props: Props) => {
  const { onSubmit } = useFormSubmit();
  return (
    <Formik<AuthEmailCodeRequestBody>
      validationSchema={authEmailCodeSchema}
      onSubmit={onSubmit}
      initialValues={{}}
      render={(props) => (
        <Form>
          <div className={styles.textFieldWrapper}>
            <Field
              label="Электронная почта"
              name="email"
              placeholder="example@mail.com"
              component={FormikTextField}
            />
          </div>
          <div className={styles.buttonGroupWrapper}>
            <ButtonBase
              icon={props.isSubmitting && <Loader color="primary" />}
              type="submit"
              fullWidth
              color="primary"
            >
              Продолжить
            </ButtonBase>
            <BaseLink
              className={styles.buttonLink}
              to={authRoutesStore.getRouteWithParent('authPhoneCodePage')}
              variant="text"
              color="primary"
            >
              Войти через телефон
            </BaseLink>
          </div>
          <PrivacyButton />
          <AlertSubmission isSubmitting errors={props.errors} />
        </Form>
      )}
    />
  );
};

export const AuthByEmailForm = withGoogleReCaptchaProvider(
  observer(AuthByEmailFormRender),
);

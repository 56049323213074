import { useEffect } from 'react';
import { reaction } from 'mobx';
import { LeafletStoreClass, ComplexFilterStore, BuildingsStore } from 'stores';

type Props = {
  leafletStore: LeafletStoreClass;
  complexFilterStore: ComplexFilterStore;
  buildingsStore: BuildingsStore;
};

export const useReactionToLoadComplexData = ({
  leafletStore,
  complexFilterStore,
  buildingsStore,
}: Props): void => {
  useEffect(() => {
    const reactionDisposer = reaction(
      () => [
        leafletStore.lng,
        leafletStore.lat,
        leafletStore.zoom,
        leafletStore.isInitialized,
        complexFilterStore.compileFilters,
      ],
      () => {
        if (!leafletStore.isInitLatLng()) {
          return;
        }

        buildingsStore.clear();

        void buildingsStore.searchComplexes(
          complexFilterStore.compileFilters,
          leafletStore.mapBounds,
          buildingsStore.pageInfo.pageInfo,
        );
      },
      { delay: 300 },
    );

    return () => {
      reactionDisposer();
    };
  }, []);
};

import React from 'react';
import styles from './styles.module.css';
import { AuthPopup } from '../../components/AuthPopup';
import { useGetQueryString } from '../../../../hooks';

/**
 * @link https://www.figma.com/file/C7GVSyerTsOKDU1YagwmXS/%D0%9A%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82-%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8F?node-id=46%3A23
 * */
export const AuthEmailSendLinkPage = () => {
  const { query } = useGetQueryString<{ email: string }>();

  return (
    <AuthPopup>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Отправили вам ссылку <br /> для входа
        </div>
        <div className={styles.subTitle}>
          Ссылка отправлена на почту <strong>{query?.email}</strong> и действует
          24 часа
        </div>
      </div>
    </AuthPopup>
  );
};

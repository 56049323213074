import React, { ComponentProps } from 'react';
import { MayBe } from 'types';
import { DateFormat, ViewCount } from 'components/atoms';
import { InfoCard } from '../InfoCard';
import styles from './styles.module.css';

type Props = {
  viewCount?: MayBe<number>;
  /** @todo: добавить в апи эту дату */
  viewed_at?: MayBe<string>;
} & Omit<ComponentProps<typeof InfoCard>, 'children'>;

export const InfoCardEdit = ({
  apartmentCount,
  viewCount,
  updated_at,
  viewed_at,
}: Props) => (
  <InfoCard updated_at={updated_at} apartmentCount={apartmentCount}>
    <ViewCount count={viewCount} showLabel />
    {viewed_at && (
      <div className={styles.infoCardLastViewDate}>
        Последний просмотр{' '}
        <DateFormat value={viewed_at} format="dateMonthYearShortWithTime" />
      </div>
    )}
  </InfoCard>
);

import React from 'react';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { BaseLink } from 'components/atoms';
import styles from './styles.module.css';

export const AuthEmailNotFoundPage = () => (
  <AuthPopup>
    <div className={styles.wrapper}>
      <div className={styles.title}>
        Эта почта не привязана <br /> к аккаунту
      </div>
      <div className={styles.subTitle}>
        Зарегистрируйтесь, чтобы входить на сайт через эту почту
      </div>
      <div className={styles.buttonGroup}>
        <BaseLink
          fullWidth
          to={authRoutesStore.getRouteWithParent('authPhoneCodePage')}
          color="primary"
        >
          Зарегистрироваться
        </BaseLink>
        <BaseLink
          fullWidth
          to={authRoutesStore.getRouteWithParent('authEmailCodePage')}
          color="default"
        >
          Войти с другой почтой
        </BaseLink>
      </div>
    </div>
  </AuthPopup>
);

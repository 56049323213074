import React from 'react';
import { useHistory } from 'react-router-dom';
import plusIcon from 'assets/plus.svg';
import { appModalRoutesService } from 'services';
import { Icon } from 'components/atoms/Icon';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import styles from './styles.module.css';

export const OffersNotFound = () => {
  const { push } = useHistory();
  return (
    <div className={styles.offersNotFoundWrapper}>
      <h2 className={styles.offersNotFoundTitle}>
        Здесь пока нет ни одной подборки
      </h2>
      <ButtonBase
        onClick={() =>
          push(appModalRoutesService.replaceModalType('offersCreate'))
        }
        icon={<Icon src={plusIcon} size={16} />}
      >
        Создать подборку
      </ButtonBase>
    </div>
  );
};

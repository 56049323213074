import { matchPath, useLocation } from 'react-router';
import { useEffect } from 'react';
import { usePrevState } from 'hooks';
import { appRoutesService, Params } from 'services';
import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';

/**
 * @desc закрытие квартиры если изменилась ссылка и она не совпадает
 * */
export const useCloseApartmentAfterChangeLink = () => {
  const { apartmentUIStore } = rootApartmentStore;
  const location = useLocation();

  const isOpenApartment = matchPath<Pick<Params, 'key'>>(location.pathname, {
    path: appRoutesService.getRoute('layoutActiveApartment'),
    exact: true,
    strict: true,
  });

  const prevIsOpenApartment = usePrevState(isOpenApartment);

  useEffect(() => {
    if (prevIsOpenApartment && !isOpenApartment) {
      apartmentUIStore.clearActiveApartment();
    }
  }, [isOpenApartment]);
};

import React from 'react';
import { Text } from 'components/atoms';

import iconLock from 'assets/lockBlack.svg';

import styles from './styles.module.css';

export const OfferNotAvailable = () => (
  <div className={styles.wrapper}>
    <img src={iconLock} className={styles.icon} alt="smth" />
    <Text weight="bold" tagName="h2" className={styles.title}>
      Эта подборка была закрыта, либо удалена
    </Text>
    <Text className={styles.description}>
      Попросите риелтора открыть для вас подборку
    </Text>
  </div>
);

import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import switcherMap from 'assets/switcherMap.svg';
import { ComplexRawInOffer } from 'services';
import { Icon } from 'components/atoms/Icon';
import { Loader } from 'components/atoms/Loader';
import { ComplexCardDesktopMap } from '../ComplexCardDesktopMap';
import { useComplexOnFullscreenMap } from '../../../../hooks/useComplexOnFullscreenMap';

type Props = {
  complex?: ComplexRawInOffer;
};

export const ComplexCardMap = ({ complex }: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const { onOpenMap, isLoading } = useComplexOnFullscreenMap({
    complexId: complex?.id.toString(),
  });
  if (isLarge) {
    return (
      <ComplexCardDesktopMap
        isLoading={isLoading}
        onOpenMap={onOpenMap}
        complex={complex}
      />
    );
  }
  return (
    <div>
      <ButtonBase
        onClick={onOpenMap}
        icon={isLoading ? <Loader /> : <Icon src={switcherMap} />}
      />
    </div>
  );
};

import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  maxLength?: number;
  rows?: number;
  cols?: number;
  disabled?: boolean;
  [prop: string]: any;
};

export const Textarea = ({
  className,
  name,
  placeholder,
  maxLength,
  rows,
  cols,
  disabled,
  ...rest
}: Props) => (
  <textarea
    id={name}
    className={cc([styles.textarea, className])}
    placeholder={placeholder}
    maxLength={maxLength}
    rows={rows}
    cols={cols}
    disabled={disabled}
    {...rest}
  />
);

import React from 'react';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  className?: string;
};

export const NavList = ({ className }: Props) => (
  <div className={cc([styles.wrapper, className])}>
    <ButtonBase color="transparent">Купить</ButtonBase>
    <ButtonBase color="transparent">Сдать</ButtonBase>
    <ButtonBase color="transparent">Продать</ButtonBase>
  </div>
);

import React, { ReactNode } from 'react';
import { observer } from 'mobx-react';
import { rootProfileStore } from 'stores/user/RootProfileStore';

type Props = {
  componentIsAuth?: ReactNode;
  componentIsNotAuth?: ReactNode;
};

export const ComponentToggleIsAuthRender = ({
  componentIsAuth,
  componentIsNotAuth,
}: Props) => {
  const { isAuth } = rootProfileStore.authStore;
  return <>{isAuth ? componentIsAuth : componentIsNotAuth}</>;
};

export const ComponentToggleIsAuth = observer(ComponentToggleIsAuthRender);

import { StylesConfig } from 'react-select/src/styles';
import { CSSObject } from '@emotion/serialize';
import { Option } from './types';

export const selectStyles: StylesConfig<Option, boolean, any> = {
  control(base: CSSObject, { menuIsOpen }): CSSObject {
    return {
      ...base,
      border: '1px solid #DFDFEB',
      borderRadius: '8px',
      minHeight: '40px',
      ...(menuIsOpen && {
        boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px 8px 0 0',
      }),
    };
  },
  indicatorSeparator(): CSSObject {
    return {
      display: 'none',
    };
  },
  placeholder(base: CSSObject): CSSObject {
    return {
      ...base,
      fontFamily: 'Circe',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',

      color: '#B9B9B9',
    };
  },
  valueContainer(base: CSSObject): CSSObject {
    return {
      ...base,
      padding: '2px 12px',
    };
  },
  singleValue(base: CSSObject): CSSObject {
    return {
      ...base,
      fontFamily: 'Circe',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',

      color: '#404042',
      marginLeft: 0,
      marginRight: 0,
      maxWidth: '100%',
    };
  },
  menu(base: CSSObject): CSSObject {
    return {
      ...base,
      marginTop: 0,
      borderRadius: 0,
      border: '1px solid #DFDFEB',
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',
    };
  },
  menuList(base: CSSObject): CSSObject {
    return {
      ...base,
    };
  },
  option(base: CSSObject, { isSelected }): CSSObject {
    return {
      ...base,
      fontFamily: 'Circe',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '20px',

      color: '#404042',

      padding: '5px 10px',
      ':hover': {
        ...(!isSelected && {
          backgroundColor: '#F1F6FC',
        }),
      },
      ...(isSelected && {
        backgroundColor: '#4E6AFF',
        color: '#ffffff',
      }),
    };
  },

  multiValue(): CSSObject {
    return {
      margin: '2px',
    };
  },
};

import { filter, find, flow, get, includes, map } from 'lodash/fp';
import { toJS } from 'mobx';
import { ID } from 'types';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import { Dictionary, DictionaryItem, DictionaryKeys } from './types';
import { TabItemProps } from '../../../../components/containers/Tabs';

const filterById = (dictItemId: number[]) =>
  filter((item: DictionaryItem): boolean =>
    includes(get('id')(item), dictItemId),
  );
export const getItems = get<Dictionary, 'items'>('items');

export const findInfoItems = (id: ID) =>
  find<DictionaryItem>((info) => id === info.id);

export const findInfoItemsByKey = (key: string) =>
  find<DictionaryItem>((info) => key === info.key);

export const getInfoByKey = (key: DictionaryKeys) => get(key);

export const getInfoItemById = (key: DictionaryKeys) => (id: ID) =>
  flow(getInfoByKey(key), getItems, findInfoItems(id));

export const getInfoItemByKey = (dictKey: DictionaryKeys) => (key: string) =>
  flow(getInfoByKey(dictKey), getItems, findInfoItemsByKey(key));

/**
 * @return "value" or "value1, value2"
 * */
export const findDictItemColor = (
  dictKey: DictionaryKeys,
  dictItemId: number[],
) =>
  flow<
    any,
    Partial<Record<DictionaryKeys, Dictionary>>,
    Dictionary,
    DictionaryItem[],
    DictionaryItem[],
    (string | undefined)[]
  >(
    toJS,
    get<Partial<Record<DictionaryKeys, Dictionary>>, any>(dictKey),
    getItems,
    filterById(dictItemId),
    map((item) => {
      if (Array.isArray(item.data)) {
        return undefined;
      }
      return item.data.color;
    }),
  );

export const findDictItemValue = (
  dictKey: DictionaryKeys,
  dictItemId: number[],
) =>
  flow<
    any,
    Dictionary,
    DictionaryItem[],
    DictionaryItem[],
    (string | undefined)[]
  >(
    get<Partial<Record<DictionaryKeys, Dictionary>>, any>(dictKey),
    getItems,
    filterById(dictItemId),
    map(get<DictionaryItem, 'value'>('value')),
  );

export const infoItemsToTabItemPropsList = map<
  DictionaryItem,
  TabItemProps<any>
>((item) => ({
  label: item.value,
  data: item,
}));

export const infoItemsToOptions = map<DictionaryItem, Option>((item) => ({
  label: item.value,
  value: item,
}));

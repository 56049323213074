import React, { useEffect, useMemo, useState } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';

import { Feature, Params } from 'services';
import { BaseLink, Divider } from 'components/atoms';

import iconRight from 'assets/arrowRight.svg';
import iconLeft from 'assets/arrowLeftGray.svg';

import { NearbyObjects } from 'pages/ComplexPage/components/NearbyObjects';
import { Benefits } from 'pages/ComplexPage/containers/Benefits';

import { CatalogsTable } from 'pages/ComplexPage/containers/ComplexInfo/components/CatalogsTable';
import { ComplexInfoDescription } from 'pages/ComplexPage/containers/ComplexInfo/components/ComplexInfoDescription';
import { complexItemStore } from 'stores/ComplexItemStore/store';

import { getWindowsFromLayers } from 'pages/LayoutPage/utils';
import { offerItemStore, offerApartmentStore } from 'stores';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { MainApartmentInfo } from './components/MainApartmentInfo';
import { ApartmentActions } from './components/ApartmentActions';
import { ApartmentInfo } from './components/ApartmentInfo';
import { MobileInfoHeader } from './components/MobileInfoHeader';

import styles from './styles.module.css';
import { useGetPathToOffers } from './hooks/useGetPathToOffers';
import { ConstructionProgressContainers } from '../../components/containers/Albums/ConstructionProgressContainers';
import { PhotoAlbumContainer } from '../../components/containers/Albums/PhotoAlbumContainer';
import { ComplexPresentationCarouselContainer } from './containers/ComplexPresentationCarouselContainer';
import { getIndexNextApartment, getNextApartmentId } from './helpers';
import {
  useLoadOfferByHash,
  useLoadApartmentFromOffer,
} from '../../hooks/offer';
import { useGetOfferViewFieldStatus } from '../OffersViewPage/hooks/useGetOfferViewFieldStatus';

export const OfferApartmentPageRender = () => {
  const { offerId, hash } = useParams<Pick<Params, 'offerId' | 'hash'>>();
  const { apartmentId } = useParams<Pick<Params, 'apartmentId'>>();

  const { state } = offerApartmentStore;
  const { apartment, complex, apartment_layouts } = state;

  const { offer } = offerItemStore;
  const { goToOfferPage } = useGetPathToOffers(offerId, hash);

  const { isShowMap, isShowComplex } = useGetOfferViewFieldStatus();

  const [isIframeMode, setsIframeMode] = useState(false); 

  useLoadOfferByHash({
    hash,
    offerId,
  });

  useLoadApartmentFromOffer({
    offerHash: offer?.hash,
    apartmentId,
  });

  const nextApartmentIndex = getIndexNextApartment(apartmentId, offer);

  const nextApartmentId = getNextApartmentId(nextApartmentIndex)(offer);

  const windows: Feature[] | undefined = useMemo(
    () => getWindowsFromLayers(state?.apartment?.layouts),
    [apartment?.layouts],
  );

  const formattedPolygon: Array<[number, number]> =
    complexItemStore.reversePolygonCoords(complex?.polygon);
  
    useEffect(() => {
    if (window.self !== window.top) {
      // условие сработает, если данный компонент отрисовывается в iframe на другом сайте
      setsIframeMode(true);
    } else {
      setsIframeMode(false);
    }
    }, []);

  if (!complex || !apartment) {
    return null;
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.wrapper}>
        <MobileInfoHeader
          goToOfferPage={goToOfferPage}
          complex={complex}
          apartment={apartment}
        />

        <ButtonBase
          onClick={goToOfferPage}
          icon={<img src={iconLeft} alt="go to main page" />}
          className={styles.toOffersLink}
        >
          Назад к подборке
        </ButtonBase>

        <section className={styles.flat}>
          <ComplexPresentationCarouselContainer complex={complex} />

          <div className={cc([styles.container, styles.mainInfoWrapper])}>
            <div className={styles.allFlatsInfo}>
              <MainApartmentInfo
                apartment={apartment}
                complex={complex}
                apartmentLayouts={apartment_layouts}
              />

              <div className={styles.mobileApartmentActions}>
                <ApartmentActions apartment={apartment} complex={complex} isIframeMode={isIframeMode} />
              </div>

              <CatalogsTable
                catalogs={complex?.catalogs || {}}
                excludeInfoKeys={[
                  'residential_complex_advantages',
                  'residential_complex_badges',
                ]}
              />

              <Divider top bottom />

              {isShowComplex && (
                <ComplexInfoDescription
                  content={complex?.description || ''}
                  className={styles.descriptionWrapper}
                />
              )}

              <ApartmentInfo apartment={apartment} />
            </div>

            <div className={styles.desktopApartmentActions}>
              <ApartmentActions apartment={apartment} complex={complex} isIframeMode={ isIframeMode} />
            </div>
          </div>

          <Benefits
            className={styles.container}
            complexCatalogs={complex?.catalogs}
          />

          {isShowMap && (
            <NearbyObjects
              sectionTitle="Расположение и вид из окон"
              mapClassName={styles.nearbyMap}
              className={cc([styles.container, styles.nearbyTitle])}
              buildings={complex?.buildings || []}
              polygon={formattedPolygon}
              windows={windows}
            />
          )}

          <PhotoAlbumContainer
            albums={complex.albums || []}
            complexId={complex?.id}
            className={styles.container}
            to={{
              search: '?hideFeedbackForm=true',
            }}
          />

          <ConstructionProgressContainers
            albums={complex.albums || []}
            complexId={complex?.id}
            className={styles.container}
            to={{
              search: '?hideFeedbackForm=true',
            }}
          />

          {!isIframeMode && <div className={styles.toNextOfferWrapper}>
              <BaseLink
                params={{ offerId, apartmentId: nextApartmentId?.toString() }}
                path="offersApartmentView"
                icon={
                  <img
                    src={iconRight}
                    width="30.8"
                    height="26.2"
                    alt="go to next apartment"
                  />
                }
                iconRight
                className={styles.toNextOfferLink}
              >
                Смотреть следующую квартиру
              </BaseLink>
            </div>
          }
        </section>
      </div>
    </div>
  );
};

export const OfferApartmentPage = observer(OfferApartmentPageRender);

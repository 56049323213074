import React from 'react';
import { Route, Switch } from 'react-router';
import { appRoutesService } from 'services';
import { PageWrapper } from 'components/atoms';
import { guardedRoute } from 'hocs';
import { ProfileEditorPage, FavoritesPage, OffersPage } from './pages';
import { Layout } from './components/Layout/Layout';
import { NavbarMainPage } from '../Main/containers/Navbar/NavbarMainPage';
import { FeedsPage } from './pages/FeedsPage';
import styles from './styles.module.css';

export const ProfilePage = () => (
  <PageWrapper className={styles.root}>
    <div className={styles.navbarWrapper}>
      <NavbarMainPage />
    </div>
    <Layout>
      <Switch>
        <Route
          path={appRoutesService.getRoute('favorites')}
          component={FavoritesPage}
          exact
        />
        <Route
          path={appRoutesService.getRoute('feeds')}
          component={FeedsPage}
        />
        <Route
          path={appRoutesService.getRoute('offers')}
          component={guardedRoute(OffersPage)}
        />
        <Route
          path={appRoutesService.getRoute('profile')}
          component={guardedRoute(ProfileEditorPage)}
        />
      </Switch>
    </Layout>
  </PageWrapper>
);

import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { FORM_SUBMISSION_ERROR } from 'services/constants';
import { get, has } from 'lodash';
import { Alert } from '../Alert';

type Props = Pick<FormikProps<any>, 'errors' | 'isSubmitting'> & {
  className?: string;
};

export const AlertSubmission = ({ errors, isSubmitting, className }: Props) => {
  const isShow = has(errors, FORM_SUBMISSION_ERROR) && isSubmitting;
  return (
    <Alert className={className} isShow={isShow}>
      {get(errors, FORM_SUBMISSION_ERROR)}
    </Alert>
  );
};

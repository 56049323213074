import {
  apartmentsService,
  ApartmentDto,
  RequestPageInfo,
  ResponsePageInfo,
  BuildingSearchParameters,
} from 'services';
import { action, makeObservable, observable } from 'mobx';
import { RequestApiStore } from '../../request/RequestApiStore';

import { RequestPageInfoStore } from '../../request/RequestPageInfoStore';

export class ApartmentsStore {
  apartments: ResponsePageInfo<ApartmentDto[]> = {};

  pageInfo: RequestPageInfoStore;

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();
    this.pageInfo = new RequestPageInfoStore();

    makeObservable(this, {
      resetStore: action.bound,
      resetApartmentState: action.bound,
      setResponse: action.bound,
      clearData: action.bound,
      loadApartments: action.bound,

      apartments: observable,
    });
  }

  get hasMore(): boolean {
    return this.apartments.total === this.apartments.data?.length;
  }

  // <editor-fold desc="RESET FILTERS">
  resetStore = () => {
    this.pageInfo.resetPageInfo();
    this.resetApartmentState();
  };

  resetApartmentState = () => {
    this.apartments = {};
  };
  // </editor-fold>

  clearData = () => {
    this.apartments.data = [];
  };

  setResponse = (result: ResponsePageInfo<ApartmentDto[]> | undefined) => {
    if (!result) {
      return;
    }

    this.apartments.currentPage = result.currentPage;
    this.apartments.resultsPerPage = result.resultsPerPage;
    this.apartments.total = result.total;
    this.apartments.totalPages = result.totalPages;

    this.apartments.data = [
      ...(this.apartments.data || []),
      ...(result.data || []),
    ];
  };

  loadApartments = async (
    complexId?: number | string,
    pageInfo?: RequestPageInfo,
    filter?: BuildingSearchParameters,
  ): Promise<ResponsePageInfo<ApartmentDto[]> | undefined> => {
    try {
      if (!complexId) {
        return undefined;
      }

      this.requestApi.setLoading(true);

      const result = await apartmentsService.getApartments(pageInfo, {
        ...filter,
        complexIds: [String(complexId)],
      });

      this.setResponse(result);

      this.requestApi.setLoading(false);

      return result;
    } catch (e) {
      this.requestApi.setError(true);
      return undefined;
    }
  };
}

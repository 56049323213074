import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import cc from 'classcat';
import { AlbumFilterOption } from 'stores/AlbumsStore';
import { AlbumPhotoToFilter } from 'services';
import styles from '../../styles.module.css';

type Props = {
  albumFilterOptions: AlbumFilterOption[];
  slideToAlbums: (albumId: number) => void;
  activeIndex: number;
  photosToShow: AlbumPhotoToFilter[];
};

export const AlbumMonthTabs = ({
  albumFilterOptions,
  photosToShow,
  slideToAlbums,
  activeIndex,
}: Props) => (
  <Swiper spaceBetween={30} slidesPerView="auto" id="albumsHeader">
    {albumFilterOptions.map(({ albumId, title }) => (
      <SwiperSlide key={albumId} className={styles.topSwiperSlide}>
        <div
          onClick={() => slideToAlbums(albumId)}
          className={cc([
            albumId === photosToShow[activeIndex]?.albumId &&
              styles.topSwiperSlideActive,
          ])}
        >
          {title}
        </div>
      </SwiperSlide>
    ))}
  </Swiper>
);

import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import { MayBe } from 'types';
import { Image } from 'types/Image';
import { Company } from 'services';
import styles from './styles.module.css';

type Props = {
  logo?: MayBe<Image>;
  title?: MayBe<string>;
  address?: MayBe<string>;
  developer?: MayBe<Company>;
};

export const ComplexLogo = ({ logo, title, developer, address }: Props) => (
  <div className={styles.complexLogoWrapper}>
    <div className={styles.complexLogoImageWrapper}>
      <img
        className={styles.complexLogoImageWrapper}
        src={logo?.url || ''}
        alt="developer logo"
      />
    </div>
    <div>
      <div className={styles.complexLogoTitle}>
        <LinesEllipsis
          text={title?.trim()}
          maxLine="2"
          ellipsis="..."
          trimRight
        />
      </div>
      {developer && (
        <div className={styles.complexLogoDeveloperName}>
          {developer?.title}
        </div>
      )}
      {address && <div className={styles.complexLogoAddress}>{address}</div>}
    </div>
  </div>
);

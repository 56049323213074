import React from 'react';
import { NavbarDesktop } from '../NavbarDesktop';
import { NavbarMobile } from '../NavbarMobile';
import { useNavbarPopupMenu } from '../NavbarPopupMenu';
import { useNavbarPopupSearch } from '../NavbarPopupSearch';

export const NavbarMainPage = () => {
  const { showNavbarPopupMenu } = useNavbarPopupMenu();
  const { showNavbarPopupSearch } = useNavbarPopupSearch();

  return (
    <>
      <NavbarDesktop />
      <NavbarMobile
        onOpenMenu={showNavbarPopupMenu}
        onOpenSearch={showNavbarPopupSearch}
      />
    </>
  );
};

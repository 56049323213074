import { reaction } from 'mobx';
import { ID } from 'types';
import { FavoritesService } from 'services/v1/rc/favorites';
import { IDisposer } from 'mobx-state-tree';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { FavoritesIdsStore, FavoritesStore } from './index';

export class FavoritesComposeAuth {
  authStore: AuthStore;

  favoritesIds: FavoritesIdsStore;

  favoritesService: FavoritesService;

  favoritesStore: FavoritesStore;

  isAuthDisposer: IDisposer | undefined;

  constructor(
    authStore: AuthStore,
    favoritesIds: FavoritesIdsStore,
    favoritesService: FavoritesService,
    favoritesStore: FavoritesStore,
  ) {
    this.favoritesService = favoritesService;
    this.authStore = authStore;
    this.favoritesIds = favoritesIds;
    this.favoritesStore = favoritesStore;
  }

  initReaction = () => {
    this.isAuthDisposer = reaction(
      () => this.authStore.isAuth,
      (isAuth, prevIsAuth) => {
        if (!prevIsAuth && isAuth) {
          void this.syncLogin();
        } else if (prevIsAuth && !isAuth) {
          this.syncLogout();
        }
      },
      {
        fireImmediately: true,
      },
    );
  };

  removeReaction = () => {
    if (this.isAuthDisposer) {
      this.isAuthDisposer();
    }
  };

  /**
   * @desc выполнить синхронизацию кеша с базой после авторизации
   * */
  syncLogin = async () => {
    const { apartmentIds, complexIds } = this.favoritesIds.state;

    const [, result] = await this.favoritesService.sync({
      apartments: apartmentIds,
      complexes: complexIds,
    });

    this.favoritesIds.setApartmentIds(result?.apartments);
    this.favoritesIds.setComplexIds(result?.complexes);
  };

  /**
   * @desc выполнить синхронизацию после выхода
   * */
  syncLogout = () => {
    this.favoritesIds.clear();
    this.favoritesStore.clear();
  };

  /**
   * @desc
   * */
  toggleApartment = async (id: ID) => {
    let result;
    if (this.authStore.isAuth) {
      result = await this.favoritesService.toggle({
        recordId: id,
        type: 'apartment',
      });
    }
    this.favoritesIds.toggleApartment(id);
    return result;
  };

  /**
   * @desc
   * */
  toggleComplex = async (id: ID) => {
    let result;
    if (this.authStore.isAuth) {
      result = await this.favoritesService.toggle({
        recordId: id,
        type: 'complex',
      });
    }
    this.favoritesIds.toggleComplex(id);
    return result;
  };
}

import React from 'react';
import { observer } from 'mobx-react';
import { infoStore } from 'stores';
import cityImage from 'assets/telegram.svg';
import styles from './styles.module.css';

export const ChoicerUserCityRender = () => {
  const { city, requestApi } = infoStore;
  return (
    <>
      {!requestApi.isLoading && city?.name && (
        <div className={styles.currentCity}>
          <img src={cityImage} alt="city-svg" />
          <p>{city.name}</p>
        </div>
      )}
    </>
  );
};

export const ChoicerUserCity = observer(ChoicerUserCityRender);

/**
 * Plural forms for russian words
 * @param  {Integer} count quantity for word
 * @param  {Array} words Array of words. Example: ['депутат', 'депутата', 'депутатов'], ['коментарий', 'коментария', 'комментариев']
 * @return {String}        Count + plural form for word
 */
export const pluralize = (
  count: number,
  words: string[],
  wordOnly?: boolean,
) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const counter = wordOnly ? '' : `${count} `;

  return `${counter}${
    words[
      count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]
    ]
  }`;
};

import React, {
  useState,
  useMemo,
  useCallback,
  MouseEvent,
  useRef,
} from 'react';
import { Link } from 'react-router-dom';
import { useResizeObserver } from 'beautiful-react-hooks';
import { Popover } from 'react-tiny-popover';
import cc from 'classcat';
import { flow } from 'lodash/fp';

import { Switch } from 'components/atoms';
import { appRoutesService, User } from 'services';
import { stopPropagation } from 'utils/fp/stopPropagation';

import { MayBe } from 'types';
import { ProfileHeaderButton } from '../ProfileHeaderButton';
import styles from './styles.module.css';

type Props = {
  user: MayBe<User>;
  logout?: () => void;
};

export const ProfileHeaderMenu = ({ user, logout }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>();
  const selectButtonDOMRect = useResizeObserver(buttonRef);

  const [isOpen, setOpen] = useState(false);
  const toggleOpen = useCallback((): void => {
    setOpen(!isOpen);
  }, [isOpen]);

  const popoverContent = useMemo(
    () => (
      <div
        style={{
          width: selectButtonDOMRect?.width || 'auto',
        }}
        className={styles.dropdownOverlay}
      >
        <div
          className={cc([
            styles.dropdownItem,
            styles.dropdownItemDark,
            styles.dropdownItemBorder,
            styles.dropdownItemWithSwitch,
          ])}
        >
          Я с клиентом <Switch />
        </div>
        <Link
          to={appRoutesService.replaceRoute('profile')}
          className={styles.dropdownItem}
        >
          Профиль
        </Link>
        <button
          type="button"
          onClick={logout}
          className={cc([styles.dropdownItem, styles.dropdownItemAlert])}
        >
          Выход
        </button>
      </div>
    ),
    [selectButtonDOMRect],
  );

  return (
    <Popover
      onClickOutside={toggleOpen}
      isOpen={isOpen}
      positions={['bottom']}
      content={popoverContent}
      ref={buttonRef as any}
    >
      <ProfileHeaderButton
        onClick={flow<any, MouseEvent, void>(stopPropagation, toggleOpen)}
        user={user}
      />
    </Popover>
  );
};

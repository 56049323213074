import { flow, get, head, isNil, map, negate, find, isEqual } from 'lodash/fp';
import { toJS } from 'mobx';
import { compareByNumber, compareByPhaseTitle, isNotNil, sort } from 'utils/fp';
import { MayBe } from 'types';
import { Address, FilePresets, Complex, AddressScheme } from './types';
import { findBuildingById } from '../building/handlers';
import { BuildingEntity } from '../building/types';
import { FILES_API_ROUTES } from '../../../constants';

export const getFirstBuildingId = flow<
  any,
  Array<BuildingEntity>,
  Array<BuildingEntity>,
  Array<BuildingEntity>,
  BuildingEntity | undefined,
  number | undefined
>(toJS, sort(compareByNumber), sort(compareByPhaseTitle), head, get('id'));

export const findBuildingByIdInComplex = (building_id?: string) =>
  flow<any, Array<BuildingEntity> | undefined, BuildingEntity | undefined>(
    get<Complex, 'buildings'>('buildings'),
    findBuildingById(building_id),
  );

export const setPresetToImage = (url: string, preset: FilePresets) =>
  url.replace(
    [process.env.REACT_APP_FILES_API, FILES_API_ROUTES.uploads].join('/'),
    [process.env.REACT_APP_FILES_API, FILES_API_ROUTES.uploads, preset].join(
      '/',
    ),
  );

export const joinTypeWithTitle = map<Address, string>(({ type, title }) =>
  [type, title].filter(negate(isNil)).join(' '),
);

export const equalAddressBySchema = (target: AddressScheme) =>
  flow(get(['scheme']), isEqual(target));

export const findAddressBySchema = (target: AddressScheme) =>
  find<Address>(equalAddressBySchema(target));

/**
 * @desc вернет улицу и дом
 * */
export const getStreetAndHome = (addressDetail?: MayBe<Address[]>) =>
  [
    findAddressBySchema('street')(toJS(addressDetail)),
    findAddressBySchema('house')(toJS(addressDetail)),
  ]
    .filter(isNotNil)
    .map(get('title'))
    .join(' ');

import { makeObservable, observable, action } from 'mobx';
import {
  AttemptResult,
  OffersEditViewResponse,
  OffersService,
  offersService,
  OffersViews,
} from '../../services';
import { ID, MayBe } from '../../types';
import { RequestApiStore } from '../request/RequestApiStore';

export class OffersEditView {
  viewFields: OffersViews[] = [];

  offerId: MayBe<ID> = null;

  requestApiState: RequestApiStore;

  offersService: OffersService;

  constructor(offersService: OffersService) {
    this.offersService = offersService;
    this.requestApiState = new RequestApiStore();

    makeObservable(this, {
      viewFields: observable,
      offerId: observable,
      clear: action,
      setOfferId: action,
      setViewFields: action,
      toggleViewFieldWithSave: action,
      saveViewField: action,
    });
  }

  clear = () => {
    this.offerId = null;
    this.viewFields = [];
    this.requestApiState.clear();
  };

  isInclude = (targetField: OffersViews) =>
    this.viewFields.includes(targetField);

  setOfferId = (offerId: MayBe<ID>) => {
    this.offerId = offerId;
  };

  setViewFields = (viewFields: OffersViews[]) => {
    this.viewFields = viewFields;
  };

  deleteViewField = (viewFields: OffersViews[]) => (targetField: OffersViews) =>
    viewFields.filter((field) => field !== targetField);

  addViewField = (viewFields: OffersViews[]) => (targetField: OffersViews) =>
    [...viewFields, targetField];

  toggleViewFieldWithSave = async (targetField: OffersViews) => {
    const [, result] = await this.saveViewField(targetField);

    if (!result) {
      return;
    }

    if (result.state === 'add') {
      this.viewFields = observable.array(
        this.addViewField(this.viewFields)(targetField),
      );
    }
    if (result.state === 'delete') {
      this.viewFields = observable.array(
        this.deleteViewField(this.viewFields)(targetField),
      );
    }
  };

  saveViewField = async (
    field: OffersViews,
  ): Promise<AttemptResult<OffersEditViewResponse>> => {
    if (!this.offerId) {
      // eslint-disable-next-line no-console
      console.error('this.offerId is not define');
      return [null, null];
    }
    this.requestApiState.setLoading(true);
    const [error, result] = await this.offersService.editView({
      field,
      offerId: this.offerId,
    });

    if (error) {
      this.requestApiState.setError(true, error.message);
    }

    this.requestApiState.setLoading(true);
    return [error, result];
  };
}

export const offersEditView = new OffersEditView(offersService);

import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { FormikMaskedInput, Loader, BaseLink } from 'components/atoms';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { PrivacyButton } from '../../../../components/PrivacyButton';
import styles from './styles.module.css';
import { dataTestIdToProps } from '../../../../../../utils/test/dataTestIdToProps';

type Props = FormikProps<any>;

export const AuthPhoneCodePageFormRenderTestIds = {
  openAuthEmailCodePage: 'AuthPhoneCodePage_openAuthEmailCodePage',
  title: 'AuthPhoneCodePage_title',
  phoneField: 'AuthPhoneCodePage_phoneField',
  submitButton: 'AuthPhoneCodePage_submitButton',
};

export const FormRender = ({ handleSubmit, isSubmitting }: Props) => (
  <form onSubmit={handleSubmit}>
    <div
      {...dataTestIdToProps(AuthPhoneCodePageFormRenderTestIds.title)}
      className={styles.title}
    >
      Добро пожаловать!
    </div>

    <Field
      {...dataTestIdToProps(AuthPhoneCodePageFormRenderTestIds.phoneField)}
      label="Телефон"
      name="phone"
      component={FormikMaskedInput}
    />

    <div className={styles.buttonGroup}>
      <ButtonBase
        {...dataTestIdToProps(AuthPhoneCodePageFormRenderTestIds.submitButton)}
        icon={isSubmitting && <Loader color="primary" />}
        type="submit"
        fullWidth
        color="primary"
      >
        Продолжить
      </ButtonBase>
      <BaseLink
        {...dataTestIdToProps(
          AuthPhoneCodePageFormRenderTestIds.openAuthEmailCodePage,
        )}
        fullWidth
        to={authRoutesStore.getRouteWithParent('authEmailCodePage')}
        variant="text"
        color="primary"
      >
        Войти через почту
      </BaseLink>
    </div>
    <PrivacyButton />
  </form>
);

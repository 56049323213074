import React from 'react';
import { Feed } from 'services/v1/feed/types';
import { FeedItem } from '../FeedItem';
import { FeedListWrapper } from '../FeedListWrapper';

type Props = {
  list: Feed[];
};

export const FeedList = ({ list }: Props) => (
  <FeedListWrapper>
    {list.map((feed) => (
      <FeedItem key={feed.hash} {...feed} />
    ))}
  </FeedListWrapper>
);

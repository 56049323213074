import React, { ReactNode } from 'react';
import cc from 'classcat';
import zoomWhiteIcon from 'assets/zoomWhite.svg';
import styles from './styles.module.css';

type Props = {
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
};

export const OverlayZoomButton = ({ children, onClick, className }: Props) => (
  <button
    className={cc([styles.button, className])}
    type="button"
    onClick={onClick}
  >
    {children}
    <img className={styles.icon} src={zoomWhiteIcon} alt="zoom icon" />
  </button>
);

import React from 'react';
import cc from 'classcat';
import { ID, MayBe } from 'types';
import styles from '../../../../components/containers/SalesDepartmentsCard/styles.module.css';
import { BaseLink } from '../../../../components/atoms';
import { dataTestIdToProps } from '../../../../utils/test/dataTestIdToProps';

type Props = {
  complexId?: MayBe<ID>;
  buildingId?: MayBe<string>;
  disabled?: boolean;
  isVillage?: boolean;
  className?: string;
};

const OpenLayoutPageButtonTestIDs = {
  button: 'OpenLayoutPageButton_button',
};

export const OpenLayoutPageButton = ({
  complexId,
  buildingId,
  disabled,
  className,
  isVillage = false,
}: Props) => (
  <BaseLink
    {...dataTestIdToProps(OpenLayoutPageButtonTestIDs.button)}
    color="primary"
    disabled={!buildingId || !complexId || disabled}
    path="layoutActiveTab"
    fullWidth
    params={{
      complexId: complexId?.toString(),
      buildingId: buildingId?.toString(),
      layoutTabs: 'grid',
    }}
    className={cc([styles.gridLayerBtn, className])}
  >
    {isVillage ? 'Генплан' : 'К квартирам'}
  </BaseLink>
);

import React from 'react';
import { observer } from 'mobx-react';
import { DictionaryKeys } from 'services';
import { infoStore } from 'stores/InfoStore';

type Props = {
  dictKey: DictionaryKeys;
  className?: string;
  tagName?: string;
  dictStore?: typeof infoStore;
};

export const DictionaryNameViewRender = ({
  dictKey,
  className,
  tagName,
  dictStore,
  ...rest
}: Props) =>
  React.createElement(
    tagName || 'span',
    {
      className,
      ...rest,
    },
    dictStore?.getInfoBy(dictKey)?.title,
  );

DictionaryNameViewRender.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  dictStore: infoStore,
};

export const DictionaryNameView = observer(DictionaryNameViewRender);

import React, { useCallback } from 'react';
import zoomIn from 'assets/zoomIn.svg';
import zoomOut from 'assets/zoomOut.svg';
import { Map as MapType } from 'leaflet';
import { MayBe } from 'types';
import { useMap } from 'react-leaflet';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from 'components/atoms/Icon';
import styles from './styles.module.css';

type Props = {
  mapInstance?: MayBe<MapType>;
};

export const LeafletZoom = ({ mapInstance }: Props) => {
  const _mapInstance: MapType = useMap();

  const onZoomIn = useCallback(
    (mapInstance?: MayBe<MapType>) => () => {
      if (mapInstance) {
        mapInstance.zoomIn();
      }
    },
    [],
  );

  const onZoomOut = useCallback(
    (mapInstance?: MayBe<MapType>) => () => {
      if (mapInstance) {
        mapInstance.zoomOut();
      }
    },
    [],
  );

  return (
    <div className={styles.zoom}>
      <ButtonBase
        icon={<Icon alt="zoom in" src={zoomIn} />}
        onClick={onZoomIn(mapInstance || _mapInstance)}
      />
      <ButtonBase
        icon={<Icon alt="zoom out" src={zoomOut} />}
        onClick={onZoomOut(mapInstance || _mapInstance)}
      />
    </div>
  );
};

import { useEffect } from 'react';
import { reaction } from 'mobx';
import { ID } from 'types';
import { addToOffersStore, apartmentInOffersStatusStore } from 'stores';
import { throttle } from 'lodash';
import { rootProfileStore } from '../../../../../stores/user/RootProfileStore';

type Props = {
  apartmentId?: ID;
};

const getApartmentStatusThrottle = throttle(
  apartmentInOffersStatusStore.getApartmentStatus,
  1000,
);

/** @desc отслеживает статус сохранения квартиры в подборках и выполняет запрос для
 * проверки есть ли квартира хотя бы в одной подборке
 * */
export const useUpdateApartmentStatus = ({ apartmentId }: Props) => {
  const { haveAnApartmentInAnyOffers, getApartmentStatus } =
    apartmentInOffersStatusStore;

  const { isAuth } = rootProfileStore.authStore;

  useEffect(() => {
    if (!apartmentId || !isAuth) {
      return undefined;
    }

    if (haveAnApartmentInAnyOffers(apartmentId) === undefined) {
      void getApartmentStatus(apartmentId);
    }

    const disposer = reaction(
      () => addToOffersStore.saveRequestApiStore.isLoading,
      (isLoading, prevIsLoading) => {
        if (isLoading !== prevIsLoading) {
          void getApartmentStatusThrottle(apartmentId);
        }
      },
    );

    return () => disposer();
  }, [apartmentId, isAuth]);
};

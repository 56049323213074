/**
 * @desc стор хранит текущую активную квартиру и предоставляет вспомогательные
 * методы для отображения квартир на странице шахматка, план, список квартир
 * */
import { makeObservable, action, observable } from 'mobx';
import { ApartmentDto } from 'services';
import { MayBe } from 'types';

export class ApartmentUIStore {
  activeApartment: ApartmentDto | undefined = undefined;

  isOpen = false;

  constructor() {
    makeObservable(this, {
      toggleActiveApartment: action.bound,
      clearActiveApartment: action.bound,
      setIsOpen: action.bound,
      addApartment: action.bound,
      activeApartment: observable.shallow,
      isOpen: observable,
    });
  }

  isActiveApartment(id: number): boolean {
    if (this.activeApartment === undefined) {
      return false;
    }

    return this.activeApartment.id === id;
  }

  isActiveApartmentByNumber(number?: MayBe<string | number>): boolean {
    if (this.activeApartment === undefined) {
      return false;
    }

    return this.activeApartment.number === number;
  }

  setIsOpen = (isOpen: boolean): void => {
    this.isOpen = isOpen;
  };

  addApartment = (apartment: ApartmentDto | undefined): void => {
    this.activeApartment = apartment;
  };

  toggleActiveApartment(apartment: ApartmentDto | undefined): void {
    if (apartment && apartment.id && this.isActiveApartment(apartment.id)) {
      this.activeApartment = undefined;
      this.isOpen = false;
      return;
    }
    this.isOpen = true;
    this.activeApartment = apartment;
  }

  clearActiveApartment(): void {
    this.isOpen = false;
    this.activeApartment = undefined;
  }
}

import { rootProfileStore } from 'stores/user/RootProfileStore';

export const useAuthUserIsRealtor = () => {
  const { isAuth } = rootProfileStore.authStore;
  const { user } = rootProfileStore.authStore.authUser;

  return {
    isRealtor: isAuth && user?.role === 'realtor',
  };
};

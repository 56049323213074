import React, { useCallback } from 'react';
import { Row, UseTableInstanceProps } from 'react-table';
import { observer } from 'mobx-react';
import { ApartmentDto } from 'services';
import { TBody, Td, Tr } from 'components/atoms';

import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';
import { useGetOpenApartment } from '../../../hooks/useGetOpenApartment';
import { DesktopSkeletonRow } from '../DesktopSkeletonRow';

type Props = {
  getTableBodyProps: UseTableInstanceProps<ApartmentDto>['getTableBodyProps'];
  rows: UseTableInstanceProps<ApartmentDto>['rows'];
  prepareRow: UseTableInstanceProps<ApartmentDto>['prepareRow'];
};

export const TableBodyRender = ({
  getTableBodyProps,
  rows,
  prepareRow,
}: Props) => {
  const { apartmentUIStore, apartmentsStore } = rootApartmentStore;
  const onOpenApartment = useGetOpenApartment();

  const RenderRow = useCallback(
    (row: Row<ApartmentDto>) => {
      prepareRow(row);
      return (
        <Tr
          onClick={onOpenApartment(row.original.id, row.original.number)}
          isActive={
            row?.original?.id
              ? apartmentUIStore.isActiveApartment(row?.original?.id)
              : false
          }
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => (
            <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
          ))}
        </Tr>
      );
    },
    [prepareRow, rows],
  );

  return (
    <TBody {...getTableBodyProps()}>
      {rows.map(RenderRow)}

      {apartmentsStore.requestApi.isLoading && (
        <>
          <DesktopSkeletonRow />
          <DesktopSkeletonRow />
          <DesktopSkeletonRow />
        </>
      )}
    </TBody>
  );
};

export const TableBody = observer(TableBodyRender);

import React from 'react';
import { toJS } from 'mobx';
import options from 'assets/options.svg';
import close from 'assets/close.svg';
// import save from 'assets/save.svg';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Badge } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import { getCountActiveFilter } from 'components/containers/Search/utils';

type Props<Filter extends {}> = {
  onFilterReset?: () => void;
  onFilterOpen?: () => void;
  resultedFilters?: Partial<Filter>;
  hideSaveButton?: boolean;
};

export const FilterButtonWithFilterCountTestIDs = {
  open: 'LayoutFiltersOpen',
  reset: 'LayoutFiltersReset',
};

export const FilterButtonWithFilterCount = <Filter extends {}>({
  onFilterOpen,
  onFilterReset,
  resultedFilters,
  hideSaveButton,
}: Props<Filter>) => {
  const countActiveFilter = getCountActiveFilter(toJS(resultedFilters));
  return (
    <>
      <ButtonBase
        data-test-id={FilterButtonWithFilterCountTestIDs.open}
        onClick={onFilterOpen}
        icon={
          countActiveFilter > 0 ? (
            <Badge size="medium" isCircle variants="alert">
              {countActiveFilter}
            </Badge>
          ) : (
            <Icon alt="more filter" src={options} />
          )
        }
      >
        Фильтры
      </ButtonBase>
      {countActiveFilter > 0 && (
        <>
          <ButtonBase
            data-test-id={FilterButtonWithFilterCountTestIDs.reset}
            onClick={onFilterReset}
            icon={<Icon size={10} alt="clear filter" src={close} />}
          >
            Сбросить
          </ButtonBase>
          {/* {!hideSaveButton && (
            <ButtonBase icon={<Icon alt="save filter" src={save} />} />
          )} */}
        </>
      )}
    </>
  );
};

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFetchAuthValidationCode } from '../../../../hooks/useFetchAuthValidationCode';
import { injectReCaptchaTokenToFormValues } from '../../../../../../utils/injectReCaptchaTokenToFormValues';
import { getAuthPhoneCodeRequestBody } from '../../../../utils';

export const useReCall = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { fetchPhoneCode, query, requestState } = useFetchAuthValidationCode();

  return {
    reCall: injectReCaptchaTokenToFormValues(executeRecaptcha)(
      fetchPhoneCode(getAuthPhoneCodeRequestBody(query)),
    ),
    requestState,
    query,
  };
};

import {
  OffersListParams,
  OffersListResponse,
  OffersService,
  offersService,
  RequestPageInfo,
} from 'services';
import { makeObservable, observable, action, computed } from 'mobx';
import { RequestApiStore } from 'stores';
import { RequestPageInfoStore } from '../request/RequestPageInfoStore';
import { OffersFilterStore } from './OffersFilterStore';
import { CancelTokenStore } from '../request/CancelTokenStore';

export class OffersStore extends CancelTokenStore {
  state: OffersListResponse = {};

  pageInfo: RequestPageInfoStore;

  offersService: OffersService;

  requestApiStore: RequestApiStore;

  offersFilterStore: OffersFilterStore;

  constructor(offersService: OffersService) {
    super();

    this.offersService = offersService;
    this.requestApiStore = new RequestApiStore();
    this.pageInfo = new RequestPageInfoStore();
    this.offersFilterStore = new OffersFilterStore();
    makeObservable(this, {
      state: observable,
      clear: action,
      clearState: action,
      fetch: action,

      hasMore: computed,
      offers: computed,
    });
  }

  clear(): void {
    this.offersFilterStore.clear();
    this.state = {};
    this.pageInfo.resetPageInfo();
  }

  clearState(): void {
    this.state = {};
  }

  get offers() {
    return this.state.data;
  }

  get hasMore(): boolean {
    return this.state?.currentPage && this.state?.totalPages
      ? this.state?.currentPage < this.state?.totalPages
      : false;
  }

  async fetch(params: OffersListParams, pageInfo?: RequestPageInfo) {
    this.executeCancelToken();

    this.requestApiStore.setLoading(true);
    const [error, result] = await this.offersService.list(params, pageInfo, {
      cancelToken: this.cancelTokenSource?.token,
    });

    if (error && !this.errorIsCancel(error)) {
      this.requestApiStore.setError(true);
    }

    if (result) {
      this.state = {
        ...result,
        data: [...(this.state.data || []), ...(result.data || [])],
      };
    }

    this.requestApiStore.setLoading(false);
    return [error, result];
  }
}

export const offersStore = new OffersStore(offersService);

import React from 'react';
import { observer } from 'mobx-react';
import { useLocation, useRouteMatch } from 'react-router';
import { Text } from 'components/atoms//Text';
import { BaseLink } from 'components/atoms';

import useWindowSize from 'hooks/useWindowSize';
import styles from './styles.module.css';

export const ProfileMenuRender = () => {
  const { isExact } = useRouteMatch();
  const { pathname } = useLocation();
  const match = useRouteMatch<{ menuItem?: string }>('/profile/:menuItem');
  const menuItem = match?.params?.menuItem;
  const { width = 0 } = useWindowSize();

  const isMobile = width <= 768;

  if (menuItem === 'offers' && isMobile) {
    return null;
  }

  return (
    <aside className={styles.profileMenu}>
      <Text tagName="h2" className={styles.profileMenuHeader}>
        Личный кабинет
      </Text>
      <ul className={styles.profileMenuList}>
        <li>
          <BaseLink
            activeClassName={styles.activeMenu}
            className={styles.profileMenuLink}
            path="profile"
            variant="text"
            isActive={() => {
              if (isExact || pathname.includes('editor')) {
                return true;
              }

              return false;
            }}
            color="primary"
          >
            Профиль
          </BaseLink>
        </li>

        <li>
          <BaseLink
            className={styles.profileMenuLink}
            activeClassName={styles.activeMenu}
            exact
            path="favorites"
            variant="text"
            color="primary"
          >
            Избранное
          </BaseLink>
        </li>

        <li>
          <BaseLink
            className={styles.profileMenuLink}
            activeClassName={styles.activeMenu}
            path="offers"
            exact
            variant="text"
            color="primary"
          >
            Подборки
          </BaseLink>
        </li>

        <li>
          <BaseLink
            className={styles.profileMenuLink}
            activeClassName={styles.activeMenu}
            path="feeds"
            exact
            variant="text"
            color="primary"
          >
            Фиды
          </BaseLink>
        </li>
      </ul>
    </aside>
  );
};

export const ProfileMenu = observer(ProfileMenuRender);

import React from 'react';
import SimpleBar from 'simplebar-react';
import { TruncateHtml } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  name: string;
  description: string;
};

export const AlbumDescription = ({ description, name }: Props) => (
  <SimpleBar className={styles.swiperDescription}>
    <h3>{name}</h3>
    <TruncateHtml atOneLine text={description} />
  </SimpleBar>
);

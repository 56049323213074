import React, { ReactNode } from 'react';
import cc from 'classcat';
import { getIndentClasses, IndentsProps } from '../Indents';
import styles from './styles.module.css';

type Props = {
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  element?: any;
  reverse?: boolean;
  [k: string]: any;
} & IndentsProps;

export const IconWithText = ({
  children,
  icon,
  className,
  indent,
  element,
  reverse,
  ...rest
}: Props) =>
  React.createElement(
    element || 'div',
    {
      ...rest,
      className: cc([
        styles.iconWithTextWrapper,
        {
          [styles.iconWithTextWrapperReverse]: reverse,
        },
        className,
        getIndentClasses(indent),
      ]),
    },
    <>
      <div className={styles.iconWrapper}>{icon}</div>

      {children}
    </>,
  );

import { AxiosRequestConfig } from 'axios';
import { makeRequest, makeRequestWithAuth } from './makeRequest';
import { loggerService } from '../../loggerService';
import { AttemptResult } from '../../types';
import { isAxiosError } from './helpers';

export const attempt = async <RT extends {}>(
  config: AxiosRequestConfig,
): Promise<AttemptResult<RT>> => {
  try {
    const result = await makeRequest<RT>(config);

    return [null, result.data];
  } catch (e) {
    loggerService.error(e);
    return [e, null];
  }
};

export const attemptWithAuth = async <RT extends {}>(
  config: AxiosRequestConfig,
): Promise<AttemptResult<RT>> => {
  try {
    const result = await makeRequestWithAuth<RT>(config);

    if (isAxiosError(result)) {
      throw result;
    }

    return [null, result.data];
  } catch (e) {
    loggerService.error(e);
    return [e, null];
  }
};

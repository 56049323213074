import React, { ReactNode } from 'react';
import cc from 'classcat';
import { Text } from '../Text';
import styles from './styles.module.css';

type Props = {
  severity?: 'error' | 'primary';
  children?: ReactNode;
  isShow?: boolean;
  className?: string;
};

export const Alert = ({
  severity = 'error',
  children,
  isShow,
  className,
}: Props) =>
  isShow ? (
    <div
      className={cc([
        styles.wrapper,
        {
          [styles.error]: severity === 'error',
          [styles.primary]: severity === 'primary',
        },
        className,
      ])}
    >
      <Text tagName="div" align="center" size="L" lineHeight="XL">
        {children}
      </Text>
    </div>
  ) : null;

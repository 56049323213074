import React, { ReactNode, ChangeEvent } from 'react';
import cc from 'classcat';

import styles from './styles.module.css';

export type CheckboxProps = {
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  label?: ReactNode;
};

export const Checkbox = ({
  name,
  label,
  className,
  onChange,
  checked = false,
  disabled = false,
}: CheckboxProps) => {
  const classes = cc([
    styles.root,
    {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
    },
    className,
  ]);

  return (
    <label className={classes}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        name={name}
        onChange={onChange}
        className={styles.input}
      />
      <span className={styles.wrapper} />
      {label && <div className={styles.labelText}>{label}</div>}
    </label>
  );
};

import React from 'react';
import { pick } from 'lodash';
import { FieldProps } from 'formik/dist/Field';
import { Input } from '../Input';
import { FormValidationStatuses } from '../FormValidationStatuses';
import { FormFieldLabel } from '../FormFieldLabel';
import { DATA_TEST_ID } from '../../../../utils/test/dataTestIdToProps';

type Props<V> = FieldProps<V> & {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
};

export const FormikTextField = ({
  field,
  label,
  placeholder,
  disabled,
  className,
  ...rest
}: Props<any>) => (
  <FormValidationStatuses className={className} name={field.name}>
    <>
      {label && (
        <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
      )}
      <Input
        {...pick(rest, DATA_TEST_ID)}
        placeholder={placeholder}
        onBlur={field?.onBlur}
        onChange={field?.onChange}
        value={field?.value}
        name={field?.name}
        disabled={disabled}
      />
    </>
  </FormValidationStatuses>
);

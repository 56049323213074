import React from 'react';
import { flow, join, filter, includes } from 'lodash/fp';
import { Address, AddressScheme, joinTypeWithTitle } from 'services';
import { MayBe } from '../../../types';

type Props = {
  address?: MayBe<Address[]>;
  excludeAddressScheme?: AddressScheme[];
};

export const AddressFullView = ({ address, excludeAddressScheme }: Props) => {
  const filteredAddress = filter<Address>(
    ({ scheme }) => !includes(scheme, excludeAddressScheme),
  )(address);

  return (
    <>
      {flow<any, string[], string>(
        joinTypeWithTitle,
        join(', '),
      )(filteredAddress)}
    </>
  );
};

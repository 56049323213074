import React, { LegacyRef, memo, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { appRoutesService, Cell, Params } from '../../../../../../services';
import { GridCard } from '../GridCard';
import { useGridCard } from './hooks/useGridCard';
import styles from './styles.module.css';
import { replaceURIReservedSymbols } from '../../../../../../utils';

type Props = {
  matrixItem: Cell;
};

const GridCardLinkTestIDs = {
  link: 'GridCardLink_link',
};

export const GridCardLinkRender = ({ matrixItem }: Props) => {
  const { complexId, layoutTabs, buildingId } =
    useParams<Pick<Params, 'complexId' | 'layoutTabs' | 'buildingId'>>();

  const { apartment, isApartmentActive, cardRef, isFoundApartment } =
    useGridCard({ matrixItem });

  const linkTo = useMemo(
    () =>
      appRoutesService.replaceRoute('layoutActiveApartment', {
        apartmentId: apartment?.id?.toString() || '',
        complexId,
        layoutTabs,
        buildingId,
        /** @desc в номере квартиры могут быть зарезервированные символы */
        apartmentNumber: replaceURIReservedSymbols(
          matrixItem?.title?.toString() || '',
        ),
      }),
    [complexId, layoutTabs, buildingId, apartment?.id, matrixItem?.title],
  );

  return (
    <div
      className={cc([
        {
          [styles.loaderWrapper]: !apartment && isFoundApartment === undefined,
        },
        styles.gridCardLinkWrapper,
      ])}
      ref={cardRef as LegacyRef<HTMLDivElement>}
    >
      {!apartment && !isFoundApartment && (
        <div className={styles.gridCardLink}>
          <GridCard
            notPublished
            apartment={{
              number: matrixItem.title,
            }}
          />
        </div>
      )}
      {apartment && (
        <Link
          {...dataTestIdToProps(GridCardLinkTestIDs.link)}
          title={apartment?.id?.toString() || ''}
          className={styles.gridCardLink}
          to={linkTo}
        >
          <GridCard isActive={isApartmentActive} apartment={apartment} />
        </Link>
      )}
    </div>
  );
};
export const GridCardLink = memo(observer(GridCardLinkRender));

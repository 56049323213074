import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { findById } from 'utils/fp';
import { BuildingEntity, Complex, Params } from 'services';
import { useCreateBuildingsTabs } from './useCreateBuildingsTabs';
import {
  useChangeFilterByBuilding,
  UseChangeFilterByBuildingReturnType,
} from './useChangeFilterByBuilding';
import { TabItemProps } from '../../../components/containers/Tabs';

export type UseBuildingNavBarTabsReturnType = {
  buildingsTabs: TabItemProps<BuildingEntity>[];
  activeBuilding: number;
} & UseChangeFilterByBuildingReturnType;

export const useBuildingNavBarTabs = (
  complex: Complex | null,
): UseBuildingNavBarTabsReturnType => {
  const { buildingId } = useParams<Pick<Params, 'buildingId'>>();
  const changeFilter = useChangeFilterByBuilding();

  const buildingsTabs = useCreateBuildingsTabs({
    buildings: complex?.buildings,
  });

  const initActiveTab = useMemo(
    () => buildingsTabs.findIndex(findById(buildingId)),
    [buildingId, buildingsTabs],
  );

  return {
    ...changeFilter,
    buildingsTabs,
    activeBuilding: initActiveTab,
  };
};

import React, { MouseEvent, useEffect } from 'react';
import { useId } from 'react-id-generator';
import { observer } from 'mobx-react';

import { rootProfileStore } from 'stores/user/RootProfileStore';

import { EmptyData } from 'components/atoms/EmptyData';

import { ApartmentsCard } from './ApartmentsCard';
import styles from './styles.module.css';
import { dataTestIdToProps } from '../../../../../../utils/test/dataTestIdToProps';

export const ApartmentsListTestIDs = {
  wrapper: 'ApartmentsList_wrapper',
};

export const ApartmentsListRender = () => {
  const {
    state: { apartments },
    loadFavoritesApartments,
    setApartments,
  } = rootProfileStore.favoritesStore;
  const { favoritesIds } = rootProfileStore.favoritesComposeAuth;

  const idList: string[] = useId(apartments.length, 'apartments');

  const toggleApartmentFavorites = (id: number) => async (evt: MouseEvent) => {
    evt.preventDefault();
    const result = await rootProfileStore.favoritesComposeAuth.toggleApartment(
      id,
    );
    if (result) {
      if (!favoritesIds.apartmentIds.length) {
        setApartments([]);
        return;
      }
      void loadFavoritesApartments(favoritesIds.apartmentIds);
    }
  };

  useEffect(() => {
    if (!favoritesIds.apartmentIds.length) {
      setApartments([]);
      return;
    }
    void loadFavoritesApartments(favoritesIds.apartmentIds);
  }, []);

  return (
    <main
      {...dataTestIdToProps(ApartmentsListTestIDs.wrapper)}
      className={styles.main}
    >
      {!apartments.length && (
        <EmptyData
          title="В избранном пока нет квартир"
          description="Нажимайте на сердечко, чтобы объекты оказались на этой странице"
        />
      )}

      {apartments.length > 0 && (
        <ul className={styles.list}>
          {apartments.map((apartment, index) => (
            <li key={idList[index]} className={styles.listItem}>
              <ApartmentsCard
                apartment={apartment}
                toggleApartment={toggleApartmentFavorites}
              />
            </li>
          ))}
        </ul>
      )}
    </main>
  );
};

export const ApartmentsList = observer(ApartmentsListRender);

import React, {
  ChangeEvent,
  FocusEventHandler,
  useCallback,
  useMemo,
} from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  name?: string;
  type?: 'checkbox' | 'radio';

  className?: string;
};

export const Switch = ({
  checked,
  disabled,
  onChange,
  name,
  onFocus,
  onBlur,
  type = 'checkbox',
  className,
}: Props) => {
  const id = useMemo(() => `${Math.random()}-${name || ''}`, []);
  const _onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked, event);
    }
  }, []);
  return (
    <div className={cc([styles.wrapper, className])}>
      <input
        onChange={_onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        checked={checked}
        name={name}
        className={styles.input}
        type={type}
        id={id}
      />
      <label className={styles.label} htmlFor={id}>
        Toggle
      </label>
    </div>
  );
};

import { useModal } from 'react-modal-hook';
import ReactModal from 'react-modal';
import React, { ComponentProps, useMemo } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import styles from '../styles.module.css';
import { AllFiltersPopup } from '../AllFiltersPopup';
import { MobilePopup, Text } from '../../../atoms';
import { Icon } from '../../../atoms/Icon';
import arrowLeft from '../../../../assets/arrowLeftDark.svg';

export const useAllFiltersPopup = () => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (props: ComponentProps<typeof ReactModal>) => (
        <ReactModal
          {...props}
          isOpen
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          overlayClassName={styles.modalOverlay}
          className={styles.modal}
        />
      );
    }
    return (
      props: Pick<
        ComponentProps<typeof MobilePopup>,
        'children' | 'isOpen' | 'onClose'
      >,
    ) => (
      <MobilePopup
        IconBack={<Icon src={arrowLeft} />}
        headerChildren={
          <Text align="center" size="L" weight="700">
            Фильтр
          </Text>
        }
        {...props}
      />
    );
  }, [isLarge]);

  const [showAllFiltersPopup, hideAllFiltersPopup] = useModal(
    () => (
      <PopupWrapper
        isOpen
        onClose={hideAllFiltersPopup}
        onRequestClose={hideAllFiltersPopup}
      >
        <AllFiltersPopup onClose={hideAllFiltersPopup} />
      </PopupWrapper>
    ),
    [PopupWrapper],
  );

  return {
    showAllFiltersPopup,
    hideAllFiltersPopup,
  };
};

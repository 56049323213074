import { useEffect } from 'react';
import { useWillUnmount } from 'beautiful-react-hooks';
import { useParams } from 'react-router-dom';
import { appRoutesService, Params } from '../../../../services';
import { authRoutesStore } from '../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes';

/**
 * @desc запись в стор для вычисления маршрутизации для модуля авторизации корневого маршрута
 * */
export const useSetParentRouteToAuthRoutes = () => {
  const { complexId, buildingId, layoutTabs } =
    useParams<Pick<Params, 'complexId' | 'buildingId' | 'layoutTabs'>>();

  useEffect(() => {
    const url = appRoutesService.replaceRoute('layoutActiveTab', {
      layoutTabs,
      buildingId,
      complexId,
    });
    authRoutesStore.setParentRoute(url);
  }, [layoutTabs, buildingId, complexId]);

  useWillUnmount(() => {
    authRoutesStore.setParentRoute('');
  });
};

import React, { useMemo } from 'react';
import { flow, get } from 'lodash/fp';
import cc from 'classcat';

import { DictionaryView, NumberFormat, Text } from 'components/atoms';
import { ApartmentInOffer, DictionaryKeys } from 'services';

import styles from './styles.module.css';

type Props = {
  apartment: ApartmentInOffer;
};

export const ApartmentInfo = ({ apartment }: Props) => {
  const roomType = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('rooms'),
      )(apartment),
    [apartment],
  );

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('apartment_type_room'),
      )(apartment),
    [apartment],
  );

  const apartmentFinishing = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('residential_complex_finishing'),
      )(apartment),
    [apartment],
  );

  return (
    <div className={styles.wrapper}>
      <Text tagName="h3" className={styles.title}>
        О квартире
      </Text>

      <ul className={styles.flatsList}>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Тип помещения</Text>
          <Text className={styles.value} weight="bold">
            <DictionaryView
              dictKey="apartment_type_room"
              dictItemId={apartmentTypeRoomId}
            />
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Количество комнат</Text>
          <Text className={styles.value} weight="bold">
            <DictionaryView dictKey="rooms" dictItemId={roomType} />
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Отделка</Text>
          <Text className={styles.value} weight="bold">
            <DictionaryView
              dictKey="residential_complex_finishing"
              dictItemId={apartmentFinishing}
            />
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Этаж</Text>
          <Text className={styles.value} weight="bold">
            {apartment.floor}
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Пентхаус</Text>
          <Text className={styles.value} weight="bold">
            {apartment.is_penthouse ? 'Да' : 'Нет'}
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Количество лоджий</Text>
          <Text className={styles.value} weight="bold">
            {apartment.number_loggias ? apartment.number_loggias : '0'}
          </Text>
        </li>
        <li className={styles.listItem}>
          <Text className={styles.itemTitle}>Количество балконов</Text>
          <Text className={styles.value} weight="bold">
            {apartment.number_balconies ? apartment.number_balconies : '0'}
          </Text>
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Площадь общая</Text>
          <NumberFormat
            value={apartment?.square}
            className={styles.value}
            format="meter2"
          />
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Площадь жилая</Text>
          <NumberFormat
            value={apartment?.square_living}
            className={styles.value}
            format="meter2"
          />
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Площадь санузла</Text>
          <NumberFormat
            value={apartment?.square_bathroom}
            className={styles.value}
            format="meter2"
          />
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Площадь кухни</Text>
          <NumberFormat
            value={apartment?.square_kitchen}
            className={styles.value}
            format="meter2"
          />
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Объединенных санузлов</Text>
          <Text className={styles.value} weight="bold">
            {apartment.number_bathroom_merged
              ? apartment.number_bathroom_merged
              : '0'}
          </Text>
        </li>

        <li className={cc([styles.listItem, styles.secondColumn])}>
          <Text className={styles.itemTitle}>Разделенных санузлов</Text>
          <Text className={styles.value} weight="bold">
            {apartment.number_bathroom_unmerged
              ? apartment.number_bathroom_unmerged
              : '0'}
          </Text>
        </li>
      </ul>
    </div>
  );
};

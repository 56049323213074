import { get, has } from 'lodash/fp';
import { loggerService } from '../../services';

type State = Partial<Record<any, any>>;

type Key = string;

/**
 * @desc объект для хранения данных запросов. Структура хранения выглядит так:
 * {
 *   [имя коллекции/сущности]:{
 *     [ключ к экземпляру сущности, прим. id]
 *   }
 * }
 * */
export class RequestCacheStore {
  private state: State = {};

  clearCache() {
    this.state = {};
  }

  setCollection(collectionName: string) {
    this.state[collectionName] = {};
  }

  setObject(collectionName: string, key: Key, value: any) {
    if (!this.hasCollection(collectionName)) {
      this.setCollection(collectionName);
    }
    this.state[collectionName][key] = value;
  }

  hasObject(collectionName: string, key: Key) {
    return has([collectionName, key], this.state);
  }

  hasCollection(collectionName: string) {
    return has([collectionName], this.state);
  }

  getObject<T extends {} | []>(
    collectionName: string,
    key: Key,
  ): T | undefined {
    if (!this.hasCollection(collectionName)) {
      loggerService.debug(`collectionName "${collectionName}" not found`);
      return undefined;
    }
    return get([collectionName, key], this.state) as T | undefined;
  }
}

export const requestCacheStore = new RequestCacheStore();

import React, { useMemo } from 'react';
import expander from 'assets/expander.svg';
import { DateFormat, FormFieldLabel, Icon, Input } from 'components/atoms';
import { CopyLinkButton } from 'components/containers/CopyLink/components/CopyLinkButton';
import { API_ROUTES } from 'services/constants';
import { Feed } from 'services/v1/feed/types';
import { CopyLink } from 'components/containers/CopyLink';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import styles from './styles.module.css';

type Props = Feed;

export const FeedItem = ({ city, hash, updatedAt }: Props) => {
  const sharedLink = useMemo(
    () =>
      [
        process.env.REACT_APP_API_V1,
        API_ROUTES.feed.download.replace(':hash', hash),
      ].join(''),
    [],
  );

  return (
    <div>
      <CopyLink link={sharedLink}>
        {({ isAnimation, onClick }) => (
          <>
            <FormFieldLabel fieldName="">{city}</FormFieldLabel>
            <div className={styles.feedItemWrapper}>
              <div className={styles.feedItemFieldWrapper}>
                <Input
                  value={sharedLink}
                  onChange={() => {
                    //
                  }}
                />
                <CopyLinkButton isAnimation={isAnimation} onClick={onClick} />
                <ButtonBase
                  tagName="a"
                  download
                  href={sharedLink}
                  icon={<Icon src={expander} />}
                />
              </div>
              <div className={styles.feedItemUpdateAt}>
                Обновлено{' '}
                <DateFormat
                  value={updatedAt}
                  format="dateMonthYearShortInTime"
                />
              </div>
            </div>
          </>
        )}
      </CopyLink>
    </div>
  );
};

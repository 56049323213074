import React, { ComponentProps, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useIsEditOffer } from './hooks/useIsEditOffer';
import { offerItemStore } from '../../stores';
import { appRoutesService, Params } from '../../services';
import { OffersViewContainer } from './containers/OffersViewContainer';
import { useGetResponsiveModalComponent } from '../../hooks';
import { BasePopup } from '../../components/atoms';
import { rootProfileStore } from '../../stores/user/RootProfileStore';

export const OffersViewPagePopupRender = () => {
  // <editor-fold desc="избавится от дублирования">
  const location = useLocation();
  const { push } = useHistory();
  const { isEditOffer, isOfferPreview } = useIsEditOffer({
    authStore: rootProfileStore.authStore,
  });
  const { offerId } = useParams<Pick<Params, 'offerId' | 'offerTabs'>>();

  const { offer } = offerItemStore;

  useEffect(() => {
    if (!offerId) {
      return undefined;
    }
    offerItemStore.currentOfferId = parseInt(offerId, 10);
    void offerItemStore.fetchOffer({
      offerId: offerItemStore.currentOfferId,
    });

    return () => {
      offerItemStore.currentOfferId = null;
    };
  }, [offerId, location]);
  // </editor-fold>

  const { PopupWrapper } = useGetResponsiveModalComponent<
    ComponentProps<typeof BasePopup>
  >({
    mobileHeaderChildrenTitle: offer?.title,
    DesktopModalWrapper: (props) => <BasePopup {...props} isOpen size="XL" />,
  });

  return (
    <PopupWrapper
      onClose={() => push(appRoutesService.getRoute('offers'))}
      isOpen
    >
      {offer && (
        <OffersViewContainer
          offer={offer}
          isOfferPreview={isOfferPreview}
          isEditOffer={isEditOffer}
        />
      )}
    </PopupWrapper>
  );
};

export const OffersViewPagePopup = observer(OffersViewPagePopupRender);

import React from 'react';
import { ComplexAlbum } from 'services';
import { getConstructionProgressAlbums } from 'services/v1/rc/album/helpers';
import { ID, MayBe } from 'types';
import { LocationDescriptorObject } from 'history';
import { AlbumsWithBlock } from '../AlbumsWithBlock';

type Props = {
  albums: Array<ComplexAlbum>;
  complexId?: MayBe<ID>;
  className?: string;
  innerRef?: React.RefObject<HTMLDivElement>;
  to?: LocationDescriptorObject<any>;
};

export const ConstructionProgressContainers = ({
  albums,
  complexId,
  className,
  innerRef,
  to,
}: Props) => {
  const constructionsAlbums: ComplexAlbum[] =
    getConstructionProgressAlbums(albums);

  return (
    <>
      {constructionsAlbums.length > 0 && complexId && (
        <AlbumsWithBlock
          to={to}
          innerRef={innerRef}
          className={className}
          title="Ход строительства"
          albums={constructionsAlbums}
          complexId={complexId}
        />
      )}
    </>
  );
};

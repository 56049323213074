import {
  flow,
  entries,
  map,
  reduce,
  filter,
  isEmpty,
  negate,
  isArray,
  isNumber,
} from 'lodash/fp';
import { Offers } from '../../../../../services';
import { OffersEditorFormValues } from '../types';
import { MayBe } from '../../../../../types';

export const formValuesToOffer = flow(
  entries,
  /** @desc удалить все пустые поля из результирующего объекта,
   * поля с массивами всегда содержат по умолчанию 1 пустой элемент
   * */
  filter(([key, value]) => {
    if (isNumber(value)) {
      return true;
    }
    if (isArray(value)) {
      value = filter(negate(isEmpty))(value);
    }
    return !isEmpty(value);
  }),
  map(([key, value]) => ({
    [key]: value,
  })),
  reduce((obj, acc) => ({ ...acc, ...obj }), {}),
);

export const offerToFormValues = (
  offer: MayBe<Offers>,
): OffersEditorFormValues => ({
  offerId: offer?.id,
  title: offer?.title,
  customer: offer?.customer,
  description: offer?.description,
  emails: offer?.emails?.length ? offer.emails : [''],
  phones: offer?.phones?.length ? offer.phones : [''],
});

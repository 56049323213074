import React, { useMemo } from 'react';
import { MayBe } from 'types';
import { toJS } from 'mobx';
import { isNotNil } from 'utils/fp';
import { Address, equalAddressBySchema, getStreetAndHome } from 'services';
import { flow, get, filter, negate, join, map } from 'lodash/fp';
import styles from './styles.module.css';

type Props = {
  addressDetail?: MayBe<Address[]>;
};

export const ComplexCardAddress = ({ addressDetail }: Props) => {
  const street = useMemo(
    () => getStreetAndHome(addressDetail),
    [addressDetail],
  );

  const address = useMemo(
    () =>
      flow(
        filter(negate(equalAddressBySchema('street'))),
        filter(negate(equalAddressBySchema('house'))),
        map(get('title')),
        filter(isNotNil),
        join(' '),
      )(toJS(addressDetail)),
    [addressDetail],
  );

  return (
    <div className={styles.complexCardAddressWrapper}>
      {street && <div className={styles.complexCardAddressShort}>{street}</div>}
      {address && (
        <div className={styles.complexCardAddressFull}>{address}</div>
      )}
    </div>
  );
};

import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { Block } from 'pages/ComplexPage/components/Block';

import { Stock } from 'services';
import { PromotionDesktopList } from './components/PromotionDesktopList';
import { PromotionMobileList } from './components/PromotionMobileList';
import styles from './styles.module.css';

type Props = {
  list: Stock[];
};

export const PromotionsSection = ({ list }: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  return (
    <Block className={styles.wrapperBlock} title="Актуальные акции">
      {isLarge && <PromotionDesktopList list={list} />}
      {!isLarge && <PromotionMobileList list={list} />}
    </Block>
  );
};

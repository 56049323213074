import React, { CSSProperties } from 'react';
import cc from 'classcat';
import { IndentsProps, getIndentClasses } from '../Indents';
import styles from './styles.module.css';

type Props = {
  size?: 10 | 16 | 20 | 24 | 32 | 120;
  src?: string;
  alt?: string;
  className?: string;
  style?: CSSProperties;
} & IndentsProps;

export const Icon = ({ className, indent, alt, size = 16, ...rest }: Props) => (
  <img
    className={cc([
      styles.icon,
      className,
      getIndentClasses(indent),
      {
        [styles.icon_size_10]: size === 10,
        [styles.icon_size_16]: size === 16,
        [styles.icon_size_20]: size === 20,
        [styles.icon_size_24]: size === 24,
        [styles.icon_size_32]: size === 32,
        [styles.icon_size_120]: size === 120,
      },
    ])}
    alt={alt}
    {...rest}
  />
);

import { createElement, ComponentType, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES_MODAL, appModalRoutesService } from 'services';
import { searchParamsToObject } from 'utils/queryString';

type Props = {
  path: keyof typeof APP_ROUTES_MODAL;
  component?: ComponentType<any>;
};

export const ModalRoute = ({ path, component }: Props) => {
  const [state, setState] = useState(false);
  const { search } = useLocation();

  const isActive = appModalRoutesService.modalIsOpen(path, search);

  useEffect(() => {
    setState(!state);
  }, [search]);

  if (isActive) {
    if (!component) {
      // eslint-disable-next-line no-console
      console.error(
        `[ERROR]: component is undefined for path ${path}`,
        component,
      );
      return null;
    }
    const searchObject = searchParamsToObject(search);
    const location = appModalRoutesService.getLocationForModal(
      path,
      searchObject,
    );
    return createElement(component, { location });
  }

  return null;
};

import React from 'react';

import { FirstColumnFilter } from '../Search/components/FirstColumnFilters';
import { SecondColumnFilter } from '../Search/components/SecondColumnFilters';
import { ThirdColumnFilters } from '../Search/components/ThirdColumnFilters';

import styles from './AllFilters.module.css';

export const FirstTab: React.FC = () => (
  <>
    <div className={styles.formColumn}>
      <FirstColumnFilter />
    </div>

    <div className={styles.formColumn}>
      <SecondColumnFilter />
    </div>

    <div className={styles.formColumn}>
      <ThirdColumnFilters />
    </div>
  </>
);

import React, { useState, VFC, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { size } from 'lodash/fp';
import { Collapse } from 'react-collapse';

import {
  getFirstBuildingId,
  Params,
  Complex,
  ComplexRaw,
  StatsGroupsItem,
} from 'services';
import { observer } from 'mobx-react';
import { pluralize } from 'utils/pluralize';
import { Box } from 'components/styledComponents/Box';
import arrowDownIcon from 'assets/arrowDownIcon.svg';
import { viewContext } from 'context/ViewProvider';
import { infoStore } from 'stores/InfoStore';
import { Block } from '../../components/Block';
import { ApartmentQueuesFilter } from './components/ApartmentQueuesFilter';
import { QueueBuildingsFilter } from './components/QueueBuildingsFilter';
import styles from './styles.module.css';
import { buildingGridStore } from '../../../LayoutPage/stores/buildingGridStore';
import { VillageQueuesFilter } from './components/VillageQueuesFilter';
import * as S from './QueuesBlock.styled';

type CollapseBoxProps = {
  name: string;
  isCollapsed: boolean;
  data: StatsGroupsItem;
  complexId?: string;
};

const CollapseBox: React.FC<CollapseBoxProps> = ({
  data,
  name,
  isCollapsed,
}) => {
  const [collapsed, toggleCollapsed] = useState(isCollapsed);
  const { villageData } = useContext(viewContext);

  const handleClick = () => toggleCollapsed((prevState) => !prevState);

  const { squareMin, sumMax, sumMin } = data;

  const numberFormatter = (num: number, minVal: number, letter: string) =>
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    Math.sign(num) * ((Math.floor(num) / minVal).toFixed(1) as never) + letter;

  const getOutput = (num: number) => {
    if (!num) {
      return 0;
    }

    if (num >= 1000000) {
      return numberFormatter(num, 1.0e6, ' млн');
    }

    if (num >= 10000) {
      return numberFormatter(num, 1.0e3, ' тыс');
    }

    return num.toLocaleString();
  };

  const sData = villageData[name];

  const filteredMin = (val: string) =>
    sData?.data?.reduce((prev: any, current: any) =>
      prev[val] < current[val] ? prev : current,
    )[val];

  const filteredMax = (val: string) =>
    sData?.data?.reduce((prev: any, current: any) =>
      prev[val] < current[val] ? prev : current,
    )[val];

  const dicts = (dict: string) =>
    (dict && infoStore.getInfoBy(dict as any)?.items) || [];

  const getDictValue = (id: number, dict: any) =>
    dicts(dict).find((e) => id === e.id)?.value;

  return sData?.data.length ? (
    <S.CollapseWrap>
      <S.CollapseHeader onClick={handleClick}>
        <S.Title>{villagesObjects[name]}</S.Title>
        <S.Info>от {Math.round(filteredMin('square')) || squareMin} м2</S.Info>
        <S.Info>
          {filteredMin('sumMin') ||
            (sumMin && `от ${getOutput(Number(sumMin))}`)}{' '}
          {filteredMax('sumMax') ||
            (sumMax && `до ${getOutput(Number(sumMax))}`)}
        </S.Info>
        <S.Counter>
          {pluralize(sData?.data.length, ['объект', 'объекта', 'объектов'])}

          {!collapsed ? (
            <img src={arrowDownIcon} alt="" className={styles.iconUp} />
          ) : (
            <img src={arrowDownIcon} alt="" className={styles.iconDown} />
          )}
        </S.Counter>
      </S.CollapseHeader>

      <Collapse isOpened={collapsed}>
        <Box display="flex" flexDirection="column" py="20px">
          {sData?.data.map((el: any, key: number) => (
            <>
              <S.TableRow>
                <S.TableCol>
                  <S.TItemImg>
                    {!!el.photos?.[0]?.logo && (
                      <img
                        className={styles.image}
                        src={el.photos?.[0].logo}
                        alt=""
                        width="134"
                        height="134"
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    )}
                  </S.TItemImg>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Кол-во этажей:</S.TItemFirst>
                  <S.TItemSecond>{el.number_floors}</S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Количество спален:</S.TItemFirst>
                  <S.TItemSecond>{el.number_bedrooms}</S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Площадь участка:</S.TItemFirst>
                  <S.TItemSecond> {el.square_land}</S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Площадь дома:</S.TItemFirst>
                  <S.TItemSecond>{el.square}</S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Отделка: </S.TItemFirst>
                  <S.TItemSecond>
                    {getDictValue(
                      el?.catalogs?.residential_complex_finishing?.[0],
                      'residential_complex_finishing',
                    )}
                  </S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.TItemFirst>Материал: </S.TItemFirst>
                  <S.TItemSecond>
                    {getDictValue(
                      el?.catalogs?.village_material?.[0],
                      'village_material',
                    )}
                  </S.TItemSecond>
                </S.TableCol>
                <S.TableCol>
                  <S.Price>{Number(el.price).toLocaleString()} ₽</S.Price>
                  <S.PriceM>
                    {Number(el.price_m2).toLocaleString()} ₽/ м²
                  </S.PriceM>
                </S.TableCol>
              </S.TableRow>
              {key !== sData?.data.length - 1 && <S.BorderRow />}
            </>
          ))}
        </Box>
      </Collapse>
    </S.CollapseWrap>
  ) : null;
};

type VillagesObjects = { [key: string]: string };

const villagesObjects: VillagesObjects = {
  finished: 'Готовые дома',
  building: 'Идет строительство',
  template: 'Типовые проекты',
};

type Props = {
  apartmentsCount: number;
  isVillage: boolean;
  statsGroups: ComplexRaw['statsGroups'];
} & Pick<Complex, 'buildings'>;

export const QueuesBlockRender: VFC<Props> = ({
  apartmentsCount,
  buildings,
  isVillage,
  statsGroups,
}): JSX.Element => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  const currentBuilding = buildingGridStore.gridBordObject?.id
    ? buildingGridStore.gridBordObject?.id.toString()
    : getFirstBuildingId(buildings)?.toString();

  const title = isVillage
    ? `${pluralize(apartmentsCount, [
        'объект',
        'объекта',
        'объектов',
      ])} в продаже`
    : `${pluralize(apartmentsCount, [
        'квартира',
        'квартиры',
        'квартир',
      ])} в продаже в ${pluralize(size(buildings), [
        'корпусе',
        'корпусах',
        'корпусов',
      ])}`;

  return (
    <Block title={title} className={styles.queuesWrapper}>
      {isVillage ? (
        <>
          <VillageQueuesFilter
            buildingId={currentBuilding}
            complexId={complexId}
            isVillage={isVillage}
          />
        </>
      ) : (
        <>
          <QueueBuildingsFilter
            className={styles.wrapperQueueBuildingsFilter}
            buildings={buildings}
          />

          <ApartmentQueuesFilter
            buildingId={currentBuilding}
            complexId={complexId}
          />
        </>
      )}

      {isVillage && statsGroups && (
        <Box mt="20px">
          {Object.keys(statsGroups).map((name: string, idx: number) => (
            <CollapseBox
              key={name}
              name={name}
              isCollapsed={false}
              data={statsGroups[name]}
              complexId={complexId}
            />
          ))}
        </Box>
      )}
    </Block>
  );
};

export const QueuesBlock = observer(QueuesBlockRender);

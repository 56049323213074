import { useEffect, useState } from 'react';
import { complexItemStore } from 'stores/ComplexItemStore/store';
import { requestCacheStore } from 'stores';
import { Complex } from 'services';
import { useBuildingsOnMapPopup } from '../../containers/BuildingsOnMap';

type Props = {
  complexId?: string;
};

export const useComplexOnFullscreenMap = ({ complexId }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [currentComplex, setCurrentTarget] = useState<Complex | undefined>(
    undefined,
  );

  const getCurrentComplexFromCache = (): Complex | undefined =>
    requestCacheStore.getObject<Complex>('complex', complexId || '');

  useEffect(() => {
    setCurrentTarget(getCurrentComplexFromCache());
  }, []);

  const { showBuildingsOnMapPopup } = useBuildingsOnMapPopup({
    buildings: currentComplex?.buildings,
  });

  const onOpenMap = async () => {
    if (!complexId || complexItemStore.requestApi.isLoading) {
      return;
    }

    if (!requestCacheStore.hasObject('complex', complexId)) {
      setLoading(true);
      await complexItemStore.fetchComplex(Number(complexId));
      setLoading(false);
      if (!complexItemStore.requestApi.isError) {
        requestCacheStore.setObject(
          'complex',
          complexId,
          complexItemStore.target,
        );
      }
    }

    setCurrentTarget(getCurrentComplexFromCache());

    showBuildingsOnMapPopup();
  };

  return {
    onOpenMap,
    isLoading,
    showBuildingsOnMapPopup,
  };
};

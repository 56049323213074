import { createContext } from 'react';
import { TabProps } from '../hooks';

export const TabsContext = createContext<TabProps<any>>({
  changeTab: () => () => null,
  setTabList: () => null,
  currentActiveTab: 0,
  tabList: [],
});

export const TabsProvider = TabsContext.Provider;

export const TabsConsumer = TabsContext.Consumer;

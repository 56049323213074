import qs from 'qs';
import { ThrowableResult } from '../../types';
import { makeRequest } from '../../libs/axios/makeRequest';
import { API_ROUTES } from '../../constants';
import { loggerService } from '../../loggerService';
import { Agency } from './types';

export const agenciesService = {
  async getAgencies(search?: string): Promise<ThrowableResult<Agency[]>> {
    try {
      const { data } = await makeRequest<Record<string, Agency>>({
        url: API_ROUTES.agencies,
        method: 'get',
        params: {
          search,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });

      return Object.entries(data).map(([_, item]) => item);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  async getAgencyById(agencyId: string): Promise<ThrowableResult<Agency>> {
    try {
      const { data } = await makeRequest<Agency>({
        url: API_ROUTES.agency.replace(':agencyId', agencyId.toString()),
        method: 'get',
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};

import { action, makeObservable, observable } from 'mobx';
import { RequestApiStore } from '../request/RequestApiStore';
import { Stock, stockService } from '../../services';

export class PromotionStore {
  promotion: Stock = {};

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      promotion: observable,
      clear: action.bound,
      loadPromotion: action.bound,
      setPromotion: action.bound,
    });
  }

  clear() {
    this.promotion = {};
    this.requestApi.clear();
  }

  setPromotion(stock: Stock) {
    this.promotion = stock;
  }

  async loadPromotion(stockId: string) {
    this.requestApi.setLoading(true);
    this.requestApi.setError(false);

    try {
      const result = await stockService.getStockById(stockId);

      this.setPromotion(result);
    } catch (e) {
      this.requestApi.setError(true);
    }
    this.requestApi.setLoading(false);
  }
}

export const promotionStore = new PromotionStore();

import React from 'react';
import { observer } from 'mobx-react';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { changeEmailStore } from 'pages/ProfilePage/stores/ChangeEmailStore';
import { useHistory } from 'react-router-dom';
import { appRoutesService, authEmailService } from 'services';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { AuthPopup } from '../../../../../AuthPage/components/AuthPopup';
import styles from './styles.module.css';

export const NewEmailLinkIsOutOfDatePopupRender = () => {
  const { push } = useHistory();

  const oldEmailConfirm = async () => {
    try {
      void (await authEmailService.code({
        email: rootProfileStore.authUserStore.user?.email,
      }));
      push(appRoutesService.getRoute('profileConfirmOldEmail'));
    } catch (e) {
      // todo
    }
  };
  const newEmailConfirm = async () => {
    if (!changeEmailStore.state.newEmail) {
      return;
    }
    try {
      void (await authEmailService.change({
        email: changeEmailStore.state.newEmail,
      }));
      push(appRoutesService.getRoute('profileConfirmNewEmail'));
    } catch (e) {
      // todo
    }
  };

  const retryConfirm = () => {
    if (changeEmailStore.state.isChange) {
      if (!changeEmailStore.state.oldEmailIsConfirm) {
        void oldEmailConfirm();
        return;
      }
      if (!changeEmailStore.state.newEmailIsConfirm) {
        void newEmailConfirm();
      }
    }
  };

  return (
    <AuthPopup>
      <div className={styles.wrapper}>
        <div className={styles.title}>Ссылка устарела</div>
        <div className={styles.subTitle}>
          Запросите новую ссылку, или отмените <br />
          процесс смены почты
        </div>
        <div className={styles.buttonGroup}>
          <ButtonBase color="primary" onClick={retryConfirm}>
            Выслать заново
          </ButtonBase>
          <ButtonBase onClick={changeEmailStore.clear}>
            Отменить процесс смены почты
          </ButtonBase>
        </div>
      </div>
    </AuthPopup>
  );
};

export const NewEmailLinkIsOutOfDatePopup = observer(
  NewEmailLinkIsOutOfDatePopupRender,
);

import React from 'react';
import salesDepartmentPreview from 'assets/images/salesDepartmentPreview.jpg';
import { AuthForGetAccessToFeature } from 'pages/AuthPage/pages/AuthForGetAccessToFeature';
import styles from './styles.module.css';

export const AuthForGetAccessToSalesDepartments = () => (
  <AuthForGetAccessToFeature
    title="Просмотр контактов доступен только зарегистрированным пользователям"
    subTitle="Зарегистрируйтесь, это бесплатно"
    imagePreview={salesDepartmentPreview}
    imageWrapperClassName={styles.imageWrapper}
  />
);

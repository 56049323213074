import React from 'react';
import { observer } from 'mobx-react';

import { FavoritesNavButton } from 'components/containers/FavoritesNavButton';
import { OffersNavButton } from 'components/containers/OffersNavButton';

import { ProfileButtonAndLoginDesktop } from '../../../../ProfileButtonAndLogin';
import styles from './styles.module.css';

export const NavbarRightContentRenderer = () => (
  <div className={styles.wrapper}>
    <FavoritesNavButton />

    <OffersNavButton />

    <ProfileButtonAndLoginDesktop />
  </div>
);

export const NavbarRightContent = observer(NavbarRightContentRenderer);

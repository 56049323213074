import React, { ReactElement } from 'react';
import cc from 'classcat';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';

import { useRemoveApartmentFromOffer } from 'pages/OfferApartmentPage/hooks/useRemoveApartmentFromOffer';
import { useApartmentHrefToClipboard, useUserIsOwnersOfferItem } from 'hooks';

import { ApartmentInOffer, OffersViewItem } from 'services';
import { MayBe } from 'types';

import styles from './styles.module.css';
import { CopyLinkButton } from '../../../../components/containers/CopyLink/components/CopyLinkButton';
import { CopyLink } from '../../../../components/containers/CopyLink';
import { offerItemStore } from '../../../../stores';

type Props = {
  offer: MayBe<OffersViewItem>;
  apartment: ApartmentInOffer;
  className?: string;
  children?: ReactElement;
  //дизейблим всплывашку с доп.действиями, если находимся в iframe
  isDisableActions?: boolean;
};

export const ApartmentsCardActionsBar = ({
  offer,
  apartment,
  className,
  children,
  isDisableActions,
}: Props) => {
  const { userIsOwner } = useUserIsOwnersOfferItem({ offer });

  const { removeApartment } = useRemoveApartmentFromOffer(
    apartment?.id,
    offer?.id,
    offerItemStore.reFetch,
  );
  const { getLinkToApartment } = useApartmentHrefToClipboard(
    offer?.id,
    offer?.hash,
    apartment?.id,
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.childrenWrapper}>{children}</div>

      {!isDisableActions && <div className={cc([styles.actionsBarWrapper, className])}>
        <CopyLink
          defaultLabel="Поделится"
          successLabel="Поделится"
          link={getLinkToApartment()}
        >
          {(props) => <CopyLinkButton variant="text" {...props} />}
        </CopyLink>

        {userIsOwner && (
          <ButtonBase
            className={styles.apartmentLink}
            variant="text"
            color="alert"
            onClick={removeApartment}
          >
            Удалить
          </ButtonBase>
        )}
      </div> }
    </div>
  );
};

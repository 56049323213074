import React, { useCallback, useState } from 'react';
import { appRoutesService, Stock } from 'services';
import cc from 'classcat';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';
import { PromotionCard } from '../PromotionCard';
import { ShowAllButton } from '../../../../../../components/atoms/ShowAllButton';

type Props = {
  list: Stock[];
};

export const PromotionDesktopList = ({ list }: Props) => {
  const [openList, setOpenList] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpenList(!openList);
  }, [openList]);

  return (
    <>
      <div
        className={cc([
          styles.wrapper,
          {
            [styles.open]: openList,
          },
        ])}
      >
        {list.map((stock) => (
          <Link
            className={styles.link}
            to={appRoutesService.replaceRoute('stock', {
              complexId: stock?.residential_complex_id?.toString(),
              stockId: stock?.id?.toString(),
            })}
          >
            <PromotionCard key={stock.id} {...stock} />
          </Link>
        ))}
      </div>
      {list.length > 2 && (
        <ShowAllButton onClick={toggleOpen} isVisible={openList} />
      )}
    </>
  );
};

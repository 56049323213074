import React, { useCallback, useMemo, useState } from 'react';
import { flow } from 'lodash/fp';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { Popover } from 'react-tiny-popover';
import { PopoverContent, PopoverItem } from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { preventDefault } from 'utils/fp/preventDefault';
import { Icon } from 'components/atoms/Icon';
import dropdownOpen from 'assets/dropdownOpen.svg';
import { useHistory } from 'react-router-dom';
import { offersEditStore, offersStore } from 'stores';
import { appModalRoutesService, Offers } from 'services';
import { ID } from 'types';
import { useOfferStatus } from '../../hooks/useOfferStatus';
import styles from './styles.module.css';

type Props = Pick<Offers, 'id' | 'status_id' | 'is_favorite' | 'title'>;

export const OffersCardPopoverRender = ({
  id,
  status_id,
  is_favorite,
  title,
}: Props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { push } = useHistory();

  const onEdit = useCallback((id: ID) => {
    push(
      appModalRoutesService.replaceModalType('offersEdit', {
        offerId: id.toString(),
      }),
    );
  }, []);

  const { statusIsActive, statusIsDelete, statusIsFinish } =
    useOfferStatus(status_id);

  const reloadingOffers = useCallback(() => {
    offersStore.pageInfo.resetPageInfo();
    offersStore.clearState();
    offersStore.fetch(
      offersStore.offersFilterStore.params,
      offersStore.pageInfo.pageInfo,
    );
  }, []);

  const onChangeStatus = useCallback(
    (request: any) => async () => {
      setMenuIsOpen(false);
      const [, result] = await request({
        offerId: id,
      });
      if (result?.status) {
        reloadingOffers();
      }
    },
    [id],
  );

  const toggleMenu = () => setMenuIsOpen(!menuIsOpen);

  const popoverContent = useMemo(
    () => (
      <PopoverContent>
        <PopoverItem
          onClick={(e) => {
            e.stopPropagation();
            onEdit(id);
            setMenuIsOpen(false);
          }}
        >
          Редактировать
        </PopoverItem>
        <PopoverItem
          isActive={is_favorite}
          onClick={(e) => {
            e.stopPropagation();
            onChangeStatus(offersEditStore.addToFavorites)();
          }}
        >
          В избранное
        </PopoverItem>

        {statusIsFinish && (
          <PopoverItem
            onClick={(e) => {
              e.stopPropagation();
              onChangeStatus(offersEditStore.setActiveStatus)();
            }}
          >
            В работу
          </PopoverItem>
        )}

        {statusIsActive && (
          <PopoverItem
            onClick={(e) => {
              e.stopPropagation();
              onChangeStatus(offersEditStore.setFinishStatus)();
            }}
          >
            Закрыть
          </PopoverItem>
        )}

        {!statusIsDelete && (
          <PopoverItem
            onClick={(e) => {
              e.stopPropagation();
              onChangeStatus(offersEditStore.delete)();
            }}
            color="alert"
          >
            Удалить
          </PopoverItem>
        )}
      </PopoverContent>
    ),
    [is_favorite, statusIsFinish, statusIsActive, statusIsDelete],
  );

  return (
    <Popover
      content={popoverContent}
      onClickOutside={toggleMenu}
      positions={['bottom', 'right']}
      isOpen={menuIsOpen}
    >
      <span>
        <ButtonBase
          onClick={flow(preventDefault, toggleMenu)}
          icon={
            <Icon
              className={cc({
                [styles.offersCardInfoTitleIconOpen]: menuIsOpen,
              })}
              src={dropdownOpen}
              size={10}
            />
          }
          iconRight
          size="small"
          color="transparent"
          className={styles.offersCardInfoTitle}
        >
          {title}
        </ButtonBase>
      </span>
    </Popover>
  );
};

export const OffersCardPopover = observer(OffersCardPopoverRender);

import React, { useEffect, useMemo } from 'react';
import { useMediaQuery, useWillUnmount } from 'beautiful-react-hooks';
import { observer } from 'mobx-react';
import { ID } from 'types';
import { Drawer } from 'components/atoms/Popup/Drawer';
import { MobileDrawer } from 'components/atoms/Popup/MobileDrawer';
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { useHistory } from 'react-router-dom';
import { appModalRoutesService } from 'services';
import { AddToOffersHeader } from '../../components/AddToOffersHeader';
import { AddToOffersHeaderMobile } from '../../components/AddToOffersHeaderMobile';

import { AddToOffersList } from '../../components/AddToOffersList';
import { useLoadOffers } from '../../hooks/useLoadOffers';
import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  apartmentId?: ID;
};

export const AddToOffersFormRender = ({
  isOpen,
  onClose,
  apartmentId,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 768px)');
  const { push } = useHistory();

  const Popup = useMemo(() => (isLarge ? Drawer : MobileDrawer), [isLarge]);

  useLoadOffers(apartmentId);

  useWillUnmount(addToOffersStore.clear);

  useEffect(() => {
    if (!apartmentId) {
      return;
    }

    addToOffersStore.targetApartment = apartmentId;
  }, [apartmentId]);

  const onCreate = () => {
    push(appModalRoutesService.pushNewModalType('offersCreate'));
  };

  return (
    <Popup
      isHeaderSticky
      position="left"
      onClose={onClose}
      headerChildren={<AddToOffersHeaderMobile onCreateNewOffer={onCreate} />}
      isOpen={isOpen}
    >
      <div className={styles.addToOffersFormWrapper}>
        <AddToOffersHeader onCreateNewOffer={onCreate} />
        <AddToOffersList offers={addToOffersStore.offers} />
      </div>
    </Popup>
  );
};

export const AddToOffersForm = observer(AddToOffersFormRender);

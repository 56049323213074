import * as Cookies from 'js-cookie';
import qs from 'qs';
import { MayBe } from '../../../types';

export const AUTH_TOKEN_NAME = 'X-AUTH-TOKEN';

export type AuthTokenObject = {
  token: MayBe<string>;
  expiresAt: MayBe<string>;
};

export const authTokenStorageService = {
  /**
   * @param token
   * @param expiresAt - ISODate
   * */
  setToken: (token: MayBe<string>, expiresAt: string) => {
    if (token) {
      Cookies.set(
        AUTH_TOKEN_NAME,
        qs.stringify({
          token,
          expiresAt,
        }),
        {
          expires: new Date(expiresAt),
          secure: true,
        },
      );
    } else {
      Cookies.remove(AUTH_TOKEN_NAME);
    }
  },
  getToken: (): MayBe<AuthTokenObject> => {
    const state = Cookies.get(AUTH_TOKEN_NAME) as MayBe<string>;
    if (state) {
      const stateObject = qs.parse(state) as AuthTokenObject;
      if (stateObject) {
        return stateObject;
      }
    }
    return null;
  },

  remove: () => {
    Cookies.remove(AUTH_TOKEN_NAME);
  },
};

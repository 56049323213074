import React, { useMemo } from 'react';
import { FieldProps } from 'formik/dist/Field';
import MaskedInput from 'react-text-mask';
import styles from './styles.module.css';
import { FormValidationStatuses } from '../FormValidationStatuses';
import { FormFieldLabel } from '../FormFieldLabel';

const masksInputPatterns = {
  mobile: {
    placeholder: '+7 (   )    -   -   ',
    pattern: [
      '+',
      /[1-9]/,
      ' ',
      '(',
      /\d/,
      /\d/,
      /\d/,
      ')',
      ' ',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ],
  },
};

type Props<V> = FieldProps<V> & {
  label?: string;
  placeholder?: string;
  pattern?: keyof typeof masksInputPatterns;
  className?: string;
  disabled?: boolean;
};

export const FormikMaskedInput = ({
  field,
  label,
  pattern = 'mobile',
  className,
  disabled,
}: Props<any>) => {
  const patternProps = useMemo(() => masksInputPatterns[pattern], [pattern]);
  return (
    <FormValidationStatuses className={className} name={field.name}>
      <div>
        {label && (
          <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
        )}
        <MaskedInput
          disabled={disabled}
          className={styles.maskedInput}
          {...field}
          guide
          mask={patternProps.pattern}
          placeholder={patternProps.placeholder}
          id={field?.name}
          type="text"
        />
      </div>
    </FormValidationStatuses>
  );
};

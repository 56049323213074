import React, { useMemo } from 'react';
import { Formik, Form } from 'formik';
import { toJS } from 'mobx';
import { flow, filter, map } from 'lodash/fp';

import { cityStore } from 'stores';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import { observer } from 'mobx-react';
import { Route } from 'react-router';
import { AlertSubmission } from 'components/atoms/AlertSubmission';

import { userProfileFormSchema } from 'validationSchemas';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { appRoutesService } from 'services';
import { USER_ROLES } from 'services/constants';
import { isNotNil } from 'utils/fp';
import { MayBe } from 'types';
import { UserFormRender } from './components/UserFormRender';
import { cityInfoToCitySelectOption, userToUserProfileForm } from './utils';
import { ProfileConfirmOldPhonePopup } from '../ProfileConfirmOldPhonePopup';
import { ProfileConfirmNewPhonePopup } from '../ProfileConfirmNewPhonePopup';
import { ProfileNewPhonePopup } from '../ProfileNewPhonePopup';
import { useOnSubmit } from './hook/useOnSubmit';
import { changeEmailStore } from '../../stores/ChangeEmailStore';
import { NewEmailPopup } from './pages/NewEmailPopup';
import { OldEmailConfirmPopup } from './pages/OldEmailConfirmPopup';
import { NewEmailLinkIsOutOfDatePopup } from './pages/NewEmailLinkIsOutOfDatePopup';
import { UserProfileForm } from './types';
import { NewEmailConfirmPopup } from './pages/NewEmailConfirmPopup';
import styles from './styles.module.css';
import { changePhoneStore } from '../../stores/ChangePhoneStore';

export const ProfileEditorPageRender = ({
  authStore = rootProfileStore.authStore,
}) => {
  const { authUser, logout } = authStore;

  const citiesOptions = useMemo(
    () =>
      flow<any, MayBe<Option>[], Option[]>(
        map(cityInfoToCitySelectOption),
        filter(isNotNil),
      )(cityStore?.cities) || [],
    [cityStore.requestApi.isLoading],
  );

  const { onSubmit } = useOnSubmit();

  const initialValues = useMemo(
    (): any =>
      authUser.user && {
        ...userToUserProfileForm([...USER_ROLES])(toJS(authUser.user)),
        newEmail: changeEmailStore.state.newEmail,
        newPhone: changePhoneStore.state.newPhone,
      },
    [
      authUser.user,
      changePhoneStore.state.newPhone,
      changeEmailStore.state.newEmail,
    ],
  );

  if (!initialValues) {
    return null;
  }

  return (
    <>
      <Formik<UserProfileForm>
        validationSchema={userProfileFormSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form className={styles.wrapper}>
            <UserFormRender
              citiesOptions={citiesOptions}
              formikProps={props}
              logout={logout}
            />
            <AlertSubmission
              isSubmitting={props.isSubmitting}
              errors={props.errors}
            />
          </Form>
        )}
      </Formik>
      <Route
        path={appRoutesService.getRoute('profileConfirmOldPhone')}
        exact
        component={ProfileConfirmOldPhonePopup}
      />
      <Route
        path={appRoutesService.getRoute('profileConfirmNewPhone')}
        exact
        component={ProfileConfirmNewPhonePopup}
      />
      <Route
        path={appRoutesService.getRoute('profileNewPhone')}
        exact
        component={ProfileNewPhonePopup}
      />
      <Route
        path={appRoutesService.getRoute('profileNewEmail')}
        exact
        component={NewEmailPopup}
      />
      <Route
        path={appRoutesService.getRoute('profileConfirmOldEmail')}
        exact
        component={OldEmailConfirmPopup}
      />
      <Route
        path={appRoutesService.getRoute('profileLinkIsOutOfDatePage')}
        exact
        component={NewEmailLinkIsOutOfDatePopup}
      />
      <Route
        path={appRoutesService.getRoute('profileConfirmNewEmail')}
        exact
        component={NewEmailConfirmPopup}
      />
    </>
  );
};

export const ProfileEditorPage = observer(ProfileEditorPageRender);

import React, { useMemo } from 'react';
import { flow, get, isNil } from 'lodash/fp';
import cc from 'classcat';
import { ErrorBoundary, OverlayZoomButton } from 'components/atoms';
import {
  ApartmentDto,
  BuildingEntity,
  Complex,
  Feature,
  findBuildingByIdInComplex,
} from 'services';
import { Header } from '../Header';
import { RenderBuildingToMap } from '../RenderBuildingToMap';
import { ShowApartmentOnFloorLayout } from '../ShowApartmentOnFloorLayout';
import { getWindowsFromLayers, reversePolygonCoords } from '../../../../utils';
import { useBuildingOnMapPopup } from '../../../../containers/BuildingOnMapPopup';

import styles from './styles.module.css';
import { dataTestIdToProps } from '../../../../../../utils/test/dataTestIdToProps';
import { rootApartmentStore } from '../../../../../../stores/Apartment/RootApartmentStore';

type Props = {
  apartment?: ApartmentDto;
  complex: Complex | null;
};

const ApartmentViewContentTestIDs = {
  wrapper: 'ApartmentViewContent_wrapper',
};

export const ApartmentViewContent = ({ apartment, complex }: Props) => {
  const { apartmentUIStore } = rootApartmentStore;

  const isNotShowFloorPlan = flow(
    get('layouts'),
    isNil,
  )(apartmentUIStore.activeApartment);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const building: BuildingEntity | undefined = findBuildingByIdInComplex(
    apartment?.building_id?.toString(),
  )(complex);

  const windows: Feature[] | undefined = useMemo(
    () => getWindowsFromLayers(apartment?.layouts),
    [apartment?.layouts],
  );

  const reversedPolygon = useMemo(
    () => reversePolygonCoords(building?.polygon),
    [building?.polygon],
  );

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon: reversedPolygon,
  });

  return (
    <>
      <div
        {...dataTestIdToProps(ApartmentViewContentTestIDs.wrapper)}
        className={cc([
          styles.wrapper,
          {
            [styles.wrapperIsNotFloorPlan]: isNotShowFloorPlan,
          },
        ])}
      >
        {/* @todo: PromotionsHeader */}
        <Header apartment={apartment} />

        {/* @todo: Map */}
        {!isNotShowFloorPlan && reversedPolygon && windows && (
          <div className={styles.mapWrapper}>
            <ErrorBoundary>
              <OverlayZoomButton
                className={styles.complexMap}
                onClick={showBuildingOnMapPopup}
              >
                <RenderBuildingToMap
                  hideZoomControl
                  className={styles.complexMap}
                  polygon={reversedPolygon}
                  windows={windows}
                />
              </OverlayZoomButton>
            </ErrorBoundary>
          </div>
        )}

        {/* @todo: Description */}
        {/* @todo: Sales department */}

        {/*
        @todo: import Albums from 'components/containers/ResidentialComplexPanel/components/Albums';
       */}
      </div>
      {!isNotShowFloorPlan && (
        <div className={styles.floorPlanWrapper}>
          <ErrorBoundary>
            <ShowApartmentOnFloorLayout
              hideZoomControl
              apartment={apartmentUIStore.activeApartment}
            />
          </ErrorBoundary>
        </div>
      )}
    </>
  );
};

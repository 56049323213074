import React from 'react';
import styles from './styles.module.css';
import { Text } from '../Text';
import { MobileAutoSuggestOption } from './index';

/**
 * @link https://github.com/moroshko/react-autosuggest#getsectionsuggestions-required-when-multisectiontrue
 * */
export const renderSuggestion = ({
  label,
  icon,
}: MobileAutoSuggestOption<any>) => (
  <>
    {icon && <img className={styles.selectIcon} src={icon} alt="icon" />}
    <Text size="L">{label}</Text>
  </>
);

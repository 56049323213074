import { CityInfo, User, UserProfileEditRequestBody } from 'services';
import { MayBe } from 'types';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import { OTHER_OPTION } from 'services/constants';
import { optionIsOther } from 'services/constants/handlers';
import * as Yup from 'yup';
import { UserProfileForm } from '../types';

export const beforeUpload = (file: File): boolean => {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/webp';

  if (!isJpgOrPng) {
    // eslint-disable-next-line no-console
    console.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    // eslint-disable-next-line no-console
    console.error('Image must smaller than 5MB!');
  }
  return isJpgOrPng && isLt2M;
};

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/webp'];

export const fileValidate = Yup.mixed()
  .test(
    'fileSize',
    'Размер файла не должен превышать 5 мегабайт',
    (value: any) =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      value && value.size <= FILE_SIZE,
  )
  .test(
    'fileFormat',
    `Допустимые форматы файла: ${SUPPORTED_FORMATS.join(', ')}`,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    (value: any) => value && SUPPORTED_FORMATS.includes(value.type),
  );

export const cityInfoToCitySelectOption = (city: CityInfo): MayBe<Option> =>
  city?.name
    ? {
        label: city?.name,
        value: city?.id,
      }
    : null;

const getOtherOptionOrTargetOption =
  (developerCustom: MayBe<string>) =>
  (option: MayBe<Option>): MayBe<Option> =>
    developerCustom ? OTHER_OPTION : option;

export const userToUserProfileForm =
  (roles: Option[]) =>
  (user: User): UserProfileForm => {
    const role = roles.find(({ value }) => value === user.role) || null;

    const developer = getOtherOptionOrTargetOption(user.developerCustom)(
      (user.developer && {
        label: user.developer.title,
        value: user.developer.id,
      }) ||
        null,
    );

    const agency = getOtherOptionOrTargetOption(user.developerCustom)(
      (user.agency && {
        label: user.agency.title,
        value: user.agency.id,
      }) ||
        null,
    );

    return {
      ...user,
      city: user.city && cityInfoToCitySelectOption(user?.city),
      role,
      developer,
      agency,
    };
  };

export const getUserJobForRequest = (
  role?: MayBe<Option>,
  developer?: MayBe<Option>,
  agency?: MayBe<Option>,
  developerCustom?: MayBe<string>,
): Pick<
  UserProfileEditRequestBody,
  'developerId' | 'agencyId' | 'developerCustom'
> => {
  if (role?.value === 'developer') {
    return {
      developerId: optionIsOther(developer) ? null : developer?.value,
      agencyId: null,
      developerCustom: optionIsOther(developer) ? developerCustom : null,
    };
  }
  if (role?.value === 'realtor') {
    return {
      developerId: null,
      agencyId: optionIsOther(agency) ? null : agency?.value,
      developerCustom: optionIsOther(agency) ? developerCustom : null,
    };
  }

  return {
    developerId: null,
    agencyId: null,
    developerCustom: null,
  };
};

/**
 * @desc конвертирование объекта из формы в объект для сохранения данных пользователя
 * */
export const userProfileFormToUser = (
  user: UserProfileForm,
): UserProfileEditRequestBody => ({
  name: user.name,
  surname: user.surname,
  description: user.description || null,
  cityId: user?.city?.value || null,
  role: user.role?.value || null,

  ...getUserJobForRequest(
    user.role,
    user.developer,
    user.agency,
    user.developerCustom,
  ),
});

import React, { ChangeEventHandler, useState } from 'react';

import { Input } from 'components/atoms/Form/Input';

import {
  complexFilterStore,
  ComplexFilterType,
} from 'stores/ComplexFilterStore';

type PriceSelectorProps = {
  value: number | null | undefined;
  label: string;
  values: string[];
  onChange?: (value: string) => void;
  filterKey: keyof ComplexFilterType;
};

export const PriceSelector = ({
  value,
  label,
  values,
  onChange,
  filterKey,
}: PriceSelectorProps) => {
  const unit = '₽';

  const [inputValue, setInputValue] = useState(value?.toString() || '');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
    complexFilterStore.filters.setField(filterKey, +event.target.value);
  };

  return (
    <div>
      <div style={{ fontWeight: 'bold' }}>{label}</div>
      <Input
        onChange={handleChange}
        value={inputValue}
        customWidth="140px"
        placeholder="₽₽₽"
        maxLength={15}
      />
      {values.map((value) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          key={value}
          style={{
            fontSize: '18px',
            color: '#4E6AFF',
            cursor: 'pointer',
            paddingTop: '10px',
          }}
          onClick={() => {
            complexFilterStore.filters.setField(
              filterKey,
              +value.replaceAll(' ', ''),
            );
            setInputValue(value);
          }}
        >
          {label} {value} {unit}
        </div>
      ))}
    </div>
  );
};

import React, { useContext, useMemo } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { MediaQuery } from 'components/containers/MediaQuery';
import { TabContentRenderer } from 'components/atoms';
import { useParams } from 'react-router-dom';
import { Params } from 'services';
import styles from './styles.module.css';
import { BuildingNavBar } from '../../components/BuildingNavBar';
import { BuildingNavSelect } from '../../components/BuildingNavSelect';
import { BuildingNavBarTabsContext } from '../../context/BuildingNavBarTabsContext';
import { getActiveTabByRouteName } from '../../utils';
import { LayoutTabs, LayoutTabsEnum } from '../../types';
import { ApartmentViewFilter } from '../../components/ApartmentViewFilter';
import { FloorListContainer } from '../../pages/FloorPlans/containers/FloorListContainer';
import { FloorSelect } from '../../pages/FloorPlans/containers/FloorSelect';
import { SectionSelect } from '../SectionSelect';

type Props = {
  children: any;
};

/**
 * @desc контейнер в котором рендерится контент вкладок "шахматка", "план этажа", "список квартир".
 * так же рендерится панель с этажами для вкладки "план этажа" и навигация по корпусам.
 * */
export const LayoutContainerRender = ({ children }: Props) => {
  const { layoutTabs } = useParams<Pick<Params, 'layoutTabs'>>();
  /**
   * @desc свойства для табов корпусов
   * */
  const { changeFilterByBuildings, buildingsTabs, activeBuilding } = useContext(
    BuildingNavBarTabsContext,
  );

  const buildingNavBarProps = useMemo(
    () => ({
      onChangeTab: changeFilterByBuildings,
      tabs: buildingsTabs,
      initActiveTab: activeBuilding,
    }),
    [activeBuilding, buildingsTabs, changeFilterByBuildings],
  );

  return (
    <main
      className={cc([
        styles.layoutContainerWrapper,
        {
          [styles.layoutContainerFloorPlanGrid]:
            getActiveTabByRouteName(layoutTabs as LayoutTabs) ===
            LayoutTabsEnum.floorPlan,
        },
      ])}
    >
      <nav className={styles.layoutContainerNav}>
        <MediaQuery mediaQuery="(max-width: 1199px) and (min-width: 768px)">
          {/** @desc для планшета */}
          <div className={styles.layoutContainerBuildingsTablet}>
            <BuildingNavBar
              wrapperClassname={styles.layoutContainerBuildingNavBarWrapper}
              {...buildingNavBarProps}
            />
            <TabContentRenderer
              currentActiveTab={getActiveTabByRouteName(
                layoutTabs as LayoutTabs,
              )}
              tabs={{
                [LayoutTabsEnum.grid]: <ApartmentViewFilter />,
                [LayoutTabsEnum.floorPlan]: (
                  <div className={styles.selectGroup}>
                    <SectionSelect />
                    <ApartmentViewFilter />
                  </div>
                ),
              }}
            />
          </div>
        </MediaQuery>

        <MediaQuery mediaQuery="(max-width: 767px)">
          {/** @desc для мобильного */}
          <div className={styles.layoutContainerBuildingsAndSortWrapper}>
            <BuildingNavSelect {...buildingNavBarProps} />

            <TabContentRenderer
              currentActiveTab={getActiveTabByRouteName(
                layoutTabs as LayoutTabs,
              )}
              tabs={{
                [LayoutTabsEnum.grid]: <ApartmentViewFilter />,
                [LayoutTabsEnum.floorPlan]: (
                  <div className={styles.selectGroup}>
                    <SectionSelect />
                    <FloorSelect />
                  </div>
                ),
              }}
            />
          </div>
        </MediaQuery>

        <MediaQuery mediaQuery="(min-width: 1200px)">
          <div className={styles.buildingNavBarDesktopWrapper}>
            <BuildingNavBar
              wrapperClassname="wrapperClassname"
              {...buildingNavBarProps}
            />
            <TabContentRenderer
              currentActiveTab={getActiveTabByRouteName(
                layoutTabs as LayoutTabs,
              )}
              tabs={{
                [LayoutTabsEnum.floorPlan]: <SectionSelect />,
              }}
            />
          </div>
        </MediaQuery>
      </nav>

      <MediaQuery mediaQuery="(min-width: 768px)">
        <TabContentRenderer
          currentActiveTab={getActiveTabByRouteName(layoutTabs as LayoutTabs)}
          tabs={{
            [LayoutTabsEnum.floorPlan]: (
              <aside className={styles.layoutContainerFloorSidebar}>
                <FloorListContainer />
              </aside>
            ),
          }}
        />
      </MediaQuery>
      <div className={styles.layoutContainerMain}>{children}</div>
    </main>
  );
};

export const LayoutContainer = observer(LayoutContainerRender);

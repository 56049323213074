import React, { memo } from 'react';

import { Company, SalesDepartments } from 'services/v1/rc/view/types';
import { get, filter, isEqual, flow, groupBy, isEmpty, head } from 'lodash/fp';
import { ID, MayBe } from 'types';
import styles from './styles.module.css';
import { Text } from '../../atoms/Text';
import { SalesDepartmentCompanyBlock } from './component/SalesDepartmentCompanyBlock';
import { dateFormat } from '../../atoms';
import { OpenLayoutPageButton } from '../../../pages/ComplexPage/components/OpenLayoutPageButton';

type Props = {
  salesDepartments?: MayBe<SalesDepartments[]>;
  developer?: MayBe<Company>;
  complexId?: ID;
  buildingId?: MayBe<ID>;
  complexUpdatedAt?: MayBe<string>;

  hiddenFooter?: boolean;
  isVillage?: boolean;
};

const getAllAgencySalesDepartments = (developerId?: ID) =>
  flow<any, SalesDepartments[], Record<number, SalesDepartments[]>>(
    filter(
      (dep: SalesDepartments) =>
        !isEqual(
          get<SalesDepartments, 'developer_id'>('developer_id', dep),
          developerId,
        ),
    ),
    groupBy<SalesDepartments>('developer_id'),
  );
const getAllDeveloperSalesDepartments = (developerId?: ID) =>
  flow<any, SalesDepartments[], Record<number, SalesDepartments[]>>(
    filter((dep: SalesDepartments) =>
      isEqual(
        get<SalesDepartments, 'developer_id'>('developer_id', dep),
        developerId,
      ),
    ),
    groupBy<SalesDepartments>('developer_id'),
  );

/**
 *
 * @desc Карточка отделов продаж.
 * - если нет ни застройщика ни отделов продаж, скрыть
 * - если отделов продаж нет и есть застройщик, то показать логотип и название застройщика
 * - если есть только застройщик и его отделы продаж, показать все
 * - если есть застройщик и агентства, показать все
 * */
export const SalesDepartmentsCard = ({
  developer,
  salesDepartments,
  complexId,
  buildingId,
  complexUpdatedAt,
  hiddenFooter,
  isVillage = false,
}: Props) => {
  const developerId = get<Company, 'id'>('id', developer);

  const agencySalesDepartments =
    getAllAgencySalesDepartments(developerId)(salesDepartments);

  const developerSalesDepartments =
    getAllDeveloperSalesDepartments(developerId)(salesDepartments);

  if (!agencySalesDepartments && !developerSalesDepartments && !developer) {
    return null;
  }

  return (
    <>
      <div className={styles.cardWrapper}>
        {isEmpty(developerSalesDepartments) && developer && (
          <SalesDepartmentCompanyBlock
            key={developerId}
            company={developer}
            type="developer"
          />
        )}

        {!isEmpty(developerSalesDepartments) &&
          Object.entries(developerSalesDepartments).map(
            ([developerId, salesDepartments]) => (
              <SalesDepartmentCompanyBlock
                key={developerId}
                company={flow(head, get('company'))(salesDepartments)}
                salesDepartments={salesDepartments}
                type="developer"
              />
            ),
          )}

        {Object.entries(agencySalesDepartments).map(
          ([companyId, salesDepartments]) => (
            <SalesDepartmentCompanyBlock
              key={companyId}
              company={flow(head, get('company'))(salesDepartments)}
              salesDepartments={salesDepartments}
              type="agency"
            />
          ),
        )}
      </div>
      {!hiddenFooter && (
        <div className={styles.footerWrapper}>
          <OpenLayoutPageButton
            complexId={complexId}
            buildingId={buildingId?.toString()}
            isVillage={isVillage}
          />

          <Text
            indent={{
              margin: [20],
            }}
            tagName="div"
            align="center"
            size="M"
          >
            Последнее обновление {dateFormat(complexUpdatedAt, 'dateMonthTime')}
          </Text>
        </div>
      )}
    </>
  );
};

export default memo(SalesDepartmentsCard);

import { EventedProps, createLayerComponent } from '@react-leaflet/core';
import {
  LatLngExpression,
  Marker as LeafletMarker,
  MarkerOptions,
} from 'leaflet';
import { ReactNode } from 'react';
import 'leaflet-rotatedmarker';

export interface MarkerProps extends MarkerOptions, EventedProps {
  children?: ReactNode;
  position: LatLngExpression;
  rotationOrigin: string;
  rotationAngle: number;
}
type LeafletMarkerRotate = LeafletMarker & {
  setRotationAngle: (rotationAngle: number) => void;
  setRotationOrigin: (rotationOrigin: string) => void;
};

export const MarkerRotate = createLayerComponent<LeafletMarker, MarkerProps>(
  ({ position, ...options }, ctx) => {
    const instance = new LeafletMarker(position, options);

    (instance as LeafletMarkerRotate).setRotationAngle(options.rotationAngle);
    (instance as LeafletMarkerRotate).setRotationOrigin(options.rotationOrigin);
    return { instance, context: { ...ctx, overlayContainer: instance } };
  },
  (marker, props, prevProps) => {
    marker.getElement();
    if (props.position !== prevProps.position) {
      marker.setLatLng(props.position);
    }
    if (props.icon != null && props.icon !== prevProps.icon) {
      marker.setIcon(props.icon);
    }
    if (
      props.zIndexOffset != null &&
      props.zIndexOffset !== prevProps.zIndexOffset
    ) {
      marker.setZIndexOffset(props.zIndexOffset);
    }
    if (props.opacity != null && props.opacity !== prevProps.opacity) {
      marker.setOpacity(props.opacity);
    }
    if (marker.dragging != null && props.draggable !== prevProps.draggable) {
      if (props.draggable === true) {
        marker.dragging.enable();
      } else {
        marker.dragging.disable();
      }
    }
    if (props.rotationAngle !== prevProps.rotationAngle) {
      (marker as LeafletMarkerRotate).setRotationAngle(props.rotationAngle);
    }
    if (props.rotationOrigin !== prevProps.rotationOrigin) {
      (marker as LeafletMarkerRotate).setRotationOrigin(props.rotationOrigin);
    }
  },
);

import React from 'react';
import { observer } from 'mobx-react';

import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { ApartmentsStore } from 'stores/Apartment/ApartmentsStore/ApartmentsStore';
import {
  ComplexItemStore,
  complexItemStore,
} from 'stores/ComplexItemStore/store';

import {
  buildingGridStore,
  BuildingGridStore,
} from './stores/buildingGridStore';
import { appRoutesService } from '../../services';

import { GridApartments } from './pages/GridApartments';
import { ApartmentView } from './pages/ApartmentView';

import { useLayoutTabsState } from './hooks/useLayoutTabsState';

import { LayoutTabsEnum } from './types';

import styles from './styles.module.css';
import { FloorPlans } from './pages/FloorPlans';
import { TabContentRenderer } from '../../components/atoms';
import { LayoutPageHeaderDesktop } from './containers/LayoutPageHeaderDesktop';
import { useMainChessFiltersPopup } from './containers/MainChessFilter/hooks';
import { LayoutPageHeaderMobile } from './containers/LayoutPageHeaderMobile';
import { ApartmentList } from './pages/ApartmentList';
import { useBuildingNavBarTabs } from './hooks/useBuildingNavBarTabs';
import { MediaQuery } from '../../components/containers/MediaQuery';
import { BuildingNavBarTabsContextProvider } from './context/BuildingNavBarTabsContext';
import { LayoutContainer } from './containers/LayoutContainer';
import { AuthPage } from '../AuthPage';
import { useSetParentRouteToAuthRoutes } from './hooks/useSetParentRouteToAuthRoutes';
import { useCloseApartmentViewHandler } from './hooks/useCloseApartmentViewHandler';
import { useLoadingComplexData } from './hooks/useLoadingComplexData';
import { useCloseApartmentAfterChangeLink } from './hooks/useCloseApartmentAfterChangeLink';
import { rootApartmentStore } from '../../stores/Apartment/RootApartmentStore';
import { AuthForGetAccessToApartment } from './pages/AuthForGetAccessToApartment';
import { useAutoCloseApartmentViewForNotAuth } from './hooks/useAutoCloseApartmentViewForNotAuth';

type Props = {
  complexItemStore: ComplexItemStore;
  apartmentsStore: ApartmentsStore;
  buildingGridStore: BuildingGridStore;
};

const LayoutPageTestIDs = {
  wrapper: 'LayoutPage_wrapper',
};

export const LayoutPageRender = ({
  complexItemStore,
  buildingGridStore,
}: Props) => {
  const { target: complexData } = complexItemStore;

  const { apartmentUIStore, apartmentsStore } = rootApartmentStore;

  const { tabList, changeTab, currentActiveTab } = useLayoutTabsState();

  const { changeFilterByBuildings, buildingsTabs, activeBuilding } =
    useBuildingNavBarTabs(complexData);

  useCloseApartmentAfterChangeLink();

  useSetParentRouteToAuthRoutes();

  useLoadingComplexData();

  const { showMainChessFilters } = useMainChessFiltersPopup();

  const { closeApartmentView } = useCloseApartmentViewHandler(currentActiveTab);

  useAutoCloseApartmentViewForNotAuth({
    time: 10000,
    isOpen: apartmentUIStore.isOpen,
  });

  return (
    <BuildingNavBarTabsContextProvider
      value={{ changeFilterByBuildings, buildingsTabs, activeBuilding }}
    >
      <>
        <div
          {...dataTestIdToProps(LayoutPageTestIDs.wrapper)}
          className={styles.wrapper}
        >
          <MediaQuery mediaQuery="(max-width: 1199px)">
            <LayoutPageHeaderMobile
              showMainChessFilters={showMainChessFilters}
              changeTab={changeTab}
              currentActiveTab={currentActiveTab}
              tabList={tabList}
            />
          </MediaQuery>

          <MediaQuery mediaQuery="(min-width: 1200px)">
            <LayoutPageHeaderDesktop
              headerProps={{
                currentActiveTab,
                tabList,
                changeTab,
                showMainChessFilters,
              }}
            />
          </MediaQuery>

          <LayoutContainer>
            <TabContentRenderer
              tabs={{
                [LayoutTabsEnum.grid]: (
                  <GridApartments
                    complexItemStore={complexItemStore}
                    buildingGridStore={buildingGridStore}
                  />
                ),
                [LayoutTabsEnum.floorPlan]: (
                  <FloorPlans
                    complexItemStore={complexItemStore}
                    buildingGridStore={buildingGridStore}
                  />
                ),
                [LayoutTabsEnum.apartments]: (
                  <ApartmentList
                    complexItemStore={complexItemStore}
                    apartmentsStore={apartmentsStore}
                  />
                ),
              }}
              currentActiveTab={currentActiveTab}
            />
          </LayoutContainer>
        </div>

        <ApartmentView
          onClose={closeApartmentView}
          apartment={apartmentUIStore.activeApartment}
          isOpen={apartmentUIStore.isOpen}
        />

        <AuthPage
          components={{
            authPhoneCodePage: () => <AuthForGetAccessToApartment />,
            authEmailCodePage: () => <AuthForGetAccessToApartment />,
          }}
          parentRoute={appRoutesService.getRoute('layoutActiveTab')}
        />
      </>
    </BuildingNavBarTabsContextProvider>
  );
};

LayoutPageRender.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  complexItemStore,
  // eslint-disable-next-line react/default-props-match-prop-types
  buildingGridStore,
};

export const LayoutPage = observer(LayoutPageRender);

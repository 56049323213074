import axios, { CancelTokenSource } from 'axios';
import { MayBe } from '../../../types';

export class CancelTokenStore {
  cancelTokenSource: MayBe<CancelTokenSource> = null;

  createCancelToken = () => {
    this.cancelTokenSource = axios.CancelToken.source();
  };

  executeCancelToken = () => {
    if (this.cancelTokenSource !== null) {
      this.cancelTokenSource.cancel('');
    }
    this.createCancelToken();
  };

  errorIsCancel = (error: any) => axios.isCancel(error);
}

import React from 'react';
import { ComplexRawInOffer } from 'services';
import { ComplexCardMap } from './components/ComplexCardMap';
import { ComplexLogo } from './components/ComplexLogo';
import { ComplexCardAddress } from './components/ComplexCardAddress';
import styles from './styles.module.css';

type Props = {
  complex?: ComplexRawInOffer;
  showCard: boolean;
  showMap: boolean;
};

export const ComplexCard = ({ complex, showMap, showCard }: Props) => {
  if (!showCard && !showMap) {
    return null;
  }

  return (
    <div className={styles.complexCardWrapper}>
      {showCard && (
        <div>
          <ComplexLogo
            title={complex?.title}
            logo={complex?.logo}
            developer={complex?.developer}
          />
        </div>
      )}
      <div className={styles.complexCardRight}>
        {showCard && (
          <ComplexCardAddress addressDetail={complex?.addressDetail} />
        )}
        {showMap && <ComplexCardMap complex={complex} />}
      </div>
    </div>
  );
};

import React from 'react';
import { Realtor } from 'services';
import { isNotNil } from 'utils/fp';
import { Avatar } from 'components/atoms/Avatar';
import avatarPlaceholder from 'assets/noAvatar.svg';
import styles from './styles.module.css';

type Props = {
  realtor?: Realtor;
};

export const RealtorLogo = ({ realtor }: Props) => (
  <div className={styles.realtorLogoWrapper}>
    {realtor?.logo?.logo && (
      <Avatar
        size={60}
        src={realtor?.logo?.url || avatarPlaceholder}
        className={styles.realtorLogo}
      />
    )}
    <div>
      <div className={styles.realtorLogoTitle}>
        {[realtor?.surname, realtor?.name].filter(isNotNil).join(' ')}
      </div>
      <div className={styles.realtorLogoSubTitle}>Риэлтор</div>
    </div>
  </div>
);

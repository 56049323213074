import React from 'react';
import { BaseLink } from 'components/atoms';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import styles from './styles.module.css';

export const PrivacyButtonTestIDs = {
  button: 'PrivacyButton_button',
};

type Props = {
  to?: string;
};

export const PrivacyButton = ({ to }: Props) => (
  <div className={styles.wrapper}>
    Нажимая «Продолжить» вы соглашаетесь с{' '}
    <BaseLink
      {...dataTestIdToProps(PrivacyButtonTestIDs.button)}
      className={styles.iconWithTextWrapper}
      {...(to
        ? { to }
        : {
            path: 'privacy',
          })}
    >
      политикой конфиденциальности
    </BaseLink>
  </div>
);

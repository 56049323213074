import React from 'react';
import { OffersViews } from 'services';
import { observer } from 'mobx-react';
import { ListItem } from 'components/atoms/ListItem';
import { Switch } from 'components/atoms/Switch';
import { OfferViewFieldOption } from '../../types';
import styles from './styles.module.css';

type Props = {
  options: OfferViewFieldOption[];
  isChecked: (name: OffersViews) => boolean;
  onChecked: (name: OffersViews) => () => any;
};

export const VisibilitySettingsListRender = ({
  options,
  isChecked,
  onChecked,
}: Props) => (
  <div className={styles.visibilitySettingFormWrapper}>
    {options.map(({ label, name }) => (
      <ListItem
        className={styles.visibilitySettingFormListItem}
        key={label}
        component="div"
        badge={<Switch checked={isChecked(name)} onChange={onChecked(name)} />}
      >
        {label}
      </ListItem>
    ))}
  </div>
);

export const VisibilitySettingsList = observer(VisibilitySettingsListRender);

import React from 'react';
import { useGetQueryString } from 'hooks';
import styles from './styles.module.css';
import { AuthPopup } from '../../components/AuthPopup';

export const AuthPhoneRegistrationSuccessPage = () => {
  const { query } = useGetQueryString<{ email: string }>();

  return (
    <AuthPopup>
      <div className={styles.wrapper}>
        <div className={styles.title}>Подтвердите почту</div>
        <div className={styles.subTitle}>
          Мы выслали письмо с кнопкой подтверждения на почту
          <strong>{query?.email}</strong>
        </div>
      </div>
    </AuthPopup>
  );
};

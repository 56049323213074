import React from 'react';
import { TabProps } from './hooks';
import { TabButton, TabList } from './components';
import { dataTestIdToProps } from '../../../utils/test/dataTestIdToProps';

type Props<TabItemPayload> = Omit<TabProps<TabItemPayload>, 'setTabList'> & {
  testId?: string;
};

/**
 * @desc визуальная часть табов без стейта и логики
 * */
export const TabsView = <TabItemPayload extends {}>({
  currentActiveTab,
  tabList,
  changeTab,
  testId,
}: Props<TabItemPayload>) => (
  <TabList
    {...dataTestIdToProps([testId, 'TabList'].join('_'))}
    currentActiveTab={currentActiveTab}
  >
    {tabList.map((item, index) => (
      <TabButton
        {...dataTestIdToProps([testId, 'TabButton'].join('_'))}
        key={index.toString()}
        isActive={index === currentActiveTab}
        onClick={changeTab(index)}
        {...item}
      />
    ))}
  </TabList>
);

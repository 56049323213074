import React, { ReactNode, CSSProperties, ChangeEventHandler } from 'react';
import cc from 'classcat';

import styles from './styles.module.css';

type InputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
  customWidth?: string;
  placeholder?: string;
  customIcon?: ReactNode;
  maxLength?: number;
  customStyles?: CSSProperties;
  className?: string;
  name?: string;
  disabled?: boolean;
};

export const Input = ({
  customIcon,
  onChange,
  onBlur,
  value = '',
  customWidth = '100%',
  placeholder = '',
  maxLength,
  customStyles = {},
  className,
  name,
  disabled,
  ...rest
}: InputProps) => (
  <div
    className={cc([
      className,
      styles.input,
      {
        [styles.inputDisabled]: disabled,
      },
    ])}
  >
    {customIcon && <span className={styles.customIcon}>{customIcon}</span>}
    <input
      {...rest}
      disabled={disabled}
      type="text"
      maxLength={maxLength}
      className={cc([
        styles.inputTag,
        {
          [styles.inputTagIcon]: !!customIcon,
        },
      ])}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      style={{
        width: customWidth,
        ...customStyles,
      }}
      placeholder={placeholder}
    />
  </div>
);

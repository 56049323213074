import { useEffect } from 'react';
import {
  LatLngExpression,
  LatLngTuple,
  Map,
  Map as MapType,
  polyline,
} from 'leaflet';
import { useMap } from 'react-leaflet';
import { MayBe } from 'types';
import { flow, map, get } from 'lodash/fp';
import { BuildingEntity, reversePolygonCoords } from 'services';
import { isNotNil } from 'utils/fp';

const fitBounds =
  (map: Map) => (latlngs: LatLngExpression[] | LatLngExpression[][]) => {
    const polygonPolyline = polyline(latlngs).addTo(map);
    map.fitBounds(polygonPolyline.getBounds());
  };

type Props = {
  mapInstance?: MayBe<MapType>;
  buildings?: Array<BuildingEntity>;
  polygon?: LatLngTuple[];
};

/**
 * @desc компонент для центрования карты по переданным полигонам,
 * ВАЖНО! компонент будет выполнять центрование только по одному из способов,
 * то есть либо по массиву polygon либо по корпусам ЖК
 * */
export const LeafletCentroidByPolygons = ({
  mapInstance,
  buildings,
  polygon,
}: Props) => {
  const localeMapInstance = useMap();

  useEffect(() => {
    const _map = mapInstance || localeMapInstance;

    if (isNotNil(buildings) && isNotNil(polygon)) {
      throw new Error(
        'LeafletCentroid может выполнять центровку карты только по одному из параметров: buildings, polygon',
      );
    }

    /**
     * @desc отцентровать по списку корпусов
     * */
    if (isNotNil(buildings)) {
      const buildingsPolygons = flow(
        map(get<BuildingEntity, 'polygon'>('polygon')),
        map(reversePolygonCoords),
      )(buildings);
      fitBounds(_map)(buildingsPolygons);
    }

    /**
     * @desc отцентровать по списку корпусов
     * */
    if (polygon) {
      fitBounds(_map)(polygon);
    }
  }, [buildings, polygon]);

  return null;
};

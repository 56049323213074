import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apartmentsService, Params } from 'services';
import { requestCacheStore } from 'stores';
import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';
import { refreshStore } from '../stores/RefreshStore/RefreshStore';

type Props = {
  apartmentsService: typeof apartmentsService;
};

/** @desc слушаем изменение apartmentId в маршруте и обновляем данные для квартиры в сайдбаре */
export const useInitApartmentSidebar = ({ apartmentsService }: Props) => {
  const { apartmentUIStore } = rootApartmentStore;
  const { apartmentId } = useParams<Pick<Params, 'apartmentId'>>();

  const loadApartment = useCallback(() => {
    if (apartmentId && apartmentUIStore.isOpen) {
      if (requestCacheStore.hasObject('Apartment', apartmentId)) {
        apartmentUIStore.addApartment(
          requestCacheStore.getObject('Apartment', apartmentId),
        );
        return;
      }
      void apartmentsService
        .getApartmentsById(apartmentId)
        .then((data) => {
          requestCacheStore.setObject('Apartment', apartmentId, data);
          apartmentUIStore.addApartment(data);
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    }
  }, [apartmentId]);

  useEffect(
    () => () => {
      apartmentUIStore.clearActiveApartment();
    },
    [],
  );

  useEffect(() => {
    if (apartmentId) {
      apartmentUIStore.setIsOpen(true);
      loadApartment();
    }
    refreshStore.add(loadApartment);
    return () => {
      refreshStore.remove(loadApartment);
    };
  }, [apartmentId]);

  return undefined;
};

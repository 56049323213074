import React from 'react';
import L, { LatLngTuple } from 'leaflet';
import { useModal } from 'react-modal-hook';
import arrowLeftColor from 'assets/arrowLeftColor.svg';
import yaMapIcon from 'assets/yandex-maps.svg';

import { BaseMapContainer, MobilePopup, Text } from 'components/atoms';

import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Marker } from 'react-leaflet';
import styles from './styles.module.css';
import { Icon } from '../../atoms/Icon';
import { YandexMapLayer } from '../../atoms/Map/YandexMapLayer';
import { MapPin } from '../../../pages/Main/containers/LeafletMap/components/MapMarker';
import { MayBe } from '../../../types';
import { getLinkToMap } from '../../../utils/getLinkToMap';

type Props = {
  centerLatLng: LatLngTuple;
  address?: MayBe<string>;
  zoom?: number;
};

export const MobileAddressMapPopup = ({
  centerLatLng,
  address,
  zoom,
}: Props) => (
  <div className={styles.wrapper}>
    <BaseMapContainer
      crs={L.CRS.EPSG3395}
      zoom={zoom || 18}
      center={centerLatLng}
    >
      <YandexMapLayer />
      <Marker icon={MapPin} position={centerLatLng} />
    </BaseMapContainer>

    <div className={styles.footer}>
      {address && <div className={styles.address}>{address}</div>}
      <ButtonBase
        icon={<Icon size={16} src={yaMapIcon} />}
        tagName="a"
        target="_blank"
        href={getLinkToMap(centerLatLng[1], centerLatLng[0])}
      >
        Открыть в Яндекс.Картах
      </ButtonBase>
    </div>
  </div>
);

export const useMobileAddressMapPopup = (props: Props) => {
  const [showMobileAddressMapPopup, hideMobileAddressMapPopup] = useModal(
    () => (
      <MobilePopup
        IconBack={<Icon size={24} src={arrowLeftColor} alt="go back" />}
        headerChildren={
          <Text align="center" size="L" weight="700">
            Карта
          </Text>
        }
        onClose={hideMobileAddressMapPopup}
        isOpen
      >
        <MobileAddressMapPopup {...props} />
      </MobilePopup>
    ),
  );

  return {
    showMobileAddressMapPopup,
    hideMobileAddressMapPopup,
  };
};

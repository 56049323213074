import { AttemptResult, ResponseStatus } from '../../../types';
import { API_ROUTES } from '../../../constants';
import { attempt } from '../../../libs/axios/attempt';

export const authLogoutService = {
  logout: async (): Promise<AttemptResult<ResponseStatus>> =>
    attempt<ResponseStatus>({
      url: API_ROUTES.auth.logout,
      method: 'get',
    }),
};

import { FormikHelpers } from 'formik/dist/types';
import { FORM_SUBMISSION_ERROR } from 'services/constants';
import { getResponseErrorMessage } from '../../services/libs/axios/helpers';

export const submissionErrorsHandler = (
  error: any,
  formikHelpers: FormikHelpers<any>,
) => {
  if (error.response) {
    formikHelpers.setErrors({
      [FORM_SUBMISSION_ERROR]: getResponseErrorMessage(error),
    });
  } else {
    formikHelpers.setErrors({
      [FORM_SUBMISSION_ERROR]:
        'Не удалось отправить форму. Проверьте подключение к интернету и обновите браузер',
    });
  }
};

import React from 'react';
import { RenderSuggestionsContainerParams } from 'react-autosuggest';
import SimpleBar from 'simplebar-react';

type Props = RenderSuggestionsContainerParams;

/**
 * @link https://github.com/moroshko/react-autosuggest#rendersuggestionscontainer-optional
 * */
export const RenderSuggestionsContainer = ({
  containerProps,
  children,
}: Props) => {
  const { ref, ...restContainerProps } = containerProps;

  if (!children) {
    return null;
  }
  return (
    <SimpleBar scrollableNodeProps={{ ref }} {...restContainerProps}>
      {children}
    </SimpleBar>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { ID } from 'types';
import addToCollection from 'assets/addToCollection.svg';

import addToCollectionGray from 'assets/addToCollectionGray.svg';
import { Loader } from 'components/atoms/Loader';
import { Icon } from 'components/atoms/Icon';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { appModalRoutesService, appRoutesService } from 'services';
import { apartmentInOffersStatusStore } from 'stores';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { useUpdateApartmentStatus } from './hooks/useUpdateApartmentStatus';

type Props = {
  showLabels?: boolean;
  apartmentId?: ID;
  textClassName?: string;
};

export const OffersStatusAndAddButtonRender = ({
  apartmentId,
  showLabels,
  textClassName,
}: Props) => {
  const { push } = useHistory();
  const { haveAnApartmentInAnyOffers, requestApiState } =
    apartmentInOffersStatusStore;

  useUpdateApartmentStatus({ apartmentId });

  const addToOffersIcon =
    apartmentId && haveAnApartmentInAnyOffers(apartmentId) ? (
      <Icon src={addToCollection} />
    ) : (
      <Icon src={addToCollectionGray} />
    );

  const onClick = () => {
    if (rootProfileStore.authStore.isAuth) {
      push(
        appModalRoutesService.replaceModalType('addToOffer', {
          apartmentId: apartmentId?.toString(),
        }),
      );
    } else {
      push(appRoutesService.getRoute('authPhoneCodePage'));
    }
  };

  return (
    <ButtonBase
      onClick={onClick}
      icon={
        requestApiState.isLoading && rootProfileStore.authStore.isAuth ? (
          <Loader size="small" />
        ) : (
          addToOffersIcon
        )
      }
      textClassName={textClassName}
    >
      {showLabels && 'В подборку'}
    </ButtonBase>
  );
};

export const OffersStatusAndAddButton = observer(
  OffersStatusAndAddButtonRender,
);

import React from 'react';
import { ButtonBase } from '../../../../../pages/LayoutPage/components/ButtonBase';
import { Icon } from '../../../../atoms/Icon';
import close from '../../../../../assets/close.svg';
import styles from './styles.module.css';

type Props = {
  onClose: () => void;
  onCleanFilter: () => void;
};

export const FilterBottomButtons = ({ onClose, onCleanFilter }: Props) => (
  <>
    <div className={styles.desktopButtons}>
      <ButtonBase
        onClick={onCleanFilter}
        icon={<Icon size={10} alt="close" src={close} />}
      >
        Сбросить
      </ButtonBase>
      <ButtonBase onClick={onClose} color="primary">
        Показать
      </ButtonBase>
    </div>

    <div className={styles.mobileButtons}>
      <ButtonBase color="primary" onClick={onClose} fullWidth>
        Показать
      </ButtonBase>
      <ButtonBase fullWidth>Сохранить поиск</ButtonBase>
      <ButtonBase onClick={onCleanFilter} fullWidth>
        Сбросить
      </ButtonBase>
    </div>
  </>
);

import React from 'react';
import styles from './styles.module.css';

type Props = {
  activeIndex: number;
  photosLength: number;
};

export const AlbumPhotoCounter = ({ photosLength, activeIndex }: Props) => (
  <span className={styles.photoCount}>
    {`Фото ${activeIndex + 1}/${photosLength}`}
  </span>
);

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import { Tabs } from 'components/atoms/Tabs';
import styles from './styles.module.css';

type Props = {
  items: Record<string, string>;
  refsMap: Record<string, React.RefObject<HTMLDivElement>>;
  modalContentRef: HTMLDivElement | undefined;
};

export const CardTabs = ({ items, refsMap, modalContentRef }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  smoothscroll.polyfill();

  const associatedRefs = new Map<string, React.RefObject<HTMLDivElement>>();

  Object.keys(refsMap).forEach((key) => {
    associatedRefs.set(items[key], refsMap[key]);
  });

  return (
    <Tabs
      items={items}
      TabContent={() => <></>}
      rootWrapperClassName={styles.rootTabRoot}
      itemsWrapperClassName={styles.rootTabItems}
      TabItem={(active, text, onClick) => (
        <li key={text}>
          <button
            className={`${styles.rootTabItem} ${
              active ? styles.rootTabItemActive : ''
            }`}
            onClick={() => {
              onClick();
              if (associatedRefs.has(text)) {
                const anchor: React.RefObject<HTMLDivElement> | undefined =
                  associatedRefs.get(text);
                if (anchor?.current) {
                  modalContentRef?.scroll({
                    behavior: 'smooth',
                    left: 0,
                    top: anchor.current.offsetTop - 70,
                  });
                }
              }
            }}
            type="button"
          >
            {text}
          </button>
        </li>
      )}
    />
  );
};

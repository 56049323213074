import { action, makeObservable, observable, computed, reaction } from 'mobx';
import { head, get } from 'lodash';
import { ApartmentDto, getMaxRowsTitles, Section } from 'services';
import { MayBe } from 'types';
import { findById } from '../../../../utils/fp';
import { buildingGridStore } from '../buildingGridStore';

export type FloorsParams = Array<[string, ApartmentDto[], boolean]>;

type State = {
  /** @desc массив кортежей с квартирами для каждого этажа */
  floors: FloorsParams;
  /** @desc индекс активного этажа */
  activeFloor: number;
  /** @desc индекс активной секции */
  activeSection: number;
  activeApartmentIndex: number | undefined;
  activeApartment: ApartmentDto | undefined;
  getSections: Section[];
};

export class FloorPlanStore {
  state: State = {
    floors: [],
    activeFloor: 0,
    activeSection: 0,
    activeApartment: undefined,
    activeApartmentIndex: undefined,
    getSections: [],
  };

  constructor() {
    makeObservable(this, {
      state: observable.deep,
      changeActiveFloor: action.bound,
      clear: action,
      mapFloorsToState: action.bound,
      mapApartmentToFloors: action.bound,

      activeApartmentIndex: computed,
      activeFloor: computed,
      activeFloorNumber: computed,
      activeApartment: computed,
      floors: computed,
      activeFloorApartment: computed,
    });

    reaction(
      () => buildingGridStore?.getSections,
      (getSections) => {
        this.state.getSections = getSections;
      },
    );
  }

  clear = () => {
    this.state.activeFloor = 0;
    this.state.floors = [];
    this.state.activeApartmentIndex = undefined;
    this.state.activeApartment = undefined;
  };

  get floors() {
    return this.state.floors;
  }

  get activeFloor() {
    return this.state.activeFloor;
  }

  get activeSection() {
    return this.state.activeSection;
  }

  get activeFloorNumber() {
    return get(this.state.floors, [this.state.activeFloor, 0]);
  }

  get activeApartmentIndex() {
    return this.state.activeApartmentIndex;
  }

  get activeApartment() {
    return this.state.activeApartment;
  }

  get activeFloorApartment() {
    return get(this.state.floors, [this.state.activeFloor, 1]);
  }

  /**
   * @desc записать новую открытую квартиру и ее индекс в стор
   * */
  setActiveApartment = (activeApartment: ApartmentDto | undefined) => {
    if (activeApartment?.floor) {
      this.state.activeFloor = this.findFloorIndexByNumber(
        activeApartment.floor.toString(),
      );

      const apartmentsOfFloor = this.getApartmentByFloorTitle(this.activeFloor)(
        this.floors,
      );

      const apartmentId = activeApartment?.id?.toString();

      this.state.activeApartment = apartmentsOfFloor?.find(
        findById(apartmentId),
      );
      this.state.activeApartmentIndex = apartmentsOfFloor?.findIndex(
        findById(apartmentId),
      );
    } else {
      this.state.activeApartmentIndex = undefined;
      this.state.activeApartment = undefined;
    }
  };

  /** @desc поиск квартиры по индексу в массиве текущего активного этажа */
  findApartmentByIndex = (index: number) => {
    const apartmentsOfFloor = this.getApartmentByFloorTitle(this.activeFloor)(
      this.floors,
    );
    return apartmentsOfFloor ? apartmentsOfFloor[index] : undefined;
  };

  getApartmentByFloorTitle =
    (floorNumber: number) =>
    (floors: FloorsParams): ApartmentDto[] | undefined =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      get(floors, [floorNumber, 1]);

  findFloorIndexByNumber = (number: string) =>
    this.state.floors.findIndex((floor) => head(floor) === number);

  /** @desc изменить индекс активного этажа */
  changeActiveFloor = (activeFloor: number): void => {
    this.state.activeFloor = activeFloor;
  };

  /** @desc изменить индекс активной секции */
  changeActiveSection = (activeSection: number): void => {
    if (activeSection !== this.state.activeSection) {
      const { floors, getSections = [] } = this.state;
      const sectionFloors =
        getSections[activeSection]?.matrix?.rowsTitles || [];

      const nextFloors = floors.map((values) => {
        values[2] = sectionFloors?.includes(values[0]);
        return values;
      });

      this.state.floors = nextFloors;
    }
    this.state.activeSection = activeSection;
  };

  /** @desc сформировать список этажей, порядок этажей от последнего к первому */
  mapFloorsToState = (sections?: MayBe<Section[]>) => {
    const { getSections = [], activeSection } = this.state;
    const sectionFloors = getSections[activeSection]?.matrix?.rowsTitles || [];

    if (!sections) {
      return;
    }
    const rowsTitles: string[] = getMaxRowsTitles(sections || []);

    if (!rowsTitles) {
      return;
    }
    const floors: FloorsParams = observable.array([]);

    rowsTitles.forEach((floor) => {
      floors.push([floor, [], sectionFloors.includes(floor)]);
    });

    this.state.floors = floors;
  };

  /** @desc сопоставить квартиры с их этажами */
  mapApartmentToFloors = (apartments?: MayBe<ApartmentDto[]>) => {
    if (!apartments) {
      return;
    }

    const { floors } = this.state;

    apartments?.forEach((apartment) => {
      if (!apartment?.floor) {
        return;
      }
      const number = apartment?.floor?.toString();
      const floorIndex = this.findFloorIndexByNumber(number);
      const apartmentListIndex = 1;

      floors[floorIndex][apartmentListIndex] = [
        ...floors[floorIndex][apartmentListIndex],
        apartment,
      ];
    });

    this.state.floors = floors;
  };
}

export const floorPlanStore = new FloorPlanStore();

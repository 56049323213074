import * as YupDefault from 'yup';
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Обязательно для заполнения',
  },
  string: {
    email: 'Неверный формат электронной почты',
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Должно быть не менее $ {min} символов.',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Должен быть не более ${max} символов',
  },
});

export const Yup = YupDefault;

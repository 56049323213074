import React, { memo, useMemo } from 'react';
import { head } from 'lodash/fp';
import { useId } from 'react-id-generator';
import deepEqual from 'fast-deep-equal';
import { Cell, getMatrix, Matrix, Section } from '../../../../../../services';

import { MayBe } from '../../../../../../types';
import { SectionItem } from '../SectionItem';
import { GridCardLink } from '../GridCardLink';
import { cellGroupByXY } from '../../utils';

type Props = {
  section?: MayBe<Section>;
  /** @desc кол-во колонок в предыдущей секции + 1 */
  offsetX: number;
  offsetY: number;
};

export const GridSectionRender = ({ section, offsetX, offsetY }: Props) => {
  const matrix: MayBe<Matrix> | undefined = useMemo(
    () => getMatrix(section),
    [section],
  );

  const items = useMemo(
    () => matrix?.items && cellGroupByXY(matrix?.items),
    [matrix?.items],
  );

  const itemsEntries: [string, Cell[]][] = useMemo(() => {
    if (!items) {
      return [];
    }
    return Object.entries(items);
  }, [items]);
  const idList: string[] = useId(itemsEntries.length, 'SectionItem');

  const rowOffset = 3 + offsetY;
  const colOffset = 2 + offsetX;

  return (
    <>
      {itemsEntries &&
        itemsEntries.map(([_, cells], index) => {
          const firstCell: Cell = head(cells) as Cell;

          return (
            <SectionItem
              key={idList[index]}
              matrixItem={firstCell}
              rowOffset={rowOffset}
              colOffset={colOffset}
            >
              {cells.map((item) => (
                <GridCardLink key={item.title} matrixItem={item} />
              ))}
            </SectionItem>
          );
        })}
    </>
  );
};

export const GridSection = memo(GridSectionRender, deepEqual);

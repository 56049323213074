import React from 'react';
import cc from 'classcat';
import { TruncateHtml } from 'components/atoms/TruncateHtml';
import styles from './styles.module.css';

type Props = {
  content?: string;
  className?: string;
};

export const ComplexInfoDescription = ({ content, className }: Props) => (
  <div className={cc([styles.content, className])}>
    <TruncateHtml text={content} />
  </div>
);

import { useEffect } from 'react';
import { offerItemStore } from '../../../stores';

type Props = {
  hash?: string;
  offerId?: string;
};

export const useLoadOfferByHash = ({ hash, offerId }: Props) => {
  useEffect(() => {
    if (!hash) {
      return;
    }
    void offerItemStore.fetchOfferByHash({ hash });
  }, [offerId]);
};

import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/react';

type Props = {
  children?: ReactNode;
};

export const ErrorBoundary = ({ children }: Props) => (
  <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>
);

import React, { ComponentProps } from 'react';
import cc from 'classcat';
import clipboardIcon from 'assets/clipboardIcon.svg';
import tickIcon from 'assets/tick.svg';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from 'components/atoms';
import { RenderPropProps } from '../../index';
import styles from './styles.module.css';

type Props = RenderPropProps & ComponentProps<typeof ButtonBase>;

export const CopyLinkButton = ({
  isAnimation,
  onClick,
  label,
  variant,
  ...rest
}: Props) => (
  <ButtonBase
    onClick={onClick}
    variant={variant}
    className={cc([
      styles.button,
      {
        [styles.buttonLink]: variant === 'text',
        [styles.animate]: isAnimation,
      },
    ])}
    icon={
      variant !== 'text' && (
        <Icon
          src={cc({
            [clipboardIcon]: !isAnimation,
            [tickIcon]: isAnimation,
          })}
        />
      )
    }
    {...rest}
  >
    {label}
  </ButtonBase>
);

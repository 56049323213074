import React, { useCallback, useEffect } from 'react';
import { observer } from 'mobx-react';
import { FloorList } from '../../components/FloorList';
import { floorPlanStore } from '../../../../stores/FloorPlanStore';

export const FloorListContainerRender = () => {
  const { activeFloor, floors, changeActiveFloor } = floorPlanStore;

  useEffect(() => {
    const firstItem = floors.findIndex((e) => e[2]);
    changeActiveFloor(firstItem);
  }, [floors]);

  const onChange = useCallback(
    (index) =>
      (event: any): void => {
        changeActiveFloor(index);
      },
    [],
  );

  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
      }}
    >
      <FloorList
        onChange={onChange}
        floors={floors}
        activeFloor={activeFloor}
      />
    </div>
  );
};

export const FloorListContainer = observer(FloorListContainerRender);

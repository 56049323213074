import { useCallback } from 'react';
import { OffersEditNoteBody } from 'services';
import { offersEditStore } from 'stores';
import { EditOffersEditNoteFormValues } from 'pages/OffersViewPage/types';

export const useEditNotesForClientSubmit = () => {
  const onListenSubmit = useCallback(
    async (values?: EditOffersEditNoteFormValues) => {
      await offersEditStore.editNote(values as OffersEditNoteBody);
    },
    [],
  );

  return {
    onListenSubmit,
  };
};

import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L, { Map as MapType } from 'leaflet';
import { LeafletStoreClass } from 'stores/LeafletStore';

type Props = {
  storeInstance: LeafletStoreClass;
  polygon: number[][];
};

export const InfoMap = (props: Props) => {
  const map: MapType = useMap();
  map.options.crs = L.CRS.EPSG3395;
  const { storeInstance, polygon } = props;

  useEffect(() => {
    storeInstance.setInstance(map);
    storeInstance.zoom = 14;

    setTimeout(() => {
      if (polygon) {
        const [s, w, n, e]: number[] = [
          polygon.slice().sort((a, b) => a[0] - b[0])[0][0],
          polygon.slice().sort((a, b) => a[1] - b[1])[0][1],
          polygon.slice().sort((a, b) => b[0] - a[0])[0][0],
          polygon.slice().sort((a, b) => b[1] - a[1])[0][1],
        ];

        if (storeInstance.instance) {
          storeInstance.instance.fitBounds(
            [
              [s, w],
              [n, e],
            ],
            { padding: [25, 25] },
          );
        }
        map.invalidateSize();
      }
    });
  }, [storeInstance.instance]);
  return <></>;
};

import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';

import { LogoWithContent, BaseLink, Icon } from 'components/atoms';
import { Complex, getFirstBuildingId } from 'services';

import iconRight from 'assets/rightIcon.svg';
import { StatsTotal } from '../StatsTotal';

import styles from './styles.module.css';

type Props = {
  complex: Complex;
};

export const ComplexInfoHeader = ({ complex }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.left_content}>
      <LogoWithContent src={complex.logo?.url || ''} alt="logo">
        <div className={styles.complexTitle}>
          <LinesEllipsis
            text={complex.title?.trim()}
            maxLine="2"
            ellipsis="..."
            trimRight
          />
        </div>
      </LogoWithContent>
    </div>

    <div className={styles.right_content}>
      <StatsTotal total={complex.stats?.total} />
    </div>

    <BaseLink
      params={{
        complexId: complex?.id?.toString(),
        layoutTabs: 'grid',
        buildingId: getFirstBuildingId(complex.buildings)?.toString(),
      }}
      path="layoutActiveTab"
      icon={<Icon src={iconRight} />}
      iconRight
      className={styles.toFlatsLink}
    >
      Шахматка и план
    </BaseLink>
  </div>
);

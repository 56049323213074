import { makeObservable, observable, action } from 'mobx';
import qs from 'qs';
import { History } from 'history';
import { APP_ROUTES } from '../../../../services';

type AuthRoutes = keyof Pick<
  typeof APP_ROUTES,
  | 'authEmailCodePage'
  | 'authEmailConfirmPage'
  | 'authEmailNotFoundPage'
  | 'authEmailSendLinkPage'
  | 'authEmailValidatePage'
  | 'authPhoneCodePage'
  | 'authPhoneRegistrationPage'
  | 'authPhoneRegistrationSuccessPage'
  | 'authPhoneValidatePage'
  | 'authEmailChangePage'
  | 'linkIsOutOfDatePage'
  | 'privacy'
>;

/**
 * @desc стор хранит
 * */
export class AuthRoutesStore {
  parentRoute = '';

  successAuthRoute: History.LocationDescriptor<any> | string = '';

  constructor() {
    makeObservable(this, {
      successAuthRoute: observable,
      parentRoute: observable,
      setParentRoute: action,
    });
  }

  setParentRoute = (parentRoute: string) => {
    this.parentRoute = parentRoute;
  };

  setSuccessAuthRoute = (
    successAuthRoute: History.LocationDescriptor<any> | string,
  ) => {
    this.successAuthRoute = successAuthRoute;
  };

  /**
   * @desc
   * */
  getRouteWithParent(key: AuthRoutes, queryString?: object): string {
    const authRoute = APP_ROUTES[key];

    const targetRoute = [this.parentRoute, authRoute].join('');

    if (queryString) {
      return [targetRoute, qs.stringify(queryString)].join('?');
    }

    return targetRoute;
  }
}

export const authRoutesStore = new AuthRoutesStore();

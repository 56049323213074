import React, { useState, createContext, ReactNode } from 'react';

export type FiltersContext = {
  currentActiveTab: number;
  setCurrentActiveTab: (val: number) => void;
};

const filtersContext = createContext<FiltersContext>({
  currentActiveTab: 0,
  setCurrentActiveTab: () => {},
});

const { Provider } = filtersContext;

const FiltersProvider = (props: { children: ReactNode }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState<number>(0);

  const states = {
    currentActiveTab,
  };

  const actions = {
    setCurrentActiveTab,
  };

  return <Provider value={{ ...states, ...actions }} {...props} />;
};

export { FiltersProvider, filtersContext };

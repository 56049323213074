import { BaseMapContainer, FullscreenPopup } from 'components/atoms';
import React, { ComponentProps } from 'react';
import { useModal } from 'react-modal-hook';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { LeafletCentroidByPolygons } from 'components/atoms/Map/LeafletCentroidByPolygons';
import { BuildingsPolygons } from '../../../../components/atoms/Map/BuildingsPolygons';
import styles from './styles.module.css';

type Props = ComponentProps<typeof BuildingsPolygons>;

export const BuildingsOnMap = ({ buildings }: Props) => {
  if (!buildings) {
    return null;
  }
  return (
    <div className={styles.buildingsOnMapWrapper}>
      <BaseMapContainer
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <LeafletCentroidByPolygons buildings={buildings} />
        <YandexMapLayer />

        <BuildingsPolygons buildings={buildings} />
      </BaseMapContainer>
    </div>
  );
};

export const useBuildingsOnMapPopup = (props: Props) => {
  const [showBuildingsOnMapPopup, hideBuildingsOnMapPopup] = useModal(
    () => (
      <FullscreenPopup isOpen onClose={hideBuildingsOnMapPopup}>
        <BuildingsOnMap {...props} />
      </FullscreenPopup>
    ),
    [props],
  );

  return {
    showBuildingsOnMapPopup,
    hideBuildingsOnMapPopup,
  };
};

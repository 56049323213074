import React, { ComponentProps, ReactNode } from 'react';
import cc from 'classcat';
import ReactModal from 'react-modal';

import styles from './styles.module.css';

import { MobilePopupHeader } from './components/MobilePopupHeader';

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
} & ComponentProps<typeof MobilePopupHeader>;

export const MobilePopup = ({
  children,
  headerChildren,
  IconBack,
  onClose,
  isOpen,
  className,
}: Props) => (
  <ReactModal
    ariaHideApp={!isOpen}
    isOpen={isOpen}
    onRequestClose={onClose}
    overlayClassName={cc([className, styles.overlay])}
    className={styles.content}
  >
    <MobilePopupHeader
      headerChildren={headerChildren}
      onClose={onClose}
      IconBack={IconBack}
    />
    <div className={styles.scrollbarWrapper}>{children}</div>
  </ReactModal>
);

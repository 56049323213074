import React from 'react';
import cc from 'classcat';

import style from './style.module.css';
import stylesSize from './size.module.css';
import { getIndentClasses, IndentsProps } from '../Indents';

type Props = {
  src?: string;
  alt?: string;
  size?: 'medium' | 'large' | 'xLarge';
  className?: string;
  isCircle?: boolean;
} & IndentsProps;

export const ResidentialComplexLogo = ({
  src,
  size = 'medium',
  alt,
  indent,
  className,
  isCircle,
}: Props) => (
  <div
    className={cc([
      style.wrapper,
      stylesSize[size],
      getIndentClasses(indent),
      className,
      {
        [style.wrapperCircle]: isCircle,
      },
    ])}
  >
    <img className={cc([style.image])} src={src} alt={alt} />
  </div>
);

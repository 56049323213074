import React from 'react';
import { flow, map, get, head } from 'lodash/fp';
import { Polygon } from 'react-leaflet';
import { BuildingEntity, reversePolygonCoords } from 'services';
import { LatLngTuple } from 'leaflet';

type Props = {
  buildings?: Array<BuildingEntity>;
};

export const BuildingsPolygons = ({ buildings }: Props) => (
  <>
    {flow(
      map(get<BuildingEntity, 'polygon'>('polygon')),
      map(reversePolygonCoords),
      (coords: LatLngTuple[]) => (
        <Polygon
          key={head(coords)?.toString()}
          pathOptions={{ color: '#4E6AFF' }}
          positions={coords}
        />
      ),
    )(buildings)}
  </>
);

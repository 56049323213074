import { FormikHelpers } from 'formik/dist/types';
import { loggerService } from '../services';

/**
 * @param executeRecaptcha - функция из хука useGoogleReCaptcha
 * @param onSubmit - функция для submit.
 *
 * @desc функция выполняет получение токена капчи, вставляет его в данные формы и передает измененное состояние в вашу функцию onSubmit
 * */
export const injectReCaptchaTokenToFormValues =
  <Values extends {}>(
    executeRecaptcha?: (action?: string) => Promise<string>,
  ) =>
  (
    onSubmit: (
      values: Values,
      formikHelpers: FormikHelpers<Values>,
    ) => void | Promise<any>,
  ) =>
  async (values: Values, formikHelpers: FormikHelpers<Values>) => {
    if (!executeRecaptcha || !onSubmit) {
      loggerService.warn(
        '[injectReCaptchaTokenToFormValues] executeRecaptcha or onSubmit is not defined.',
      );
      return [values, formikHelpers];
    }

    try {
      const token = await executeRecaptcha();

      return onSubmit(
        {
          ...values,
          'g-recaptcha-response': token,
        },
        formikHelpers,
      );
    } catch (e) {
      loggerService.error('[injectReCaptchaTokenToFormValues]', e);
    }
    return [values, formikHelpers];
  };

import React from 'react';
import { useParams } from 'react-router-dom';
import { Params } from '../../../../services';
import { TabContentRenderer } from '../../../../components/atoms';
import { getActiveTabByRouteName } from '../../utils';
import { LayoutTabs, LayoutTabsEnum } from '../../types';
import { ApartmentViewFilter } from '../ApartmentViewFilter';

/** @deprecated */
/**
 * @desc фильтр представления квартиры нужен только в шахматке,
 * этот компонент реализует это условие и показывает фильтр только если открыта шахматка
 * */
export const ApartmentViewFilterWithShowCondition = () => {
  const { layoutTabs } = useParams<Pick<Params, 'layoutTabs'>>();
  return (
    <TabContentRenderer
      currentActiveTab={getActiveTabByRouteName(layoutTabs as LayoutTabs)}
      tabs={{
        [LayoutTabsEnum.grid]: <ApartmentViewFilter />,
        [LayoutTabsEnum.floorPlan]: <ApartmentViewFilter />,
      }}
    />
  );
};

import { matchPath } from 'react-router';
import { history } from 'hooks/useHistory';
import { favoritesService } from 'services/v1/rc/favorites';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { AuthTokenStore } from 'stores/user/AuthStore/AuthTokenStore';
import { AuthUserStore } from 'stores/user/AuthStore/AuthUserStore';
import { FavoritesComposeAuth } from 'stores/user/FavoritesStore/FavoritesComposeAuth';
import { FavoritesIdsStore } from 'stores/user/FavoritesStore/FavoritesIdsStore';
import {
  appRoutesService,
  authTokenStorageService,
  Params,
  userProfileService,
  userServiceStorage,
} from 'services';
import { LogoutResetStore } from '../AuthStore/LogoutResetStore';
import { FavoritesStore } from '../FavoritesStore';
import {infoStore} from "../../InfoStore";

export class RootProfileStore {
  authUserStore: AuthUserStore;

  authTokenStore: AuthTokenStore;

  authStore: AuthStore;

  logoutResetStores: LogoutResetStore;

  favoritesComposeAuth: FavoritesComposeAuth;

  favoritesIdsStore: FavoritesIdsStore;

  favoritesStore: FavoritesStore;

  constructor() {
    // <editor-fold desc="сторы для профиля и авторизации">
    this.authUserStore = new AuthUserStore();
    this.authTokenStore = new AuthTokenStore();
    this.authStore = new AuthStore(
      this.authUserStore,
      this.authTokenStore,
      userProfileService,
    );
    this.logoutResetStores = new LogoutResetStore();
    // </editor-fold>

    // <editor-fold desc="FAVORITES">
    this.favoritesIdsStore = new FavoritesIdsStore();

    this.favoritesStore = new FavoritesStore();

    this.favoritesComposeAuth = new FavoritesComposeAuth(
      this.authStore,
      this.favoritesIdsStore,
      favoritesService,
      this.favoritesStore,
    );
    // </editor-fold>
  }

  init = () => {
    if (this.currentRouteIsAuthKey()) {
      this.initWithAuthKeyFromAdmin();
    } else {
      this.baseInit();
    }
  };

  /**
   * @desc обычная инициализация частей приложения связанных с авторизацией
   * */
  baseInit = () => {
    // <editor-fold desc="AUTH TOKEN STORE INITIALIZE">
    const token = authTokenStorageService.getToken();
    this.authTokenStore.setTokenToStoreAndRequestHeader(
      token?.token,
      token?.expiresAt,
    );
    this.authTokenStore.initReaction();
    this.authTokenStore.syncTrunk();
    // </editor-fold>

    // <editor-fold desc="AUTH USER STORE INITIALIZE">
    this.authUserStore.setUser(userServiceStorage.getUser());
    this.authUserStore.syncTrunk();
    // </editor-fold>

    this.logoutResetStores.subscribeToIsAuth();

    this.favoritesIdsStore.syncTrunk();
    this.favoritesComposeAuth.initReaction();

    infoStore.loadInfo();
  };

  /**
   * @desc инициализация приложения по ссылке из админ панели
   * для входа под определенным пользователем
   * */
  initWithAuthKeyFromAdmin = () => {
    const expiresAt = new URLSearchParams(window.location.search).get(
      'expiresAt',
    );
    const matchPath = this.currentRouteIsAuthKey();

    // <editor-fold desc="CLEAR FAVORITES IDs STORE AND AUTH STORES">
    this.favoritesIdsStore.clear();
    this.authStore.clear();
    // </editor-fold>

    // <editor-fold desc="AUTH USER STORE INITIALIZE">
    this.authTokenStore.syncTrunk();
    this.authTokenStore.setTokenToStoreAndRequestHeader(
      matchPath?.params.key,
      expiresAt,
    );
    this.authTokenStore.initReaction();
    // </editor-fold>

    // <editor-fold desc="AUTH USER STORE INITIALIZE">
    this.authUserStore.syncTrunk();
    void this.authStore.updateUser().then(([error, result]) => {
      if (error) {
        history.push(appRoutesService.getRoute('linkIsOutOfDatePage'));
        this.authStore.clear();
      }
      if (result) {
        history.push(appRoutesService.getRoute('main'));
      }
    });
    // </editor-fold>

    this.logoutResetStores.subscribeToIsAuth();
    this.favoritesIdsStore.syncTrunk();
    this.favoritesComposeAuth.initReaction();
  };

  currentRouteIsAuthKey = () =>
    matchPath<Pick<Params, 'key'>>(window.location.pathname, {
      path: appRoutesService.getRoute('authKey'),
    });
}

export const rootProfileStore = new RootProfileStore();

rootProfileStore.init();

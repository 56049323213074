import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { AuthEmailCodeRequestBody, authEmailService } from 'services';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

export const useFormSubmit = () => {
  const { push } = useHistory();

  const onSubmit = useCallback(async (values: AuthEmailCodeRequestBody) => {
    const [error, result] = await authEmailService.code({
      ...values,
    });

    if (result) {
      const redirectURL = authRoutesStore.getRouteWithParent(
        'authEmailSendLinkPage',
        {
          email: values.email,
        },
      );
      push(redirectURL);
    }
    if (error) {
      const redirectURL = authRoutesStore.getRouteWithParent(
        'authEmailNotFoundPage',
      );
      push(redirectURL);
    }
  }, []);

  return {
    onSubmit,
  };
};

import React from 'react';
import { observer } from 'mobx-react';
import { ApartmentDto } from 'services';
import { ApartmentsStore } from 'stores/Apartment/ApartmentsStore/ApartmentsStore';
import { useMediaQuery } from 'beautiful-react-hooks';
import { ApartmentTable } from './components/DesktopComponents/ApartmentTable';
import { ComplexItemStore } from '../../../../stores/ComplexItemStore/store';

import { ApartmentListScrollContainer } from './components/ApartmentListScrollContainer';
import { ListMobile } from './components/MobileComponents/ListMobile';
import { ApartmentsNotFound } from '../../containers/ApartmentsNotFound';
import { useApartmentListRequests } from './hooks/useApartmentListRequests';

type Props = {
  complexItemStore: ComplexItemStore;
  apartmentsStore: ApartmentsStore;
};

export const ApartmentListRender = ({
  apartmentsStore,
  complexItemStore,
}: Props) => {
  const { apartments } = apartmentsStore;
  const isLarge = useMediaQuery('(min-width: 1024px)');

  const { initLoading } = useApartmentListRequests({
    apartmentsStore,
    complexItemStore,
  });

  const data: ApartmentDto[] = apartments.data || [];

  return (
    <ApartmentsNotFound isLoading={initLoading} apartmentCount={data.length}>
      <ApartmentListScrollContainer apartmentsStore={apartmentsStore}>
        {isLarge && <ApartmentTable data={data} />}
        {!isLarge && (
          <ListMobile
            isLoading={apartmentsStore.requestApi.isLoading}
            data={data}
          />
        )}
      </ApartmentListScrollContainer>
    </ApartmentsNotFound>
  );
};

export const ApartmentList = observer(ApartmentListRender);

import React, { ComponentProps, ReactNode, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { useGetResponsiveModalComponent } from '../../../../hooks';
import { BasePopup } from '../../../../components/atoms';
import { authRoutesStore } from '../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes';

type Props = {
  children: ReactNode;
  mobileHeaderChildren?: ReactNode;
  mobileHeaderChildrenTitle?: string;
  onClose?: () => void;
  testId?: string;
} & Pick<ComponentProps<typeof BasePopup>, 'size'>;

export const AuthPopupTestIDs = {
  wrapper: 'AuthPopup_wrapper',
};

export const AuthPopup = ({
  children,
  mobileHeaderChildren,
  mobileHeaderChildrenTitle,
  onClose,
  size,
  testId,
}: Props) => {
  const { push } = useHistory();
  const { PopupWrapper } = useGetResponsiveModalComponent({
    DesktopModalWrapper: BasePopup,
    mobileHeaderChildrenTitle,
    mobileHeaderChildren,
  });

  const handlePopupClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      push(authRoutesStore.parentRoute);
    }
  }, []);

  return (
    <PopupWrapper
      {...dataTestIdToProps(testId || AuthPopupTestIDs.wrapper)}
      size={size || 'SM'}
      onClose={handlePopupClose}
      isOpen
    >
      {children}
    </PopupWrapper>
  );
};

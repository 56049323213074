import React, { ReactNode } from 'react';
import styles from './styles.module.css';

type Props = { className?: string; children: ReactNode };

/** @todo: избавиться */
/** @deprecated */
export const PageWrapper = ({ children, className = '' }: Props) => (
  <div className={`${styles.wrapper} ${className}`}>{children}</div>
);

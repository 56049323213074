import React, { useState, TouchEventHandler } from 'react';
import { withProps } from 'recompose';
import { MayBe } from 'types';
import { OffersViewItem, Params } from 'services';
import { InfoCardView } from 'pages/OffersViewPage/components/InfoCardView';
import { useOfferStatus } from 'pages/ProfilePage/pages/OffersPage/hooks/useOfferStatus';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { Header } from '../../components/Header';
import { InfoCardEdit } from '../../components/InfoCardEdit';
import { ApartmentAndComplexList } from '../ApartmentAndComplexList';
import { Sidebar } from '../Sidebar';
import {
  IsEditOfferContext,
  IsEditOfferContextProps,
} from '../../context/IsEditOfferContext';
import { OfferNotAvailable } from '../../components/OfferNotAvailable';
import { useUserIsOwnersOfferItem } from '../../../../hooks';

type Props = {
  offer: MayBe<OffersViewItem>;
  isIframeMode?: boolean;
} & IsEditOfferContextProps;

export const OffersViewContainerRender = ({
  offer,
  isEditOffer,
  isOfferPreview,
  isIframeMode,
}: Props) => {
  const { hash } = useParams<Pick<Params, 'hash'>>();

  const { statusIsActive } = useOfferStatus(offer?.status_id as number);
  const { userIsOwner } = useUserIsOwnersOfferItem({ offer });

  const isNotActive = statusIsActive !== undefined && !statusIsActive;

  const showNotAvailable = isNotActive && !isEditOffer;

  const showContent = statusIsActive || (isEditOffer && userIsOwner);

  const [show, setShow] = useState(false);

  const handleTouch: TouchEventHandler = () => {
    setShow(!show);
  };

  return (
    <IsEditOfferContext.Provider value={{ isEditOffer, isOfferPreview }}>
      <Layout
        content={() => (
          <>
            {showNotAvailable && <OfferNotAvailable />}

            {showContent && (
              <>
                <Header title={offer?.title}>
                  {isEditOffer && (
                    <InfoCardEdit
                      apartmentCount={offer?.apartments?.length || 0}
                      viewCount={offer?.views_count}
                      updated_at={offer?.updated_at}
                      viewed_at={offer?.viewed_at}
                    />
                  )}

                  {!isEditOffer && hash && (
                    <InfoCardView
                      hash={hash}
                      apartmentCount={offer?.apartments?.length || 0}
                      updated_at={offer?.updated_at}
                    />
                  )}
                </Header>
                <ApartmentAndComplexList
                  offer={offer}
                  offerId={offer?.id}
                  offerHash={offer?.hash}
                  apartments={offer?.apartments}
                  complexes={offer?.complexes}
                  isIframeMode={isIframeMode}
                />
              </>
            )}
          </>
        )}
        sidebar={withProps({ isEditOffer, offer, handleTouch })(Sidebar)}
        isEdit={isEditOffer}
        isShown={show}
      />
    </IsEditOfferContext.Provider>
  );
};

export const OffersViewContainer = observer(OffersViewContainerRender);

import { DivIcon } from 'leaflet';
import location_icon from '../../../../../../assets/bigPin.svg';
import styles from './styles.module.css';

export const MapPin = new DivIcon({
  html: `<img src="${location_icon}" alt="pin" style="height:48px"/>`,
});

export const getMapPin = (style: string) =>
  new DivIcon({
    html: `<img src="${location_icon}" alt="pin" style="${style}"/>`,
  });
export const MapPoint = new DivIcon({
  html: `<div class="${styles.point}"></div>  `,
});

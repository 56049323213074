import { Icon } from 'components/atoms/Icon';
import React from 'react';
import ReactJoin from 'react-join';
import userGray from 'assets/userGray.svg';
import { NumberFormat } from 'components/atoms';
import { MayBe } from '../../../../../../types';
import styles from './styles.module.css';

type Props = {
  name?: MayBe<string>;
  emails?: MayBe<string[]>;
  phones?: MayBe<string[]>;
};

export const OffersContact = ({ name, phones, emails }: Props) => (
  <div className={styles.offersContactWrapper}>
    <div className={styles.offersContactLeft}>
      <div className={styles.offersContactClientIcon}>
        <Icon src={userGray} size={16} />
      </div>
      <div className={styles.offersContactClientName}>{name}</div>
    </div>
    <div className={styles.offersContactRight}>
      {phones && (
        <div>
          <ReactJoin separator={<span>, </span>}>
            {phones.map((phone, index) => (
              <NumberFormat
                key={index.toString()}
                format="mobile"
                value={phone}
              />
            ))}
          </ReactJoin>
        </div>
      )}
      {emails && <div>{emails.join(', ')}</div>}
    </div>
  </div>
);

import React from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { flow, add, size, map, reduce } from 'lodash/fp';
import { Icon, Badge, BaseLink, Text, ListItem } from 'components/atoms';
import { preventDefault } from 'utils/fp/preventDefault';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import likeGray from '../../../assets/likeGray.svg';
import styles from '../../../pages/Main/containers/Navbar/NavbarDesktop/components/NavbarRightContent/styles.module.css';
import { dataTestIdToProps } from '../../../utils/test/dataTestIdToProps';

type Props = {
  onClick?: () => void;
};

export const FavoritesNavButtonTestIDs = {
  button: 'FavoritesNavButton_button',
};

/** @todo логику работы с избранным перенести в hoc */
export const FavoritesNavButtonRender = ({ onClick }: Props) => {
  const isLarge = useMediaQuery('(min-1024px)');
  const { complexIds, apartmentIds } = rootProfileStore.favoritesIdsStore;
  const totalFavoritesCount = flow(
    map(size),
    reduce(add)(0),
  )([complexIds, apartmentIds]);

  const BadgeWithFavoritesCount =
    totalFavoritesCount > 0 ? (
      <Badge color="#4E6AFF" className={styles.badge}>
        {totalFavoritesCount}
      </Badge>
    ) : null;

  if (isLarge) {
    return (
      <BaseLink
        {...dataTestIdToProps(FavoritesNavButtonTestIDs.button)}
        icon={<Icon src={likeGray} />}
        path="favorites"
        color="transparent"
        badge={BadgeWithFavoritesCount}
      >
        Избранное
      </BaseLink>
    );
  }

  return (
    <BaseLink
      {...dataTestIdToProps(FavoritesNavButtonTestIDs.button)}
      path="favorites"
      component={(props: any) => (
        <ListItem
          {...props}
          onClick={(event: any) => {
            flow(preventDefault, props.navigate)(event);
            if (onClick) {
              onClick();
            }
          }}
          icon={<Icon src={likeGray} />}
          badge={BadgeWithFavoritesCount}
          component="a"
        >
          <Text weight="700">Избранное</Text>
        </ListItem>
      )}
    />
  );
};

export const FavoritesNavButton = observer(FavoritesNavButtonRender);

import { UIPropertyVisibilityFilters } from '../../UI/UIPropertyVisibilityFilters';
import {
  ApartmentsViewFilterValues,
  apartmentUIPropertyVisibilityFilter,
} from '../ApartmentsViewFilters/ApartmentsViewFilters';
import { ApartmentsStore } from '../ApartmentsStore/ApartmentsStore';
import { ApartmentUIStore } from '../ApartmentUIStore/ApartmentUIStore';
import { ApartmentAccessStatusStore } from '../ApartmentAccessStatusStore';

export class RootApartmentStore {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public readonly apartmentsStore: ApartmentsStore,
    public readonly apartmentAccessStatusStore: ApartmentAccessStatusStore,
    public readonly apartmentUIStore: ApartmentUIStore,
    public readonly apartmentsViewFilters: UIPropertyVisibilityFilters<ApartmentsViewFilterValues>,
  ) {
    //
  }
}

const apartmentsStore = new ApartmentsStore();
const apartmentUIStore = new ApartmentUIStore();
const apartmentAccessStatusStore = new ApartmentAccessStatusStore();

apartmentAccessStatusStore.syncTrunk();

export const rootApartmentStore = new RootApartmentStore(
  apartmentsStore,
  apartmentAccessStatusStore,
  apartmentUIStore,
  apartmentUIPropertyVisibilityFilter,
);

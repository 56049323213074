import React from 'react';
import styles from './style.module.css';

import { Skeleton } from '../../../../components/atoms/Skeleton';

export const ComplexInfoSkeleton = () => (
  <div className={styles.wrapper}>
    <Skeleton
      width={60}
      height={60}
      animation="wave"
      className={styles.logo}
      highlightColor="rgba(35, 46, 73, 0.05)"
    />
    <div className={styles.content}>
      <Skeleton
        width={70}
        height={16}
        animation="wave"
        className={styles.title}
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        width={180}
        height={14}
        animation="wave"
        className={styles.address}
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
      <Skeleton
        width={90}
        height={21}
        animation="wave"
        highlightColor="rgba(35, 46, 73, 0.05)"
      />
    </div>
  </div>
);

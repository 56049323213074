import React from 'react';
import { observer } from 'mobx-react';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { useHistory } from 'react-router-dom';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { appRoutesService } from 'services';
import styles from '../../../../../AuthPage/pages/AuthEmailSendLinkPage/styles.module.css';

export const OldEmailConfirmPopupRender = () => {
  const { push } = useHistory();

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Сначала подтвердите <br /> старую почту
        </div>
        <div className={styles.subTitle}>
          Ссылка отправлена на почту{' '}
          <strong>{rootProfileStore.authUserStore.user?.email}</strong> и
          действует 24 часа
        </div>
      </div>
    </AuthPopup>
  );
};

export const OldEmailConfirmPopup = observer(OldEmailConfirmPopupRender);

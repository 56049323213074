import React, { ReactNode } from 'react';
import cc from 'classcat';
import { Text } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  fieldName: string;
  className?: string;
};

export const FormFieldLabel = ({ children, fieldName, className }: Props) => (
  <Text
    weight="700"
    size="M"
    className={cc([styles.label, className])}
    tagName="label"
    htmlFor={fieldName}
  >
    {children}
  </Text>
);

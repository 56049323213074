import React from 'react';
import refresh from 'assets/refresh.svg';

import {
  dateFormat,
  AddressFullView,
  ResidentialComplexLogo,
} from 'components/atoms';
import { useMediaQuery } from 'beautiful-react-hooks';
import { ButtonBase } from '../ButtonBase';

import styles from './style.module.css';
import { ComplexRaw } from '../../../../services';

type Props = {
  onRefresh?: () => void;
} & Pick<ComplexRaw, 'title' | 'address' | 'logo' | 'updated_at'>;

export const ComplexInfo = ({
  logo,
  title,
  address,
  updated_at,
  onRefresh,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  return (
    <div className={styles.wrapper}>
      <ResidentialComplexLogo size="large" src={logo?.logo} />
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {Array.isArray(address) && (
          <div className={styles.address}>
            <AddressFullView
              address={address}
              excludeAddressScheme={['country', 'region', 'settlement']}
            />
          </div>
        )}
        <ButtonBase
          onClick={onRefresh}
          color={isLarge ? 'normal' : 'default'}
          size="small"
          icon={<img src={refresh} alt="refresh" />}
          className={styles.refreshButton}
        >
          Обновлено {updated_at && dateFormat(updated_at, 'dateMonthTime')}
        </ButtonBase>
      </div>
    </div>
  );
};

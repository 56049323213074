import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';
import {
  authPhoneService,
  AuthPhoneValidateRequestBody,
  loggerService,
} from 'services';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { useRequestApi } from 'hooks';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { AuthPhoneValidateFormValues } from '../../types';
import { authRoutesStore } from '../../../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes';

type Props = {
  authStore: AuthStore;
};

export const useFormSubmit = ({ authStore }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { isError, isSubmitting, setError, setSubmitting, clear } =
    useRequestApi();
  const { push } = useHistory();

  const onSubmit = async (
    values: AuthPhoneValidateFormValues,
    formikHelpers: FormikHelpers<any>,
  ) => {
    clear();
    try {
      const data = await authPhoneService.validate({
        ...values,
      });

      if (data && data.isNew) {
        const redirectUrl = authRoutesStore.getRouteWithParent(
          'authPhoneRegistrationPage',
          {
            ...values,
            expiresAt: data.expiresAt,
          },
        );
        push(redirectUrl);
      } else {
        authStore.setAuthData(data.user, data.hash, data.expiresAt);
        const redirectUrl = authRoutesStore.successAuthRoute;
        push(redirectUrl);
      }
      setSubmitting(true);
    } catch (e) {
      loggerService.error(e);
      submissionErrorsHandler(e, formikHelpers);
      setSubmitting(true);
      setError(true);
    }
  };

  return {
    onSubmit:
      injectReCaptchaTokenToFormValues<AuthPhoneValidateRequestBody>(
        executeRecaptcha,
      )(onSubmit),
    isError,
    isSubmitting,
  };
};

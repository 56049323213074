import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { BasePopup } from 'components/atoms/Popup/BasePopup';

import iconLeft from 'assets/leftIcon.svg';

import { CardTabs } from 'pages/ComplexPage/components/CardTabs';
import { FlexBox, ModalRoute, FloorPlanOverlay } from 'components/atoms';
import { NearbyObjects } from 'pages/ComplexPage/components/NearbyObjects';
import { appRoutesService, getFirstBuildingId, Params } from 'services';
import { feedbackStore } from 'stores/FeedbackStore/FeedbackStore';

import { PhotoAlbumContainer } from 'components/containers/Albums/PhotoAlbumContainer';
import {
  getCountConstructionProgressAlbums,
  getCountPhotoAlbums,
} from 'services/v1/rc/album/helpers';
import { useWillUnmount } from 'beautiful-react-hooks';
import { guardedModalRoute } from 'hocs';
import { TabButton, TabList } from 'components/containers/Tabs';
import { ViewProvider } from 'context';
import { ComplexPresentationCarousel } from './containers/ComplexPresentationCarousel';
import { ComplexInfo } from './containers/ComplexInfo';
import { Benefits } from './containers/Benefits';
import { QueuesBlock } from './containers/QueuesBlock';
import { ComplexInfoDescription } from './containers/ComplexInfo/components/ComplexInfoDescription';
import { CatalogsTable } from './containers/ComplexInfo/components/CatalogsTable';

import { complexItemStore } from '../../stores/ComplexItemStore/store';
import { DealDocuments } from './components/DealDocuments';
import { SalesDepartmentsCard } from '../../components/containers/SalesDepartmentsCard';
import { BaseLink } from '../../components/atoms';

import styles from './styles.module.css';
import { PromotionPage } from '../PromotionPage';
import { PromotionsSection } from './containers/PromotionsSection';
import { ConstructionProgressContainers } from '../../components/containers/Albums/ConstructionProgressContainers';
import { dataTestIdToProps } from '../../utils/test/dataTestIdToProps';
import { AuthPage } from '../AuthPage';
import { SalesDepartmentsPage } from '../SalesDepartmentsPage';
import { authRoutesStore } from '../../stores/user/AuthStore/AuthRoutes/AuthRoutes';
import { AuthForGetAccessToSalesDepartments } from './pages/AuthForGetAccessToSalesDepartments';
import { buildingFilters, complexFilterStore } from '../../stores';
import * as S from './ComplexPage.styled';

const mapTabs = [
  {
    label: 'Карта',
  },
  {
    label: 'План',
  },
];

const ComplexPageTestIDs = {
  wrapper: 'ComplexPage_wrapper',
};

export const ComplexPageRender = () => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  const [currentMapTab, setMapTab] = useState(0);
  const {
    isVisible,
    target: complexData,
    toggleVisibility,
    fetchComplex,
    clearTarget,
  } = complexItemStore;

  const isVillage = complexData?.type === 'village';

  useEffect(() => {
    /**
     * @desc сохранение состояния фильтра с главной страницы для фильтра в шахматке
     * */
    buildingFilters.filters.setFields(complexFilterStore.filters.filters);

    const url = appRoutesService.replaceRoute('view', {
      complexId,
    });
    authRoutesStore.setParentRoute(url);
  }, [complexId]);

  useWillUnmount(() => {
    authRoutesStore.setParentRoute('');
    authRoutesStore.setSuccessAuthRoute('');
  });

  const targetBadges: number[] =
    (complexData?.catalogs?.residential_complex_badges &&
      [...complexData?.catalogs?.residential_complex_badges].sort(
        (a, b) => a - b,
      )) ||
    [];

  const history = useHistory();

  const docsRef = useRef<HTMLDivElement>(null);
  const photosRef = useRef<HTMLDivElement>(null);
  const benefitsRef = useRef<HTMLDivElement>(null);
  const constructionRef = useRef<HTMLDivElement>(null);
  const nearRef = useRef<HTMLDivElement>(null);

  const [forwardedContentRef, setForwardedContentRef] = useState<
    HTMLDivElement | undefined
  >(undefined);

  /**
   * @desc переделать
   * */
  useEffect(() => {
    if (!complexData || complexData?.id !== Number(complexId)) {
      void fetchComplex(Number(complexId));
      toggleVisibility(true);
      feedbackStore.setInitialValues({
        type: 'complex',
        recordId: complexId,
      });
    }
  }, [complexId]);

  const tabs: Record<string, { title: string; show: boolean }> = {
    ComplexInfo: { title: 'Информация о ЖК', show: true },
    saleOccasions: { title: 'Акции', show: false },
    news: { title: 'Новости', show: false },
    near: { title: 'Поблизости', show: true },
    benefits: { title: 'Преимущества', show: true },
    apartments: { title: 'Квартиры', show: false },
    albums: {
      title: 'Фотографии',
      show: getCountPhotoAlbums(complexData?.albums) > 0,
    },
    constructionAlbums: {
      title: 'Ход строительства',
      show: getCountConstructionProgressAlbums(complexData?.albums) > 0,
    },
    documents: { title: 'Документы и сделка', show: true },
    sellPoints: { title: 'Отделы продаж', show: false },
  };

  const tabItemList = Object.keys(tabs).reduce((acc, current) => {
    if (tabs[current]?.show) {
      acc[current] = tabs[current].title;
    }
    return acc;
  }, {} as Record<string, string>);

  if (complexData === null) {
    return null;
  }

  const formattedPolygon: Array<[number, number]> =
    complexItemStore.reversePolygonCoords(complexData.polygon);

  return (
    <BasePopup
      isOpen={isVisible}
      onClose={() => {
        toggleVisibility(false);
        history.push('/');
        clearTarget();
      }}
      buttonCloseClassName={styles.buttonClosePopup}
      className={styles.complexPageModal}
      size="XL"
      setForwardedContentRef={setForwardedContentRef}
    >
      <div
        {...dataTestIdToProps(ComplexPageTestIDs.wrapper)}
        className={styles.root}
      >
        {complexData && (
          <ComplexPresentationCarousel
            complex={complexData}
            badges={targetBadges}
          />
        )}

        <div className={styles.toMainPageLink}>
          <BaseLink
            path="main"
            icon={<img src={iconLeft} alt="go to main page" />}
            className={styles.toFlatsLink}
          >
            Назад на главную
          </BaseLink>
        </div>

        <CardTabs
          items={tabItemList}
          refsMap={{
            near: nearRef,
            documents: docsRef,
            albums: photosRef,
            benefits: benefitsRef,
            constructionAlbums: constructionRef,
          }}
          modalContentRef={forwardedContentRef}
        />

        <FlexBox
          className={styles.contentRoot}
          flexDirection="column"
          alignItems="normal"
        >
          <section className={styles.mainInfoWrapper}>
            <div className={styles.complexPage_wrapper_complexInfo}>
              <ComplexInfo complex={complexData} polygon={formattedPolygon} />
            </div>

            <div className={styles.descriptionAndCatalog_wrapper}>
              {complexData?.description && (
                <div
                  className={styles.descriptionAndCatalog_description_wrapper}
                >
                  <ComplexInfoDescription
                    content={complexData?.description || ''}
                  />
                </div>
              )}
              <CatalogsTable
                catalogs={complexData.catalogs}
                excludeInfoKeys={[
                  'residential_complex_advantages',
                  'residential_complex_badges',
                ]}
              />
            </div>

            {complexData.developer && (
              <div className={styles.salesDepartmentsCard_wrapper}>
                <SalesDepartmentsCard
                  complexId={complexData.id}
                  complexUpdatedAt={complexData.updated_at}
                  developer={complexData.developer}
                  salesDepartments={complexData.salesDepartments}
                  buildingId={getFirstBuildingId(complexData.buildings)}
                  isVillage={isVillage}
                />
              </div>
            )}
          </section>

          {Array.isArray(complexData.stocks) && (
            <PromotionsSection list={complexData.stocks} />
          )}

          <S.TabsMenuWrapper>
            <TabList currentActiveTab={currentMapTab}>
              {mapTabs.map(({ label }, idx) => (
                <TabButton
                  isActive={idx === currentMapTab}
                  onClick={() => setMapTab(idx)}
                  key={label}
                  label={label}
                />
              ))}
            </TabList>
          </S.TabsMenuWrapper>

          {currentMapTab === 0 && complexData.buildings && (
            <NearbyObjects
              innerRef={nearRef}
              description="Поблизости"
              buildings={complexData.buildings}
              polygon={formattedPolygon}
            />
          )}

          {currentMapTab === 1 && complexData?.sectionLogo && (
            <FloorPlanOverlay imageUrl="https://files.dev-esello.ru/uploads/floor_layout/2021/4/93/4b/934b5c4dc0a2e1b4069577d54c914777.png" />
          )}

          <Benefits
            complexCatalogs={complexData.catalogs}
            innerRef={benefitsRef}
          />

          <ViewProvider>
            <QueuesBlock
              apartmentsCount={complexData?.stats?.total?.count || 0}
              buildings={complexData.buildings}
              isVillage={isVillage}
              statsGroups={complexData.statsGroups}
            />
          </ViewProvider>

          <PhotoAlbumContainer
            albums={complexData.albums || []}
            complexId={complexData.id}
            innerRef={photosRef}
          />

          <ConstructionProgressContainers
            albums={complexData.albums || []}
            complexId={complexData.id}
            innerRef={constructionRef}
          />

          <DealDocuments
            documents={complexData.documents}
            innerRef={docsRef}
            catalogs={complexData.catalogs}
            infoKeys={[
              'residential_complex_contract_type',
              'residential_complex_payment_type',
            ]}
          />
        </FlexBox>
      </div>
      <Switch>
        <Route path={appRoutesService.getRoute('stock')}>
          <PromotionPage />
        </Route>
      </Switch>

      <AuthPage
        components={{
          authPhoneCodePage: () => <AuthForGetAccessToSalesDepartments />,
          authEmailCodePage: () => <AuthForGetAccessToSalesDepartments />,
        }}
        parentRoute={appRoutesService.getRoute('view')}
      />
      <ModalRoute
        component={guardedModalRoute(
          SalesDepartmentsPage,
          authRoutesStore.getRouteWithParent('authPhoneCodePage'),
        )}
        path="salesDepartment"
      />
    </BasePopup>
  );
};

export const ComplexPage = observer(ComplexPageRender);

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  title: string;
  subTitle: string;
  formComponent: ReactNode;
  imagePreview: string;
  imageWrapperClassName?: string;
};

export const MustBeAuthForContinue = ({
  title,
  subTitle,
  formComponent,
  imagePreview,
  imageWrapperClassName,
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.content}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subTitle}</p>
      <div className={styles.formWrapper}>{formComponent}</div>
    </div>
    <div className={cc([styles.imageWrapper, imageWrapperClassName])}>
      <img className={styles.image} src={imagePreview} alt="" />
    </div>
  </div>
);

import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik/dist/types';
import { Alert, AlertSubmission } from 'components/atoms';
import { authPhoneValidateSchema } from 'validationSchemas';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import {
  appRoutesService,
  AuthPhoneCodeRequestBody,
  authPhoneService,
  AuthPhoneValidateRequestBody,
} from 'services';
import { useRequestApi } from 'hooks';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { useFetchAuthValidationCode } from '../../../AuthPage/hooks/useFetchAuthValidationCode';
import styles from '../../../AuthPage/pages/AuthPhoneValidatePage/styles.module.css';
import { FormRender } from '../../../AuthPage/pages/AuthPhoneValidatePage/components/FormRender';
import { withGoogleReCaptchaProvider } from '../../../../contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { changePhoneStore } from '../../stores/ChangePhoneStore';

export const ProfileConfirmOldPhonePopupRender = () => {
  const { fetchPhoneCode, requestState, query } = useFetchAuthValidationCode();
  const { setError, setSubmitting, clear } = useRequestApi();

  const { push } = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (
    values: AuthPhoneValidateRequestBody,
    formikHelpers: FormikHelpers<AuthPhoneValidateRequestBody>,
  ) => {
    clear();
    try {
      await authPhoneService.validate(values);
      changePhoneStore.setOldPhoneConfirm(true);
      push(appRoutesService.getRoute('profileConfirmNewPhone'));
    } catch (e) {
      submissionErrorsHandler(e, formikHelpers);
      setSubmitting(true);
      setError(true);
    }
  };

  useEffect(() => {
    reaction(
      (): boolean => rootProfileStore.authStore.isAuth,
      () => {
        if (!rootProfileStore.authUserStore.user?.phone) {
          return;
        }
        void injectReCaptchaTokenToFormValues<AuthPhoneCodeRequestBody>(
          executeRecaptcha,
        )((values) => {
          fetchPhoneCode({
            ...values,
            phone: rootProfileStore.authUserStore.user?.phone,
          })(values);
        })({}, {} as any);
      },
      {
        fireImmediately: true,
      },
    );
  }, []);

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
      mobileHeaderChildrenTitle="Подтверждение номера"
    >
      <Formik<AuthPhoneValidateRequestBody>
        initialValues={{
          phone: rootProfileStore.authUserStore.user?.phone,
          authId: query.id,
        }}
        validationSchema={authPhoneValidateSchema}
        onSubmit={injectReCaptchaTokenToFormValues<AuthPhoneValidateRequestBody>(
          executeRecaptcha,
        )(onSubmit)}
        render={(props) => (
          <AuthFormWrapper
            className={styles.wrapper}
            showButtonBack
            onClickButtonBack={() => {
              push(appRoutesService.getRoute('profileNewPhone'));
            }}
          >
            <div className={styles.formWrapper}>
              <FormRender
                {...props}
                UIDictionary={{
                  title: 'Сейчас мы позвоним вам на старый номер',
                  subTitle:
                    'Введите последние 4 цифры номера, с которого поступит звонок',
                }}
                reCall={injectReCaptchaTokenToFormValues(executeRecaptcha)(
                  fetchPhoneCode(query),
                )}
              />
            </div>

            <AlertSubmission isSubmitting errors={props.errors} />

            <Alert isShow={requestState.isError && requestState.isSubmitting}>
              Не удалось выполнить звонок. Проверьте подключение к интернету и
              обновите браузер
            </Alert>
          </AuthFormWrapper>
        )}
      />
    </AuthPopup>
  );
};

export const ProfileConfirmOldPhonePopup = withGoogleReCaptchaProvider(
  observer(ProfileConfirmOldPhonePopupRender),
);

import React, { ComponentProps, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import L from 'leaflet';

import { BaseMapContainer, FloorPlanOverlay, Icon } from 'components/atoms';
import { FullscreenPopup } from 'components/atoms/Popup';
import {
  ApartmentDto,
  getAllApartmentPolygons,
  getAllApartmentsLayoutsIds,
  getAllFloorPlans,
  getApartmentSquares,
  Squares,
} from 'services';
import { MayBe } from 'types';
import { PolygonCoordinates } from 'types/PolygonCoordinates';
import { SquareMetersOnFloorPlan } from 'pages/LayoutPage/pages/FloorPlans/containers/SquareMetersOnFloorPlan';
import { Box } from 'components/styledComponents/Box';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import eyeDark from 'assets/eyeDark.svg';
import eye from 'assets/eye.svg';
import { buildingGridStore } from 'pages/LayoutPage/stores/buildingGridStore';
import { useApartmentPlanOnMap } from '../../pages/ApartmentView/hooks/useApartmentPlanOnMap';
import { FloorPopupsNavBar } from '../../components/FloorPopupsNavBar';
import { ApartmentsOnFloorPlan } from '../../pages/FloorPlans/containers/ApartmentsOnFloorPlan';
import styles from './styles.module.css';

type Props = {
  apartment: ApartmentDto | undefined;
  initialCount?: number;
} & Pick<ComponentProps<typeof FullscreenPopup>, 'onClose'>;

/**
 * @link https://www.figma.com/file/VH9HbBNBGglqqZsQBBtNkU/%D0%A8%D0%B0%D1%85%D0%BC%D0%B0%D1%82%D0%BA%D0%B0?node-id=1%3A720
 * @desc Планировка квартиры на плане этажа
 * */
export const ShowApartmentOnFloorPopup = ({
  onClose,
  apartment,
  initialCount,
}: Props) => {
  const [showMeters, toggleShowMeters] = useState<boolean>(true);
  const apartmentsLayouts = buildingGridStore?.getApartmentsLayouts || [];

  const floorPlans: MayBe<string>[] = getAllFloorPlans(apartment);
  const apartmentPolygons: (PolygonCoordinates[] | undefined)[] =
    getAllApartmentPolygons(apartment);

  const apartmentsLayoutsIds: number[] = getAllApartmentsLayoutsIds(apartment);

  const {
    currentPlan,
    currentPolygon,
    isShowLevelStepper,
    count,
    setCount,
    maxLevel,
  } = useApartmentPlanOnMap({
    plans: floorPlans,
    initialCount,
    polygons: apartmentPolygons,
  });

  const apartmentSquares = useMemo(
    () => getApartmentSquares(apartmentsLayouts, apartmentsLayoutsIds),
    [apartmentsLayouts, apartmentsLayoutsIds],
  );

  const handleToggleShowMeters = () => {
    toggleShowMeters((prev) => !prev);
  };

  return (
    <FullscreenPopup
      header={
        isShowLevelStepper && (
          <div className={styles.header}>
            <FloorPopupsNavBar
              count={count}
              maxLevel={maxLevel}
              setCount={setCount}
            />
          </div>
        )
      }
      isOpen
      onClose={onClose}
    >
      <BaseMapContainer
        crs={L.CRS.Simple}
        scrollWheelZoom
        minZoom={0}
        maxZoom={2}
        maxBoundsViscosity={1}
        className={styles.map}
        style={{
          marginTop: isShowLevelStepper ? 0 : '50px',
        }}
      >
        <FloorPlanOverlay imageUrl={currentPlan} fitBoundsOfPlan />

        {currentPolygon && (
          <ApartmentsOnFloorPlan apartmentPosition={[currentPolygon]} />
        )}

        {!!apartmentSquares?.length && showMeters && (
          <SquareMetersOnFloorPlan
            apartmentSquaresPosition={apartmentSquares as Squares[][]}
          />
        )}

        {!!apartmentSquares?.length && (
          <Box position="absolute" zIndex={999} bottom="10px" left="10px">
            <ButtonBase
              color={showMeters ? 'default' : 'disable'}
              onClick={handleToggleShowMeters}
              icon={<Icon size={16} src={showMeters ? eyeDark : eye} />}
            >
              Площадь
            </ButtonBase>
          </Box>
        )}
      </BaseMapContainer>
    </FullscreenPopup>
  );
};

export const useShowApartmentOnFloorPopup = (
  apartment: ApartmentDto | undefined,
  initialCount?: number,
) => {
  const [showShowApartmentOnFloorPopup, hideShowApartmentOnFloorPopup] =
    useModal(
      () => (
        <ShowApartmentOnFloorPopup
          initialCount={initialCount}
          apartment={apartment}
          onClose={hideShowApartmentOnFloorPopup}
        />
      ),
      [apartment],
    );

  return {
    showShowApartmentOnFloorPopup,
    hideShowApartmentOnFloorPopup,
  };
};

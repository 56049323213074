import React from 'react';
import { offersStore } from 'stores';

export const useOnScrollIncrement = () => {
  const onScrollIncrement = (
    ev: React.SyntheticEvent<HTMLDivElement>,
  ): void => {
    if (offersStore.requestApiStore.isLoading) {
      return;
    }

    const target = ev.target as HTMLDivElement;

    const isBottom =
      target.scrollHeight - target.scrollTop - 10 < target.clientHeight;

    if (offersStore.hasMore && isBottom) {
      const page = (offersStore.pageInfo?.pageInfo?.page || 1) + 1;

      if (
        offersStore?.state &&
        offersStore?.state?.totalPages &&
        page > offersStore?.state?.totalPages
      ) {
        return;
      }

      offersStore.pageInfo.changePage(page);
    }
  };

  return {
    onScrollIncrement,
  };
};

import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { NewEmailFormValues } from '../../types';
import styles from '../../../../../ProfileNewPhonePopup/components/FormRender/styles.module.css';
import { ButtonBase } from '../../../../../../../LayoutPage/components/ButtonBase';
import { Loader } from '../../../../../../../../components/atoms/Loader';
import { FormikTextField } from '../../../../../../../../components/atoms/Form/FormikTextField';

type Props = FormikProps<NewEmailFormValues>;

export const FormRender = ({ handleSubmit, isSubmitting }: Props) => (
  <form className={styles.form} onSubmit={handleSubmit}>
    <div className={styles.title}>Введите новый адрес электронной почты</div>
    <Field
      label="Эл. почта"
      name="newEmail"
      type="email"
      component={FormikTextField}
    />
    <ButtonBase
      icon={isSubmitting && <Loader color="primary" />}
      type="submit"
      fullWidth
      color="primary"
    >
      Продолжить
    </ButtonBase>
  </form>
);

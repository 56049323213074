import { dateFormat } from 'components/atoms';
import { filter, flow, get, isEqual, map, negate, size } from 'lodash/fp';
import { flatten } from 'lodash';
import { Album, AlbumPhoto, AlbumPhotoToFilter, ComplexAlbum } from './types';
import { AlbumFilterOption } from '../../../../stores/AlbumsStore';

export const sortAlbumsDesc = (albums: ComplexAlbum[]) =>
  [...albums].sort((a, b) => {
    if (a.dateAt && b.dateAt) {
      return Date.parse(a.dateAt) - Date.parse(b.dateAt);
    }
    return 0;
  });

export const typeIsConstructionProgress = isEqual('construction-progress');

export const typeIsNotConstructionProgress = negate(typeIsConstructionProgress);

export const getAlbumType = get('type');

export const formatDateAt = (item: ComplexAlbum): ComplexAlbum => ({
  ...item,
  dateAt: dateFormat(item?.dateAt, 'monthYear') as string,
});

export const getPhotoAlbums = flow<any, ComplexAlbum[], ComplexAlbum[]>(
  filter(flow(getAlbumType, typeIsNotConstructionProgress)),
  sortAlbumsDesc,
);

export const getCountPhotoAlbums = flow(getPhotoAlbums, size);

export const getConstructionProgressAlbums = flow<
  any,
  ComplexAlbum[],
  ComplexAlbum[],
  ComplexAlbum[]
>(
  filter(flow(getAlbumType, typeIsConstructionProgress)),
  sortAlbumsDesc,
  map<ComplexAlbum, ComplexAlbum>(formatDateAt),
);

export const getCountConstructionProgressAlbums = flow(
  getConstructionProgressAlbums,
  size,
);

export const mapAlbumIdToAlbumPhoto = (albums: Album[]): AlbumPhotoToFilter[] =>
  flow<any, AlbumPhotoToFilter[][], AlbumPhotoToFilter[]>(
    map<Album, AlbumPhotoToFilter[]>((album: Album) =>
      flow<any, AlbumPhoto[] | undefined, AlbumPhotoToFilter[]>(
        get<Album, 'photos'>('photos'),
        map<AlbumPhoto, any>(
          (photo: AlbumPhoto): AlbumPhotoToFilter => ({
            ...photo,
            albumId: album.id,
          }),
        ),
      )(album),
    ),
    flatten,
  )(albums);

export const equalByType = (targetAlbum: Album) => (otherAlbum: Album) =>
  targetAlbum.type === otherAlbum.type;

export const albumToAlbumFilterOptions = (
  albums: Album[],
): AlbumFilterOption[] =>
  albums.map((album) => {
    const title =
      album.type === 'construction-progress'
        ? (dateFormat(`${album.dateAt}`, 'monthYear') as string)
        : album.name;

    return {
      title,
      albumId: album.id,
    };
  });

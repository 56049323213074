import { action, makeObservable, observable, computed } from 'mobx';
import { UIPropertyVisibilityFiltersOption } from './types';

/** @desc фильтр позволяет управлять отображением разных частей UI компонента,  */
export class UIPropertyVisibilityFilters<FilterObject extends {}> {
  filter: Map<
    keyof FilterObject,
    UIPropertyVisibilityFiltersOption<keyof FilterObject>
  > = observable.map(new Map());

  constructor(
    options: UIPropertyVisibilityFiltersOption<keyof FilterObject>[],
  ) {
    this.setFilterToState(options);

    makeObservable(this, {
      setActive: action.bound,
      options: computed,
      filterMap: computed,
    });
  }

  get filterMap() {
    return this.filter;
  }

  get options() {
    const options: UIPropertyVisibilityFiltersOption<keyof FilterObject>[] = [];
    this.filter.forEach((value, key) => {
      options.push(value);
    });
    return options;
  }

  setFilterToState = (
    options: UIPropertyVisibilityFiltersOption<keyof FilterObject>[],
  ) => {
    options.forEach((option) => {
      this.filter.set(option.value, option);
    });
  };

  setActive = (key: keyof FilterObject): void => {
    const option = this.filter.get(key);
    if (!option) {
      return;
    }

    this.filter.set(key, {
      ...option,
      active: !option.active,
    });
  };

  isActive = (key: keyof FilterObject): boolean =>
    this.filterMap.get(key)?.active || false;
}

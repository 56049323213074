import React, { TextareaHTMLAttributes } from 'react';
import { FieldProps } from 'formik/dist/Field';
import { FormValidationStatuses } from '../FormValidationStatuses';
import { FormFieldLabel } from '../FormFieldLabel';
import { Textarea } from '../Textarea';

type Props<V> = FieldProps<V> & {
  label?: string;
  placeholder?: string;
  className?: string;
} & Pick<TextareaHTMLAttributes<V>, 'rows' | 'cols' | 'disabled' | 'maxLength'>;

export const FormikTextareaField = ({
  field,
  label,
  placeholder,
  maxLength,
  rows,
  cols,
  disabled,
  className,
}: Props<any>) => (
  <FormValidationStatuses className={className} name={field.name}>
    <>
      {label && (
        <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
      )}
      <Textarea
        {...field}
        name={field?.name}
        placeholder={placeholder}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
        disabled={disabled}
      />
    </>
  </FormValidationStatuses>
);

import React, { useContext } from 'react';

import { Text, InputGroup, Input } from 'components/atoms';

import { viewContext } from 'context/ViewProvider';
import styles from './styles.module.css';

const InputRow: React.FC<any> = ({ filterKey, ...rest }) => {
  const { villageFilter, handleChangeVillageFilter } = useContext(viewContext);

  return (
    <Input
      value={villageFilter[filterKey]}
      name={filterKey}
      onChange={handleChangeVillageFilter}
      {...rest}
    />
  );
};

export const SmallFilters = () => (
  <div className={styles.leftWrapper}>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Площадь дома
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="squareMin" />
        <InputRow placeholder="до" filterKey="squareMax" />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Площадь участка
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="squareLandMin" />
        <InputRow placeholder="до" filterKey="squareLandMax" />
      </InputGroup>
    </div>
    <div>
      <Text
        indent={{
          margin: [0, 0, 5],
        }}
        tagName="span"
        size="S"
        weight="bold"
      >
        Стоимость
      </Text>
      <InputGroup>
        <InputRow placeholder="от" filterKey="priceMin" />
        <InputRow placeholder="до" filterKey="priceMax" />
      </InputGroup>
    </div>
  </div>
);

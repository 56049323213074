import { toJS } from 'mobx';
import { useEffect } from 'react';
import { TabProps, useTabs } from 'components/containers/Tabs';
import { infoStore } from 'stores';
import { DictionaryItem, infoItemsToTabItemPropsList } from 'services';

type ReturnProps = {
  statuses: DictionaryItem[];
} & TabProps<DictionaryItem>;

export const useOffersTabs = (): ReturnProps => {
  const statuses = infoStore.getInfoBy('offer_status')?.items || [];
  const {
    currentActiveTab,
    changeTab,
    tabList,
    setTabList,
    getCurrentActiveTab,
  } = useTabs<DictionaryItem>({
    tabList: [],
  });

  useEffect(() => {
    setTabList(infoItemsToTabItemPropsList(toJS(statuses)));
  }, [statuses]);

  return {
    currentActiveTab,
    changeTab,
    tabList,
    setTabList,
    statuses,
    getCurrentActiveTab,
  };
};

import { action, makeObservable, observable } from 'mobx';
import { MayBe } from 'types';
import { SyncTrunk } from '../../../stores/SyncTrunk/SyncTrunk';

type State = {
  newEmail: MayBe<string>;
  oldEmailIsConfirm: boolean;
  newEmailIsConfirm: boolean;
  isChange: boolean;
};

export class ChangeEmailStore {
  state: State = {
    newEmail: null,
    oldEmailIsConfirm: false,
    newEmailIsConfirm: false,
    isChange: false,
  };

  constructor() {
    makeObservable(this, {
      state: observable,

      setNewEmail: action.bound,
      setOldEmailConfirm: action.bound,
      setNewEmailConfirm: action.bound,
    });
  }

  clear = () => {
    this.state.newEmail = null;
    this.state.oldEmailIsConfirm = false;
    this.state.newEmailIsConfirm = false;
    this.state.isChange = false;
  };

  setNewEmail(newEmail: MayBe<string>) {
    this.state.newEmail = newEmail;
    this.state.isChange = !!newEmail;
  }

  setOldEmailConfirm(confirm: boolean) {
    this.state.oldEmailIsConfirm = confirm;
  }

  setNewEmailConfirm(confirm: boolean) {
    this.state.newEmailIsConfirm = confirm;
  }
}

export const changeEmailStore = new ChangeEmailStore();

const trunk = new SyncTrunk({
  storageKey: 'changeEmailStore',
  store: changeEmailStore,
  storage: localStorage,
});

trunk.init();

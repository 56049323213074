import React from 'react';
import { BaseLink } from 'components/atoms';
import { preventDefault } from 'utils/fp/preventDefault';
import { ApartmentInOffer } from 'services';
import { ID, MayBe } from 'types';
import { ApartmentCard } from '../ApartmentCard';

import styles from './styles.module.css';

type Props = {
  apartment: ApartmentInOffer;
  offerId?: MayBe<ID>;
  offerHash?: string;
  isEditOffer: boolean;
  showPrice: boolean;
  complexAddress?: MayBe<string>;
};

export const ApartmentCardWithLink = ({
  apartment,
  offerId,
  offerHash,
  isEditOffer,
  complexAddress,
  showPrice,
}: Props) => (
  <BaseLink
    path="offersApartmentView"
    params={{
      apartmentId: apartment?.id?.toString(),
      offerId: offerId?.toString(),
      hash: offerHash,
    }}
    searchParams={{
      isEdit: isEditOffer,
    }}
    component={(props: any) => (
      <a
        href={props.href}
        onClick={(event) => {
          preventDefault(event);
          props.navigate();
        }}
        className={styles.iconWithTextWrapper}
      >
        <ApartmentCard
          showPrice={showPrice}
          complexAddress={complexAddress}
          apartment={apartment}
        />
      </a>
    )}
  />
);

import React from 'react';
import { useHistory } from 'react-router';
import { Alert } from 'components/atoms';
import { appRoutesService } from 'services';
import styles from './styles.module.css';

type Props = {
  offerId?: string;
};

export const YouAreLookingOfferAsAClient = ({ offerId }: Props) => {
  const history = useHistory();
  return (
    <Alert className={styles.alertWrapper} isShow severity="primary">
      Вы просматриваете подборку глазами клиента.{' '}
      <a
        onClick={(event) => {
          event.preventDefault();
          history.push(
            appRoutesService.replaceRoute('profileOfferViewRealtor', {
              offerId,
            }),
          );
        }}
        href="#!"
      >
        Вернуться в подборку
      </a>
    </Alert>
  );
};

import React, { useState } from 'react';
import { capitalize, flow, get, replace, trim } from 'lodash/fp';
import { ShowAllButton } from 'components/atoms/ShowAllButton';
import { DictionaryView, Text } from 'components/atoms';
import documentIcon from 'assets/documentIcon.svg';
import { DictionaryKeys } from 'services';
import { infoStore } from 'stores';
import { DealDocument } from 'types/DealDocument';
import sizeOf from '../../../../utils/readebleFileSize';
import { Block } from '../Block';

import styles from './styles.module.css';

type Props = {
  innerRef: React.RefObject<HTMLDivElement>;
  documents?: Array<DealDocument>;
  catalogs: Partial<Record<DictionaryKeys, number[]>>;
  infoKeys: DictionaryKeys[];
};

export const DealDocuments = ({
  innerRef,
  documents,
  catalogs,
  infoKeys,
}: Props) => {
  const contractInfo = Object.entries(catalogs).filter(([key]) =>
    infoKeys.includes(key as DictionaryKeys),
  );

  const [docVisible, setDocVisible] = useState(false);

  const handleDocVisible = () => {
    setDocVisible(!docVisible);
  };

  let documentsToShow: DealDocument[] = [];
  if (documents) {
    if (documents.length <= 6) {
      documentsToShow = documents;
    } else {
      documentsToShow = docVisible ? documents : documents.slice(0, 6);
    }
  }

  return (
    <Block title="Сделка и документы" className={styles.documentsSection}>
      <div className={styles.docHeader} ref={innerRef}>
        {contractInfo.map(
          ([infoKey, items]) =>
            items && (
              <div key={infoKey}>
                <Text
                  tagName="div"
                  indent={{
                    margin: [0, 0, 5],
                  }}
                  size="XS"
                  lineHeight="M"
                >
                  {flow(
                    get('title'),
                    replace('ЖК: ', ''),
                    trim,
                    capitalize,
                  )(infoStore?.getInfoBy(infoKey as DictionaryKeys))}
                </Text>
                <Text tagName="div" weight="bold" size="M" lineHeight="M">
                  <DictionaryView
                    dictKey={infoKey as DictionaryKeys}
                    dictItemId={items}
                  />
                </Text>
              </div>
            ),
        )}
      </div>

      {documents && (
        <section className={styles.files}>
          {documentsToShow.map((document) => {
            const matchFormat = document.url.match(/\.(.[^.]+)$/);
            let format = 'unknown';
            if (matchFormat) {
              [, format] = matchFormat;
            }
            return (
              <div key={document.id} className={styles.docCard}>
                <img
                  src={documentIcon}
                  alt="doc"
                  style={{ marginRight: '10px' }}
                />
                <div className={styles.docMeta}>
                  <a href={document.url} style={{ textDecoration: 'none' }}>
                    <span className={styles.docName}>{document.title}</span>
                  </a>
                  <span className={styles.docSize}>{`${sizeOf(
                    document.size,
                  )}, ${format}`}</span>
                </div>
              </div>
            );
          })}
        </section>
      )}

      {documents && documents.length > 6 && (
        <ShowAllButton onClick={handleDocVisible} isVisible={docVisible} />
      )}
    </Block>
  );
};

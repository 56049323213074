import React from 'react';
import cc from 'classcat';
import { useField, useFormikContext } from 'formik';
import ReactCodeInput, { ReactCodeInputProps } from 'react-code-input';
import { FieldProps } from 'formik/dist/Field';
import { FormValidationStatuses } from '../FormValidationStatuses';
import styles from './styles.module.css';

type Props<V> = FieldProps<V> & Partial<ReactCodeInputProps>;

export const FormikCodeInput = ({
  field,
  type,
  fields,
  inputMode,
  ...rest
}: Props<any>) => {
  const [, meta] = useField(field.name);
  const { isValid, submitCount } = useFormikContext();
  const submitError = !isValid && submitCount > 0 && meta?.error;

  const isError = (meta?.touched && meta?.error) || submitError;
  return (
    <FormValidationStatuses {...rest} name={field.name}>
      <ReactCodeInput
        className={cc([
          styles.codeInput,
          {
            [styles.isError]: isError,
          },
        ])}
        value={field.value}
        onChange={field.onChange(field.name)}
        type={type || 'number'}
        fields={fields || 4}
        inputMode={inputMode || 'numeric'}
        name={field.name}
      />
    </FormValidationStatuses>
  );
};

import React from 'react';
import { FieldProps } from 'formik/dist/Field';
import cc from 'classcat';
import deepEqual from 'fast-deep-equal';
import { flow, filter, cond, map, stubTrue } from 'lodash/fp';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { FormFieldLabel } from 'components/atoms';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import styles from './styles.module.css';

type Props<V> = FieldProps<V> & {
  label?: string;
  className?: string;
  options?: Option[];
  type?: 'checkbox' | 'radio';

  /** скрыть активный элемент, работает только для типа radio */
  hideIsActive?: boolean;
};

/**
 * @desc компонент для отображения данных в виде группы кнопок, работа.т по принципу радио батонов
 * */
export const FormikFieldButtonGroup = ({
  label,
  field,
  className,
  options,
  type = 'radio',
  hideIsActive,
  form,
}: Props<any>) => {
  const onClick = (option: Option) => () => {
    if (type === 'checkbox') {
      // @todo: сделать как утвердим дизайн
    }
    if (type === 'radio') {
      form.setFieldValue(field.name, option);
    }
  };

  return (
    <>
      {label && (
        <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
      )}
      <div className={cc([styles.formikFieldButtonGroup, className])}>
        {flow(
          cond<any, Option[]>([
            [
              () => !!hideIsActive,
              filter<Option>(
                (option: Option) => !deepEqual(option, field.value),
              ),
            ],
            [stubTrue, (options: Option[]) => options || []],
          ]),
          map(({ value, label }) => (
            <ButtonBase
              onClick={onClick({ value, label })}
              fullWidth
              key={label}
            >
              {label}
            </ButtonBase>
          )),
        )(options)}
      </div>
    </>
  );
};

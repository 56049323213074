import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { get } from 'lodash';
import { useWindowResize } from 'beautiful-react-hooks';
import ReactModal from 'react-modal';
import cc from 'classcat';

import closeWhite from 'assets/closeWhite.svg';
import { DATA_TEST_ID } from 'utils/test/dataTestIdToProps';
import { ErrorBoundary } from '../../ErrorBoundary';

import styles from './styles.module.css';

type Props = {
  size?: 'SM' | 'MD' | 'LG' | 'XL';
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  setForwardedContentRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | undefined>
  >;
  className?: string;
  buttonCloseClassName?: string;
};

export const BasePopup = ({
  size = 'MD',
  children,
  isOpen,
  onClose,
  setForwardedContentRef,
  className,
  buttonCloseClassName,
  ...rest
}: Props) => {
  const ref = useRef<HTMLDivElement | undefined>(undefined);
  const [_isCenter, setIsCenter] = useState(false);

  const getIsCenter = useCallback(
    (content: HTMLDivElement | undefined) => {
      if (!content) {
        return;
      }
      const { clientHeight } = content;
      if (clientHeight > window.innerHeight) {
        setIsCenter(false);
      } else if (!_isCenter) {
        setIsCenter(true);
      }
    },
    [_isCenter, ref.current],
  );

  useWindowResize(() => {
    if (ref.current) {
      getIsCenter(ref.current);
    }
  });

  useEffect(() => {
    if (ref.current) {
      getIsCenter(ref.current);
    }
  }, [getIsCenter, ref.current]);

  return (
    <ReactModal
      testId={get(rest, [DATA_TEST_ID])}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      isOpen={isOpen}
      ariaHideApp={!isOpen}
      onRequestClose={onClose}
      overlayClassName={cc([
        styles.modalOverlay,
        className,
        {
          [styles.sizeSM]: size === 'SM',
          [styles.sizeMD]: size === 'MD',
          [styles.sizeLG]: size === 'LG',
          [styles.sizeXL]: size === 'XL',
          [styles.center]: _isCenter,
        },
      ])}
      className={cc([styles.modalContent, styles[`size${size || 'MD'}`]])}
      overlayRef={(ref) => {
        if (setForwardedContentRef) {
          return setForwardedContentRef(ref);
        }
        return null;
      }}
      contentRef={(node: any) => {
        ref.current = node;
        getIsCenter(node);
      }}
    >
      <button
        onClick={onClose}
        type="button"
        className={cc([styles.closeButton, buttonCloseClassName])}
      >
        <img
          src={closeWhite}
          className={styles.closeButtonIcon}
          alt="close modal"
        />
      </button>
      <ErrorBoundary>{children}</ErrorBoundary>
    </ReactModal>
  );
};

import React from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { TabItemProps, TabsView } from 'components/containers/Tabs';
import { TabsSelect } from 'components/containers/TabsSelect';
import { Text } from 'components/atoms';

import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import styles from './styles.module.css';

type Props = {
  currentActiveTab: number;
  tabList: TabItemProps<any>[];
  changeTab: (index: number) => () => void;
  title: string;
};

export const FavoritesPageHeaderTestIDs = {
  wrapper: 'FavoritesPageHeader_wrapper',
  title: 'FavoritesPageHeader_wrapper',
  tabsView: 'FavoritesPageHeader_tabsView',
};

export const FavoritesPageHeaderRender = ({
  currentActiveTab,
  tabList,
  changeTab,
  title,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  return (
    <header
      {...dataTestIdToProps(FavoritesPageHeaderTestIDs.wrapper)}
      className={styles.header}
    >
      <Text
        {...dataTestIdToProps(FavoritesPageHeaderTestIDs.title)}
        weight="bold"
        className={styles.headerText}
        tagName="h3"
      >
        {title}
      </Text>

      {isLarge ? (
        <TabsView<any>
          testId={FavoritesPageHeaderTestIDs.tabsView}
          currentActiveTab={currentActiveTab}
          changeTab={changeTab}
          tabList={tabList}
        />
      ) : (
        <TabsSelect
          tabList={tabList}
          changeTab={changeTab}
          currentActiveTab={currentActiveTab}
        />
      )}
    </header>
  );
};

export const FavoritesPageHeader = observer(FavoritesPageHeaderRender);

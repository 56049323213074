import React from 'react';
import { ComplexAlbum } from 'services/v1/rc/album/types';
import { AlbumList } from 'components/atoms';
import { Block } from 'pages/ComplexPage/components/Block';
import { LocationDescriptorObject } from 'history';

type Props = {
  title?: string;
  albums: ComplexAlbum[];
  innerRef?: React.RefObject<HTMLDivElement>;
  complexId: number;
  className?: string;
  to?: LocationDescriptorObject<any>;
};
/**
 * @todo: переименовать компонент
 * @todo: карточку с альбомом вынести в отдельный компонент
 * @todo: увеличить отступ от заголовка к контенту
 * */
export const AlbumsWithBlock = ({
  innerRef,
  title,
  albums,
  complexId,
  className,
  to,
}: Props) => (
  <Block title={title} className={className}>
    <div ref={innerRef}>
      <AlbumList to={to} albums={albums} complexId={complexId.toString()} />
    </div>
  </Block>
);

import { ReactElement } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';

type Props = {
  mediaQuery: string;
  children: ReactElement;
};

export const MediaQuery = ({ mediaQuery, children }: Props) => {
  const isShow = useMediaQuery(mediaQuery);
  if (!isShow) {
    return null;
  }
  return children;
};

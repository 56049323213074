import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { FormikMaskedInput, Loader } from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';

import styles from './styles.module.css';

type Props = FormikProps<any>;

export const FormRender = ({ handleSubmit, isSubmitting }: Props) => (
  <form className={styles.form} onSubmit={handleSubmit}>
    <div className={styles.title}>Введите новый номер телефона</div>
    <Field label="Телефон" name="newPhone" component={FormikMaskedInput} />
    <ButtonBase
      icon={isSubmitting && <Loader color="primary" />}
      type="submit"
      fullWidth
      color="primary"
    >
      Продолжить
    </ButtonBase>
  </form>
);

import React, { ReactNode } from 'react';
import cc from 'classcat';
import arrowLeftDark from 'assets/arrowLeftDark.svg';
import { Icon } from 'components/atoms/Icon';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  showButtonBack?: boolean;
  onClickButtonBack?: () => void;
};

export const AuthFormWrapper = ({
  children,
  className,
  showButtonBack,
  onClickButtonBack,
}: Props) => (
  <div className={cc([styles.wrapper, className])}>
    {showButtonBack && (
      <button
        onClick={onClickButtonBack}
        type="button"
        className={styles.buttonBack}
      >
        <Icon size={24} src={arrowLeftDark} />
      </button>
    )}
    {children}
  </div>
);

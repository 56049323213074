import React, { useCallback, useEffect, useState } from 'react';
import { MayBe } from 'types';
import { OffersViewItem } from 'services';
import { debounce } from 'lodash';

import { observer } from 'mobx-react';
import { offersEditStore } from 'stores';
import { FormFieldLabel, Loader, Textarea } from 'components/atoms';

import { useEditNotesForClientSubmit } from '../../hooks/useEditNotesForClientSubmit';
import styles from './style.module.css';

type Props = {
  offer?: MayBe<OffersViewItem>;
};

export const EditNotesForClientRender = ({ offer }: Props) => {
  const { editNoteRequestApiState } = offersEditStore;

  const { onListenSubmit } = useEditNotesForClientSubmit();
  const [values, setValues] = useState({
    offerId: offer?.id,
    note: offer?.note,
  });

  const debounceOnListenSubmit = useCallback(debounce(onListenSubmit, 500), [
    onListenSubmit,
  ]);

  const onChange = (event: any) => {
    setValues({
      ...values,
      note: event.target.value,
    });
    debounceOnListenSubmit({
      ...values,
      note: event.target.value,
    });
  };

  const onBlur = () => onListenSubmit(values);

  useEffect(() => {
    if (offer?.id) {
      setValues({
        offerId: offer?.id,
        note: offer?.note,
      });
    }
  }, [offer?.note, offer?.id]);

  return (
    <div className={styles.editNotesForClientWrapper}>
      <FormFieldLabel fieldName="note">Заметки для клиента</FormFieldLabel>

      <Textarea
        onBlur={onBlur}
        onChange={onChange}
        value={values.note}
        id="note"
        name="note"
        rows={5}
      />

      {editNoteRequestApiState.isLoading && (
        <div className={styles.editOfferLoader}>
          <Loader />
        </div>
      )}
    </div>
  );
};

export const EditNotesForClient = observer(EditNotesForClientRender);

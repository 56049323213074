import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { AlertSubmission, Alert } from 'components/atoms';
import { withGoogleReCaptchaProvider } from 'contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { observer } from 'mobx-react';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { authPhoneValidateSchema } from 'validationSchemas';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { authRoutesStore } from 'stores/user/AuthStore/AuthRoutes/AuthRoutes';

import { FormRender } from './components/FormRender';
import styles from './styles.module.css';
import { useFormSubmit } from './hooks/useFormSubmit/useFormSubmit';
import { useReCall } from './hooks/useReCall';
import { AuthPhoneValidateFormValues } from './types';

export const AuthPhoneValidatePageTestIDs = {
  popup: 'AuthPhoneValidatePage_popup',
};

export const AuthPhoneValidatePageRender = () => {
  const { push } = useHistory();

  const { reCall, requestState, query } = useReCall();

  const { onSubmit } = useFormSubmit({ authStore: rootProfileStore.authStore });

  const initialValues = useMemo(
    () => ({
      phone: query.phone,
      authId: query.id,
    }),
    [query],
  );

  return (
    <AuthPopup
      testId={AuthPhoneValidatePageTestIDs.popup}
      mobileHeaderChildrenTitle="Подтверждение номера"
    >
      <Formik<AuthPhoneValidateFormValues>
        initialValues={initialValues}
        enableReinitialize
        validationSchema={authPhoneValidateSchema}
        render={(props) => (
          <>
            <AuthFormWrapper
              className={styles.wrapper}
              showButtonBack
              onClickButtonBack={() => {
                const redirectURL =
                  authRoutesStore.getRouteWithParent('authPhoneCodePage');
                push(redirectURL);
              }}
            >
              <div className={styles.formWrapper}>
                <FormRender
                  {...props}
                  reCall={reCall}
                  UIDictionary={{
                    title: 'Мы отправили вам SMS сообщение',
                    subTitle:
                      'Введите 4 цифры с кодом подтверждения',
                  }}
                />
              </div>

              <div className={styles.alertWrapper}>
                <AlertSubmission isSubmitting errors={props.errors} />

                <Alert
                  isShow={requestState.isError && requestState.isSubmitting}
                >
                  {requestState?.errorMessage}
                  {!requestState?.errorMessage && (
                    <>
                      Не удалось выполнить звонок. Проверьте подключение к
                      интернету и обновите браузер
                    </>
                  )}
                </Alert>
              </div>
            </AuthFormWrapper>
          </>
        )}
        onSubmit={onSubmit}
      />
    </AuthPopup>
  );
};

export const AuthPhoneValidatePage = withGoogleReCaptchaProvider(
  observer(AuthPhoneValidatePageRender),
);

import { makeObservable, observable, action } from 'mobx';
import { MayBe } from '../../types';

export type RequestApiStoreState = {
  isLoading?: boolean;
  isError?: boolean;
  isSubmitting?: boolean;
};

export class RequestApiStore {
  isLoading = false;

  isError = false;

  isSubmitting = false;

  errorMessage: MayBe<string> = null;

  constructor(initialState?: RequestApiStoreState) {
    this.isLoading = initialState?.isLoading || false;
    this.isError = initialState?.isError || false;
    this.isSubmitting = initialState?.isSubmitting || false;

    makeObservable(this, {
      isLoading: observable,
      isError: observable,
      isSubmitting: observable,
      errorMessage: observable,
      clear: action.bound,
      setLoading: action.bound,
      setError: action.bound,
      setSubmitting: action.bound,
    });
  }

  clear = () => {
    this.isError = false;
    this.isLoading = false;
    this.isSubmitting = false;
    this.errorMessage = null;
  };

  setLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  setError(isError: boolean, message: MayBe<string> = null): void {
    this.isError = isError;
    this.errorMessage = message;
  }

  setSubmitting(isError: boolean): void {
    this.isSubmitting = isError;
  }
}

export const requestApiInstance = new RequestApiStore();

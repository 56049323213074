import {
  flow,
  map,
  flatten,
  get,
  last,
  sortBy,
  filter,
  isNil,
  negate,
  reverse,
  join,
  find,
} from 'lodash/fp';
import { identity } from 'lodash';
import { MayBe } from '../../../../types';
import { BuildingEntity, Matrix, Section } from './types';
import { findById } from '../../../../utils/fp';

export const getRows = get<Matrix, 'rows'>('rows');
export const getMatrix = get<Section, 'matrix'>('matrix');
export const getColsSizes = get<Matrix, 'colsSizes'>('colsSizes');
export const getRowsTitles = get('rowsTitles');
export const getColsTitles = get('colsTitles');

export const getAllColsSizes = flow<
  any,
  MayBe<Matrix>[],
  (MayBe<number[]> | undefined)[],
  number[][],
  number[]
>(
  map<Section, MayBe<Matrix>>(getMatrix),
  map<MayBe<Matrix>, MayBe<number[]> | undefined>(getColsSizes),
  filter(negate(isNil)),
  flatten,
);

export const getMaxRows = flow<
  any,
  MayBe<Matrix>[],
  Matrix[],
  number[],
  number[],
  number | undefined
>(
  map<Section, MayBe<Matrix>>(getMatrix),
  filter(negate(isNil)),
  map<Matrix, number>(getRows),
  sortBy(identity),
  last,
);

export const getAllColsTitles = flow(
  map<Section, MayBe<Matrix>>(getMatrix),
  map<MayBe<Matrix>, string[]>(getColsTitles),
  flatten,
);

export const findBuildingById = (building_id?: string) =>
  find<BuildingEntity>(findById(building_id));

export const filterSectionByMatrix = filter((item: Section) =>
  negate(isNil)(getMatrix(item)),
);

export const getSectionWithMaxRows = (
  sections: Section[],
): Section | undefined =>
  flow<any, Section[], any[], Section | undefined>(
    filter(negate(isNil)),
    sortBy('matrix.rows'),
    last,
  )(sections);

/** */
export const getMaxRowsTitles = (sections: Section[]) =>
  flow<any, Section | undefined, Matrix | any, string[]>(
    getSectionWithMaxRows,
    getMatrix,
    getRowsTitles,
  )(sections);

/**
 * @example
 * source = ['', 'foo', '']
 * target = ['', 'bar', '', 'foobar']
 * result = [
 *    [''],
 *    ['foo','bar'],
 *    [''],
 *    ['foobar']
 * ]
 *
 * @desc метод для слияния двух string[] в один string[][], в ответе будет тип string[][]
 * для того чтобы не привязыватся к конкретному форматированию этого слияния.
 * */
const mergeDescription =
  (source: string[]) =>
  (target: string[]): string[][] => {
    const sourceLocale = source.length > target.length ? source : target;
    const targetLocale = source.length > target.length ? target : source;

    return sourceLocale.reduce((accum: string[][], item, index) => {
      if (targetLocale[index]) {
        accum.push([item, targetLocale[index]]);
      } else {
        accum.push([item]);
      }
      return accum;
    }, []);
  };

export const getAllRowsTitlesDescription = (sections: Section[]) => {
  const sectionWithMaxRows = getSectionWithMaxRows(sections);
  if (
    !sectionWithMaxRows ||
    !sectionWithMaxRows.matrix ||
    !sectionWithMaxRows.matrix.rowsTitlesDescription
  ) {
    return [];
  }
  /** reverse нужен тк список заголовков и описаний строк хранится в обратном порядке от большего к меньшему
   * При мерже списков нам нужно мержить их от меньшего к большему
   * */
  let rowsTitlesDescription = reverse(
    sectionWithMaxRows?.matrix?.rowsTitlesDescription,
  );

  /** секции с меньшим или равным кол-вом этажей */
  const restSections: string[][] = flow<any, Section[], string[][], string[][]>(
    filter((section: Section) => section.id !== sectionWithMaxRows.id),
    map(get('matrix.rowsTitlesDescription')),
    map(reverse),
  )(sections);

  restSections.forEach((item) => {
    rowsTitlesDescription = flow<any, string[][], string[]>(
      mergeDescription(rowsTitlesDescription),
      map(join('')),
    )(item);
  });

  return reverse(rowsTitlesDescription);
};

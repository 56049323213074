import React, { ReactNode } from 'react';
import cc from 'classcat';
import stylesSize from './size.module.css';
import stylesVariants from './variants.module.css';
import styles from './styles.module.css';
import { IndentsProps, getIndentClasses } from '../Indents';

type Props = {
  children?: ReactNode;
  size?: 'small' | 'medium' | 'large';
  variants?: 'default' | 'alert';
  color?: string;
  isCircle?: boolean;
  className?: string;
} & IndentsProps;

export const Badge = ({
  children,
  size = 'medium',
  variants = 'default',
  color,
  indent,
  isCircle,
  className,
}: Props) => (
  <div
    style={
      color
        ? {
            backgroundColor: color,
          }
        : undefined
    }
    className={cc([
      styles.badge,
      stylesSize[size],
      {
        [stylesVariants[variants]]: variants,
        [stylesSize.content]: !isCircle && !!children,
        [stylesSize.isCircle]: isCircle,
      },
      getIndentClasses(indent),
      className,
    ])}
  >
    {children}
  </div>
);

import React from 'react';

import ReactiveCheckbox from 'components/containers/Search/components/ReactiveCheckbox';
import { InputGroup } from 'components/atoms';
import { ReactiveInput } from 'components/containers/Search/components/ReactiveInput';
import { ReactiveSelect } from 'components/containers/Search/components/ReactiveSelect';

import { buildingFilters } from 'stores/BuildingFilters';

import styles from '../../../../../components/containers/AllFilters/AllFilters.module.css';

const SecondColumnFilters = () => (
  <>
    <div className={styles.floorsItem}>
      <span className={styles.formLabel}>Этаж</span>
      <InputGroup>
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="floorMin"
          placeholder="Не ниже"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={buildingFilters.filters}
          filterKey="floorMax"
          placeholder="Не выше"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.floorsCheckboxes}>
      <ReactiveCheckbox
        store={buildingFilters.filters}
        filterKey="notFirstFloor"
        placeholder="Не первый"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={buildingFilters.filters}
        filterKey="notLastFloor"
        placeholder="Не последний"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={buildingFilters.filters}
        filterKey="isLastFloor"
        placeholder="Только последний"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={buildingFilters.filters}
        filterKey="isPenthouse"
        placeholder="Пентхаус"
        className={styles.floorsCheckbox}
      />
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Статус продаж</span>
        <ReactiveSelect
          store={buildingFilters.filters}
          filterKey="salesStatus"
          dictKey="residential_complex_sales_status"
          placeholder="Любой"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Тип договора</span>
        <ReactiveSelect
          store={buildingFilters.filters}
          filterKey="contractType"
          dictKey="residential_complex_contract_type"
        />
      </div>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Способ оплаты</span>
      <ReactiveSelect
        store={buildingFilters.filters}
        filterKey="paymentType"
        dictKey="residential_complex_payment_type"
      />
    </div>
  </>
);

export default SecondColumnFilters;

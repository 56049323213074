/**
 * @param search - ?foo=bar
 * */
export const searchParamsToObject = (search: string): Record<string, any> => {
  const object: Record<string, any> = {};

  new URLSearchParams(search).forEach((value, key) => {
    object[key] = value;
  });

  return object;
};

import React from 'react';
import cc from 'classcat';
import fbIcon from 'assets/social/fb_red.svg';
import telegramIcon from 'assets/social/telegramm_red.svg';
import vkIcon from 'assets/social/vk_red.svg';
import whatsAppIcon from 'assets/social/WhatsApp_1_.svg';
import { Icon } from '../Icon';
import styles from './styles.module.css';

type Props = {
  type: 'vk' | 'fb' | 'tg' | 'whatsApp';
  onClick?: () => void;
};

export const SocialButton = ({ type, onClick }: Props) => (
  <button
    onClick={onClick}
    className={cc([
      styles.socialButton,
      {
        [styles.socialButtonVK]: type === 'vk',
        [styles.socialButtonFB]: type === 'fb',
        [styles.socialButtonTG]: type === 'tg',
        [styles.socialButtonWhatsApp]: type === 'whatsApp',
      },
    ])}
    type="submit"
  >
    <Icon
      src={cc({
        [vkIcon]: type === 'vk',
        [fbIcon]: type === 'fb',
        [telegramIcon]: type === 'tg',
        [whatsAppIcon]: type === 'whatsApp',
      })}
    />
  </button>
);

import React from 'react';
import { Text } from 'components/atoms';
import arrowLeftDark from 'assets/arrowLeftDark.svg';
import arrowRightDark from 'assets/arrowRightDark.svg';
import styles from './styles.module.css';

type Props = {
  onDecrement?: () => void;
  count: number;
  minCount: number;
  maxCount: number;
  onIncrement?: () => void;
};

export const LevelStepper = ({
  minCount,
  maxCount,
  onDecrement,
  onIncrement,
  count,
}: Props) => (
  <div className={styles.wrapper}>
    <button
      disabled={count <= minCount}
      className={styles.button}
      type="button"
      onClick={onDecrement}
    >
      <img className={styles.buttonIcon} src={arrowLeftDark} alt="" />
    </button>
    <Text className={styles.counter} size="XS" weight="bold">
      {count} уровень
    </Text>
    <button
      disabled={count >= maxCount}
      className={styles.button}
      type="button"
      onClick={onIncrement}
    >
      <img className={styles.buttonIcon} src={arrowRightDark} alt="" />
    </button>
  </div>
);

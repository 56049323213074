import { has } from 'lodash/fp';
import { Cell } from 'services';

/**
 * @return {
 *   'x.y': Cell[],
 *   'x.y': Cell[],
 *   'x.y': Cell[],
 * }
 * @desc группирует ячейки матрицы по координатам
 * */
export const cellGroupByXY = (items: Cell[]) =>
  items.reduce(
    (accum: Record<string, Cell[]>, item): Record<string, Cell[]> => {
      const key = [item.x, item.y].join('.');
      if (has(key, accum)) {
        accum[key].push(item);
      } else {
        accum[key] = [item];
      }
      return accum;
    },
    {},
  );

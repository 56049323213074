import React, { ReactNode, MouseEvent } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;

  isActive?: boolean;
  color?: 'alert' | undefined;
  onClick?: (e: MouseEvent) => void;
};

export const PopoverItem = ({
  children,
  className,
  isActive,
  color,
  onClick,
}: Props) => (
  <div
    onClick={onClick}
    className={cc([
      styles.item,
      {
        [styles.itemActive]: isActive,
        [styles.colorAlert]: color === 'alert',
      },
      className,
    ])}
  >
    {children}
  </div>
);

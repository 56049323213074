import React from 'react';
import { Marker } from 'react-leaflet';
import { divIcon } from 'leaflet';
import { Squares } from 'services';
import { renderToStaticMarkup } from 'react-dom/server';
import { infoStore } from 'stores';
import { MetersBadge } from 'components/atoms/MetersBadge';

type Props = {
  apartmentSquaresPosition: Squares[][];
  apartmentColors?: string[];
  isSingleApartment?: boolean;
  activeApartmentIndex?: number;
};

export const Badge = ({
  badgePallate: { bg, color, border },
  id,
  val,
  bgColor,
}: any) => {
  const statuses = infoStore.getInfoBy('rooms_type')?.items || [];
  const currentStatus = statuses.find((e) => id === e.id);
  const currentValue = currentStatus?.value || '';

  return (
    <MetersBadge
      bgColor={bgColor || bg}
      color={color}
      border={border}
      currentValue={currentValue}
      val={val}
    />
  );
};

export const SquareMetersOnFloorPlan = ({
  apartmentSquaresPosition,
  apartmentColors,
  isSingleApartment,
  activeApartmentIndex,
}: Props) => {
  const getApartmentColor = (index: number) => {
    // if (activeApartmentIndex === index) {
    //   return { bg: '#4E6AFF', color: '#fff' };
    // }
    const res = '';
    return {
      bg: '#F1F6FC',
      color: '#000',
      border: '#DFDFEB',
    };
  };

  const icon = (val: string, id: number, badgePallate?: any) => {
    const defaultPallate = { bg: '#4E6AFF', color: '#fff' };
    return divIcon({
      html: renderToStaticMarkup(
        <Badge badgePallate={badgePallate} id={id} val={val} />,
      ),
    });
  };

  return (
    <>
      {apartmentSquaresPosition.map(
        (
          {
            latitude,
            longitude,
            latitude_absolute,
            longitude_absolute,
            square,
            room_type_id,
          }: any,
          index: number,
        ) => (
          <Marker
            key={index.toString()}
            position={[latitude, longitude]}
            icon={icon(square, room_type_id, getApartmentColor(index))}
          />
        ),
      )}
    </>
  );
};

import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { FormikCodeInput, Loader } from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';

import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import styles from './styles.module.css';

type Props = FormikProps<any> & {
  reCall: Function;

  UIDictionary: {
    title: string;
    subTitle: string;
  };
};

export const AuthPhoneValidatePageFormRenderTestIDs = {
  title: 'AuthPhoneValidatePageFormRender_title',
  subTitle: 'AuthPhoneValidatePageFormRender_subTitle',
  codeField: 'AuthPhoneValidatePageFormRender_codeField',
  submitButton: 'AuthPhoneValidatePageFormRender_submitButton',
  reCallButton: 'AuthPhoneValidatePageFormRender_reCallButton',
};

export const FormRender = ({
  isSubmitting,
  handleSubmit,
  reCall,
  UIDictionary,
}: Props) => (
  <form className={styles.wrapper} onSubmit={handleSubmit}>
    <div
      {...dataTestIdToProps(AuthPhoneValidatePageFormRenderTestIDs.title)}
      className={styles.title}
    >
      {UIDictionary.title}
    </div>
    <div
      {...dataTestIdToProps(AuthPhoneValidatePageFormRenderTestIDs.subTitle)}
      className={styles.subTitle}
    >
      {UIDictionary.subTitle}
    </div>

    <Field
      {...dataTestIdToProps(AuthPhoneValidatePageFormRenderTestIDs.codeField)}
      name="code"
      component={FormikCodeInput}
    />

    <div className={styles.buttonGroup}>
      <ButtonBase
        {...dataTestIdToProps(
          AuthPhoneValidatePageFormRenderTestIDs.submitButton,
        )}
        icon={isSubmitting && <Loader color="primary" />}
        type="submit"
        fullWidth
        color="primary"
      >
        Продолжить
      </ButtonBase>
      <ButtonBase
        {...dataTestIdToProps(
          AuthPhoneValidatePageFormRenderTestIDs.reCallButton,
        )}
        onClick={reCall as any}
        fullWidth
        variant="text"
        color="primary"
      >
        Отправить еще раз
      </ButtonBase>
    </div>
  </form>
);

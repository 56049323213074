import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  top?: boolean;
  bottom?: boolean;
  className?: string;
};

export const Divider = ({ top, bottom, className }: Props) => (
  <hr
    className={cc([
      styles.divider,
      className,
      {
        [styles.top]: top,
        [styles.bottom]: bottom,
      },
    ])}
  />
);

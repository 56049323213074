import React from 'react';
import { observer } from 'mobx-react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { flow } from 'lodash/fp';
import addToCollectionGray from 'assets/addToCollectionGray.svg';
import { BaseLink, Text, Icon, ListItem } from 'components/atoms';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { preventDefault } from 'utils/fp/preventDefault';

export const OffersNavButtonRender = () => {
  const isLarge = useMediaQuery('(min-1024px)');

  const path = rootProfileStore.authStore.isAuth
    ? 'offers'
    : 'authPhoneCodePage';

  if (isLarge) {
    return (
      <BaseLink
        icon={<Icon src={addToCollectionGray} />}
        path={path}
        color="transparent"
      >
        Мои подборки
      </BaseLink>
    );
  }

  return (
    <BaseLink
      path={path}
      component={(props: any) => (
        <ListItem
          {...props}
          onClick={(event: any) => {
            flow(preventDefault, props.navigate)(event);
          }}
          icon={<Icon src={addToCollectionGray} />}
          component="a"
        >
          <Text weight="700">Мои подборки</Text>
        </ListItem>
      )}
    />
  );
};

export const OffersNavButton = observer(OffersNavButtonRender);

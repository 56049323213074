import React from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { Params } from 'services';
import { FloorSideBar } from './components/FloorSideBar';
import { GridContainer } from './components/GridContainer';
import { SectionHeaderBar } from './components/SectionHeaderBar';
import { GridSectionList } from './components/GridSectionList';
import { BuildingGridStore } from '../../stores/buildingGridStore';
import { ComplexItemStore } from '../../../../stores/ComplexItemStore/store';
import { ApartmentsNotFound } from '../../containers/ApartmentsNotFound';
import { GridSkeletonLoader } from './components/GridSkeletonLoader';
import { useGridApartmentRequest } from '../../hooks/useGridApartmentRequest';

type Props = {
  complexItemStore?: ComplexItemStore;
  buildingGridStore?: BuildingGridStore;
};

export const GridApartmentsRender = ({
  buildingGridStore,
  complexItemStore,
}: Props) => {
  const { complexId, layoutTabs, buildingId } =
    useParams<Pick<Params, 'complexId' | 'layoutTabs' | 'buildingId'>>();

  useGridApartmentRequest({
    buildingGridStore,
    complexItemStore,
    complexId,
    layoutTabs,
    buildingId,
  });

  const sections = buildingGridStore?.getSections || [];
  const apartment = buildingGridStore?.getApartments || [];

  return (
    <ApartmentsNotFound
      isLoading={buildingGridStore?.requestApi.isLoading}
      apartmentCount={apartment.length}
    >
      <>
        {buildingGridStore?.requestApi.isLoading && <GridSkeletonLoader />}
        {!buildingGridStore?.requestApi.isLoading && (
          <GridContainer sections={sections}>
            <SectionHeaderBar sections={sections} />
            <FloorSideBar sections={sections} />
            <GridSectionList sections={sections} />
          </GridContainer>
        )}
      </>
    </ApartmentsNotFound>
  );
};

export const GridApartments = observer(GridApartmentsRender);

import React, { ComponentProps } from 'react';
import { size } from 'lodash/fp';
import { LatLngTuple } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { Feature } from 'services/v1/rc/apartment/types';
import { BaseMapContainer } from 'components/atoms';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { LeafletCentroidByPolygons } from 'components/atoms/Map/LeafletCentroidByPolygons';
import { WindowMarkers } from 'components/atoms/Map/WindowMarkers';

type Props = {
  polygon?: LatLngTuple[];
  className?: string;
  windows: Feature[] | undefined;
} & Pick<ComponentProps<typeof BaseMapContainer>, 'hideZoomControl'>;

export const RenderBuildingToMap = ({
  className,
  windows,
  polygon,
  hideZoomControl,
}: Props) => (
  <div className={className}>
    {polygon && size(polygon) > 0 && (
      <BaseMapContainer
        hideZoomControl={hideZoomControl}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <LeafletCentroidByPolygons polygon={polygon} />
        <YandexMapLayer />
        <Polygon pathOptions={{ color: '#4E6AFF' }} positions={polygon} />
        <WindowMarkers windows={windows} />
      </BaseMapContainer>
    )}
  </div>
);

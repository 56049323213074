import qs from 'qs';
import { serialize } from 'object-to-formdata';
import { AttemptResult, ThrowableResult } from '../../../types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { API_ROUTES } from '../../../constants';
import { loggerService } from '../../../loggerService';
import {
  AuthPhoneChangeConfirmRequestBody,
  AuthPhoneChangeConfirmResponse,
  AuthPhoneChangeRequestBody,
  AuthPhoneChangeResponse,
  AuthPhoneCodeRequestBody,
  AuthPhoneCodeResponse,
  AuthPhoneRegistrationRequestBody,
  AuthPhoneRegistrationResponse,
  AuthPhoneValidateRequestBody,
  AuthPhoneValidateResponse,
} from './types';
import { attemptWithAuth } from '../../../libs/axios/attempt';

export const authPhoneService = {
  code: async (
    data: AuthPhoneCodeRequestBody,
  ): Promise<ThrowableResult<AuthPhoneCodeResponse>> => {
    try {
      const responseAxiosResponse = await makeRequest<AuthPhoneCodeResponse>({
        url: API_ROUTES.auth.phone.code,
        method: 'post',
        data: serialize(data),
      });
      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  validate: async (
    data: AuthPhoneValidateRequestBody,
  ): Promise<ThrowableResult<AuthPhoneValidateResponse>> => {
    try {
      const responseAxiosResponse =
        await makeRequest<AuthPhoneValidateResponse>({
          url: API_ROUTES.auth.phone.validate,
          method: 'post',
          data: serialize(data),
        });

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  registration: async (
    data: AuthPhoneRegistrationRequestBody,
  ): Promise<ThrowableResult<AuthPhoneRegistrationResponse>> => {
    try {
      const responseAxiosResponse =
        await makeRequest<AuthPhoneRegistrationResponse>({
          url: API_ROUTES.auth.phone.registration,
          method: 'post',
          data: serialize(data),
        });

      return responseAxiosResponse.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  change: async (
    data: AuthPhoneChangeRequestBody,
  ): Promise<AttemptResult<AuthPhoneChangeResponse>> =>
    attemptWithAuth<AuthPhoneChangeResponse>({
      url: API_ROUTES.auth.phone.change.change,
      method: 'post',
      params: {
        ...data,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }),
  changeConfirm: async (
    data: AuthPhoneChangeConfirmRequestBody,
  ): Promise<AttemptResult<AuthPhoneChangeConfirmResponse>> =>
    attemptWithAuth<AuthPhoneChangeConfirmResponse>({
      url: API_ROUTES.auth.phone.change.confirm,
      method: 'post',
      params: {
        ...data,
      },
      paramsSerializer: (params) => qs.stringify(params),
    }),
};

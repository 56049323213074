import React, { ReactNode, DetailedHTMLProps, HTMLAttributes } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

export type TrProps = {
  children?: ReactNode;
  className?: string;
  isActive?: boolean;
} & DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>;

export const Tr = ({ children, className, isActive, ...rest }: TrProps) => (
  <tr
    {...rest}
    className={cc([
      styles.tr,
      className,
      {
        [styles.isActive]: isActive,
      },
    ])}
  >
    {children}
  </tr>
);

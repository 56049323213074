import React from 'react';
import verify from 'assets/verify.svg';
import { Realtor } from 'services';
import { createCountFormatter } from 'utils/createCountFormatter';
import { IconWithText, DateFormat, Icon } from 'components/atoms';
import { RealtorLogo } from '../RealtorLogo';
import styles from './styles.module.css';

type Props = {
  complexCount?: number;
  startDate?: string;
  realtor?: Realtor;
};

const complexCountFormat = createCountFormatter({
  few: 'объектов',
  one: 'объект',
  two: 'объекта',
});

export const RealtorInfo = ({ complexCount, startDate, realtor }: Props) => (
  <div className={styles.wrapper}>
    <RealtorLogo realtor={realtor} />
    <div className={styles.realtorInfoList}>
      {startDate && (
        <div>
          На сайте с{' '}
          <strong>
            <DateFormat format="dateMonthTime" value={startDate} />
          </strong>
        </div>
      )}
      <div>
        <IconWithText reverse icon={<Icon src={verify} size={16} />}>
          Документы подтверждены
        </IconWithText>
      </div>
      {complexCount && (
        <div>
          Подобрано <strong>{complexCount}</strong>{' '}
          {complexCountFormat(complexCount)}
        </div>
      )}
    </div>
  </div>
);

import React from 'react';
import { HeaderGroup } from 'react-table';
import { Th, THead, Tr } from 'components/atoms';
import { HeaderGroupWithSortAndFilter } from 'types/ReactTable';
import { ApartmentDto } from 'services/v1/rc/apartment/types';

type Props = {
  headerGroups: Array<HeaderGroup<ApartmentDto>>;
};

export const THeader: React.FC<Props> = ({ headerGroups }) => (
  <THead>
    {headerGroups.map((headerGroup) => (
      <Tr {...headerGroup.getHeaderGroupProps()}>
        {(
          headerGroup.headers as unknown as HeaderGroupWithSortAndFilter<ApartmentDto>[]
        ).map((column: HeaderGroupWithSortAndFilter<ApartmentDto>) => (
          <Th
            canFilter={column.canFilter}
            canSort={column.canSort}
            isSorted={column.isSorted}
            isSortedDesc={column.isSortedDesc}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            <div>
              {column.canFilter && column?.Filter
                ? column.render('Filter')
                : null}
            </div>
            {column.render('Header')}
          </Th>
        ))}
      </Tr>
    ))}
  </THead>
);

import React from 'react';
import { Skeleton } from 'components/atoms/Skeleton';

import styles from './skeletonStyles.module.css';

const sizes = [
  {
    w: '30%',
    h: 16,
  },
  {
    w: '70%',
    h: 16,
  },
  {
    w: 164,
    h: 16,
  },
];

const flatSizes = {
  w: 70,
  h: 10,
};

export const ComplexListSkeletonTitle = () => (
  <Skeleton
    width={178}
    height={18}
    animation="wave"
    className={styles.buildingObjectsTitle}
    highlightColor="rgba(35, 46, 73, 0.05)"
  />
);

const skeletonList = Array.from({ length: 6 }, (_, index) => (
  <article className={styles.skeletonItem} key={index}>
    <Skeleton
      skeletonSizes={sizes}
      wrapperDirection="column"
      color="#d3d3e2"
      animation="wave"
      className={styles.cardTitleItem}
      wrapperClassName={styles.cardTitle}
    />
    <div className={styles.flatsWrapper}>
      <div>
        <span className={styles.flatsTitle}>Студия</span>
        <Skeleton
          height={flatSizes.h}
          width={flatSizes.w}
          wrapperDirection="row"
          className={styles.skeletonFlatsItem}
          wrapperClassName={styles.skeletonFlatsWrapper}
        />
      </div>
      <div>
        <span className={styles.flatsTitle}>1 комната</span>
        <Skeleton
          height={flatSizes.h}
          width={flatSizes.w}
          wrapperDirection="row"
          className={styles.skeletonFlatsItem}
          wrapperClassName={styles.skeletonFlatsWrapper}
        />
      </div>
      <div>
        <span className={styles.flatsTitle}>2+ комнаты</span>
        <Skeleton
          height={flatSizes.h}
          width={flatSizes.w}
          wrapperDirection="row"
          className={styles.skeletonFlatsItem}
          wrapperClassName={styles.skeletonFlatsWrapper}
        />
      </div>
    </div>
  </article>
));

export const ComplexListSkeleton = () => <>{skeletonList}</>;

import { useCallback } from 'react';
import { omit } from 'lodash';
import {
  AuthEmailCodeRequestBody,
  AuthEmailCodeResponse,
  authEmailService,
  AuthPhoneCodeRequestBody,
  AuthPhoneCodeResponse,
  authPhoneService,
  GRecaptchaResponse,
} from 'services';
import { getResponseErrorMessage } from 'services/libs/axios/helpers';
import { useGetQueryString, useRequestApi } from 'hooks';

/**
 * @desc методы для запроса на получение кода подтверждения телефона и почты
 *
 * */
export const useFetchAuthValidationCode = () => {
  const { query, setQuery } = useGetQueryString<
    Omit<AuthPhoneCodeResponse['auth'], 'description'> & { phone: number }
  >();

  const {
    isError,
    isSubmitting,
    isLoading,
    setError,
    setSubmitting,
    setLoading,
    clear,
    errorMessage,
  } = useRequestApi();

  const fetchPhoneCode = useCallback(
    (data: AuthPhoneCodeRequestBody) =>
      async (
        captcha: Partial<GRecaptchaResponse>,
      ): Promise<AuthPhoneCodeResponse | Error> => {
        clear();
        setLoading(true);

        try {
          const result = await authPhoneService.code({
            ...data,
            'g-recaptcha-response': captcha['g-recaptcha-response'],
          });

          /**
           * записываю предыдущее значение querystring,
           * новые данные для валидации кода подтверждения и
           * номер пользователя
           * */
          setQuery({
            ...omit(query, ['description', 'createdAt', 'type']),
            ...omit(result.auth, ['description', 'createdAt', 'type']),
            phone: data.phone,
          });

          setSubmitting(true);
          setLoading(false);
          return result;
        } catch (e) {
          setError(true, getResponseErrorMessage(e));
          setLoading(false);
          setSubmitting(true);
          return e as Error;
        }
      },
    [query],
  );

  const fetchEmailCode = useCallback(
    async (
      data: AuthEmailCodeRequestBody,
    ): Promise<AuthEmailCodeResponse | null> => {
      clear();
      setLoading(true);
      const [error, result] = await authEmailService.code(data);

      if (result) {
        setQuery({
          ...query,
          ...result.auth,
        });
        setSubmitting(true);
        setLoading(false);
        return result;
      }

      if (error) {
        setSubmitting(true);
        setLoading(false);
        setError(true);
      }

      return null;
    },
    [query],
  );

  return {
    fetchEmailCode,
    fetchPhoneCode,
    query,
    requestState: {
      isError,
      isSubmitting,
      isLoading,
      errorMessage,
    },
  };
};

import React, { ReactElement } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children: ReactElement[];
};

export const InputGroup = ({ children }: Props) => (
  <div className={styles.group}>
    {React.Children.toArray(children).map((child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          className: cc([
            child.props.className,
            {
              [styles.group_item_first]: index === 0,
              [styles.group_item_last]: index === 1,
            },
          ]),
        });
      }
      return child;
    })}
  </div>
);

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';
import { Text } from '../../Text';

type Props = {
  children: ReactNode;
  isError?: boolean | undefined;
  className?: string;
  classNameError?: string;
  error?: string;
};

export const FormValidationStatusesView = ({
  children,
  className,
  classNameError,
  error,
  isError,
  ...rest
}: Props) => (
  <div
    {...rest}
    className={cc([
      className,
      {
        [styles.isError]: isError,
      },
    ])}
  >
    {children}
    {isError && (
      <Text size="XS" className={cc([styles.errorMessage, classNameError])}>
        {error}
      </Text>
    )}
  </div>
);

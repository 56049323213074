import React from 'react';

type Props = {
  imageUrl: string;
};

export const SliderImageRender = ({ imageUrl }: Props) => (
  <img
    data-src={imageUrl}
    src={imageUrl}
    className="swiper-lazy"
    loading="lazy"
    alt="Слайдер альбома"
  />
);

export const SliderImage = SliderImageRender;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import cc from 'classcat';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import ClampText from 'react-clamp-text';
import { IndentsProps, getIndentClasses } from '../Indents';
import { ShowAllButton } from '../ShowAllButton';

import styles from './styles.module.css';

type Props = {
  text?: string;
  maxLine?: number;
  atOneLine?: boolean;
} & IndentsProps;

export const TruncateHtml = ({
  maxLine = 6,
  text,
  indent,
  atOneLine,
}: Props) => {
  const [contentGreaterThanMaxLine, setContentGreaterThanMaxLine] =
    useState(false);
  const [isClamped, setClamped] = useState(false);
  const wrapperRef = useRef<any>();
  const ref = useRef<any>();

  const toggleClamped = useCallback(() => {
    setClamped(!isClamped);
  }, [setClamped, isClamped]);

  useEffect(() => {
    if (ref.current) {
      setContentGreaterThanMaxLine(ref.current.clamped);
      setClamped(ref.current.clamped);
    }
  }, [ref.current]);

  if (atOneLine)
    return (
      <ClampText
        line={3}
        showMore
        className={styles.oneLineButton}
        showMoreText="Открыть описание"
        showLessText="Скрыть описание"
      >
        test {text}
      </ClampText>
    );

  return (
    <div
      className={cc([styles.truncateHtmlWrapper, getIndentClasses(indent)])}
      ref={wrapperRef}
    >
      <div
        className={cc([
          styles.truncateHtmlContent,
          {
            [styles.is_hidden]: !isClamped,
          },
        ])}
      >
        <HTMLEllipsis
          ref={ref}
          unsafeHTML={text}
          maxLine={maxLine}
          ellipsis=""
          basedOn="letters"
        />
      </div>
      <div
        className={cc([
          styles.truncateHtmlContent,
          {
            [styles.is_hidden]: isClamped,
          },
        ])}
        dangerouslySetInnerHTML={{
          __html: text || '',
        }}
      />
      {contentGreaterThanMaxLine && (
        <ShowAllButton
          onClick={toggleClamped}
          isVisible={!isClamped}
          notAllVisibleText="Показать полностью"
        />
      )}
    </div>
  );
};

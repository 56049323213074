import React, { useMemo } from 'react';
import { format as defaultDateFormat, getQuarter, getYear } from 'date-fns';
import { ru } from 'date-fns/locale';
import { fixIsoDateSafari } from 'utils';
import { MayBe } from '../../../types';
import { loggerService } from '../../../services';

export const dateFormatPatterns = {
  /** Mo, Tu, We, etc. */
  shortWeek: 'cccccc',
  /** 00:00 */
  time: 'kk:mm',
  /** 22 февраля 2020 */
  dateMonthTime: 'd MMMM kk:mm',
  /** 22 февраля 2020 */
  dateMonthYear: 'd MMMM yyyy',
  /** 31.12.2021 */
  dateMonthYearShort: 'd.MM.yyyy',
  /** 31.12.2021 00:00 */
  dateMonthYearShortWithTime: 'd.MM.yyyy kk:mm',
  /** 31.12.2021 в 00:00 */
  dateMonthYearShortInTime: 'd.MM.yyyy в kk:mm',
  /** Июнь 2020 */
  monthYear: 'MMMM yyyy',
  /** 2020 */
  year: 'yyyy',
  /** 1K */
  quarter: 'qo',
};

export type DateFormats =
  | 'shortWeek'
  | 'time'
  | 'dateMonthTime'
  | 'dateMonthYear'
  | 'dateMonthYearShort'
  | 'dateMonthYearShortWithTime'
  | 'dateMonthYearShortInTime'
  | 'monthYear'
  | 'year'
  | 'quarter';

export const dateFormat = (
  value?: MayBe<string | Date>,
  format?: DateFormats,
) => {
  try {
    const formatDateString =
      typeof value === 'string' ? fixIsoDateSafari(value) : value;
    const date = formatDateString ? new Date(formatDateString) : null;

    if (!date) {
      return date;
    }
    if (format === 'quarter') {
      return `${getQuarter(date)}K`;
    }
    if (format === 'year') {
      return getYear(date).toString();
    }
    return defaultDateFormat(
      date,
      dateFormatPatterns[format || 'dateMonthYearShort'],
      {
        locale: ru,
      },
    );
  } catch (e) {
    loggerService.error(e);
  }
  return value;
};

type Props = {
  value?: string | Date;
  format?: DateFormats;
};

export const DateFormat = ({ format, value }: Props) => {
  const formatValue = useMemo(
    () => value && dateFormat(value, format),
    [value, format],
  );
  return React.createElement('span', {}, formatValue);
};

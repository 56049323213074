import React, { memo } from 'react';
import { get } from 'lodash/fp';
import cc from 'classcat';
import ReactJoin from 'react-join';
import { isNotNil } from 'utils/fp';
import { ApartmentDto } from '../../../../../../../services';
import {
  DictionaryBadge,
  DictionaryView,
  NumberFormat,
} from '../../../../../../../components/atoms';
import styles from './styles.module.css';

type Props = {
  onClick: () => void;
  isActive?: boolean;
  apartment: ApartmentDto;
};

export const ListItemRender = ({ apartment, isActive, onClick }: Props) => {
  const catalogs = get('catalogs')(apartment);

  const residential_complex_finishing = get('residential_complex_finishing')(
    catalogs,
  );
  const rooms = get('rooms')(catalogs);
  const apartment_type_room = get('apartment_type_room')(catalogs);
  const apartment_status = get('apartment_status')(catalogs);

  return (
    <div
      onClick={onClick}
      className={cc([
        styles.wrapper,
        {
          [styles.isActive]: isActive,
        },
      ])}
    >
      <div className={styles.left}>
        <div className={cc([styles.row, styles.text])}>{apartment.number}</div>
        <div className={styles.row}>
          <div className={styles.floor}>{apartment.floor}</div>
          <DictionaryView
            className={styles.text}
            dictKey="residential_complex_finishing"
            dictItemId={residential_complex_finishing}
          />
        </div>
        <div className={styles.row}>
          <ReactJoin separator={<span>, </span>}>
            {[
              rooms ? (
                <DictionaryView
                  className={styles.text}
                  dictKey="rooms"
                  dictItemId={rooms}
                />
              ) : null,
              <NumberFormat
                className={styles.text}
                value={parseFloat(apartment?.square || '0')}
                format="meter2"
              />,
            ].filter(isNotNil)}
          </ReactJoin>
        </div>
      </div>

      <div className={styles.right}>
        <div className={styles.row}>
          <DictionaryView
            className={cc([styles.typeRoom, styles.grayText])}
            dictKey="apartment_type_room"
            dictItemId={apartment_type_room}
          />
          <DictionaryBadge
            hideText
            badgeOuter
            dictKey="apartment_status"
            dictItemId={apartment_status}
          />
        </div>
        <div className={styles.row}>
          <NumberFormat
            className={cc([styles.text, styles.grayText])}
            value={apartment.price_m2}
            format="rub_meter2"
          />
        </div>
        <div className={styles.row}>
          <NumberFormat
            className={styles.text}
            value={apartment.price}
            format="rub"
          />
        </div>
      </div>
    </div>
  );
};

export const ListItem = memo(ListItemRender);

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Buildings from 'stores/Buildings';
import { BaseMapContainer } from 'components/atoms';
import LeafletStore from 'stores/LeafletStore';
import { useId } from 'react-id-generator';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { SetMapInstanceToStore } from './SetMapInstanceToStore';
import { infoStore } from '../../../../stores';
import { useUpdateMapCenter, useInitMapCenter } from './useMapCenter';
import { MarketTooltip } from './components/MarketTooltip';

const MainPageMapRender = () => {
  useInitMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  useUpdateMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  useEffect(() => LeafletStore.clear, []);

  const { complexes } = Buildings;
  const { center } = LeafletStore;

  const idList: string[] = useId(complexes.data?.pins.length, 'complexes_pins');

  return (
    <BaseMapContainer
      scrollWheelZoom
      center={center}
      zoom={12}
      mapInstanceForZoom={LeafletStore.instance}
    >
      <YandexMapLayer />
      {complexes.data?.pins?.map((pin, index) => (
        <MarketTooltip key={idList[index]} pin={pin} />
      ))}
      <SetMapInstanceToStore leafletStore={LeafletStore} />
    </BaseMapContainer>
  );
};

export const MainPageMap = observer(MainPageMapRender);

import { API_ROUTES } from 'services/constants';
import { ThrowableResult } from '../../../types';
import { Complex, ComplexRaw } from './types';
import { makeRequestWithAuth } from '../../../libs/axios/makeRequest';
import { loggerService } from '../../../loggerService';
import { PolygonCoordinates } from '../../../../types/PolygonCoordinates';
import { buildingService } from '../building';
import { BuildingEntity } from '../building/types';

export const viewService = {
  async getView(id: number): Promise<ThrowableResult<Complex>> {
    try {
      const { data } = await makeRequestWithAuth<ComplexRaw>({
        url: API_ROUTES.view.replace(':id', id.toString()),
        method: 'get',
      });

      return this.mapDtoToPlain(data);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  // eslint-disable-next-line class-methods-use-this
  mapDtoToPlain(complexDto: ComplexRaw): Complex {
    let polygon: Array<PolygonCoordinates> = [];
    let buildings: Array<BuildingEntity> = [];

    if (complexDto.polygon) {
      polygon = JSON.parse(complexDto.polygon) as Array<
        [lat: number, lng: number]
      >;
    }

    if (complexDto.buildings) {
      buildings = complexDto.buildings.map(buildingService.transformBuilding);
    }
    return {
      ...complexDto,
      polygon,
      buildings,
    } as Complex;
  },
};

import { action, makeObservable, observable } from 'mobx';
import { CityInfo, infoService } from '../../services';
import { RequestApiStore } from '../request/RequestApiStore';

export class CityStore {
  cities: CityInfo[] = [];

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      cities: observable,
      clear: action.bound,
      loadCities: action.bound,
    });

    void this.loadCities();
  }

  clear() {
    this.requestApi.clear();
    this.cities = [];
  }

  loadCities = async (): Promise<void> => {
    try {
      this.requestApi.setLoading(true);
      this.cities = await infoService.getCities();
    } catch (e) {
      this.requestApi.setError(true);
    }

    this.requestApi.setLoading(false);
  };
}

export const cityStore = new CityStore();

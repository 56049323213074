import React from 'react';
import { AuthPopup } from '../../components/AuthPopup';
import styles from './styles.module.css';

export const LinkIsOutOfDatePage = () => (
  <AuthPopup>
    <div className={styles.wrapper}>
      <div className={styles.title}>Ссылка устарела</div>
      <div className={styles.subTitle}>
        Попробуйте войти / восстановить пароль заново. Ссылки действительны в
        течение 3 часов.
      </div>
    </div>
  </AuthPopup>
);

import React from 'react';
import { addSeconds, setDay } from 'date-fns';
import { flow, map, curryRight } from 'lodash/fp';
import { Text } from 'components/atoms/Text';
import { MayBe } from '../../../../../types';
import { WorkTimes } from '../../../../../services';
import { dateFormat, DateFormats } from '../../../../atoms';

import styles from './styles.module.css';

type Props = {
  work_times?: MayBe<WorkTimes[]>;
};

const secondToHour = (seconds?: MayBe<number>): Date =>
  addSeconds(new Date(1970, 1, 1, 0, 0, 0), seconds || 0);

/**
 * @example
 * input: [39600, 67200]
 * output: ['10:00', '19:00']
 * */
const formatTimeInterval = (
  interval: [MayBe<number> | undefined, MayBe<number> | undefined],
): [string, string] =>
  flow<any, Date[], (MayBe<string | Date> | undefined)[]>(
    map(secondToHour),
    map<Date, MayBe<string | Date> | undefined>(
      curryRight<
        Date,
        DateFormats | undefined,
        MayBe<string | Date> | undefined
      >(dateFormat)('time'),
    ),
  )(interval) as [string, string];

export const WorkTimesView = ({ work_times }: Props) => (
  <div className={styles.wrapper}>
    {work_times?.map(({ day, isActive, startTime, endTime }) => (
      <div key={day} className={styles.item}>
        <Text indent={{ margin: [0, 40] }} align="left">
          {dateFormat(setDay(new Date(), day || 0), 'shortWeek')}
        </Text>
        <Text align="right">
          {!isActive && 'выходной'}
          {isActive && formatTimeInterval([startTime, endTime]).join('-')}
        </Text>
      </div>
    ))}
  </div>
);

import { get } from 'lodash';
import { AxiosError } from 'axios';
import { MayBe } from 'types';
import { AUTH_TOKEN_NAME } from 'services/storage';
import { axiosInstanceWithAuth } from './index';

export const getResponseErrorMessage = (error: any) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  get(error, ['response', 'data', 'message']);

export const isAxiosError = (payload: any): payload is AxiosError =>
  payload?.isAxiosError === true;

export const setTokenToRequestHeader = (token: MayBe<string>) => {
  if (token) {
    axiosInstanceWithAuth.defaults.headers[AUTH_TOKEN_NAME] = token;
  } else {
    delete axiosInstanceWithAuth.defaults.headers[AUTH_TOKEN_NAME];
  }
};

import React, { ReactNode } from 'react';
import cc from 'classcat';
import { capitalize, filter, flow, get, map, replace, trim } from 'lodash/fp';
import { DictionaryView, Text } from 'components/atoms';
import { infoStore } from 'stores';
import { DictionaryKeys } from 'services';
import styles from './styles.module.css';

type Props = {
  excludeInfoKeys?: DictionaryKeys[];
  catalogs: Partial<Record<DictionaryKeys, number[]>>;
  className?: string;
};

export const CatalogsTable = ({
  catalogs,
  excludeInfoKeys,
  className,
}: Props) => (
  <div className={cc([styles.wrapper, className])}>
    {flow<any, any[], ReactNode[]>(
      filter(([key]) => !excludeInfoKeys?.includes(key)),
      map(([infoKey, items]) => (
        <div key={infoKey}>
          <Text
            tagName="div"
            indent={{
              margin: [0, 0, 5],
            }}
            size="XS"
            lineHeight="M"
          >
            {flow(
              get('title'),
              replace(/Жк:|Коттеджный поселок:/gi, ''),
              trim,
              capitalize,
            )(infoStore?.getInfoBy(infoKey as DictionaryKeys))}
          </Text>
          <Text tagName="div" weight="bold" size="M" lineHeight="M">
            {items && infoKey && (
              <DictionaryView
                dictKey={infoKey as DictionaryKeys}
                dictItemId={items}
              />
            )}
          </Text>
        </div>
      )),
    )(Object.entries(catalogs))}
  </div>
);

import css from '@styled-system/css';
import { Box } from 'components/styledComponents/Box';
import styled from 'styled-components';

type BadgeProps = {
  bg: string;
  color: string;
  border: string;
};

export const Badge = styled(Box)<BadgeProps>(({ bg, color, border }) =>
  css({
    position: 'fixed',
    bg,
    color,
    borderColor: border,
    borderStyle: 'solid',
    borderWidth: border ? 2 : 0,
    borderRadius: 4,
    padding: '4px',
    textAlign: 'center',
    transform: 'translate(-50%, -50%)',
    '&, div': {
      pointerEvents: 'none',
    },
  }),
);

import { useMemo } from 'react';
import { get } from 'lodash/fp';
import { useCounter } from 'hooks/useCounter';
import { MayBe } from '../../../../../types';
import { PolygonCoordinates } from '../../../../../types/PolygonCoordinates';

type ReturnProps = {
  /** @desc кол-во этажей */
  maxLevel: number;

  /** @desc управление переключением этажей */
  count: number;
  onDecrement: () => void;
  onIncrement: () => void;
  setCount: (count: number) => void;

  /** @desc показывать ли переключатель этажей, работает только для многоэтажных квартир */
  isShowLevelStepper: boolean;
  /** @desc план текущего просматриваемого этажа */
  currentPlan: MayBe<string>;
  /** @desc полигоны для плана квартиры на текущем этаже */
  currentPolygon: PolygonCoordinates[] | undefined;
};

type Props = {
  polygons?: (PolygonCoordinates[] | undefined)[];
  plans: MayBe<string>[];
  initialCount?: number;
};

export const useApartmentPlanOnMap = ({
  initialCount,
  polygons,
  plans,
}: Props): ReturnProps => {
  const maxLevel = plans?.length || 0;

  const { count, onDecrement, onIncrement, setCount } = useCounter({
    initialCount: initialCount || 1,
    minCount: 1,
    maxCount: maxLevel,
  });

  const isShowLevelStepper = useMemo(() => maxLevel > 1, [maxLevel]);

  const currentPlan = get([count - 1], plans);
  const currentPolygon = get([count - 1], polygons);

  return {
    count,
    onDecrement,
    onIncrement,
    setCount,
    currentPlan,
    currentPolygon,
    maxLevel,
    isShowLevelStepper,
  };
};

import React from 'react';
import { AuthForGetAccessToFeature } from 'pages/AuthPage/pages/AuthForGetAccessToFeature';
import layoutPreview from 'assets/images/layoutPreview.jpg';

export const AuthForGetAccessToApartment = () => (
  <AuthForGetAccessToFeature
    title="Работа с шахматкой доступна только зарегистрированным пользователям"
    subTitle="Зарегистрируйтесь, это бесплатно"
    imagePreview={layoutPreview}
  />
);

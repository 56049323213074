import React from 'react';
import { useDidMount } from 'beautiful-react-hooks';
import { observer } from 'mobx-react';
import { EmptyData } from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { reaction } from 'mobx';
import { rootProfileStore } from '../../../../stores/user/RootProfileStore';
import { useAccessToFeedsModal } from './containers/AccessToFeedsModal';
import { FeedList } from './components/FeedList';
import styles from './styles.module.css';
import { feedStore } from '../../../../stores/user/FeedStore';
import { FeedPreloader } from './components/FeedPreloader';

export const FeedsPageRender = () => {
  const { authStore } = rootProfileStore;
  const { showAccessToFeedsModal } = useAccessToFeedsModal();
  const { feed, requestApi, isAccessToFeed } = feedStore;

  useDidMount(() => {
    if (!authStore.isAuth) {
      showAccessToFeedsModal();
    } else {
      reaction(
        () => feedStore.isAccessToFeed,
        (isAccessToFeed, prevIsAccessToFeed) => {
          if (prevIsAccessToFeed === null && !isAccessToFeed) {
            showAccessToFeedsModal();
          }
        },
      );
      void feedStore.getFeedList();
    }
  });

  return (
    <div>
      <h1 className={styles.feedsPageTitle}>Фиды</h1>

      {!requestApi.isLoading && !isAccessToFeed && (
        <div className={styles.feedsPageEmptyDataWrapper}>
          <EmptyData title="Нет доступа к фидам" />
          <ButtonBase onClick={showAccessToFeedsModal}>
            Запросить доступ
          </ButtonBase>
        </div>
      )}

      {requestApi.isLoading && <FeedPreloader />}

      {!requestApi.isLoading && isAccessToFeed && feed && (
        <FeedList list={feed} />
      )}
    </div>
  );
};

export const FeedsPage = observer(FeedsPageRender);

import React, { ReactNode, MouseEventHandler } from 'react';

import styles from './LinkButton.module.css';

type LinkButtonProps = {
  children: ReactNode;
  onClick: MouseEventHandler;
};

export const LinkButton = ({ children, onClick }: LinkButtonProps) => (
  <button className={styles.linkButton} type="button" onClick={onClick}>
    {children}
  </button>
);

import React from 'react';
import { observer } from 'mobx-react';
import { useWillUnmount, useDidMount } from 'beautiful-react-hooks';
import { OffersViews } from 'services';
import { offerItemStore, offersEditView } from 'stores';
import { VisibilitySettingsList } from '../../components/VisibilitySettingsList';
import { OfferViewFieldOption } from '../../types';

const options: OfferViewFieldOption[] = [
  {
    label: 'ЖК',
    name: 'complex',
  },
  {
    label: 'Карта',
    name: 'map',
  },
  {
    label: 'Стоимость',
    name: 'price',
  },
  {
    label: 'Застройщик',
    name: 'developer',
  },
];

export const VisibilitySettingFormRender = () => {
  const { offer } = offerItemStore;

  useWillUnmount(() => {
    offerItemStore.setViews(offersEditView.viewFields);
    offersEditView.clear();
  });

  useDidMount(() => {
    if (!offer?.views) {
      return;
    }
    offersEditView.setViewFields(offer?.views);
    offersEditView.setOfferId(offer?.id);
  });

  const onChecked = (targetField: OffersViews) => () =>
    offersEditView.toggleViewFieldWithSave(targetField);

  return (
    <VisibilitySettingsList
      options={options}
      onChecked={onChecked}
      isChecked={offersEditView.isInclude}
    />
  );
};

export const VisibilitySettingForm = observer(VisibilitySettingFormRender);

import css from '@styled-system/css';
import styled from 'styled-components';
import { Box } from 'components/styledComponents/Box';

export const Container = styled(Box)(
  css({
    width: 'auto',
    margin: '-10px',
    py: '20px',
    label: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const LeftCol = styled(Box)(
  css({
    borderRight: '1px solid #DFDFEB',
    px: '20px',
    width: '100px',
  }),
);

export const RightCol = styled(Box)(
  css({
    px: '20px',
    width: '170px',
  }),
);

export const Tabs = styled(Box)(
  css({
    px: '0px',
  }),
);

export const Tab = styled(Box)<{ active: boolean }>(({ active }) =>
  css({
    cursor: 'pointer',
    fontWeight: 'bold',
    color: active ? '#4E6AFF' : '#000000',
    fontSize: '14px',
    lineHeight: '21px',
    mb: '10px',
  }),
);

import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from 'services';
import { rootApartmentStore } from 'stores/Apartment/RootApartmentStore';
import { getUrlForCurrentActiveTab } from '../../utils';

export const useCloseApartmentViewHandler = (currentActiveTab: number) => {
  const { apartmentUIStore } = rootApartmentStore;
  const { push } = useHistory();

  const { complexId, buildingId, layoutTabs } =
    useParams<Pick<Params, 'complexId' | 'buildingId' | 'layoutTabs'>>();

  const closeApartmentView = useCallback(() => {
    const link = getUrlForCurrentActiveTab(currentActiveTab, {
      complexId,
      buildingId,
      layoutTabs,
    });

    push(link);
    apartmentUIStore.clearActiveApartment();
  }, [complexId, buildingId, layoutTabs]);

  return { closeApartmentView };
};

import cc from 'classcat';
import { isNil, negate } from 'lodash/fp';
import styles from './styles.module.css';

type IndentSize = 0 | 2 | 5 | 10 | 12 | 15 | 20 | 25 | 30 | 35 | 40;

export type IndentsProps = {
  indent?: {
    margin?: IndentSize[];
    padding?: IndentSize[];
  };
};

const IndentsPositions = ['top', 'right', 'bottom', 'left'];

export const getIndentClasses = ({
  margin,
  padding,
}: IndentsProps['indent'] = {}) => {
  let paddingClasses: (string | undefined)[] = [];
  let marginClasses: (string | undefined)[] = [];
  if (padding) {
    paddingClasses = IndentsPositions.map((pos, index) => {
      if (padding[index]) {
        return styles[
          `indent_padding_${IndentsPositions[index]}_${padding[index]}`
        ];
      }
      return undefined;
    });
  }
  if (margin) {
    marginClasses = IndentsPositions.map((pos, index) => {
      if (margin[index]) {
        return styles[
          `indent_margin_${IndentsPositions[index]}_${margin[index]}`
        ];
      }
      return undefined;
    });
  }

  return cc([...paddingClasses, ...marginClasses].filter(negate(isNil)));
};

import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import cc from 'classcat';
import closeIcon from 'assets/close.svg';
import { ErrorBoundary } from '../../ErrorBoundary';
import styles from './styles.module.css';
import { ButtonBase } from '../../../../pages/LayoutPage/components/ButtonBase';
import { Icon } from '../../Icon';

type Props = {
  children?: ReactNode;
  headerChildren?: ReactNode;
  isOpen: boolean;
  isHeaderSticky?: boolean;
  onClose?: () => void;
  setForwardedContentRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | undefined>
  >;
  className?: string;
};

export const MobileDrawer = ({
  children,
  headerChildren,
  isOpen,
  onClose,
  setForwardedContentRef,
  className,
  isHeaderSticky,
}: Props) => (
  <ReactModal
    closeTimeoutMS={250}
    isOpen={isOpen}
    ariaHideApp={!isOpen}
    onRequestClose={onClose}
    className={cc([styles.drawerContent])}
    overlayClassName={cc([styles.drawerOverlay, className])}
    overlayRef={(ref) => {
      if (setForwardedContentRef) {
        return setForwardedContentRef(ref);
      }
      return null;
    }}
  >
    <header
      className={cc([
        styles.drawerHeader,
        {
          [styles.drawerHeaderSticky]: isHeaderSticky,
        },
      ])}
    >
      <div className={styles.drawerHeaderContent}>{headerChildren}</div>
      <ButtonBase
        onClick={onClose}
        icon={<Icon src={closeIcon} />}
        color="transparent"
      />
    </header>
    <div className={styles.drawerContentScroll}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  </ReactModal>
);

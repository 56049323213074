import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import cc from 'classcat';

import { ErrorBoundary } from '../../ErrorBoundary';
import styles from './styles.module.css';

type Props = {
  position: 'left' | 'right';
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  setForwardedContentRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | undefined>
  >;
  className?: string;
};

export const Drawer = ({
  children,
  isOpen,
  onClose,
  setForwardedContentRef,
  className,
  position,
}: Props) => (
  <ReactModal
    isOpen={isOpen}
    ariaHideApp={!isOpen}
    onRequestClose={onClose}
    className={cc([styles.drawerContent])}
    overlayClassName={cc([
      styles.drawerOverlay,
      {
        [styles.drawerOverlayLeft]: position === 'left',
        [styles.drawerOverlayRight]: position === 'right',
      },
      className,
    ])}
    overlayRef={(ref) => {
      if (setForwardedContentRef) {
        return setForwardedContentRef(ref);
      }
      return null;
    }}
  >
    <ErrorBoundary>{children}</ErrorBoundary>
  </ReactModal>
);

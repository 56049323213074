import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children?: ReactNode | ReactNode[];
  className?: string;
};

export const List = ({ children, className }: Props) => (
  <div className={cc([styles.list, className])}>{children}</div>
);

import {
  filter,
  find,
  flow,
  get,
  isNumber,
  isString,
  map,
  negate,
  isNil,
  flatten,
  reverse,
} from 'lodash/fp';
import { toJS } from 'mobx';
import {
  ApartmentDto,
  Feature,
  appRoutesService,
  Params,
  Layouts,
  FeatureCollection,
  BuildingEntity,
} from 'services';
import { MayBe } from 'types/base/MayBe';
import { TabItemProps } from 'components/containers/Tabs';

import { LayoutTabs, LayoutTabsEnum } from '../types';
import { compareByPhaseTitle, findById, ifElse, sort } from '../../../utils/fp';
import { dateFormat } from '../../../components/atoms';

/**
 * @example "Сдан 1К 2020"
 * */
export const getPhaseCompletionText = (building: BuildingEntity) => {
  if (!building.phase_predicted_completion_at) return '';
  return `Сдан ${
    dateFormat(building.phase_predicted_completion_at, 'quarter') as string
  } ${dateFormat(building.phase_predicted_completion_at, 'year') as string}`;
};

/** @desc функция формирует ссылку на какой либо раздел шахматки с переданными параметрами */
export const getUrlForCurrentActiveTab = (
  currentActiveTab: number,
  params: Pick<Params, 'complexId'> &
    Pick<Params, 'layoutTabs'> &
    Pick<Params, 'buildingId'> &
    Pick<Params, 'apartmentId'> &
    Pick<Params, 'apartmentNumber'>,
) => {
  let route: string;
  if (params.apartmentId) {
    route = appRoutesService.replaceRoute('layoutActiveApartment', {
      complexId: params.complexId,
      buildingId: params.buildingId,
      apartmentId: params.apartmentId.toString(),
      apartmentNumber: params.apartmentNumber,
      layoutTabs: LayoutTabsEnum[currentActiveTab],
    });
  } else {
    route = appRoutesService.replaceRoute('layoutActiveTab', {
      complexId: params.complexId,
      buildingId: params.buildingId,
      layoutTabs: LayoutTabsEnum[currentActiveTab],
    });
  }
  return route;
};

export const getActiveTabByRouteName = (layoutTabs: LayoutTabs): number => {
  if (layoutTabs === 'floorPlan') {
    return 1;
  }
  if (layoutTabs === 'apartments') {
    return 2;
  }
  return 0;
};

export const getWindowsFromLayers = flow<
  any,
  (MayBe<FeatureCollection> | undefined)[],
  FeatureCollection[],
  MayBe<Feature[]>[],
  Feature[][],
  Feature[]
>(
  map<Layouts, MayBe<FeatureCollection> | undefined>(
    get<Layouts, 'windows'>('windows'),
  ),
  filter(negate(isNil)),
  map<FeatureCollection, MayBe<Feature[]>>(
    get<FeatureCollection, 'features'>('features'),
  ),
  filter(negate(isNil)),
  flatten,
);

export const getWindowForApartment = get<
  any,
  'layouts',
  '0',
  'windows',
  'features'
>(['layouts', '0', 'windows', 'features']) as (
  apartment?: ApartmentDto,
) => Feature[] | undefined;

export const getBuildingFromComplexById = (building_id: number) =>
  flow(toJS, get('buildings'), find(findById(building_id?.toString())));

export const getBuildingsTabs = flow<
  any,
  Array<BuildingEntity>,
  Array<BuildingEntity>,
  Array<BuildingEntity>,
  TabItemProps<BuildingEntity>[]
>(
  toJS,
  sort(compareByPhaseTitle),
  filter(
    (building) =>
      !!building?.stats?.apartments &&
      (isNumber(get('number', building)) || isString(get('number', building))),
  ),
  map((building: BuildingEntity) => ({
    id: building.id,
    label: `${building.number || ''}`,
    helperText: getPhaseCompletionText(building) || ' ',
    data: building,
  })),
);

export const reversePolygonCoords = flow<
  any,
  Array<[number, number]>,
  Array<[number, number]>,
  Array<[number, number]>
>(
  toJS,
  map(reverse),
  ifElse<Array<[number, number]>>(
    isNil,
    () => [],
    (args: Array<[number, number]>) => args,
  ),
);

import { action, makeObservable, observable } from 'mobx';
import { RequestApiStore } from '../../request/RequestApiStore';
import { MayBe } from '../../../types';
import { userFeedService } from '../../../services/v1/user/feed';
import { Feed } from '../../../services/v1/feed/types';

export class FeedStore {
  feed: MayBe<Feed[]> = null;

  isAccessToFeed: MayBe<boolean> = null;

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();

    makeObservable(this, {
      getFeedList: action,
      setIsAccessToFeed: action,
      isAccessToFeed: observable,
      feed: observable,
    });
  }

  setIsAccessToFeed = (isAccessToFeed: boolean) => {
    this.isAccessToFeed = isAccessToFeed;
  };

  getFeedList = async () => {
    this.requestApi.setLoading(true);
    const [error, result] = await userFeedService.feedList();

    if (result) {
      this.setIsAccessToFeed(true);
      this.feed = result;
    }

    if (error) {
      this.setIsAccessToFeed(false);
      this.requestApi.setError(false);
    }
    this.requestApi.setLoading(false);
  };
}

export const feedStore = new FeedStore();

import React from 'react';
import { observer } from 'mobx-react';

import { feedbackStore } from 'stores/FeedbackStore/FeedbackStore';
import { FormRender } from 'components/containers/FeedbackForm/components/FeedbackForm';
import { BaseFeedbackForm } from 'components/containers/BaseFeedbackForm';
import styles from './style.module.css';

type Props = {
  lastImageUrl?: string;
};

export const SliderFeedbackFormRender = ({ lastImageUrl }: Props) => (
  <div className={styles.wrapper}>
    {lastImageUrl && <img src={lastImageUrl} alt="feedback background" />}
    <BaseFeedbackForm feedbackStore={feedbackStore} component={FormRender} />
  </div>
);

export const SliderFeedbackForm = observer(SliderFeedbackFormRender);

import { useMemo } from 'react';
import { OFFER_STATUSES } from 'services';
import { offersEditStore } from 'stores';

export const useOfferStatus = (status_id: number) => {
  const currentStatus = useMemo(
    () => offersEditStore.getStatusById(status_id),
    [status_id, offersEditStore.infoStore.requestApi.isLoading],
  );

  const statusIsActive = useMemo(
    () => currentStatus?.key === ('offer_status_active' as OFFER_STATUSES),
    [currentStatus],
  );

  const statusIsDelete = useMemo(
    () => currentStatus?.key === ('offer_status_delete' as OFFER_STATUSES),
    [currentStatus],
  );

  const statusIsFinish = useMemo(
    () => currentStatus?.key === ('offer_status_finish' as OFFER_STATUSES),
    [currentStatus],
  );

  return {
    currentStatus,
    statusIsActive,
    statusIsDelete,
    statusIsFinish,
  };
};

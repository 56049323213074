import React, { ComponentProps, useMemo } from 'react';
import { CONTENT_DOES_NOT_EXIST } from 'services/constants';
import location_icon from 'assets/bigPin.svg';
import web_icon from 'assets/web_icon.svg';
import mail_icon from 'assets/mail_icon.svg';
import phone_icon from 'assets/phone_icon.svg';
import { getLinkToMap } from 'utils/getLinkToMap';

import { MayBe } from 'types';
import { numberFormat, NumberFormatPatterns } from '../NumberFormat';
import { IndentsProps } from '../Indents';
import { IconWithText } from '../IconWithText';
import { Icon } from '../Icon';
import styles from './styles.module.css';

const ContactViewIcons = {
  mailto: mail_icon,
  tel: phone_icon,
  site: web_icon,
  address: location_icon,
};

type Props = {
  type?: keyof typeof ContactViewIcons;
  address_original?: MayBe<string> | undefined;
  longitude?: MayBe<string> | undefined;
  latitude?: MayBe<string> | undefined;
  phone?: MayBe<string | number> | undefined;
  numberFormatPatterns?: NumberFormatPatterns;
  email?: MayBe<string> | undefined;
  site?: MayBe<string> | undefined;
  [k: string]: any;
} & IndentsProps &
  ComponentProps<typeof IconWithText>;

export const ContactView = ({
  type,
  address_original,
  longitude,
  latitude,
  phone,
  numberFormatPatterns,
  email,
  site,
  ...rest
}: Props) => {
  const icon = type && ContactViewIcons[type];
  const href = useMemo(() => {
    if (email && type === 'mailto') {
      return `mailto:${email || ''}`;
    }
    if (phone && type === 'tel') {
      return `tel:${phone}`;
    }
    if (site && type === 'site') {
      return site;
    }
    if (address_original && type === 'address') {
      return getLinkToMap(longitude, latitude);
    }
    return null;
  }, [type, address_original, longitude, latitude, phone, email, site]);

  const label = useMemo(() => {
    if (email && type === 'mailto') {
      return email;
    }
    if (phone && type === 'tel') {
      return numberFormat(phone, numberFormatPatterns || 'phone');
    }
    if (site && type === 'site') {
      return site;
    }
    if (address_original && type === 'address') {
      return address_original;
    }
    return CONTENT_DOES_NOT_EXIST;
  }, [type, address_original, longitude, latitude, phone, email, site]);

  return (
    <IconWithText
      {...rest}
      target="_blank"
      href={href || ''}
      rel="noreferrer"
      icon={<Icon size={16} src={icon} />}
      element="a"
      className={styles.contactViewLink}
    >
      {label}
    </IconWithText>
  );
};

import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import cc from 'classcat';
import { useWindowResize } from 'beautiful-react-hooks';
import styles from './styles.module.css';

type Props = {
  currentActiveTab: number;
  children: ReactNode;
  className?: string;
};

const collectionToArray = (collection: HTMLCollection): HTMLElement[] =>
  Array.from(collection) as unknown as HTMLElement[];

const filterNotIncludeClass =
  (excludeClassName: string) => () => (element: HTMLElement) =>
    !element.classList.contains(excludeClassName);

const getOffsetWidth = (element?: HTMLElement) => {
  if (!element) {
    return 0;
  }
  return element.offsetWidth;
};
const getOffsetLeft = (element?: HTMLElement) => {
  if (!element) {
    return 0;
  }
  return element.offsetLeft;
};

const getElementByIndex = (index: number) => (elements: HTMLElement[]) =>
  elements[index];

export const TabList = ({
  children,
  currentActiveTab,
  className,
  ...rest
}: Props) => {
  const tabListRef = useRef<HTMLDivElement>(null);
  const [activeWidth, setWidth] = useState<number>(0);
  const [activePosition, setPosition] = useState<number>(0);

  const onChangeActiveElement = useCallback(() => {
    if (!tabListRef.current) {
      return;
    }
    const currentActiveTabElement = getElementByIndex(currentActiveTab)(
      collectionToArray(tabListRef.current.children).filter(
        filterNotIncludeClass(styles.tabListActive),
      ),
    );
    setWidth(getOffsetWidth(currentActiveTabElement));
    setPosition(getOffsetLeft(currentActiveTabElement));
  }, [currentActiveTab, tabListRef.current]);

  useEffect(() => {
    if (tabListRef && tabListRef.current && currentActiveTab >= 0) {
      const timerId = setTimeout(onChangeActiveElement, 250);
      return () => clearTimeout(timerId);
    }
    return undefined;
  }, [currentActiveTab, children]);

  useWindowResize(onChangeActiveElement);

  return (
    <div {...rest} ref={tabListRef} className={cc([styles.tabList, className])}>
      {children}
      <div
        style={{
          width: `${activeWidth}px`,
          transform: `translate(${activePosition}px,0)`,
        }}
        className={styles.tabListActive}
      />
    </div>
  );
};

import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  getActiveTabByRouteName,
  getUrlForCurrentActiveTab,
} from '../../../utils';
import { LayoutTabs } from '../../../types';
import { Params } from '../../../../../services';

export const useGetOpenApartment = () => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { push } = useHistory();
  const { complexId, layoutTabs, buildingId } =
    useParams<
      Pick<
        Params,
        'complexId' | 'layoutTabs' | 'buildingId' | 'apartmentNumber'
      >
    >();

  return useCallback(
    (apartmentId, apartmentNumber) => () => {
      push(
        getUrlForCurrentActiveTab(
          getActiveTabByRouteName(layoutTabs as LayoutTabs),
          {
            complexId,
            apartmentId,
            buildingId,
            apartmentNumber,
          },
        ),
      );
    },
    [complexId, layoutTabs],
  );
};

import { cond, findIndex, flow, get, isEqual, size, stubTrue } from 'lodash/fp';
import { OffersViewItem } from 'services';
import { findById } from 'utils/fp';
import { MayBe } from 'types';

export const getIndexNextApartment = (
  apartmentId: string | undefined,
  offer: MayBe<OffersViewItem>,
) =>
  flow<any, any, any, number>(
    get('apartments'),
    findIndex<any>(findById(apartmentId)),
    cond([
      [
        (currentApartmentIndex: number) =>
          isEqual(currentApartmentIndex + 1)(
            flow<any, any[], number>(get(['apartments']), size)(offer),
          ),
        () => 0,
      ],
      [stubTrue, (currentApartmentIndex: number) => currentApartmentIndex + 1],
    ]),
  )(offer);

export const getNextApartmentId = (nextApartmentIndex: number) =>
  get(['apartments', nextApartmentIndex.toString(), 'id']);

import { Option } from 'components/atoms/Form/FormikSelect/types';
import { SchemaLike } from 'yup/lib/types';
import { OTHER } from 'services/constants';
import { Yup } from './yup';

export const authPhoneRegistrationSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  email: Yup.string().required(),
  role: Yup.object().required(),
  developerId: Yup.object().when(
    'role',
    (other: Option, schema: any): SchemaLike => {
      if (other && other?.value === 'developer') {
        return schema.required() as SchemaLike;
      }

      return schema as SchemaLike;
    },
  ),
  agencyId: Yup.object().when(
    'role',
    (other: Option, schema: any): SchemaLike => {
      if (other && other?.value === 'realtor') {
        return schema.required() as SchemaLike;
      }

      return schema as SchemaLike;
    },
  ),
  developerCustom: Yup.string().when(
    ['developerId', 'agencyId'],
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (developerId: Option, agencyId: Option, schema: any): SchemaLike => {
      /**
       * @desc: если в поле developerId выбрано "Другое",
       * то необходимо ввести вручную название в поле developerCustom
       * */
      if (agencyId && agencyId?.value === OTHER) {
        return schema.required() as SchemaLike;
      }
      if (developerId && developerId?.value === OTHER) {
        return schema.required() as SchemaLike;
      }
      return schema as SchemaLike;
    },
  ),
});

import { offersApartmentEdit } from '../../../../stores';

type ReturnedType = {
  removeApartment: () => Promise<void>;
};

export const useRemoveApartmentFromOffer = (
  apartmentId?: number | string | null,
  offerId?: number | string,
  successCallBack?: () => void,
): ReturnedType => {
  const removeApartment = async () => {
    await offersApartmentEdit.removeApartment(apartmentId, offerId);

    if (successCallBack) {
      successCallBack();
    }
  };

  return {
    removeApartment,
  };
};

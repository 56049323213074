/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { ComponentProps, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import { FullscreenPopup } from 'components/atoms/Popup';
import { Icon } from 'components/atoms';
import { Box } from 'components/styledComponents/Box';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { buildingGridStore } from 'pages/LayoutPage/stores/buildingGridStore';
import { Badge } from 'pages/LayoutPage/pages/FloorPlans/containers/SquareMetersOnFloorPlan';
import eyeDark from 'assets/eyeDark.svg';
import eye from 'assets/eye.svg';
import styles from './styles.module.css';
import { FloorPopupsNavBar } from '../../components/FloorPopupsNavBar';
import { MayBe } from '../../../../types';
import { useApartmentPlanOnMap } from '../../pages/ApartmentView/hooks/useApartmentPlanOnMap';
import {
  ApartmentDto,
  getAllApartmentPlans,
  getAllApartmentsLayoutsIds,
  getApartmentSquares,
  Layouts,
} from '../../../../services';

type Props = {
  apartment: ApartmentDto | undefined;
  initialCount?: number;
  apartmentSquares?: MayBe<Layouts['squares']>;
} & Pick<ComponentProps<typeof FullscreenPopup>, 'onClose'>;

/**
 * @link https://www.figma.com/file/VH9HbBNBGglqqZsQBBtNkU/%D0%A8%D0%B0%D1%85%D0%BC%D0%B0%D1%82%D0%BA%D0%B0?node-id=1%3A720
 * @desc Планировка квартиры
 * @todo решение временное, когда в API Будут обрезанные планировки КВ переделать
 * */
export const ApartmentPlanPopup = ({
  apartment,
  onClose,
  initialCount,
  apartmentSquares: apartmentSquaresProps,
}: Props) => {
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);
  const { currentPlan, isShowLevelStepper, count, setCount, maxLevel } =
    useApartmentPlanOnMap({
      plans: apartmentPlans,
      initialCount,
    });

  const [showMeters, toggleShowMeters] = useState<boolean>(true);
  const apartmentsLayouts = buildingGridStore?.getApartmentsLayouts || [];
  const floorPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  const apartmentsLayoutsIds: number[] = getAllApartmentsLayoutsIds(apartment);

  const apartmentSquares = useMemo(
    () =>
      apartmentSquaresProps ||
      getApartmentSquares(apartmentsLayouts, apartmentsLayoutsIds),
    [apartmentsLayouts, apartmentsLayoutsIds],
  );

  const handleToggleShowMeters = () => {
    toggleShowMeters((prev) => !prev);
  };

  const badgePallate = {
    bg: '#F1F6FC',
    color: '#000',
    border: '#DFDFEB',
  };

  return (
    <FullscreenPopup
      header={
        isShowLevelStepper && (
          <div className={styles.header}>
            <FloorPopupsNavBar
              count={count}
              maxLevel={maxLevel}
              setCount={setCount}
            />
          </div>
        )
      }
      isOpen
      onClose={onClose}
    >
      <div
        className={styles.wrapper}
        style={{
          marginTop: isShowLevelStepper ? 0 : '50px',
        }}
      >
        <Box
          position="relative"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          height="100vh"
        >
          <div className={styles.mapWrapper}>
            {!!apartmentSquares?.length &&
              showMeters &&
              apartmentSquares.map(
                ({
                  id,
                  room_type_id,
                  square,
                  latitude_absolute,
                  longitude_absolute,
                }: any) => (
                  <Box
                    key={id}
                    position="absolute"
                    zIndex={99999}
                    top={`${latitude_absolute}%`}
                    left={`${longitude_absolute}%`}
                  >
                    <Badge
                      badgePallate={badgePallate}
                      id={room_type_id}
                      val={square}
                    />
                  </Box>
                ),
              )}
            <img
              height={3000}
              className={styles.map}
              src={currentPlan || ''}
              alt=""
            />
          </div>
        </Box>

        {!!apartmentSquares?.length && (
          <Box position="absolute" zIndex={999} bottom={10} left={10}>
            <ButtonBase
              color={showMeters ? 'default' : 'disable'}
              onClick={handleToggleShowMeters}
              icon={<Icon size={16} src={showMeters ? eyeDark : eye} />}
            >
              Площадь
            </ButtonBase>
          </Box>
        )}
      </div>
    </FullscreenPopup>
  );
};

export const useApartmentPlanPopup = (
  apartment: ApartmentDto | undefined,
  initialCount?: number,
  apartmentSquares?: MayBe<Layouts['squares']>,
) => {
  const [showApartmentPlanPopup, hideApartmentPlanPopup] = useModal(
    () => (
      <ApartmentPlanPopup
        apartment={apartment}
        initialCount={initialCount}
        onClose={hideApartmentPlanPopup}
        apartmentSquares={apartmentSquares}
      />
    ),
    [apartment, initialCount],
  );

  return {
    showApartmentPlanPopup,
    hideApartmentPlanPopup,
  };
};

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';
import { Avatar } from '../Avatar';

type Props = {
  max?: number;
  children: ReactNode[];
  className?: string;
};

export const AvatarGroup = ({ max = 3, children, className }: Props) => {
  const isShowCount = children.length > max;

  return (
    <div className={cc([styles.wrapper, className])}>
      {React.Children.toArray(children).map((child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            className: cc([child.props.className, styles.avatar_item]),
          });
        }
        return child;
      })}

      {isShowCount && (
        <Avatar className={styles.avatar_item}>+{children.length - max}</Avatar>
      )}
    </div>
  );
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { FormikTextField } from 'components/atoms/Form/FormikTextField';
import { FormikTextareaField } from 'components/atoms/Form/FormikTextareaField';
import { FormikSelect } from 'components/atoms/Form/FormikSelect';
import logoutIcon from 'assets/logout.svg';
import { Loader } from 'components/atoms/Loader';
import { appRoutesService } from 'services';
import { Option } from 'components/atoms/Form/FormikSelect/types';
import { USER_ROLES } from 'services/constants';

import { UserProfileForm } from '../../types';
import { ImageUploader } from '../ImageUploader/ImageUploader';
import {
  findAgency,
  findDeveloper,
} from '../../../../../AuthPage/pages/AuthPhoneRegistrationPage/utilities';
import { FieldSelectOrOtherField } from '../FieldSelectOrOtherField';
import { ChangePhoneNumber } from '../ChangePhoneNumber';
import { ChangeEmail } from '../ChangeEmail';
import styles from './styles.module.css';

type Props = {
  formikProps: FormikProps<UserProfileForm>;
  logout: () => void;
  citiesOptions: Option[];
};
/**
 * @todo: разобратся с полями агенства и застройщик и кастомным полем для них,
 * проблема в том что на клиенте при регистрации можно выбрать другое но
 * этого де нет в админке, в итоге получается конфликт если
 * выбран застройщик и указано поле developerCustom что из этого показывать?
 * Плюс на клиенте при сохранении нужно делать проверку и если указан developerCustom то бропать developer и agency
 * */
export const UserFormRender = ({
  formikProps,
  logout,
  citiesOptions,
}: Props) => {
  const { isSubmitting, values, setFieldValue } = formikProps;

  const { push } = useHistory();

  const onLogoutButtonClick = () => {
    void logout();
    push(appRoutesService.getRoute('main'));
  };

  return (
    <>
      <div className={styles.mainInfoWrapper}>
        <ImageUploader values={values} setFieldValue={setFieldValue} />
        <Field
          className={styles.formField}
          label="Имя"
          name="name"
          component={FormikTextField}
        />
        <Field
          className={styles.formField}
          label="Фамилия"
          name="surname"
          component={FormikTextField}
        />
      </div>

      <Field
        className={styles.formMainField}
        label="Город по умолчанию"
        name="city"
        component={FormikSelect}
        options={citiesOptions}
        placeholder=""
      />

      <ChangePhoneNumber />

      <ChangeEmail />

      <Field
        className={styles.formMainField}
        label="Роль"
        name="role"
        placeholder=""
        options={[...USER_ROLES]}
        component={FormikSelect}
        disabled
      />

      {values.role?.value === 'realtor' && (
        <>
          <FieldSelectOrOtherField
            mainField={{
              label: 'Укажите агенство',
              name: 'agency',
              loadOptions: findAgency,
              className: styles.formMainField,
            }}
            otherField={{
              label: 'Укажите название',
              name: 'developerCustom',
              className: styles.formMainField,
            }}
          />
        </>
      )}
      {values.role?.value === 'developer' && (
        <FieldSelectOrOtherField
          mainField={{
            label: 'Укажите застройщика',
            name: 'developer',
            loadOptions: findDeveloper,
            className: styles.formMainField,
          }}
          otherField={{
            label: 'Укажите название',
            name: 'developerCustom',
            className: styles.formMainField,
          }}
        />
      )}

      <Field
        className={styles.formMainField}
        label="Описание"
        name="description"
        placeholder=""
        rows={6}
        component={FormikTextareaField}
      />
      <div className={styles.buttonGroup}>
        <ButtonBase
          icon={isSubmitting && <Loader color="primary" />}
          type="submit"
          disabled={isSubmitting}
          color="primary"
        >
          Сохранить
        </ButtonBase>

        <ButtonBase
          variant="contained"
          color="default"
          onClick={onLogoutButtonClick}
          icon={<img src={logoutIcon} alt="logout" />}
        >
          Выйти из аккаунта
        </ButtonBase>
      </div>
    </>
  );
};

import React from 'react';
import { RenderInputComponentProps } from 'react-autosuggest';
import styles from './styles.module.css';
import search from '../../../assets/search.svg';

/**
 * @link https://github.com/moroshko/react-autosuggest#renderinputcomponent-optional
 * */
export const renderInputComponent = (inputProps: RenderInputComponentProps) => (
  <div className={styles.inputWrapper}>
    <img className={styles.inputIcon} src={search} alt="search" />
    <input {...inputProps} />
  </div>
);

import { get } from 'lodash/fp';
import { TabItemProps } from '../hooks';

export const createGetDataFromTabsItem = <TabItemPayload>() =>
  get<TabItemProps<TabItemPayload>, 'data'>('data');

export const getCurrentTabItem =
  <TabItemPayload>(tabList: TabItemProps<TabItemPayload>[]) =>
  (currentActiveTab: number) =>
    get<TabItemProps<TabItemPayload>[], number>([currentActiveTab])(tabList);

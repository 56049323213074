import { AxiosError } from 'axios';
import { history } from 'hooks/useHistory';
import { appRoutesService } from '../../../appRoutesService';

export const unauthorizedInterceptorsOnRejected = (error: AxiosError) => {
  if (error.response?.status === 401) {
    history.push(appRoutesService.getRoute('authPhoneCodePage'));
  }
  return error;
};

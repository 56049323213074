import { SyncStorage } from 'stores/SyncTrunk/SyncTrunk';
import { MayBe } from 'types';
import { userServiceStorage } from 'services';

export const authUserStoreStorageAdapter: SyncStorage = {
  getItem(): MayBe<string> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const user = userServiceStorage.getUser();
    if (user) {
      return JSON.stringify(user);
    }
    return null;
  },
  setItem(_, json: string) {
    userServiceStorage.setUser(JSON.parse(json));
  },
  removeItem() {
    userServiceStorage.remove();
  },
};

import { action, makeObservable, observable, computed } from 'mobx';

import { SearchResultSetting } from '../../services';
import { MayBe } from '../../types';

type SearchResultSettingState = {
  isList?: MayBe<boolean>;
  isPin?: MayBe<boolean>;
};

export class SearchResultSettingStore {
  state: SearchResultSettingState = {
    isList: true,
    isPin: true,
  };

  constructor(initState?: SearchResultSettingState) {
    if (initState) {
      this.state = initState;
    }

    makeObservable(this, {
      state: observable,
      setIsList: action,
      setIsPin: action,
      searchResultSetting: computed,
      toApiFormat: computed,
    });
  }

  setIsList = (isList: boolean) => {
    this.state.isList = isList;
  };

  setIsPin = (isList: boolean) => {
    this.state.isPin = isList;
  };

  get searchResultSetting() {
    return this.state;
  }

  get toApiFormat(): Partial<SearchResultSetting> {
    return Object.keys(this.state).reduce(
      (acc: Partial<SearchResultSetting>, key: any) => {
        let item = this.state[key as keyof SearchResultSetting] as any;

        if (typeof item === 'boolean') {
          item = Number(item);
          acc = {
            ...acc,
            [key]: item,
          };
        }
        return acc;
      },
      {} as SearchResultSetting,
    );
  }
}

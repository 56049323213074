import { loggerService, User } from 'services';
import { MayBe } from 'types';

export const USER_STORAGE_KEY = 'user';

export const userServiceStorage = {
  setUser: (user: User) => {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
  },
  getUser: (): MayBe<User> => {
    try {
      const userRawData = localStorage.getItem(USER_STORAGE_KEY);

      if (userRawData) {
        return (JSON.parse(userRawData)?.state?.user as User) || null;
      }
    } catch (e) {
      loggerService.error(e);
    }
    return null;
  },
  remove: () => {
    localStorage.removeItem(USER_STORAGE_KEY);
  },
};

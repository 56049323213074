import qs from 'qs';
import { Developer } from './types';
import { ThrowableResult } from '../../types';
import { makeRequest } from '../../libs/axios/makeRequest';
import { API_ROUTES } from '../../constants';
import { loggerService } from '../../loggerService';

export const developersService = {
  async getDevelopers(search?: string): Promise<ThrowableResult<Developer[]>> {
    try {
      const { data } = await makeRequest<Record<string, Developer>>({
        url: API_ROUTES.developers,
        method: 'get',
        params: {
          search,
        },
        paramsSerializer: (params) => qs.stringify(params),
      });

      return Object.entries(data).map(([_, item]) => item);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  async getDeveloperById(
    developerId: string,
  ): Promise<ThrowableResult<Developer>> {
    try {
      const { data } = await makeRequest<Developer>({
        url: API_ROUTES.developer.replace(
          ':developerId',
          developerId.toString(),
        ),
        method: 'get',
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};

import React from 'react';
import { head } from 'lodash/fp';
import { ApartmentDto, getAllApartmentPlans } from 'services';
import { PlaceholderImage } from 'components/atoms';
import styles from './styles.module.css';
import { GridCardInfo } from '../../../GridApartments/components/GridCardInfo';

type Props = {
  apartment: ApartmentDto;
};

export const ApartmentInfoTooltip = ({ apartment }: Props) => {
  const apartmentPlans: string | undefined | null = head(
    getAllApartmentPlans(apartment),
  );

  return (
    <div className={styles.apartmentInfoTooltipWrapper}>
      <div className={styles.apartmentInfoTooltipLeft}>
        {apartmentPlans && (
          <img
            className={styles.apartmentInfoTooltipPlan}
            src={apartmentPlans}
            alt=""
          />
        )}
        {!apartmentPlans && <PlaceholderImage type="FLOOR_PLAN" />}
      </div>
      <div className={styles.apartmentInfoTooltipRight}>
        <GridCardInfo apartment={apartment} />
      </div>
    </div>
  );
};

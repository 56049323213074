import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';

import { InputGroup } from 'components/atoms';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import { ReactiveCheckbox } from './ReactiveCheckbox';

import styles from '../../AllFilters/AllFilters.module.css';

const SecondColumnFilterRenderer = () => (
  <>
    <div className={styles.floorsItem}>
      <span className={styles.formLabel}>Этаж</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="floorMin"
          placeholder="Не ниже"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="floorMax"
          placeholder="Не выше"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.floorsCheckboxes}>
      <ReactiveCheckbox
        store={complexFilterStore.filters}
        filterKey="notFirstFloor"
        placeholder="Не первый"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={complexFilterStore.filters}
        filterKey="notLastFloor"
        placeholder="Не последний"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={complexFilterStore.filters}
        filterKey="isLastFloor"
        placeholder="Только последний"
        className={styles.floorsCheckbox}
      />

      <ReactiveCheckbox
        store={complexFilterStore.filters}
        filterKey="isPenthouse"
        placeholder="Пентхаус"
        className={styles.floorsCheckbox}
      />
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Статус продаж</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="salesStatus"
          dictKey="residential_complex_sales_status"
          placeholder="Любой"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Тип договора</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="contractType"
          dictKey="residential_complex_contract_type"
        />
      </div>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Способ оплаты</span>
      <ReactiveSelect
        store={complexFilterStore.filters}
        filterKey="paymentType"
        dictKey="residential_complex_payment_type"
      />
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Год постройки</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="builtDateMin"
          placeholder="От"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="builtDateMax"
          placeholder="До"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Класс дома</span>
      <ReactiveSelect
        store={complexFilterStore.filters}
        filterKey="complexClass"
        placeholder="Любой"
        dictKey="residential_complex_class"
      />
    </div>
  </>
);

export const SecondColumnFilter = memo(SecondColumnFilterRenderer);

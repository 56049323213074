import React from 'react';
import { Offers } from 'services';

import { OffersCard } from '../OffersCard';
import styles from './styles.module.css';

type Props = {
  onScrollIncrement: (ev: React.SyntheticEvent<HTMLDivElement>) => void;
  offers: Offers[] | undefined;
};

export const OffersList = ({ offers, onScrollIncrement }: Props) => (
  <div onScroll={onScrollIncrement} className={styles.offersList}>
    {Array.isArray(offers) &&
      offers.map((offer) => <OffersCard {...offer} key={offer.id} />)}
  </div>
);

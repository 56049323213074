import React, { ReactElement } from 'react';
import { Polygon as PolygonComponent, useMap } from 'react-leaflet';
import { LeafletMouseEvent, Map, Polygon } from 'leaflet';
import { PolygonCoordinates } from 'types/PolygonCoordinates';

type Props = {
  apartmentPosition: PolygonCoordinates[][] | undefined;
  apartmentColors?: string[];
  activeApartmentIndex?: number;

  onPolygonClick?: (index: number) => (event: LeafletMouseEvent) => void;
  onMouseOut?: (index: number) => void;
  onMouseOver?: (index: number) => void;

  polygonChildrenRender?: (
    latLng: PolygonCoordinates[],
    index: number,
  ) => ReactElement;
};

const polygonPanToCenter = (
  map: Map,
  activeApartmentIndex: number | undefined,
  index: number,
) => {
  let timeId: NodeJS.Timeout | undefined;

  const _clearTimeout = () => timeId && clearTimeout(timeId);

  return (ref: Polygon) => {
    _clearTimeout();
    if (!ref) {
      return;
    }
    if (activeApartmentIndex === index) {
      /** @desc таймер необходимо для того чтобы полигон успел примонтироватся в карту и получить возможность
       * вычислять его положение относительно центра карты
       * */
      timeId = setTimeout(() => {
        const center = ref?.getCenter();
        map.panTo(center);
      }, 500);
    }
  };
};

export const ApartmentsOnFloorPlan = ({
  apartmentPosition,
  apartmentColors,
  activeApartmentIndex,
  onPolygonClick,
  onMouseOut,
  onMouseOver,
  polygonChildrenRender,
}: Props) => {
  const map = useMap();

  const getApartmentColor = (index: number) => {
    if (activeApartmentIndex === index || !apartmentColors) {
      return '#4E6AFF';
    }
    return apartmentColors[index];
  };

  const _onMouseOver = (index: number) => (event: LeafletMouseEvent) => {
    event.target.setStyle({
      opacity: 1,
    });
    if (onMouseOut) {
      onMouseOut(index);
    }
  };

  const _onMouseOut = (index: number) => (event: LeafletMouseEvent) => {
    event.target.setStyle({
      opacity: 0.5,
    });
    if (onMouseOver) {
      onMouseOver(index);
    }
  };

  return (
    <>
      {apartmentPosition?.map((latLng, index) => (
        <PolygonComponent
          ref={polygonPanToCenter(map, activeApartmentIndex, index)}
          positions={latLng}
          key={index.toString()}
          pathOptions={{
            color: getApartmentColor(index),
            opacity: 1,
          }}
          eventHandlers={{
            click: onPolygonClick && onPolygonClick(index),
            mouseover: _onMouseOver(index),
            mouseout: _onMouseOut(index),
          }}
        >
          {polygonChildrenRender && polygonChildrenRender(latLng, index)}
        </PolygonComponent>
      ))}
    </>
  );
};

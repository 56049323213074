import React, { ComponentProps } from 'react';
import { useModal } from 'react-modal-hook';
import { FullscreenPopup } from 'components/atoms/Popup/FullscreenPopup';
import { RenderBuildingToMap } from '../../pages/ApartmentView/components/RenderBuildingToMap';
import styles from './styles.module.css';

type Props = ComponentProps<typeof RenderBuildingToMap>;

/**
 * @link https://www.figma.com/file/VH9HbBNBGglqqZsQBBtNkU/%D0%A8%D0%B0%D1%85%D0%BC%D0%B0%D1%82%D0%BA%D0%B0?node-id=1%3A720
 * @desc план ЖК на карте в полноэкранном модальном окне
 * */
export const BuildingOnMapPopup = ({ windows, polygon }: Props) => (
  <RenderBuildingToMap
    className={styles.mapWrapper}
    polygon={polygon}
    windows={windows}
  />
);

export const useBuildingOnMapPopup = (props: Props) => {
  const [showBuildingOnMapPopup, hideBuildingOnMapPopup] = useModal(
    () => (
      <FullscreenPopup isOpen onClose={hideBuildingOnMapPopup}>
        <BuildingOnMapPopup {...props} />
      </FullscreenPopup>
    ),
    [props],
  );

  return {
    showBuildingOnMapPopup,
    hideBuildingOnMapPopup,
  };
};

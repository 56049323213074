import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  icon?: ReactNode;
  badge?: ReactNode;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  /**
   * @desc для кастомного компонента, например Link для роутинга
   * */
  component?: any;
  [k: string]: any;
};

export const ListItem = ({
  children,
  component,
  icon,
  badge,
  className,
  onClick,
  ...rest
}: Props) =>
  React.createElement(
    component || 'button',
    {
      ...(component
        ? {}
        : {
            type: 'button',
          }),
      onClick,
      className: cc([styles.listItem, className]),
      ...rest,
    },
    <>
      {icon && <span className={styles.listItemIcon}>{icon}</span>}

      <div className={styles.listItemContent}>{children}</div>

      {badge && <span className={styles.listItemBadge}>{badge}</span>}
    </>,
  );

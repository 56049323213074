import React, { useState, useCallback } from 'react';
import { get } from 'lodash';
import tableFilter from 'assets/tableFilter.svg';
import { flow } from 'lodash/fp';

import { FilterProps } from 'react-table';
import { Popover } from 'react-tiny-popover';
import { observer } from 'mobx-react';

import { ApartmentDto, DictionaryItem, DictionaryKeys } from 'services';
import { PopoverContent, Checkbox, PopoverItem, Icon } from 'components/atoms';
import { buildingFilters, infoStore } from 'stores';
import { stopPropagation } from 'utils/fp/stopPropagation';
import styles from './styles.module.css';

type Props = FilterProps<ApartmentDto> & {
  filterKey: string;
  dictionaryKey: DictionaryKeys;
};

export const ThFilterRender = ({ filterKey, dictionaryKey }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const togglePopoverOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const dictionary = infoStore.getInfoBy(dictionaryKey);

  const filterItem = get(
    buildingFilters,
    ['filters', filterKey],
    [],
  ) as number[];
  const onChangeFilter = useCallback(
    (filterItem: number[]) => (item: DictionaryItem) => () => {
      if (filterItem && filterItem.includes(item.id)) {
        buildingFilters.filters.setField<any>(
          filterKey,
          (filterItem || []).filter((filterItem) => filterItem !== item.id),
        );
      } else {
        buildingFilters.filters.setField<any>(filterKey, [
          ...(filterItem || []),
          item.id,
        ]);
      }
    },
    [],
  );

  const popoverContent = (
    <PopoverContent>
      {dictionary?.items.map((item, index) => (
        <PopoverItem key={index.toString()}>
          <Checkbox
            onChange={onChangeFilter(filterItem)(item)}
            checked={(filterItem || [])?.includes(item.id)}
            name={filterKey}
            label={item.value}
          />
        </PopoverItem>
      ))}
    </PopoverContent>
  );

  return (
    <Popover
      content={popoverContent}
      positions={['bottom']}
      isOpen={isOpen}
      onClickOutside={togglePopoverOpen}
    >
      <button
        onClick={flow(stopPropagation, togglePopoverOpen)}
        type="button"
        className={styles.button}
      >
        <Icon size={16} src={tableFilter} />
      </button>
    </Popover>
  );
};

export const ThFilter = observer(ThFilterRender);

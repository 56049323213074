import { SyncStorage } from 'stores/SyncTrunk/SyncTrunk';
import { favoritesIdsStorage } from 'services';

export const favoritesIdsStorageAdapter: SyncStorage = {
  getItem(key: string): string | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const favoritesIds = favoritesIdsStorage.get();
    if (favoritesIds) {
      return JSON.stringify(favoritesIds);
    }
    return null;
  },
  removeItem(key: string): void {
    favoritesIdsStorage.remove();
  },
  setItem(_, json: string): void {
    favoritesIdsStorage.set(JSON.parse(json));
  },
};

import { useEffect } from 'react';
import { offerApartmentStore } from 'stores';

type Props = {
  offerHash: string | undefined;
  apartmentId?: string;
};

/**
 * @desc загрузка квартиры из подборки
 * */
export const useLoadApartmentFromOffer = ({
  offerHash,
  apartmentId,
}: Props) => {
  useEffect(() => {
    if (!offerHash) {
      return;
    }
    void offerApartmentStore.loadApartmentData(
      { apartmentId: Number(apartmentId) },
      { offerHash },
    );
  }, [offerHash, apartmentId]);
};

import React from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'components/atoms/Icon';
import favouriteFilled from '../../../assets/favouriteFilled.svg';
import favouriteOutline from '../../../assets/favouriteOutline.svg';
import { ButtonBase } from '../../../pages/LayoutPage/components/ButtonBase';
import { rootProfileStore } from '../../../stores/user/RootProfileStore';

type Props = {
  showLabels?: boolean;
  complexID?: number;
  apartmentId?: number;
  className?: string;
};

export const FavoritesAddButtonRender = ({
  showLabels,
  complexID,
  apartmentId,
  className,
}: Props) => {
  const { includeComplex, includeApartment } =
    rootProfileStore.favoritesIdsStore;

  const handleClick = () => {
    if (complexID) {
      void rootProfileStore.favoritesComposeAuth.toggleComplex(complexID);
    }

    if (apartmentId) {
      void rootProfileStore.favoritesComposeAuth.toggleApartment(apartmentId);
    }
  };

  const isFavorite =
    (complexID && includeComplex(complexID)) ||
    (apartmentId && includeApartment(apartmentId));

  return (
    <ButtonBase
      icon={<Icon src={isFavorite ? favouriteFilled : favouriteOutline} />}
      onClick={handleClick}
      textClassName={className}
    >
      {showLabels && <span>Добавить в избранное</span>}
    </ButtonBase>
  );
};

export const FavoritesAddButton = observer(FavoritesAddButtonRender);

import React from 'react';
import { observer } from 'mobx-react';
import { offersStore } from 'stores';

import { OffersSearch } from '../OffersSearch';
import { OffersStatusTabs } from '../OffersStatusTabs';
import styles from './styles.module.css';

export const OffersHeaderRender = () => (
  <div className={styles.offersHeaderWrapper}>
    <div className={styles.offersHeaderTop}>
      <OffersStatusTabs />
    </div>
    <OffersSearch
      onChange={offersStore.offersFilterStore.setSearch}
      value={offersStore.offersFilterStore.searchParams}
    />
  </div>
);

export const OffersHeader = observer(OffersHeaderRender);

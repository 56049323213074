import { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { appRoutesService, authEmailService, Params } from 'services';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { changeEmailStore } from '../../../ProfilePage/stores/ChangeEmailStore';
import { authRoutesStore } from '../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes';
// @todo: добавить вывод ошибки для пользователя
/**
 * @desc страница для проверки кода из письма после авторизации
 * */
export const AuthEmailValidatePageRender = () => {
  const { push } = useHistory();
  const { code } = useParams<Pick<Params, 'code'>>();

  const validateEmail = useCallback(
    async (code: string) => {
      try {
        const result = await authEmailService.validate({
          code,
        });

        rootProfileStore.authStore.setAuthData(
          result.user,
          result.hash,
          result.expiresAt,
        );

        // TODO: подумать как отсюда убрать логику для подтверждения новой почты
        // <editor-fold desc="Выполнение запроса на установку новой почты">
        if (
          changeEmailStore.state.isChange &&
          changeEmailStore.state.newEmail
        ) {
          const [, response] = await authEmailService.change({
            email: changeEmailStore.state.newEmail,
          });
          if (response) {
            changeEmailStore.setOldEmailConfirm(true);
            return;
          }
          const redirectURL = appRoutesService.getRoute(
            'profileConfirmNewEmail',
          );
          push(redirectURL);
          return;
        }
        // </editor-fold>
        const redirectURL = authRoutesStore.successAuthRoute;
        push(redirectURL);
      } catch (e) {
        if (changeEmailStore.state.isChange) {
          push(appRoutesService.getRoute('profileLinkIsOutOfDatePage'));
        } else {
          const redirectURL = authRoutesStore.getRouteWithParent(
            'linkIsOutOfDatePage',
          );
          push(redirectURL);
        }
      }
    },
    [code],
  );

  useEffect(() => {
    if (code) {
      void validateEmail(code);
    } else {
      const redirectURL = authRoutesStore.parentRoute;
      push(redirectURL);
    }
  }, []);

  return null;
};

export const AuthEmailValidatePage = observer(AuthEmailValidatePageRender);

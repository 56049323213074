import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export const PopoverContent = ({ children, className }: Props) => (
  <div className={cc([styles.overlay, className])}>{children}</div>
);

import React, { useState } from 'react';

type Props = {
  wrapperClassName?: string;
  mainHoc: (
    visible: boolean,
    toggleVisibility: (flag: boolean) => void,
  ) => React.ReactNode;
  children: React.ReactNode;
};

export const Accordion = (props: Props) => {
  const { children, mainHoc, wrapperClassName } = props;
  const [visible, setVisible] = useState(true);

  return (
    <div className={wrapperClassName}>
      {mainHoc(visible, setVisible)}
      {visible ? children : <></>}
    </div>
  );
};

import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

export const TBody = ({ children, className }: Props) => (
  <tbody className={className}>{children}</tbody>
);

import { action, makeObservable, observable } from 'mobx';
import { FeedbackService, FeedbackEntity, feedbackService } from 'services';
import { RequestApiStore } from '../request/RequestApiStore';

export class FeedbackStore {
  requestStatus: RequestApiStore;

  feedbackService: FeedbackService;

  initialValues: Partial<FeedbackEntity> = {};

  constructor(feedbackService: FeedbackService) {
    this.feedbackService = feedbackService;
    this.requestStatus = new RequestApiStore();

    makeObservable(this, {
      setInitialValues: action,
      initialValues: observable,
    });
  }

  clear = (): void => {
    this.requestStatus.clear();
    this.initialValues = {};
  };

  setInitialValues = (initialValues: Partial<FeedbackEntity>): void => {
    this.initialValues = initialValues;
  };

  sendFeedbackData = async (data: FeedbackEntity): Promise<void> => {
    this.requestStatus.setLoading(true);
    this.requestStatus.setError(false);
    this.requestStatus.setSubmitting(false);

    try {
      void (await this.feedbackService.feedback(data));
    } catch (e) {
      this.requestStatus.setError(true);
    }

    this.requestStatus.setSubmitting(true);
    this.requestStatus.setLoading(false);
  };
}

export const feedbackStore = new FeedbackStore(feedbackService);

import React from 'react';
import { FormikProps } from 'formik/dist/types';
import { Field } from 'formik';
import { has } from 'lodash';
import { OTHER, USER_ROLES } from 'services/constants';

import { FormikTextField } from 'components/atoms/Form/FormikTextField';
import {
  FormikAsyncSelect,
  FormikSelect,
} from 'components/atoms/Form/FormikSelect';
import { Loader } from 'components/atoms/Loader';
import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import { RegistrationFormValues } from '../../types';
import { findAgency, findDeveloper } from '../../utilities';
import styles from './styles.module.css';

type Props = FormikProps<RegistrationFormValues>;

export const FormRender = ({ values, handleSubmit, isSubmitting }: Props) => {
  let otherDeveloperIsDisabled = true;

  if (values.role?.value === 'realtor') {
    otherDeveloperIsDisabled = values?.agencyId?.value !== OTHER;
  }

  if (values.role?.value === 'developer') {
    otherDeveloperIsDisabled = values?.developerId?.value !== OTHER;
  }

  const showDeveloperCustom =
    has(values, 'role.value') && values.role?.value !== 'user';

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.title}>Укажите информацию о себе</div>
      <div className={styles.fieldGroups}>
        <div className={styles.fieldNameGroups}>
          <Field label="Имя" name="name" component={FormikTextField} />
          <Field label="Фамилия" name="surname" component={FormikTextField} />
        </div>
        <Field
          label="Электронная почта"
          name="email"
          placeholder="example@mail.com"
          component={FormikTextField}
        />
        <Field
          label="Роль"
          name="role"
          placeholder=""
          options={USER_ROLES}
          component={FormikSelect}
        />

        {values.role?.value === 'realtor' && (
          <>
            <Field
              label="Укажите агенство"
              name="agencyId"
              defaultOptions={[
                {
                  label: 'Другое',
                  value: OTHER,
                },
              ]}
              placeholder=""
              loadOptions={findAgency}
              component={FormikAsyncSelect}
            />

            {showDeveloperCustom && (
              <Field
                disabled={otherDeveloperIsDisabled}
                label="Укажите название"
                name="developerCustom"
                placeholder=""
                component={FormikTextField}
              />
            )}
          </>
        )}

        {values.role?.value === 'developer' && (
          <>
            <Field
              label="Укажите застройщика"
              name="developerId"
              defaultOptions={[
                {
                  label: 'Другое',
                  value: OTHER,
                },
              ]}
              placeholder=""
              loadOptions={findDeveloper}
              component={FormikAsyncSelect}
            />

            {showDeveloperCustom && (
              <Field
                disabled={otherDeveloperIsDisabled}
                label="Укажите название"
                name="developerCustom"
                placeholder=""
                component={FormikTextField}
              />
            )}
          </>
        )}
      </div>
      <ButtonBase
        type="submit"
        icon={isSubmitting && <Loader color="primary" />}
        fullWidth
        color="primary"
      >
        Зарегистрироваться
      </ButtonBase>
    </form>
  );
};

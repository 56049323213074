import React, { MouseEventHandler } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { PriceSelector } from './PriceSelector';

type Props = {
  closePriceVisibility: MouseEventHandler;
};

export const PriceFilter = ({ closePriceVisibility }: Props) => (
  <>
    <div style={{ display: 'flex' }}>
      <PriceSelector
        label="От"
        filterKey="priceMin"
        values={['0', '1 000 000', '2 000 000', '3 000 000', '5 000 000']}
        value={complexFilterStore.filters.getField('priceMin')}
        onChange={(value) =>
          complexFilterStore.filters.setField('priceMin', +value)
        }
      />
      <div
        style={{
          paddingTop: '30px',
          width: '40px',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        —
      </div>
      <PriceSelector
        label="До"
        values={[
          '5 000 000',
          '8 000 000',
          '9 000 000',
          '10 000 000',
          '15 000 000',
        ]}
        filterKey="priceMax"
        value={complexFilterStore.filters.getField('priceMax')}
        onChange={(value) =>
          complexFilterStore.filters.setField('priceMax', +value)
        }
      />
    </div>
    <div style={{ paddingTop: '20px' }}>
      <ButtonBase fullWidth color="primary" onClick={closePriceVisibility}>
        Показать
      </ButtonBase>
    </div>
  </>
);

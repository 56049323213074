import React from 'react';
import { flow, get } from 'lodash/fp';
import styles from './styles.module.css';
import {
  DictionaryView,
  NumberFormat,
} from '../../../../../../components/atoms';
import { ApartmentDto, DictionaryKeys } from '../../../../../../services';

type Props = {
  apartment?: ApartmentDto;
};
export const ApartmentSquareInfo = ({ apartment }: Props) => {
  const residential_complex_contract_type = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('residential_complex_contract_type'),
  )(apartment);

  return (
    <div className={styles.squareListWrapper}>
      <div>
        <div className={styles.squareItemLabel}>Общая S</div>
        <div className={styles.squareItemContent}>
          <NumberFormat value={apartment?.square} format="meter2" />
        </div>
      </div>
      <div>
        <div className={styles.squareItemLabel}>S санузла</div>
        <div className={styles.squareItemContent}>
          <NumberFormat value={apartment?.square_bathroom} format="meter2" />
        </div>
      </div>
      <div>
        <div className={styles.squareItemLabel}>Жилая S</div>
        <div className={styles.squareItemContent}>
          <NumberFormat value={apartment?.square_living} format="meter2" />
        </div>
      </div>
      <div>
        <div className={styles.squareItemLabel}>S кухни</div>
        <div className={styles.squareItemContent}>
          <NumberFormat value={apartment?.square_kitchen} format="meter2" />
        </div>
      </div>
      {residential_complex_contract_type && (
        <div>
          <div className={styles.squareItemLabel}>Тип договора</div>
          <div className={styles.squareItemContent}>
            <DictionaryView
              dictKey="residential_complex_contract_type"
              dictItemId={residential_complex_contract_type}
            />
          </div>
        </div>
      )}
    </div>
  );
};

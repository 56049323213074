import { observable, action, makeObservable } from 'mobx';
import { ID, MayBe } from 'types';
import { isNotNil } from 'utils/fp';

export class BindComplexToPin {
  targetId: MayBe<ID> = null;

  constructor() {
    makeObservable(this, {
      targetId: observable,
      setTargetId: action,
    });
  }

  setTargetId = (id: MayBe<ID>) => {
    this.targetId = id;
  };

  isHover = (id: MayBe<ID>) => isNotNil(id) && id === this.targetId;
}

export const bindComplexToPin = new BindComplexToPin();

import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik/dist/types';
import { omit } from 'lodash';
import { AuthPhoneRegistrationRequestBody, authPhoneService } from 'services';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { injectReCaptchaTokenToFormValues } from 'utils/injectReCaptchaTokenToFormValues';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { RegistrationFormValues } from '../../types';
import { getUserJobForRequest } from '../../../../../ProfilePage/pages/ProfileEditorPage/utils';
import { authRoutesStore } from '../../../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes';

type Props = {
  authStore: AuthStore;
};

export const useFormSubmit = ({ authStore }: Props) => {
  const { push } = useHistory();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = useCallback(
    async (
      values: RegistrationFormValues,
      formikHelpers: FormikHelpers<any>,
    ) => {
      try {
        const data = {
          ...omit(values, [
            'developerId',
            'agencyId',
            'developerCustom',
            'role',
          ]),
          role: values.role?.value,
          ...getUserJobForRequest(
            values.role,
            values.developerId,
            values.agencyId,
            values.developerCustom,
          ),
        };

        const result = await authPhoneService.registration(
          data as AuthPhoneRegistrationRequestBody,
        );

        authStore.setAuthData(result.user, result.hash, result.expiresAt);
        const redirectURL = authRoutesStore.getRouteWithParent(
          'authPhoneRegistrationSuccessPage',
          {
            email: values.email,
          },
        );
        push(redirectURL);
      } catch (e) {
        submissionErrorsHandler(e, formikHelpers);
      }
    },
    [],
  );

  return {
    onSubmit:
      injectReCaptchaTokenToFormValues<RegistrationFormValues>(
        executeRecaptcha,
      )(onSubmit),
  };
};

import React from 'react';
import { appRoutesService, ComplexAlbum } from 'services';
import { LocationDescriptorObject } from 'history';
import { AlbumItem } from '../AlbumItem';
import styles from './styles.module.css';

type Props = {
  albums: ComplexAlbum[];
  complexId: string;
  to?: LocationDescriptorObject<any>;
};

export const AlbumList = ({ albums, complexId, to }: Props) => (
  <ul className={styles.albumsList}>
    {albums.map((album) => (
      <AlbumItem
        to={{
          pathname: appRoutesService.replaceRoute('albums', {
            complexId,
            albumId: album.id.toString(),
          }),
          ...to,
        }}
        key={album.id}
        {...album}
      />
    ))}
  </ul>
);

import { action, computed, makeObservable, observable } from 'mobx';
import { RequestPageInfo, SortDirection, SortByType } from 'services/types';

export class RequestPageInfoStore {
  initialState: RequestPageInfo;

  pageInfo: RequestPageInfo = {
    page: 1,
    limit: 30,
  };

  constructor(pageInfo?: RequestPageInfo) {
    this.initialState = pageInfo || {
      page: 1,
      limit: 30,
    };
    this.resetPageInfo();

    makeObservable(this, {
      changePage: action.bound,
      changeSortDirection: action.bound,
      changeSort: action.bound,
      resetPageInfo: action.bound,
      sort: computed,

      pageInfo: observable.deep,
    });
  }

  get sort() {
    if (this.pageInfo.sort && this.pageInfo.sortDirection) {
      return {
        sort: this.pageInfo.sort,
        sortDirection: this.pageInfo.sortDirection,
      };
    }
    return {};
  }

  get page() {
    return {
      page: this.pageInfo.page,
      limit: this.pageInfo.limit,
    };
  }

  resetPageInfo = () => {
    this.pageInfo = this.initialState;
  };

  changePage = (page: number) => {
    this.pageInfo.page = page;
  };

  changeSortDirection = (dir?: SortDirection) => {
    this.pageInfo.sortDirection = dir;
  };

  changeSort = (sort?: SortByType) => {
    this.pageInfo.sort = sort;
  };
}

import React from 'react';
import { flow, get } from 'lodash/fp';
import { Input } from 'components/atoms/Form/Input';
import { Icon } from 'components/atoms';
import search from 'assets/search.svg';

type Props = {
  value?: string;
  onChange: (value?: string) => void;
};

export const OffersSearch = ({ value, onChange }: Props) => (
  <Input
    customIcon={<Icon src={search} />}
    placeholder="Поиск"
    value={value}
    onChange={flow(get(['target', 'value']), onChange)}
  />
);

import React from 'react';
import { Formik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import { authEmailCodeSchema } from 'validationSchemas';
import { FormRender } from './components/FormRender';
import { withGoogleReCaptchaProvider } from '../../../../contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { injectReCaptchaTokenToFormValues } from '../../../../utils/injectReCaptchaTokenToFormValues';
import { AuthEmailCodeRequestBody } from '../../../../services';
import { useFormSubmit } from './hooks/useFormSubmit';

export const AuthEmailCodePageRender = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { onSubmit } = useFormSubmit();

  return (
    <AuthPopup mobileHeaderChildrenTitle="Вход через почту">
      <Formik<AuthEmailCodeRequestBody>
        validationSchema={authEmailCodeSchema}
        onSubmit={injectReCaptchaTokenToFormValues<AuthEmailCodeRequestBody>(
          executeRecaptcha,
        )(onSubmit)}
        initialValues={{}}
        render={FormRender}
      />
    </AuthPopup>
  );
};

export const AuthEmailCodePage = withGoogleReCaptchaProvider(
  AuthEmailCodePageRender,
);

import React, { ReactElement } from 'react';
import styles from './styles.module.css';

type Props = {
  children: ReactElement[];
};

/** @deprecated */
export const ButtonGroup = ({ children }: Props) => (
  <div className={styles.group}>{children}</div>
);

import { Formik } from 'formik';
import { AuthPopup } from 'pages/AuthPage/components/AuthPopup';
import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';
import { AuthFormWrapper } from 'pages/AuthPage/components/AuthFormWrapper';
import { profileNewPhoneSchema } from 'validationSchemas';
import { changePhoneStore } from 'pages/ProfilePage/stores/ChangePhoneStore';
import { withGoogleReCaptchaProvider } from '../../../../contexts/GoogleReCaptcha/withGoogleReCaptchaProvider';
import { injectReCaptchaTokenToFormValues } from '../../../../utils/injectReCaptchaTokenToFormValues';
import { appRoutesService } from '../../../../services';
import { FormRender } from './components/FormRender';
import { NewPhoneFormValues } from './types';
import { maskedInputValueToNumber } from '../../../../utils/maskedInputValueToNumber';
import styles from './styles.module.css';

export const ProfileNewPhonePopupRender = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { push } = useHistory();

  const onSubmit = (values: NewPhoneFormValues) => {
    changePhoneStore.setNewPhone(maskedInputValueToNumber(values?.newPhone));
    push(appRoutesService.replaceRoute('profileConfirmOldPhone'));
  };

  return (
    <AuthPopup
      onClose={() => {
        push(appRoutesService.getRoute('profile'));
      }}
      mobileHeaderChildrenTitle="Изменение номера"
    >
      <Formik<NewPhoneFormValues>
        initialValues={{}}
        validationSchema={profileNewPhoneSchema}
        onSubmit={injectReCaptchaTokenToFormValues<NewPhoneFormValues>(
          executeRecaptcha,
        )(onSubmit)}
        render={(props) => (
          <AuthFormWrapper
            className={styles.form}
            showButtonBack
            onClickButtonBack={() => {
              push(appRoutesService.getRoute('profile'));
            }}
          >
            <FormRender {...props} />
          </AuthFormWrapper>
        )}
      />
    </AuthPopup>
  );
};

export const ProfileNewPhonePopup = withGoogleReCaptchaProvider(
  ProfileNewPhonePopupRender,
);

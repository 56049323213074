import { SchemaLike } from 'yup/lib/types';
import { Yup } from './yup';
import { Option } from '../components/atoms/Form/FormikSelect/types';
import { OTHER } from '../services/constants';

export const userProfileFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  surname: Yup.string().required(),
  developer: Yup.object()
    .default(null)
    .nullable()
    .when('role', (other: Option, schema: any): SchemaLike => {
      if (other && other?.value === 'developer') {
        return schema.required() as SchemaLike;
      }

      return schema as SchemaLike;
    }),
  agency: Yup.object()
    .default(null)
    .nullable()
    .when('role', (other: Option, schema: any): SchemaLike => {
      if (other && other?.value === 'realtor') {
        return schema.required() as SchemaLike;
      }

      return schema as SchemaLike;
    }),
  developerCustom: Yup.string()
    .default(null)
    .nullable()
    .when(
      ['developer', 'agency'],
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (developer: Option, agency: Option, schema: any): SchemaLike => {
        /**
         * @desc: если в поле developer выбрано "Другое",
         * то необходимо ввести вручную название в поле developerCustom
         * */
        if (agency && agency?.value === OTHER) {
          return schema.required() as SchemaLike;
        }
        if (developer && developer?.value === OTHER) {
          return schema.required() as SchemaLike;
        }
        return schema as SchemaLike;
      },
    ),
});

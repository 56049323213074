import { useEffect } from 'react';
import { reaction } from 'mobx';
import { ComplexItemStore } from 'stores/ComplexItemStore/store';
import { buildingFilters } from 'stores';
import { BuildingSearchParameters, getFirstBuildingId } from 'services';
import { LayoutTabs } from '../../types';
import { BuildingGridStore } from '../../stores/buildingGridStore';

type Props = {
  complexItemStore?: ComplexItemStore;
  buildingGridStore?: BuildingGridStore;
  complexId?: string;
  layoutTabs?: string;
  buildingId?: string;
};

export const useGridApartmentRequest = ({
  buildingGridStore,
  complexItemStore,
  complexId,
  layoutTabs,
  buildingId,
}: Props) => {
  const initialRequest = (filter: Partial<BuildingSearchParameters>) => {
    if (
      complexId &&
      layoutTabs &&
      (layoutTabs as LayoutTabs) !== 'apartments'
    ) {
      const initBuildingId: any =
        buildingId || getFirstBuildingId(complexItemStore?.target?.buildings);
      if (initBuildingId) {
        void buildingGridStore?.loadingBuilding(initBuildingId, filter);
      }
    }
  };

  useEffect(() => {
    const reactionDisposer = reaction(
      (): Partial<BuildingSearchParameters> =>
        buildingFilters.filters.filterToApiFormat,
      (compileFilters) => {
        initialRequest(compileFilters);
      },
    );

    return () => {
      reactionDisposer();
    };
  }, [complexId, layoutTabs, buildingId, buildingFilters, buildingGridStore]);

  useEffect(() => {
    void initialRequest(buildingFilters.filters.filterToApiFormat);
  }, [complexId, layoutTabs, buildingId, buildingFilters]);
};

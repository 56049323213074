import React, { useMemo, useState, memo, useCallback } from 'react';
import { observer } from 'mobx-react';
import {
  flow,
  get,
  head,
  map,
  filter,
  isEqual,
  flatten,
  uniq,
  includes,
} from 'lodash/fp';
import L, { LatLngBounds } from 'leaflet';
import { MayBe } from 'types';
import {
  ApartmentDto,
  findDictItemColor,
  getAllApartmentPolygons,
  getAllApartmentsLayoutsIds,
  getAllApartmentSquares,
  getAllFloorPlans,
  getApartmentSquares,
  Params,
  Section,
  Squares,
} from 'services';
import { useParams } from 'react-router-dom';
import {
  BaseMapContainer,
  FitBoundsOfPolygons,
  FloorPlanOverlay,
  Icon,
} from 'components/atoms';
import { PolygonCoordinates } from 'types/PolygonCoordinates';
import { isNotNil } from 'utils/fp';
import { infoStore } from 'stores';
import { Tooltip } from 'react-leaflet';
import eyeDark from 'assets/eyeDark.svg';
import eye from 'assets/eye.svg';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { stopPropagation } from 'utils/fp/stopPropagation';
import { Box } from 'components/styledComponents/Box';
import { floorPlanStore } from '../../../../stores/FloorPlanStore';
import styles from './styles.module.css';
import { ApartmentsOnFloorPlan } from '../ApartmentsOnFloorPlan';
import { useOpenAnApartmentByClickOnAPolygon } from '../../hooks/useOpenAnApartmentByClickOnAPolygon';
import { ApartmentInfoTooltip } from '../../components/ApartmentInfoTooltip';
import { buildingGridStore } from '../../../../stores/buildingGridStore';
import { SquareMetersOnFloorPlan } from '../SquareMetersOnFloorPlan';

export const FloorPlansMapRender = () => {
  const [baseBoundsOfPlan, setBaseBoundsOfPlan] = useState<
    LatLngBounds | undefined
  >(undefined);

  const [showMeters, toggleShowMeters] = useState<boolean>(true);

  const { complexId, buildingId, layoutTabs } =
    useParams<Pick<Params, 'complexId' | 'buildingId' | 'layoutTabs'>>();

  const sections = buildingGridStore?.getSections || [];
  const apartmentsLayouts = buildingGridStore?.getApartmentsLayouts || [];

  const {
    activeFloorApartment,
    activeApartmentIndex,
    activeApartment,
    activeFloorNumber,
    activeSection,
  } = floorPlanStore;

  const isThereAnApartmentOnTheActiveFloor = useMemo(() => {
    if ([activeFloorNumber, activeApartment?.floor].every(isNotNil)) {
      return isEqual(activeFloorNumber?.toString())(
        activeApartment?.floor?.toString(),
      );
    }
    return false;
  }, [activeFloorNumber, activeApartment?.floor]);

  const _activeApartmentIndex = useMemo(
    () => (isThereAnApartmentOnTheActiveFloor ? activeApartmentIndex : -1),
    [activeApartmentIndex, isThereAnApartmentOnTheActiveFloor],
  );

  const section: MayBe<Section> | undefined = useMemo(
    () => get(activeSection)(sections),
    [activeSection, sections],
  );

  const apartments = useMemo(
    () =>
      filter<ApartmentDto>((item) =>
        isEqual(get('title')(section))(get('section_title')(item)),
      )(activeFloorApartment),
    [activeFloorApartment, section],
  );

  const floorPlans: MayBe<string> | undefined = useMemo(
    () => flow(head, getAllFloorPlans, head)(apartments),
    [apartments],
  );

  const apartmentPolygons: PolygonCoordinates[][] | undefined = useMemo(
    () =>
      flow<any, (PolygonCoordinates[] | undefined)[][], PolygonCoordinates[][]>(
        map(getAllApartmentPolygons),
        filter(isNotNil),
      )(apartments),
    [apartments, section],
  );

  const apartmentsLayoutsIds: number[] = useMemo(
    () => flow(map(getAllApartmentsLayoutsIds), flatten, uniq)(apartments),
    [apartments, section],
  );

  const apartmentSquares = useMemo(
    () => getApartmentSquares(apartmentsLayouts, apartmentsLayoutsIds),
    [apartmentsLayouts, apartmentsLayoutsIds],
  );

  /**
   * @desc открытие квартиры по клику на планировку,
   * меняет URL И делает запись в floorPlanStore
   * */
  const { onPolygonClick } = useOpenAnApartmentByClickOnAPolygon({
    complexId,
    buildingId,
    layoutTabs,
  });

  const apartmentColors = useMemo(
    () =>
      flow(
        map(get(['catalogs', 'apartment_status'])),
        map((ids: number[]) =>
          findDictItemColor('apartment_status', ids)(infoStore.dictionaries),
        ),
        map(head),
      )(activeFloorApartment) as string[],
    [infoStore.dictionaries, activeFloorApartment],
  );

  const boundsOfPolygons = (() => {
    if (baseBoundsOfPlan) {
      return [baseBoundsOfPlan] as unknown as PolygonCoordinates[][];
    }
    return undefined;
  })();

  const handleToggleShowMeters = useCallback(
    () => toggleShowMeters((prev) => !prev),
    [],
  );

  return (
    <BaseMapContainer
      crs={L.CRS.Simple}
      minZoom={-2}
      maxBoundsViscosity={0}
      className={styles.floorPlansMap}
    >
      {floorPlans && (
        <FloorPlanOverlay
          onChangePlanImage={setBaseBoundsOfPlan}
          fitBoundsOfPlan={false}
          imageUrl={floorPlans}
        />
      )}

      <FitBoundsOfPolygons delay={200} polygons={boundsOfPolygons} />

      {apartmentPolygons && (
        <ApartmentsOnFloorPlan
          apartmentColors={apartmentColors}
          apartmentPosition={apartmentPolygons}
          activeApartmentIndex={_activeApartmentIndex}
          onPolygonClick={onPolygonClick}
          polygonChildrenRender={(_, index) => (
            <Tooltip direction="top" opacity={1}>
              <ApartmentInfoTooltip apartment={activeFloorApartment[index]} />
            </Tooltip>
          )}
        />
      )}

      {!!showMeters && !!apartmentSquares?.length && (
        <SquareMetersOnFloorPlan
          apartmentSquaresPosition={apartmentSquares as Squares[][]}
          apartmentColors={apartmentColors}
          activeApartmentIndex={_activeApartmentIndex}
        />
      )}
      {!!apartmentSquares?.length && (
        <Box position="absolute" zIndex={999} bottom="10px" left="10px">
          <ButtonBase
            color={showMeters ? 'default' : 'disable'}
            onClick={flow(stopPropagation, handleToggleShowMeters)}
            icon={<Icon size={16} src={showMeters ? eyeDark : eye} />}
          >
            Площадь
          </ButtonBase>
        </Box>
      )}
    </BaseMapContainer>
  );
};

export const FloorPlansMap = memo(observer(FloorPlansMapRender));

import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { numeralService } from 'services';
import 'simplebar/dist/simplebar.min.css';
import 'swiper/swiper-bundle.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import 'globalStyles/swipe.css';
import 'globalStyles/reset.css';
import 'globalStyles/variables.css';
import './index.css';
import { App } from 'App';
import reportWebVitals from './reportWebVitals';

numeralService();

ReactDOM.render(<App />, document.getElementById('root'));

// measuring performance in app
// eslint-disable-next-line no-console
reportWebVitals(console.log);

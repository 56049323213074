import { useEffect } from 'react';
import { offersStore } from 'stores/OffersStore/OffersStore';
import { reaction } from 'mobx';
import { throttle } from 'lodash';
import {
  createGetDataFromTabsItem,
  getCurrentTabItem,
  TabProps,
} from 'components/containers/Tabs';
import { DictionaryItem } from 'services';
import { useHistory } from 'react-router-dom';

type Props = Pick<TabProps<DictionaryItem>, 'currentActiveTab' | 'tabList'>;

const getDataFromTabsItem = createGetDataFromTabsItem<DictionaryItem>();

const requestOffersForFilters = () => {
  offersStore.pageInfo.resetPageInfo();
  offersStore.clearState();
  offersStore.fetch(
    offersStore.offersFilterStore.params,
    offersStore.pageInfo.pageInfo,
  );
};

const requestOffersForFiltersThrottle = throttle(requestOffersForFilters, 1000);

export const useOffersLoad = ({ currentActiveTab, tabList }: Props) => {
  const { location } = useHistory();

  const { offersFilterStore } = offersStore;

  /** @desc реакция пагинации */
  useEffect(
    () =>
      reaction(
        () => offersStore.pageInfo.pageInfo.page,
        (page) => {
          if (page === 1) {
            return;
          }
          offersStore.fetch(
            offersFilterStore.params,
            offersStore.pageInfo.pageInfo,
          );
        },
      ),
    [],
  );

  /** @desc реакция изменения статуса в фильтре */
  useEffect(
    () =>
      reaction(
        () => offersFilterStore.searchStatusIds,
        requestOffersForFilters,
      ),
    [],
  );

  /** @desc реакция для поиска */
  useEffect(
    () =>
      reaction(
        () => offersFilterStore.params.search,
        requestOffersForFiltersThrottle,
      ),
    [],
  );

  useEffect(() => {
    if (tabList.length) {
      const currentTab = getCurrentTabItem(tabList)(currentActiveTab);
      const statusInfo = getDataFromTabsItem(currentTab);
      if (statusInfo) {
        offersFilterStore.setStatusIds([statusInfo.id]);
      }
    }
  }, [tabList, location, currentActiveTab]);
};

import React, { ComponentProps } from 'react';
import { SocialButton } from 'components/atoms/SocialButton';
import {
  FacebookShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';
import { useMediaQuery } from 'beautiful-react-hooks';
import { CopyLinkButton } from 'components/containers/CopyLink/components/CopyLinkButton';
import { CopyLink } from 'components/containers/CopyLink';
import { InfoCard } from '../InfoCard';
import { getLinkToOfferForClient } from '../../utils';
import styles from './styles.module.css';

type Props = {
  hash: string;
} & Omit<ComponentProps<typeof InfoCard>, 'children'>;

export const InfoCardView = ({ apartmentCount, updated_at, hash }: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const shareLink = getLinkToOfferForClient(hash || '');

  return (
    <InfoCard updated_at={updated_at} apartmentCount={apartmentCount}>
      <div className={styles.infoCardViewButtons}>
        <CopyLink
          defaultLabel={isLarge ? 'Скопировать ссылку' : null}
          successLabel={isLarge ? 'Ссылка скопирована' : null}
          link={shareLink}
        >
          {(props) => <CopyLinkButton {...props} />}
        </CopyLink>
        <WhatsappShareButton url={shareLink}>
          <SocialButton type="whatsApp" />
        </WhatsappShareButton>
        <TelegramShareButton url={shareLink}>
          <SocialButton type="tg" />
        </TelegramShareButton>
        <VKShareButton url={shareLink}>
          <SocialButton type="vk" />
        </VKShareButton>
        <FacebookShareButton url={shareLink}>
          <SocialButton type="fb" />
        </FacebookShareButton>
      </div>
    </InfoCard>
  );
};

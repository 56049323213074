import React, { memo } from 'react';
import { get, head } from 'lodash';
import { BuildingDto, Section, getSectionWithMaxRows } from 'services';
import { useId } from 'react-id-generator';
import { GridSection } from '../GridSection';
import { MayBe } from '../../../../../../types';

type Props = Pick<BuildingDto, 'sections'>;

const getPrevCols =
  (sections?: MayBe<Section[]>) =>
  (index: number): number =>
    get(sections, [index - 1, 'matrix', 'cols'], 0) as number;

const getOffsetY = (rowsTitlesMaxRows?: string[]) => (title?: string) =>
  rowsTitlesMaxRows?.findIndex((r2) => r2 === title) || 0;

export const GridSectionListRender = ({ sections }: Props) => {
  const idList: string[] = useId(sections?.length, 'GridSection');

  const sectionWithMaxRows = getSectionWithMaxRows(sections || []);

  let offsetY = 0;
  let offsetX = 0;
  return (
    <>
      {sections?.map((item, index) => {
        offsetX += index > 0 ? getPrevCols(sections)(index) : 0;
        /** вычиляем офсет по Y по заголовкам строк */
        offsetY = getOffsetY(sectionWithMaxRows?.matrix?.rowsTitles)(
          head(item?.matrix?.rowsTitles),
        );
        return (
          <GridSection
            key={idList[index]}
            section={item}
            offsetY={offsetY}
            offsetX={offsetX}
          />
        );
      })}
    </>
  );
};

export const GridSectionList = memo(GridSectionListRender);

import React, { ComponentProps } from 'react';
import { Router, useHistory } from 'react-router-dom';
import { useGetResponsiveModalComponent } from 'hooks';
import { BasePopup } from 'components/atoms';
import { OffersEditorForm } from '../OffersEditorForm';

type Props = {
  offerId?: string;
  onClose: () => void;
};

export const OffersEditorFormPopup = ({ onClose, offerId }: Props) => {
  const history = useHistory();
  const { PopupWrapper } = useGetResponsiveModalComponent<
    ComponentProps<typeof BasePopup>
  >({
    mobileHeaderChildrenTitle: 'Создание подборки',
    DesktopModalWrapper: (props) => <BasePopup {...props} isOpen size="LG" />,
  });

  return (
    <PopupWrapper isOpen onClose={onClose}>
      <Router history={history}>
        <OffersEditorForm offerId={offerId} onClose={onClose} />
      </Router>
    </PopupWrapper>
  );
};

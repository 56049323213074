import React from 'react';
import { Input } from 'components/atoms/Form/Input';
import { CopyLinkButton } from 'components/containers/CopyLink/components/CopyLinkButton';
import { CopyLink } from 'components/containers/CopyLink';
import styles from './styles.module.css';

type Props = {
  shareLink: string;
};

export const CopyLinkForm = ({ shareLink }: Props) => (
  <div className={styles.copyLinkFormWrapper}>
    <Input
      value={shareLink}
      onChange={() => {
        //
      }}
    />
    <CopyLink
      defaultLabel="Скопировать ссылку"
      successLabel="Ссылка скопирована"
      link={shareLink}
    >
      {(props) => <CopyLinkButton {...props} />}
    </CopyLink>
  </div>
);

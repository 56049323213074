import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import React, { ReactNode } from 'react';
import { isNil } from 'lodash';
import ReactJoin from 'react-join';
import { useId } from 'react-id-generator';
import { ID, MayBe } from 'types';
import { BaseLink, NumberFormat, IconWithText, Icon } from 'components/atoms';
import eye from 'assets/eye.svg';
import user from 'assets/user.svg';
import mail_icon from 'assets/mail_icon.svg';
import phone_icon from 'assets/phone_icon.svg';
import { useShareOfferLinkPopup } from '../../containers/ShareOfferLinkPopup';
import styles from './styles.module.css';

type Props = {
  children?: ReactNode;
  emails?: MayBe<string[]>;
  phones?: MayBe<string[]>;
  customer?: MayBe<string>;
  description?: MayBe<string>;
  hash?: MayBe<string>;
  offerId?: MayBe<ID>;
};

export const EditSidebar = ({
  children,
  emails,
  phones,
  hash,
  customer,
  description,
  offerId,
}: Props) => {
  const phonesIds = useId(phones?.length, 'phones');
  const emailsIds = useId(emails?.length, 'emails');
  const { showShareOfferLinkPopup } = useShareOfferLinkPopup();

  return (
    <>
      {![customer, phones, emails].every(isNil) && (
        <div className={styles.editSidebarHeader}>
          {customer && (
            <IconWithText
              className={styles.editSidebarFooterContactWrapper}
              icon={<Icon src={user} size={16} />}
            >
              {customer}
            </IconWithText>
          )}
          {phones && (
            <IconWithText
              className={styles.editSidebarFooterContactWrapper}
              icon={<Icon src={phone_icon} size={16} />}
            >
              <div>
                <ReactJoin separator={<br />}>
                  {phones?.map((phone, index) => (
                    <NumberFormat
                      key={phonesIds[index]}
                      format="mobile"
                      value={phone}
                    />
                  ))}
                </ReactJoin>
              </div>
            </IconWithText>
          )}
          {emails && (
            <IconWithText
              className={styles.editSidebarFooterContactWrapper}
              icon={<Icon src={mail_icon} size={16} />}
            >
              <div>
                <ReactJoin separator={<br />}>
                  {emails.map((email, index) => (
                    <span key={emailsIds[index]}>{email}</span>
                  ))}
                </ReactJoin>
              </div>
            </IconWithText>
          )}
        </div>
      )}
      {description && (
        <div className={styles.editSidebarOfferDescription}>{description}</div>
      )}

      {children}

      <div className={styles.editSidebarFooter}>
        <BaseLink
          pathModal="offersEdit"
          params={{
            offerId: offerId?.toString(),
          }}
          fullWidth
        >
          Редактировать подборку
        </BaseLink>
        <div className={styles.editSidebarFooterButtonGroup}>
          <ButtonBase
            onClick={showShareOfferLinkPopup}
            fullWidth
            color="primary"
          >
            Поделиться
          </ButtonBase>
          <BaseLink
            path="offersViewClient"
            target="_blank"
            searchParams={{
              isEdit: false,
            }}
            params={{
              /** @todo: использовать константу */
              offerTabs: 'list',
              hash: hash || '',
            }}
            icon={<Icon size={16} src={eye} />}
          />
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { Field } from 'formik';
import { FormikProps } from 'formik/dist/types';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { FeedbackEntity } from 'services/v1/rc/feedback/types';
import {
  FormikTextField,
  Loader,
  FormikMaskedInput,
  FormikTextareaField,
} from 'components/atoms';
import styles from './style.module.css';

type Props = FormikProps<FeedbackEntity>;

export const FormRender = ({ handleSubmit, isSubmitting }: Props) => (
  <form className={styles.form} onSubmit={handleSubmit}>
    <div className={styles.formWrapper}>
      <div className={styles.title}>Понравилась квартира?</div>

      <div className={styles.subTitle}>Свяжитесь с агентством</div>

      <div className={styles.fieldGroup}>
        <Field
          name="name"
          label="Имя"
          placeholder="Как к вам обращаться?"
          component={FormikTextField}
        />
        <Field label="Телефон" name="phone" component={FormikMaskedInput} />
        <Field
          name="comment"
          rows={3}
          label="Комментарий"
          placeholder="Что вас заинтересовало?"
          component={FormikTextareaField}
        />

        <ButtonBase
          icon={isSubmitting && <Loader color="primary" />}
          fullWidth
          type="submit"
          color="primary"
        >
          Оставить заявку
        </ButtonBase>
      </div>
    </div>
  </form>
);

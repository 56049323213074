import React, { MouseEvent } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import { Text } from 'components/atoms/Text';

import { ShortBuildingObject } from 'services';

import { rootProfileStore } from 'stores/user/RootProfileStore';
import { DictionaryBadge } from '../DictionaryBadge';
import styles from './styles.module.css';
import { indexOf } from "lodash";

type Props = {
  buildingObject: ShortBuildingObject;
  toggleComplexFavorites?: (id: number) => (evt: MouseEvent) => void;
  imageClassName?: string;
};

export const ResidentialComplexCardRender = ({
  buildingObject,
  toggleComplexFavorites,
  imageClassName,
}: Props) => {
  const maxApartmentsStatsItems = 3;
  const apartmentStatsOrder = ['rooms_studio', 'rooms_1', 'rooms_1e', 'rooms_2', 'rooms_2e', 'rooms_3', 'rooms_3e', 'rooms_4', 'rooms_4e', 'rooms_n'];

  const isVillage = buildingObject.type === 'village';

  const targetBadges = [...(buildingObject.badges || [])].sort((a, b) => a - b);
  const apartmentsStats = buildingObject.stats.apartments;
  const cottagesStats = buildingObject.stats.cottages;

  const getApartmentRoomsLabel = (key: string, isLastItem: boolean = false) => {
    let label = '';

    switch (key) {
      case 'rooms_studio':
        label = 'Студия';
        break;
      case 'rooms_1':
        label = '1 комната';
        break;
      case 'rooms_1e':
        label = '1E комната';
        break;
      case 'rooms_2':
        label = '2 комнаты';
        break;
      case 'rooms_2e':
        label = '2E комнаты';
        break;
      case 'rooms_3':
        label = '3 комнаты';
        break;
      case 'rooms_3e':
        label = '3E комнаты';
        break;
      case 'rooms_4':
        label = '4 комнаты';
        break;
      case 'rooms_4e':
        label = '4E комнаты';
        break;
      case 'rooms_n':
        label = 'Свободная';
        break;
      default:
        label = ``;
    }

    if (isLastItem) {
      return label.replace(' ', '+ ')
    }

    return label;
  };

  const getCottagesLabel = (key: string) => {
    switch (key) {
      case 'cottage':
        return 'Коттеджи';
      case 'duplex':
        return 'Дуплексы';
      case 'townhouse':
        return 'Таунхаусы';
      case 'triplex':
        return 'Триплексы';
      case 'quad_house':
        return '4х местные';
      default:
        return ``;
    }
  };

  const { includeComplex } = rootProfileStore.favoritesIdsStore;

  return (
    <>
      <div className={styles.imageWrapper}>
        <img
          className={cc([styles.images, imageClassName])}
          src={buildingObject.photo}
          loading="lazy"
          alt="object"
        />

        <div className={styles.informationOverlay}>
          {targetBadges && targetBadges.length > 0 && (
            <div className={styles.tagList}>
              <DictionaryBadge
                badgeInner
                dictItemId={targetBadges}
                dictKey="residential_complex_badges"
              />
            </div>
          )}

          <div className={styles.mainComplexInfo}>
            <div className={styles.builder}>{buildingObject.developer}</div>
            <div className={styles.name}>{buildingObject.title}</div>
            <div className={styles.address}>{buildingObject.address}</div>

            <button
              type="button"
              onClick={
                toggleComplexFavorites &&
                toggleComplexFavorites(buildingObject.id)
              }
              className={styles.iconFavorites}
            >
              {/* TODO: вынести иконочку в компонент */}
              <svg
                className={cc({
                  [styles.initial]: !includeComplex(buildingObject.id),
                  [styles.red]: includeComplex(buildingObject.id),
                })}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2727 1.45454C12.4091 1.45454 13.3523 1.8409 14.1023 2.61363C14.875 3.38636 15.2614 4.32954 15.2614 5.44317C15.2614 6.10227 15.1364 6.74999 14.8864 7.38636C14.6364 7.99999 14.1705 8.69317 13.4886 9.4659C12.8296 10.2386 12.2273 10.8864 11.6818 11.4091C11.1364 11.9318 10.2614 12.7386 9.05682 13.8295L8.00001 14.7841L6.94319 13.8636C5.375 12.4545 4.23864 11.3977 3.5341 10.6932C2.85228 9.98863 2.21591 9.15908 1.62501 8.20454C1.0341 7.24999 0.738641 6.32954 0.738641 5.44317C0.738641 4.32954 1.11364 3.38636 1.86364 2.61363C2.63637 1.8409 3.59091 1.45454 4.72728 1.45454C6.04546 1.45454 7.13637 1.9659 8.00001 2.98863C8.86364 1.9659 9.95455 1.45454 11.2727 1.45454Z"
                  fill="#ffffff"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.86364 2.61363C2.63637 1.8409 3.59091 1.45454 4.72728 1.45454C5.84209 1.45454 6.79435 1.82029 7.58406 2.55179C7.72812 2.68523 7.86677 2.83084 8.00001 2.98863C8.13324 2.83084 8.2719 2.68523 8.41595 2.55179C9.20566 1.82029 10.1579 1.45454 11.2727 1.45454C12.4091 1.45454 13.3523 1.8409 14.1023 2.61363C14.875 3.38636 15.2614 4.32954 15.2614 5.44317C15.2614 6.10227 15.1364 6.74999 14.8864 7.38636C14.6364 7.99999 14.1705 8.69317 13.4886 9.4659C12.8296 10.2386 12.2273 10.8864 11.6818 11.4091C11.1364 11.9318 10.2614 12.7386 9.05682 13.8295L8.00001 14.7841L6.94319 13.8636C5.375 12.4545 4.23864 11.3977 3.5341 10.6932C2.85228 9.98863 2.21591 9.15908 1.62501 8.20454C1.0341 7.24999 0.738641 6.32954 0.738641 5.44317C0.738641 4.32954 1.11364 3.38636 1.86364 2.61363ZM14.5297 2.19254C15.4152 3.07961 15.8614 4.17532 15.8614 5.44317C15.8614 6.18015 15.7212 6.90215 15.4448 7.60575L15.4421 7.61275C15.157 8.31235 14.6446 9.06209 13.9419 9.85907C13.2751 10.6406 12.66 11.3027 12.097 11.8423C11.545 12.3712 10.6647 13.1828 9.45959 14.2743L8.00724 15.5861L6.54212 14.31C4.9727 12.8998 3.82637 11.834 3.10983 11.1174L3.10288 11.1105C2.38723 10.371 1.72519 9.50629 1.11485 8.52035C0.480708 7.49597 0.138641 6.46729 0.138641 5.44317C0.138641 4.17944 0.570363 3.08462 1.43309 2.19574L1.43933 2.18932C2.32949 1.29916 3.43886 0.854538 4.72728 0.854538C6.00035 0.854538 7.09974 1.28186 8.00001 2.1193C8.90027 1.28186 9.99966 0.854538 11.2727 0.854538C12.5598 0.854538 13.6604 1.29855 14.5297 2.19254Z"
                  fill="#ffffff"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {!isVillage && !apartmentsStats && (
        <div className={styles.emptyInfoBlock}>
          <Text tagName="h3" className={styles.noFlats}>
            Нет квартир в продаже
          </Text>
        </div>
      )}

      {!isVillage &&
        apartmentsStats &&
        Object.keys(apartmentsStats).length > 0 && (
          <div className={styles.infoBlock}>
            {Object.keys(apartmentsStats)
              .sort((idA, idB) => indexOf(apartmentStatsOrder, idA) - indexOf(apartmentStatsOrder, idB))
              .filter((key, index) => index < maxApartmentsStatsItems)
              .map((key, index) => (
                  <div className={styles.apartmentData} key={key}>
                      <span className={styles.apartmentDataType}>
                        {index+1 === maxApartmentsStatsItems && Object.keys(apartmentsStats).length > maxApartmentsStatsItems ? getApartmentRoomsLabel(key, true) : getApartmentRoomsLabel(key)}
                      </span>
                    <div className={styles.priceWrapper}>
                        <span className={styles.apartmentDataType}>
                          {apartmentsStats[key].count > 1 ? 'от ' : ''}
                        </span>
                      <span className={styles.apartmentDataPrice}>
                            {(apartmentsStats[key].sumMin / 1e6).toFixed(1)}
                          </span>
                      <span className={styles.apartmentDataType}>млн</span>
                    </div>
                  </div>)
              )}
          </div>
        )}

      {isVillage && cottagesStats && Object.keys(cottagesStats).length > 0 && (
        <div className={styles.infoBlock}>
          {Object.keys(cottagesStats)
            .sort()
            .map((key) => (
              <div className={styles.apartmentData} key={key}>
                <span className={styles.apartmentDataType}>
                  {getCottagesLabel(key)}
                </span>
                <div className={styles.priceWrapper}>
                  <span className={styles.apartmentDataType}>
                    {cottagesStats[key].count > 1 ? 'от ' : ''}
                  </span>
                  <span className={styles.apartmentDataPrice}>
                    {(cottagesStats[key].sumMin / 1e6).toFixed(1)}
                  </span>
                  <span className={styles.apartmentDataType}>млн</span>
                </div>
              </div>
            ))}
        </div>
      )}

      {isVillage && !cottagesStats && (
        <div className={styles.emptyInfoBlock}>
          <Text tagName="h3" className={styles.noFlats}>
            Нет коттеджей в продаже
          </Text>
        </div>
      )}
    </>
  );
};

export const ResidentialComplexCard = observer(ResidentialComplexCardRender);

import { User, USER_STORAGE_KEY, userServiceStorage } from 'services';
import { makeObservable, observable, action, computed } from 'mobx';
import { MayBe } from '../../../types';
import { SyncTrunk } from '../../SyncTrunk/SyncTrunk';
import { authUserStoreStorageAdapter } from './lib/adapters/authUserStoreStorageAdapter';

export type AuthUserStoreState = {
  user: MayBe<User>;
};

/**
 * @desc хранение и доступ к объекту пользователя авторизации
 * */
export class AuthUserStore {
  state: AuthUserStoreState = {
    user: null,
  };

  constructor() {
    makeObservable(this, {
      state: observable,
      setUser: action.bound,
      clear: action.bound,
      user: computed,
    });
  }

  get user() {
    return this.state.user;
  }

  clearState() {
    this.setUser(null);
  }

  clear() {
    this.clearState();
    this.clearStorage();
  }

  clearStorage() {
    userServiceStorage.remove();
  }

  setUser(user: MayBe<User>) {
    this.state.user = user;
  }

  syncTrunk = () => {
    void new SyncTrunk({
      store: this,
      storage: authUserStoreStorageAdapter,
      storageKey: USER_STORAGE_KEY,
      autoInit: true,
    });
  };
}

import { useHistory } from 'react-router-dom';
import useQueryString from 'use-query-string';

type QueryStringResult<TQueryObject> = {
  [0]: TQueryObject;
  [1]: any;
};

export const useGetQueryString = <TQueryObject extends {}>(): {
  query: TQueryObject;
  setQuery: any;
} => {
  const { push, location } = useHistory();

  const { 0: query, 1: setQuery } = useQueryString(
    location as unknown as Location,
    push,
  ) as unknown as QueryStringResult<TQueryObject>;

  return { query, setQuery };
};

import React from 'react';
import { Box } from 'components/styledComponents/Box';
import * as S from './MetersBadge.styled';

type MetersBadgeProps = {
  bgColor: string;
  color: string;
  border: string;
  currentValue: string;
  val: number;
};

export const MetersBadge: React.FC<MetersBadgeProps> = ({
  bgColor,
  color,
  border,
  currentValue,
  val,
}) => (
  <S.Badge bg={bgColor} color={color} border={border}>
    <Box fontSize="12px" lineHeight="12px" mb="1px">
      {currentValue}
    </Box>
    <Box fontWeight="bold" fontSize="14px" lineHeight="14px">
      {val}м²
    </Box>
  </S.Badge>
);

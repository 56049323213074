import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';

import { filtersContext } from 'contexts/filtersContext';

import { complexFilterStore } from 'stores';
import styles from './AllFilters.module.css';
import { FirstTab } from './FirstTab';
import { SecondTab } from './SecondTab';

export const AllFiltersRender = () => {
  return (
    <div className={styles.formWrapper}>
      {complexFilterStore.filters.filterTab === 0 ? (
        <FirstTab />
      ) : (
        <SecondTab />
      )}
    </div>
  );
};

export const AllFilters = observer(AllFiltersRender);

import React, { TouchEventHandler } from 'react';
import { MayBe } from 'types';
import { OffersViewItem } from 'services';
import { EditSidebar } from '../../components/EditSidebar';
import { ViewSidebar } from '../../components/ViewSidebar';
import { EditOfferStatus } from '../EditOfferStatus';
import { EditNotesForClient } from '../EditNotesForClient';

import styles from './styles.module.css';

type Props = {
  isEditOffer: boolean;
  offer?: MayBe<OffersViewItem>;
  handleTouch?: TouchEventHandler;
};

export const Sidebar = ({ isEditOffer, offer, handleTouch }: Props) => (
  <>
    <div className={styles.swipeArea} onTouchEnd={handleTouch}>
      <button type="button" className={styles.openSidebarButton}>
        Открыть сайдбар
      </button>
    </div>

    {isEditOffer && (
      <EditSidebar
        offerId={offer?.id}
        hash={offer?.hash}
        customer={offer?.customer}
        description={offer?.description}
        emails={offer?.emails}
        phones={offer?.phones}
      >
        <EditOfferStatus offer={offer} />
        <EditNotesForClient offer={offer} />
      </EditSidebar>
    )}
    {!isEditOffer && <ViewSidebar offer={offer} />}
  </>
);

import React, { CSSProperties, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { isNil } from 'lodash';
import { BuildingFilterType } from 'stores/BuildingFilters';
import { Input } from 'components/atoms/Form/Input';
import { FilterStore } from 'stores/FilterStore';

type Props = {
  filterKey: any;
  store: FilterStore<any, any>;
  placeholder?: string;
  customIcon?: ReactNode;
  className?: string;
  customStyles?: CSSProperties;
};

export const ReactiveInputRender = ({
  customIcon,
  customStyles,
  placeholder = 'Выбрать',
  filterKey,
  store,
  className,
}: Props) => {
  const filterItem: number | undefined = store.filters[filterKey];

  const inputValue = String(
    isNil(filterItem) || filterItem === 0 ? '' : filterItem,
  );

  const onChange = ({ target }: any) => {
    const { value } = target;

    if (!Number.isNaN(+value)) {
      store.setField(filterKey as keyof BuildingFilterType, +value);
    }
  };

  return (
    <Input
      onChange={onChange}
      maxLength={9}
      value={inputValue}
      placeholder={placeholder}
      customIcon={customIcon}
      className={className}
      customStyles={customStyles}
    />
  );
};

export const ReactiveInput = observer(ReactiveInputRender);

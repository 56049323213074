import React from 'react';
import cc from 'classcat';
import { Text } from 'components/atoms/Text';
import { Icon } from 'components/atoms/Icon';
import colors_phone from 'assets/colors_phone.svg';
import styles from './styles.module.css';
import { ButtonBase } from '../../../../pages/LayoutPage/components/ButtonBase';

type Props = {
  onClick?: () => void;
  isError?: boolean;
  className?: string;
};

export const FormSubmitStatus = ({ onClick, isError, className }: Props) => {
  const content = {
    logo: colors_phone,
    title: 'Мы свяжемся с вами',
    description: 'Спасибо за заявку',
  };

  if (isError) {
    content.title = 'Упс!';
    content.description =
      'Что-то пошло не так, попробуйте повторить запрос позже';
  }

  return (
    <div className={cc([styles.wrapper, className])}>
      <Icon src={content.logo} size={120} />
      <Text weight="700" size="3XL" align="center">
        {content.title}
      </Text>
      <Text weight="700" size="L" align="center">
        {content.description}
      </Text>
      {onClick && <ButtonBase onClick={onClick}>Вернуться назад</ButtonBase>}
    </div>
  );
};

import React from 'react';
import { Field, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { appRoutesService, authEmailService, ResponseStatus } from 'services';
import { observer } from 'mobx-react';

import { FormikTextField } from 'components/atoms/Form/FormikTextField';
import { useGetQueryString } from 'hooks';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { ConfirmationAndChangeComponent } from '../ConfirmationAndChangeComponent';
import { changeEmailStore } from '../../../../stores/ChangeEmailStore';

import { UserProfileForm } from '../../types';
import styles from '../UserFormRender/styles.module.css';

const UIDictionary = {
  confirmationWait: 'Подтвердите переход на новую почту',
  confirmationOk: 'Подтвержден',
  openEdit: 'Изменить почту',
  save: 'Выслать заново',
};

export const ChangeEmailRender = () => {
  const { values, setFieldError, setTouched } =
    useFormikContext<UserProfileForm>();

  const { query } = useGetQueryString<{ newEmail?: string }>();

  const { push } = useHistory();

  const isConfirmed = changeEmailStore.state.isChange
    ? false
    : values.emailIsConfirmed;

  const onEdit = async () => {
    if (changeEmailStore.state.isChange) {
      if (!changeEmailStore.state.newEmail) {
        push(appRoutesService.getRoute('profileNewEmail', query));
        return;
      }

      if (!changeEmailStore.state.oldEmailIsConfirm) {
        try {
          void (await authEmailService.code({
            email: rootProfileStore.authUserStore.user?.email,
          }));
          push(appRoutesService.getRoute('profileConfirmOldEmail'));
          setFieldError('email', undefined);
        } catch (error) {
          // todo
          if (error.response) {
            const err = error.response.data as ResponseStatus;

            setTouched({
              email: true,
            });
            /**
             * todo: хак, для показа ошибки надо чтобы поле было тронуто
             * но событие touched вызывает несколько рендеров и если не делать задержку перед записью ошибки
             * то ошибка сбрасывается
             * */
            setTimeout(() => setFieldError('email', err.message), 100);
          }
        }
        return;
      }
      if (!changeEmailStore.state.newEmailIsConfirm) {
        push(appRoutesService.getRoute('profileConfirmNewEmail', query));
      }
    } else {
      changeEmailStore.state.isChange = true;
      push(appRoutesService.getRoute('profileNewEmail'));
    }
  };

  return (
    <>
      <ConfirmationAndChangeComponent
        className={styles.formMainField}
        isEdit={changeEmailStore.state.isChange}
        isConfirmed={isConfirmed}
        onSave={onEdit}
        onEdit={onEdit}
        onCancel={changeEmailStore.clear}
        UIDictionary={UIDictionary}
      >
        <Field
          label="Электронная почта"
          type="email"
          name="email"
          component={FormikTextField}
          disabled
        />
      </ConfirmationAndChangeComponent>

      {changeEmailStore.state.isChange && (
        <Field
          className={styles.formMainField}
          label="Новая электронная почта"
          name="newEmail"
          type="email"
          component={FormikTextField}
          disabled
        />
      )}
    </>
  );
};

export const ChangeEmail = observer(ChangeEmailRender);

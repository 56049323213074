import React, { ReactNode } from 'react';
import { ApartmentsStore } from 'stores/Apartment/ApartmentsStore/ApartmentsStore';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { useApartmentListIncrement } from '../../hooks/useApartmentListIncrement';
import styles from '../../styles.module.css';

type Props = { apartmentsStore: ApartmentsStore; children: ReactNode };

const ApartmentListScrollContainerTestIDs = {
  wrapper: 'ApartmentListScrollContainer_wrapper',
};

export const ApartmentListScrollContainer = ({
  apartmentsStore,
  children,
}: Props) => {
  const onIncrement = useApartmentListIncrement(apartmentsStore);
  return (
    <div
      {...dataTestIdToProps(ApartmentListScrollContainerTestIDs.wrapper)}
      className={styles.wrapper}
    >
      <div onScroll={onIncrement} className={styles.scrollContainer}>
        {children}
      </div>
    </div>
  );
};

import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';

import { InputGroup } from 'components/atoms';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';

import styles from '../../AllFilters/AllFilters.module.css';

const ThirdColumnFiltersRenderer = () => (
  <>
    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Стоимость, м²</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="priceM2Min"
          placeholder="Стоимость, от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="priceM2Max"
          placeholder="Стоимость, до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>
    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Общая площадь</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareMin"
          placeholder="Общая, от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareMax"
          placeholder="Общая, до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Площадь кухни</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareKitchenMin"
          placeholder="Кухня, от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareKitchenMax"
          placeholder="Кухня, до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formInputs}>
      <span className={styles.formLabel}>Жилая площадь</span>
      <InputGroup>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareLivingMin"
          placeholder="Жилая, от"
          className={styles.complexInputLeft}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="squareLivingMax"
          placeholder="Жилая, до"
          className={styles.complexInputRight}
        />
      </InputGroup>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Вид</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="windowType"
          dictKey="apartment_window_type"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Потолки</span>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="ceilingHeightMin"
          placeholder="От"
        />
      </div>
    </div>

    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="material"
          dictKey="residential_complex_material"
        />
      </div>

      <div className={styles.formItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="finishing"
          dictKey="residential_complex_finishing"
        />
      </div>
    </div>
  </>
);

export const ThirdColumnFilters = memo(ThirdColumnFiltersRenderer);

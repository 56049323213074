import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

import { appRoutesService, Stock } from '../../../../../../services';
import { PromotionCard } from '../PromotionCard';
import styles from './styles.module.css';

type Props = {
  list: Stock[];
};
export const PromotionMobileList = ({ list }: Props) => (
  <Swiper className={styles.list} spaceBetween={0} slidesPerView="auto">
    {list.map((stock) => (
      <SwiperSlide className={styles.swiperItem}>
        <Link
          className={styles.link}
          to={appRoutesService.replaceRoute('stock', {
            complexId: stock?.residential_complex_id?.toString(),
            stockId: stock?.id?.toString(),
          })}
        >
          <PromotionCard key={stock.id} {...stock} />
        </Link>
      </SwiperSlide>
    ))}
  </Swiper>
);

import { action, makeObservable, observable } from 'mobx';
import {
  AttemptResult,
  offersService,
  OffersService,
  OffersViewHashUrlParams,
  OffersViewItem,
  OffersViewResponse,
  OffersViews,
  OffersViewUrlParams,
} from '../../services';
import { ID, MayBe } from '../../types';
import { RequestApiStore } from '../request/RequestApiStore';

export class OfferItemStore {
  currentOfferId: MayBe<ID> = null;

  currentOfferHash: MayBe<string> = null;

  offer: MayBe<OffersViewItem> = null;

  offersService: OffersService;

  fetchOfferRequestApiState: RequestApiStore;

  constructor(offersService: OffersService) {
    this.offersService = offersService;
    this.fetchOfferRequestApiState = new RequestApiStore();

    makeObservable(this, {
      offer: observable,
      currentOfferId: observable,
      currentOfferHash: observable,
      clear: action,
      fetchOffer: action,
      fetchOfferByHash: action,
      reFetch: action,
    });
  }

  clear = () => {
    this.offer = null;
    this.currentOfferId = null;
    this.currentOfferHash = null;
    this.fetchOfferRequestApiState.clear();
  };

  setViews = (views: OffersViews[]) => {
    if (!this.offer) {
      return;
    }
    this.offer.views = views;
  };

  includeView = (field: OffersViews) =>
    this.offer?.views?.includes(field) || false;

  reFetch = () => {
    if (this.currentOfferId) {
      void offerItemStore.fetchOffer({
        offerId: this.currentOfferId,
      });
    } else if (this.currentOfferHash) {
      void offerItemStore.fetchOfferByHash({
        hash: this.currentOfferHash,
      });
    }
  };

  fetchOffer = async (
    params: OffersViewUrlParams,
  ): Promise<AttemptResult<OffersViewResponse>> => {
    this.fetchOfferRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.view(params);

    if (error) {
      this.fetchOfferRequestApiState.setError(true);
    }

    if (result) {
      this.offer = result;
    }

    this.fetchOfferRequestApiState.setLoading(false);

    return [error, result];
  };

  fetchOfferByHash = async (
    params: OffersViewHashUrlParams,
  ): Promise<AttemptResult<OffersViewResponse>> => {
    this.fetchOfferRequestApiState.setLoading(true);
    const [error, result] = await this.offersService.viewHash(params);

    if (error) {
      this.fetchOfferRequestApiState.setError(true);
    }

    if (result) {
      this.offer = result;
    }

    this.fetchOfferRequestApiState.setLoading(false);

    return [error, result];
  };
}

export const offerItemStore = new OfferItemStore(offersService);

import React, { ComponentProps } from 'react';
import { Divider } from 'components/atoms';
import { SalesDepartments } from 'services';
import { SalesDepartmentCompanyHeader } from '../SalesDepartmentCompanyHeader';
import styles from './styles.module.css';
import { CallToActionContactInfo } from '../CallToActionContactInfo';

type Props = {
  salesDepartments?: SalesDepartments[];
} & ComponentProps<typeof SalesDepartmentCompanyHeader>;

const SalesDepartmentCompanyBlockTextIds = {
  wrapper: 'SalesDepartmentCompanyBlock_wrapper',
};

export const SalesDepartmentCompanyBlock = ({
  salesDepartments,
  company,
  type,
}: Props) => (
  <>
    <div
      className={styles.wrapper}
      data-test-id={SalesDepartmentCompanyBlockTextIds.wrapper}
    >
      {company && (
        <SalesDepartmentCompanyHeader company={company} type={type} />
      )}

      {salesDepartments?.map((salesDepartment) => (
        <CallToActionContactInfo
          type={type}
          key={salesDepartment.id}
          contacts={salesDepartment.contacts || []}
          salesDepartment={salesDepartment}
        />
      ))}
    </div>

    <Divider className={styles.divider} />
  </>
);

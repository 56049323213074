import qs from 'qs';
import { AxiosRequestConfig } from 'axios';
import { API_ROUTES } from 'services/constants';
import {
  BuildingDto,
  BuildingEntity,
  BuildingSearchParameters,
} from 'services/v1/rc/building/types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { loggerService } from '../../../loggerService';
import { ThrowableResult } from '../../../types';
import { PolygonCoordinates } from '../../../../types/PolygonCoordinates';

export const buildingService = {
  async getBuilding(
    buildingId: string,
    filters?: BuildingSearchParameters,
  ): Promise<ThrowableResult<BuildingEntity>> {
    const requestObject: AxiosRequestConfig = {
      url: API_ROUTES.building.replace(':buildingId', buildingId.toString()),
      method: 'get',
      params: {
        ...filters,
      },
      paramsSerializer: (params) => qs.stringify(params),
    };

    try {
      const response = await makeRequest<BuildingDto>(requestObject);

      return this.transformBuilding(response.data);
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  transformBuilding: (building: BuildingDto): BuildingEntity => ({
    ...building,
    polygon: building.polygon
      ? (JSON.parse(building.polygon) as Array<PolygonCoordinates>)
      : [],
  }),
};

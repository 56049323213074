import React, { useEffect } from 'react';

import { useMap } from 'react-leaflet';
import L, { Map as MapType } from 'leaflet';
import { LeafletStoreClass } from 'stores/LeafletStore';

type Props = {
  leafletStore?: LeafletStoreClass;
};

export const SetMapInstanceToStore = ({ leafletStore }: Props): JSX.Element => {
  const map: MapType = useMap();
  useEffect(() => {
    leafletStore?.setInstance(map);
    map.options.crs = L.CRS.EPSG3395;
  }, [map]);
  return <></>;
};

import { developersService, agenciesService } from 'services';
import { Option } from 'components/atoms/Form/FormikSelect/types';

export const findDeveloper = async (
  inputValue: string,
  callback: (options: Option[]) => void,
) => {
  const data = await developersService.getDevelopers(inputValue);
  if (Array.isArray(data)) {
    callback(
      data.map(
        ({ id, title }): Option => ({
          label: title,
          value: id,
        }),
      ),
    );
  } else {
    callback([]);
  }
};
export const findAgency = async (
  inputValue: string,
  callback: (options: Option[]) => void,
) => {
  const data = await agenciesService.getAgencies(inputValue);
  if (Array.isArray(data)) {
    callback(
      data.map(
        ({ id, title }): Option => ({
          label: title,
          value: id,
        }),
      ),
    );
  } else {
    callback([]);
  }
};

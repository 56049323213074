import React, { ComponentProps } from 'react';
import { useModal } from 'react-modal-hook';
import { BasePopup } from 'components/atoms/Popup';
import { useGetResponsiveModalComponent } from 'hooks';
import { FeedbackForm } from '../index';

export const useFeedbackFormInPopup = () => {
  const { PopupWrapper } = useGetResponsiveModalComponent<
    ComponentProps<typeof BasePopup>
  >({
    mobileHeaderChildrenTitle: 'Оставить заявку',
    DesktopModalWrapper: (props) => <BasePopup {...props} isOpen size="MD" />,
  });

  const [showFeedbackForm, hideFeedbackForm] = useModal(
    () => (
      <PopupWrapper isOpen onClose={hideFeedbackForm}>
        <FeedbackForm goBack={hideFeedbackForm} />
      </PopupWrapper>
    ),
    [PopupWrapper],
  );

  return {
    showFeedbackForm,
    hideFeedbackForm,
  };
};

import {
  flow,
  filter,
  size,
  values,
  negate,
  isEmpty,
  cond,
  isArray,
  isObject,
  isString,
  stubTrue,
} from 'lodash/fp';
import { isNotNil } from 'utils/fp';

export const getCountActiveFilter = flow(
  values,
  filter(
    cond([
      [isArray, negate(isEmpty)],
      [isObject, negate(isEmpty)],
      [isString, negate(isEmpty)],
      [stubTrue, isNotNil],
    ]),
  ),
  size,
);

import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import cc from 'classcat';
import closeIcon from 'assets/close.svg';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  header?: ReactNode;
  topHeader?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  setForwardedContentRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | undefined>
  >;
};

export const FullscreenPopup = ({
  children,
  header,
  topHeader,
  isOpen,
  onClose,
  setForwardedContentRef,
}: Props) => (
  <ReactModal
    shouldCloseOnEsc
    isOpen={isOpen}
    ariaHideApp={false}
    onRequestClose={onClose}
    overlayClassName={cc([styles.modalOverlay])}
    className={cc([styles.modalContent])}
    overlayRef={(ref) => {
      if (setForwardedContentRef) {
        return setForwardedContentRef(ref);
      }
      return null;
    }}
  >
    {topHeader && <div className={styles.topHeader}>{topHeader}</div>}
    {header && (
      <header className={styles.header}>
        <div
          className={cc([
            styles.headerContent,
            {
              [styles.headerContentWithTopHeader]: topHeader,
            },
          ])}
        >
          {header}
        </div>
      </header>
    )}
    <button onClick={onClose} type="button" className={styles.closeButton}>
      <img
        src={closeIcon}
        className={styles.closeButtonIcon}
        alt="close modal"
      />
    </button>
    <div
      className={cc([
        {
          [styles.content]: !topHeader,
          [styles.contentWithTopHeader]: topHeader,
        },
      ])}
    >
      {children}
    </div>
  </ReactModal>
);

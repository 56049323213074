import {
  RequestPageInfo,
  ResponsePageInfo,
  SearchData,
  SearchParameters,
  searchService,
} from 'services';

import { action, computed, makeObservable, observable,} from 'mobx';
import { RequestPageInfoStore } from './request/RequestPageInfoStore';
import { RequestApiStore } from './request/RequestApiStore';
import { MapBounds } from '../types/MapBounds';

export class BuildingsStore {
  complexes: Omit<ResponsePageInfo<SearchData>, 'filterData'> = {
    total: 0,
    currentPage: 1,
    totalPages: 0,
  };

  pageInfo: RequestPageInfoStore;

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();
    this.pageInfo = new RequestPageInfoStore();

    makeObservable(this, {
      hasMore: computed,
      complexes: observable,

      resetComplexesState: action.bound,
      setResponse: action.bound,
      searchComplexes: action.bound,
      clear: action.bound,
    });
  }

  get hasMore(): boolean {
    return this.complexes.currentPage === this.complexes.totalPages;
  }

  clear(): void {
    this.pageInfo.resetPageInfo();
    this.requestApi.clear();
    this.resetComplexesState();
  }

  resetComplexesState = () => {
    this.complexes = {
      total: 0,
      currentPage: 1,
      totalPages: 0,
    };
  };

  setResponse = (
    result: Omit<ResponsePageInfo<SearchData>, 'filterData'> | undefined,
  ) => {
    if (!result) {
      return;
    }
    this.complexes.currentPage = result.currentPage;
    this.complexes.resultsPerPage = result.resultsPerPage;
    this.complexes.total = result.total;
    this.complexes.totalPages = result.totalPages;

    if (result.currentPage && result.currentPage > 1) {
      this.complexes = {
        ...this.complexes,
        data: {
          pins: [
            ...(this.complexes?.data?.pins || []),
            ...(result.data?.pins || []),
          ],
          list: [
            ...(this.complexes?.data?.list || []),
            ...(result.data?.list || []),
          ],
        },
      };
    } else {
      this.complexes.data = result.data;
    }
  };

  searchComplexes = async (
    filters: Partial<
      SearchParameters & Omit<RequestPageInfo, 'page' | 'limit'>
    >,
    mapBounds: MapBounds,
    pageInfo?: RequestPageInfo,
  ) => {
    try {
      this.requestApi.setLoading(true);
      const result = await searchService.search(filters, mapBounds, pageInfo);
      this.setResponse(result);

      this.requestApi.setLoading(false);

      return result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`error`, e);

      this.requestApi.setError(true);
      return undefined;
    }
  };
}

export const buildingsStore = new BuildingsStore();
buildingsStore.requestApi.setLoading(true);
export default buildingsStore;

import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { appRoutesService, authEmailService, Params } from 'services';
import { changeEmailStore } from '../../../ProfilePage/stores/ChangeEmailStore';
import { rootProfileStore } from '../../../../stores/user/RootProfileStore';

export const AuthEmailChangePageRender = () => {
  const { code } = useParams<Pick<Params, 'code'>>();

  const { push } = useHistory();

  const changeEmailConfirm = async () => {
    const [error, response] = await authEmailService.changeConfirm({
      code,
    });
    if (response) {
      changeEmailStore.clear();
      void rootProfileStore.authStore.updateUser();
      push(appRoutesService.getRoute('profile'));
      return;
    }
    if (error) {
      push(appRoutesService.getRoute('profileLinkIsOutOfDatePage'));
    }
  };

  useEffect(() => {
    if (code) {
      void changeEmailConfirm();
    }
  }, []);

  return null;
};

export const AuthEmailChangePage = observer(AuthEmailChangePageRender);

import React from 'react';
import { Icon } from 'components/atoms/Icon';
import map_icon from 'assets/switcherMap.svg';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Complex } from 'services';
import { useBuildingOnMapPopup } from 'pages/LayoutPage/containers/BuildingOnMapPopup';
import { RenderBuildingToMap } from 'pages/LayoutPage/pages/ApartmentView/components/RenderBuildingToMap';

import styles from './styles.module.css';

type Props = {
  complex: Complex;
  polygon?: [number, number][];
};

// TODO: кнопка будет открывать модальное окно с картой и блоком "поблизости", реализовать когда будет готово API поблизости
export const ComplexInfoAddress = ({ complex, polygon }: Props) => {
  const windows = undefined;

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon,
  });

  return (
    <div className={styles.adressWrapper}>
      <RenderBuildingToMap
        hideZoomControl
        polygon={complex.polygon}
        windows={windows}
      />
      <ButtonBase
        className={styles.right_content}
        icon={<Icon src={map_icon} />}
        onClick={showBuildingOnMapPopup}
      >
        Смотреть на карте
      </ButtonBase>
    </div>
  );
};

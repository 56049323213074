import { FormikHelpers } from 'formik';
import { userProfileService } from 'services';
import { submissionErrorsHandler } from 'utils/formik/submissionErrorsHandler';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { UserProfileForm } from '../../types';
import { userProfileFormToUser } from '../../utils';
import {infoStore} from "../../../../../../stores";

export const useOnSubmit = () => {
  const onSubmit = async (
    values: UserProfileForm,
    formikHelpers: FormikHelpers<UserProfileForm>,
  ) => {
    const user = userProfileFormToUser(values);
    const [error, response] = await userProfileService.saveUserProfile(user);

    if (response) {
      rootProfileStore.authUserStore.setUser(response.user);
      await infoStore.loadInfo();
    } else if (error) {
      submissionErrorsHandler(error, formikHelpers);
    }
  };

  return {
    onSubmit,
  };
};

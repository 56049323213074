import React from 'react';
import cc from 'classcat';

import {
  composeSkeletonElementStyle,
  defaultColor,
  defaultHighlightColor,
  defaultAnimationDuration,
} from './utils';

import { SkeletonProps } from './types';

import styles from './styles.module.css';

export const Skeleton = ({
  animation = 'pulse',
  count = 1,
  duration = defaultAnimationDuration,
  color = defaultColor,
  highlightColor = defaultHighlightColor,
  circle = false,
  skeletonSizes,
  className: customClassName,
  width,
  height,
  wrapperClassName,
  wrapperDirection = 'column',
  wrapperTag: WrapperTag = 'div',
  style,
}: SkeletonProps) => {
  const skeletonClassName = cc([
    styles.skeleton,
    {
      [styles.pulseSkeleton]: animation === 'pulse',
      [styles.waveSkeleton]: animation === 'wave',
      [styles.gradientSkeleton]: animation === 'gradient',
    },
    customClassName,
  ]);

  const skeletonWrapperClassName = cc([
    styles.skeletonWrapper,
    {
      [styles.skeletonArray]: skeletonSizes && skeletonSizes?.length > 0,
      [styles.skeletonWrapperDirection]: wrapperDirection === 'row',
    },
    wrapperClassName,
  ]);

  const elements = Array.from({ length: count }).map((_el, i) => {
    const _style = composeSkeletonElementStyle(
      width,
      height,
      color,
      highlightColor,
      duration,
      circle,
    );

    /*  eslint-disable react/no-array-index-key */
    const SkeletonElement = (
      <span
        key={i}
        className={skeletonClassName}
        style={{ ..._style, ...style }}
      />
    );

    /*  eslint-disable react/no-array-index-key */
    const SkeletonSizes = skeletonSizes?.map(
      ({ w, h }, index): JSX.Element => (
        <span
          key={index}
          className={skeletonClassName}
          style={{
            ..._style,
            ...style,
            width: w,
            height: h,
            borderRadius: w && h && circle ? '50%' : 'none',
          }}
        />
      ),
    );

    return skeletonSizes && skeletonSizes.length > 0
      ? SkeletonSizes
      : SkeletonElement;
  });

  return (
    <WrapperTag className={skeletonWrapperClassName}>{elements}</WrapperTag>
  );
};

import { RequestApiStore } from './request/RequestApiStore';

/**
 * @desc стор для выполнения загрузки SDK yamap и оповещения всех потребителей о статусе загрузки
 * */
export class YMapsStore {
  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();
    this.requestApi.setLoading(true);

    try {
      if (ymaps && ymaps.ready) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ymaps.ready(() => {
          this.requestApi.setLoading(false);
        });
      }
    } catch (e) {
      this.requestApi.setLoading(false);
      this.requestApi.setError(true);
    }
  }
}

export const ymapsStore = new YMapsStore();

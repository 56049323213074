import React from 'react';
import { observer } from 'mobx-react';
import { AuthStore } from 'stores/user/AuthStore/AuthStore';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { BaseLink } from 'components/atoms';
import { ComponentToggleIsAuth } from 'components/containers/ComponentToggleIsAuth';
import { ProfileHeaderMenu } from './components/ProfileHeaderMenu';
import { dataTestIdToProps } from '../../../../utils/test/dataTestIdToProps';

type Props = {
  loginButtonProps?: {
    fullWidth?: boolean;
  };
  authStore?: AuthStore;
};

const ProfileButtonAndLoginDesktopTestIds = {
  openAuthPhoneCodePage: 'ProfileButtonAndLoginDesktop_openAuthPhoneCodePage',
};

export const ProfileButtonAndLoginDesktopRender = ({
  loginButtonProps,
  authStore = rootProfileStore.authStore,
}: Props) => {
  const { authUser, logout } = authStore;

  return (
    <ComponentToggleIsAuth
      componentIsAuth={
        <ProfileHeaderMenu logout={logout} user={authUser.user} />
      }
      componentIsNotAuth={
        <BaseLink
          {...loginButtonProps}
          {...dataTestIdToProps(
            ProfileButtonAndLoginDesktopTestIds.openAuthPhoneCodePage,
          )}
          path="authPhoneCodePage"
          color="primary"
        >
          Вход или регистрация
        </BaseLink>
      }
    />
  );
};

export const ProfileButtonAndLoginDesktop = observer(
  ProfileButtonAndLoginDesktopRender,
);

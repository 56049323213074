import React from 'react';
import { Avatar, ContactView } from 'components/atoms';
import { Contacts } from 'services';
import { MayBe } from 'types';
import styles from './styles.module.css';

type Props = {
  contact?: MayBe<Contacts>;
};

export const ManagerCard = ({ contact }: Props) => (
  <div className={styles.wrapper}>
    <Avatar className={styles.avatar} src={contact?.logo?.logo || ''} />
    <div className={styles.content}>
      <div className={styles.fio}>{contact?.fio}</div>

      {contact?.rank && <div className={styles.rank}>{contact?.rank}</div>}

      {contact?.description && (
        <div className={styles.description}>{contact?.description}</div>
      )}

      <div className={styles.contacts}>
        <ContactView
          numberFormatPatterns="mobile"
          indent={{
            margin: [0, 10],
          }}
          type="tel"
          phone={contact?.phone}
        />
        <ContactView
          indent={{
            margin: [0, 10],
          }}
          type="mailto"
          email={contact?.email}
        />
      </div>
    </div>
  </div>
);

export default ManagerCard;

import React from 'react';
import { pluralize } from 'utils/pluralize';
import styles from './styles.module.css';
import { ComplexListSkeletonTitle } from '../ComplexListSkeleton';
import { SortDropdown } from '../../../../../../components/atoms';
import { complexFilterStore } from '../../../../../../stores/ComplexFilterStore';

type Props = {
  isLoading: boolean;
  foundedCount: number;
};

export const ComplexListHeader = ({ isLoading, foundedCount }: Props) => (
  <div className={styles.heading}>
    {isLoading && <ComplexListSkeletonTitle />}
    {!isLoading && (
      <div className={styles.headingText}>
        {` ${pluralize(foundedCount, ['Найден', 'Найдено', 'Найдено'], true)}`}
        {` ${pluralize(foundedCount, ['объект', 'объекта', 'объектов'])}`}
      </div>
    )}
    <SortDropdown
      pageInfo={complexFilterStore.pageInfo}
      onChange={complexFilterStore.setSort}
    />
  </div>
);

import React, { useCallback } from 'react';
import { FieldProps } from 'formik/dist/Field';
import Select from 'react-select';
import { get } from 'lodash/fp';
import { ValueType } from 'react-select/src/types';
import { FormValidationStatuses } from '../FormValidationStatuses';

import { FormFieldLabel } from '../FormFieldLabel';
import { MultiValue } from './components/MultiValue';
import { Option } from './types';
import { selectStyles } from './styles';

type Props<V> = FieldProps<V> & {
  label?: string;
  placeholder?: string;
  options: Option[];
  isMulti?: boolean;
  disabled?: boolean;
  className?: string;
};

export const FormikSelect = ({
  field,
  label,
  placeholder,
  options,
  isMulti,
  form,
  className,
  disabled,
}: Props<any>) => {
  const onChange = useCallback((value: ValueType<Option, boolean>) => {
    form.setFieldValue(field.name, value);
  }, []);

  return (
    <FormValidationStatuses name={field.name} className={className}>
      <>
        {label && (
          <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
        )}
        <Select
          isDisabled={disabled}
          components={{
            MultiValue,
          }}
          isMulti={isMulti}
          onChange={onChange}
          placeholder={placeholder}
          value={field.value}
          options={options}
          styles={selectStyles}
          getOptionLabel={get('label')}
          getOptionValue={get('value')}
        />
      </>
    </FormValidationStatuses>
  );
};

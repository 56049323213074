import React from 'react';
import { SelectableOffersCard } from '../SelectableOffersCard';
import styles from './styles.module.css';
import { OffersAddItem } from '../../../../../../services';
import { OffersNotFound } from '../../../OffersPage/components/OffersNotFound';

type Props = {
  offers: OffersAddItem[];
};

export const AddToOffersList = ({ offers }: Props) => (
  <div className={styles.addToOffersListWrapper}>
    {!offers.length && <OffersNotFound />}
    {offers.map((offer) => (
      <SelectableOffersCard key={offer.id} offer={offer} />
    ))}
  </div>
);

import React, { useMemo } from 'react';
import cc from 'classcat';
import { NumberFormat } from 'components/atoms';
import { MayBe } from 'types';
import styles from './styles.module.css';

type Props = {
  price_m2?: MayBe<number>;
  price?: MayBe<number>;
  old_price?: MayBe<number>;
};

export const ApartmentPrice = ({ price_m2, price, old_price }: Props) => {
  const diffPrice = useMemo(
    () => (old_price && price ? Math.abs(old_price - price) : 0),
    [old_price, price],
  );
  const diffPriceInPercent = useMemo(() => {
    if (old_price && price) {
      return (diffPrice / (old_price / 100)).toFixed(2);
    }
    return 0;
  }, [old_price, price]);

  const priceIsUp = useMemo(
    () => price && old_price && price < old_price,
    [diffPrice, price],
  );

  const priceIsDown = useMemo(
    () => price && old_price && price > old_price,
    [diffPrice, price],
  );

  return (
    <div className={styles.apartmentPriceWrapper}>
      {old_price && diffPrice !== 0 && (
        <div className={styles.apartmentOldPrice}>
          <NumberFormat format="rub" value={old_price} />
        </div>
      )}

      {price && diffPrice !== 0 && (
        <div
          className={cc([
            styles.apartmentDiffPrice,
            styles.apartmentDiffPriceIcon,
            {
              [styles.apartmentDiffPriceIcon]: priceIsUp || priceIsDown,
              [styles.apartmentDiffPriceUp]: priceIsUp,
              [styles.apartmentDiffPriceDown]: priceIsDown,
            },
          ])}
        >
          <NumberFormat format="rub" value={diffPrice} /> ({diffPriceInPercent}
          %)
        </div>
      )}

      {price && (
        <div className={styles.apartmentPrice}>
          <NumberFormat format="rub" value={price} />
        </div>
      )}

      {price_m2 && (
        <div className={styles.apartmentPriceM2}>
          <NumberFormat format="rub_meter2" value={price_m2} />
        </div>
      )}
    </div>
  );
};

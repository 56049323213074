import {
  flow,
  get,
  has,
  split,
  cond,
  stubTrue,
  flatten,
  join,
} from 'lodash/fp';

export const hasModalTypeInObject = has(['modalType']);

export const getModalType = get('modalType');

export const modalTypeToArray = flow(getModalType, split(','));

export const modalTypeStringToArray = cond([
  [hasModalTypeInObject, modalTypeToArray],
  [stubTrue, () => []],
]);

export const modalTypeArraysJoin = flow(flatten, join(','));

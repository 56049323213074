import { action, makeObservable, observable } from 'mobx';
import { CancelTokenStore } from 'stores/request/CancelTokenStore';
import { ID } from 'types';
import {
  AttemptResult,
  OffersApartmentStatusResponse,
  offersService,
  OffersService,
} from '../../services';
import { RequestApiStore } from '../request/RequestApiStore';

export class ApartmentInOffersStatusStore extends CancelTokenStore {
  statuses: Record<ID, boolean> = {};

  offersService: OffersService;

  requestApiState: RequestApiStore;

  constructor(offersService: OffersService) {
    super();
    this.offersService = offersService;
    this.requestApiState = new RequestApiStore();

    makeObservable(this, {
      statuses: observable,
      clear: action,
      getApartmentStatus: action,
      setStatus: action,
      removeStatus: action,
    });
  }

  clear = () => {
    this.statuses = {};
    this.requestApiState.clear();
  };

  haveAnApartmentInAnyOffers = (id: ID): boolean | undefined =>
    this.statuses[id];

  setStatus = (id: ID, status: boolean) => {
    this.statuses[id] = status;
  };

  removeStatus = (id: ID) => {
    delete this.statuses[id];
  };

  getApartmentStatus = async (
    apartmentId: ID,
  ): Promise<AttemptResult<OffersApartmentStatusResponse>> => {
    this.executeCancelToken();
    this.requestApiState.setLoading(true);
    this.requestApiState.setError(false);

    const [error, result] = await this.offersService.apartmentStatus(
      {
        apartmentId,
      },
      {
        cancelToken: this.cancelTokenSource?.token,
      },
    );

    if (result) {
      this.requestApiState.setLoading(false);
      this.setStatus(apartmentId, result.status);
    }

    if (error) {
      this.requestApiState.setError(true);
    }

    return [error, result];
  };
}

export const apartmentInOffersStatusStore = new ApartmentInOffersStatusStore(
  offersService,
);

import React, { useCallback } from 'react';
import { FieldProps } from 'formik/dist/Field';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { ValueType } from 'react-select/src/types';
import { FormValidationStatuses } from '../FormValidationStatuses';
import { Option } from './types';

import { FormFieldLabel } from '../FormFieldLabel';
import { MultiValue } from './components/MultiValue';

import { selectStyles } from './styles';

type Props<V> = FieldProps<V> & {
  className?: string;
  label?: string;
  placeholder?: string;
  options: Option[];
  defaultOptions?: Option[];
  isMulti?: boolean;
  isAsync?: boolean;
} & Pick<AsyncProps<any, any>, 'loadOptions'>;

export const FormikAsyncSelect = ({
  field,
  label,
  placeholder,
  options,
  isMulti,
  isAsync,
  form,
  className,
  ...rest
}: Props<any>) => {
  const onChange = useCallback((value: ValueType<Option, boolean>) => {
    form.setFieldValue(field.name, value);
  }, []);

  return (
    <FormValidationStatuses className={className} name={field.name}>
      <div>
        {label && (
          <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
        )}
        <AsyncSelect
          components={{
            MultiValue,
          }}
          isMulti={isMulti}
          onChange={onChange}
          placeholder={placeholder}
          value={field.value}
          options={options}
          styles={selectStyles}
          {...rest}
        />
      </div>
    </FormValidationStatuses>
  );
};

import React, { ReactNode } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Td = ({ children, className }: Props) => (
  <td className={cc([styles.td, className])}>{children}</td>
);

import { FloorsParams } from 'pages/LayoutPage/stores/FloorPlanStore';
import React from 'react';
import { FloorButton } from '../FloorButton';
import styles from './styles.module.css';

type Props = {
  floors: FloorsParams;
  activeFloor: number;
  onChange: (index: number) => (event: any) => void;
};

export const FloorList = ({ activeFloor, floors, onChange }: Props) => (
  <div className={styles.floorList}>
    {floors.map(([floorTitle, apartments, isVisible], index) => (
      <FloorButton
        key={floorTitle}
        onClick={onChange(index)}
        title={floorTitle}
        subTitle={apartments.length.toString()}
        isActive={activeFloor === index}
        isVisible={isVisible}
      />
    ))}
  </div>
);

import React from 'react';
import { observer } from 'mobx-react';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { ComponentToggleIsAuth } from 'components/containers/ComponentToggleIsAuth';
import { BaseLink } from 'components/atoms';
import { ProfileHeaderButton } from './components/ProfileHeaderButton';

export const ProfileButtonAndLoginMobileRender = () => {
  const { authUser } = rootProfileStore.authStore;

  return (
    <ComponentToggleIsAuth
      componentIsAuth={<ProfileHeaderButton user={authUser.user} />}
      componentIsNotAuth={
        <BaseLink fullWidth path="authPhoneCodePage" color="primary">
          Вход или регистрация
        </BaseLink>
      }
    />
  );
};
export const ProfileButtonAndLoginMobile = observer(
  ProfileButtonAndLoginMobileRender,
);

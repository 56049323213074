import { Badge, DateFormat } from 'components/atoms';
import React from 'react';
import ReactJoin from 'react-join';

import { MayBe } from '../../../../types';
import { Logo } from '../../../../services';

import { isNotNil } from '../../../../utils/fp';

import styles from './styles.module.css';

type Props = {
  is_active?: boolean;
  logo?: MayBe<Logo>;
  title?: MayBe<string>;
  end_at?: MayBe<string>;

  start_at?: MayBe<string>;
};

export const PromotionsHeader = ({
  title,
  start_at,
  end_at,
  is_active,
  logo,
}: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.leftContent}>
      {is_active && (
        <Badge size="medium" color="#20CB6A">
          активна
        </Badge>
      )}
      {!is_active && (
        <Badge size="medium" color="#DD6465">
          не активна
        </Badge>
      )}

      <h2 className={styles.title}>{title}</h2>
      <div className={styles.date}>
        <ReactJoin separator={<span>—</span>}>
          {[
            start_at ? (
              <DateFormat value={start_at} format="dateMonthYearShort" />
            ) : null,
            end_at ? (
              <DateFormat value={end_at || ''} format="dateMonthYearShort" />
            ) : null,
          ].filter(isNotNil)}
        </ReactJoin>
      </div>
    </div>

    <div className={styles.rightContent}>
      {logo?.url && (
        <img
          className={styles.image}
          src={logo?.url}
          alt={title || 'promotion preview'}
        />
      )}
    </div>
  </div>
);

import React, { memo, ReactNode } from 'react';
import { observer } from 'mobx-react';

import styles from './styles.module.css';
import { Cell } from '../../../../../../services';

type Props = {
  matrixItem: Cell;
  /** @desc кол-во колонок в предыдущей секции + 1 */
  rowOffset: number;
  colOffset: number;
  children?: ReactNode | ReactNode[];
};

export const SectionItemRender = ({
  matrixItem,
  colOffset,
  rowOffset,
  children,
}: Props) => (
  <div
    key={matrixItem.title}
    className={styles.wrapper}
    style={{
      gridRowStart: matrixItem.y + rowOffset,
      gridColumnStart: matrixItem.x + colOffset,
      gridRowEnd: matrixItem.y + rowOffset + matrixItem.offsetY,
      gridColumnEnd:
        matrixItem.x +
        colOffset +
        (matrixItem.offsetX < 1 ? 1 : matrixItem.offsetX),
    }}
  >
    {children}
  </div>
);

export const SectionItem = memo(observer(SectionItemRender));

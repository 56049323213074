import { cond, get, stubTrue } from 'lodash/fp';
import { ComplexRawInOffer } from './types';
import { isNotNil } from '../../../../utils/fp';
import { getStreetAndHome } from '../view/handlers';

export const getAddressFromOfferComplex = cond<
  ComplexRawInOffer,
  string | undefined | null
>([
  [
    (complex) => isNotNil(get(['addressDetail'], complex)),
    (complex) => getStreetAndHome(get(['addressDetail'], complex)),
  ],
  [
    (complex) => isNotNil(get(['address'], complex)),
    (complex) => get(['address'], complex),
  ],
  [stubTrue, () => undefined],
]);
